<template>
  <div class="row">
    <div>
      <input
        ref="input"
        class="form-control string optional mb-2"
        @input="handleInput"
        :placeholder="searchInputHint"
      />
      <div v-if="showSwitch">
        <a v-on:click.prevent="switchHandler" class="fw-bold" href="#">
          <span ref="linkText">{{ linkText() }}</span> <i class="far fa-hand-point-down"></i>
        </a>
        <span class="ms-4 text-muted hint">{{ hint }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { i18n } from '../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    shown: {
      required: true,
    },
    showSwitch: {
      type: Boolean,
      required: true,
    },
    favorite: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('searchInput', event.target.value)
    },
    linkText() {
      return this.favorite ? this.linkText1 : this.linkText2
    },
    switchHandler() {
      this.$emit('favoriteSet', !this.favorite)
    },
  },
  data() {
    return {
      linkText1: i18n.t('account.show_all_accounts.header'),
      linkText2: i18n.t('account.show_favourites'),
      hint: i18n.t('account.show_all_accounts.body'),
      searchInputHint: i18n.t('account.placeholder_guide'),
    }
  },
  watch: {
    shown: function (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          ;(this.$refs.input as HTMLInputElement).focus()
        })
      }
    },
  },
})
</script>
