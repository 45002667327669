<template>
  <div
    :id="id"
    :aria-labelledby="titleId"
    class="modal"
    role="dialog"
    tabindex="-1"
    aria-modal="true"
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="sizeClass" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" :id="titleId">
            <slot name="title">{{ title }}</slot>
          </h4>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            data-bs-dismiss="modal"
            :data-bs-target="`#${id}`"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <slot name="footer">
          <div v-if="showCancelButton" class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              aria-label="Close"
              data-bs-dismiss="modal"
              :data-bs-target="`#${id}`"
            >
              <font-awesome-icon :icon="['far', 'times']" />
              {{ cancelStr() }}
            </button>
            <slot name="buttons"></slot>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { i18n } from '../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    sizeClass: {
      type: String,
      default: '',
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    titleId() {
      return `${this.id}-title`
    },
    cancelStr() {
      return i18n.t('navigation.cancel')
    },
  },
})
</script>
