import { Controller } from '@hotwired/stimulus'
import { Tab } from 'bootstrap'

export default class extends Controller {
  connect() {
    let activeTab = localStorage.getItem('activeTab')

    if (activeTab) {
      Tab.getOrCreateInstance(document.querySelector('a[href="' + activeTab + '"]')).show()
    }
  }

  tabListener(element) {
    localStorage.setItem('activeTab', element.target.getAttribute('href'))
  }
}
