import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['label', 'input', 'filename']

  declare inputTarget: HTMLElement
  declare filenameTarget: HTMLElement

  connect() {
    this._handleFileChange = this._handleFileChange.bind(this)

    $(this.inputTarget).on('change', this._handleFileChange)

    if ((this.element as HTMLElement).dataset.filename) {
      this._setFileName((this.element as HTMLElement).dataset.filename)
    }
  }

  _handleFileChange() {
    let fileName = ($(this.inputTarget).val() as string).replace(/\\/g, '/').replace(/.*\//, '')
    this._setFileName(fileName)
  }

  _setFileName(fileName) {
    if (this.targets.has('filename')) {
      $(this.filenameTarget).text(fileName)
    }
  }
}
