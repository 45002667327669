import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputField']

  declare hasInputFieldTarget: boolean
  declare inputFieldTarget: HTMLInputElement

  enableField(event) {
    event.preventDefault()
    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.removeAttribute('readonly')
      this.inputFieldTarget.classList.remove('readonly')
    }
  }
}
