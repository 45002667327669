import { Controller } from '@hotwired/stimulus'
import { nextFrame } from '../../../rendering'
import $ from 'jquery'
import JsFormError from '../../../standalone/components/JsFormError'
import { i18n } from '../../../libraries/i18n'

export default class extends Controller {
  static targets = [
    'name',
    'isin',
    'listedInTheStockMarket',
    'foreignCompany',
    'exemptionMethod',
    'sameConcern',
    'longtermInvestment',
    'percentRangeOwned',
    'tradeAccount',
    'profitAccount',
    'lossAccount',
  ]
  static values = {
    accounts: { type: Object, default: {} },
  }

  declare listedInTheStockMarketTarget: HTMLInputElement
  declare foreignCompanyTarget: HTMLInputElement
  declare exemptionMethodTarget: HTMLInputElement
  declare sameConcernTarget: HTMLInputElement
  declare longtermInvestmentTarget: HTMLInputElement
  declare percentRangeOwnedTarget: HTMLInputElement
  declare tradeAccountTarget: HTMLInputElement
  declare profitAccountTarget: HTMLInputElement
  declare lossAccountTarget: HTMLInputElement
  declare accountsValue: object

  connect() {
    this._handleAjaxError = this._handleAjaxError.bind(this)
    nextFrame(() => this.showInputs())
  }

  showInput(show, ...inputs) {
    const klass = show ? 'block' : 'none'
    inputs.forEach((input) => {
      $(input).parent()[0].style.display = klass
      if (!show) input.value = ''
    })
  }

  showInputs() {
    this.showInput(true, this.listedInTheStockMarketTarget)
    this.showInput(this.listedInTheStockMarketTarget.value !== '', this.foreignCompanyTarget)
    this.showInput(this.foreignCompanyTarget.value === 'true', this.exemptionMethodTarget)
    if (this.listedInTheStockMarketTarget.value === '' || this.listedInTheStockMarketTarget.value === 'true') {
      this.showInput(false, this.percentRangeOwnedTarget, this.longtermInvestmentTarget, this.sameConcernTarget)
    } else {
      this.showInput(true, this.longtermInvestmentTarget)
      if (this.longtermInvestmentTarget.value === 'true') {
        this.showInput(true, this.percentRangeOwnedTarget)
        this.showInput(false, this.sameConcernTarget)
      } else if (this.longtermInvestmentTarget.value === 'false') {
        this.showInput(true, this.sameConcernTarget)
        this.showInput(false, this.percentRangeOwnedTarget)
      }
    }
    let listedInTheStockMarket = this.listedInTheStockMarketTarget.value === 'true'
    let foreignCompany = this.foreignCompanyTarget.value === 'true'
    let exemptionMethod = this.exemptionMethodTarget.value === 'true'
    let sameConcern = this.sameConcernTarget.value === 'true'
    let longtermInvestment = this.longtermInvestmentTarget.value === 'true'
    let percentRangeOwned = this.percentRangeOwnedTarget.value

    let chosenAccount = Object.keys(this.accountsValue).find(
      (key) =>
        this.accountsValue[key].listed_in_the_stock_market === listedInTheStockMarket &&
        this.accountsValue[key].foreign_company === foreignCompany &&
        (this.accountsValue[key].exemption_method || false) === exemptionMethod &&
        (this.accountsValue[key].same_concern || false) === sameConcern &&
        this.accountsValue[key].longterm_investment === longtermInvestment &&
        (this.accountsValue[key].percent_range_owned || '') === percentRangeOwned,
    )

    if (chosenAccount !== undefined) {
      this.tradeAccountTarget.innerText = this.accountText(chosenAccount)

      this.profitAccountTarget.innerText = this.accountText(this.accountsValue[chosenAccount].profit_account)
      this.lossAccountTarget.innerText = this.accountText(this.accountsValue[chosenAccount].loss_account)
    } else {
      this.tradeAccountTarget.innerText = null
      this.profitAccountTarget.innerText = null
      this.lossAccountTarget.innerText = null
    }
  }

  accountText(account) {
    return `${account} - ${i18n.t(`norwegian_account_plan.level_3.${account}.name`)}`
  }

  _handleAjaxError(event) {
    const data = event.detail[0]
    new JsFormError(data.model_name, data).updateForm()
  }
}
