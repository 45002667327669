import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../../util/rails_fetch'
import { BulkActionParams } from '../bulk_action_table_controller'
import { getModelHashIds } from '../../util/get_model_hash_ids'

export default class extends Controller {
  async merge(params: BulkActionParams) {
    const checkedRows = params.checkedRows
    const hashIds = getModelHashIds(checkedRows)

    const data = {
      inbox_id: hashIds,
    }

    await rails_fetch(`/${(window as any).current_company_id}/inbox/merge`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.text())
      .then((data) => {
        eval(data)
      })
  }

  async massDestroy(params: BulkActionParams) {
    const checkedRows = params.checkedRows
    const hashIds = getModelHashIds(checkedRows)

    const data = {
      inbox_id: hashIds,
    }

    await rails_fetch(`/${(window as any).current_company_id}/inbox/mass_destroy`, {
      method: 'DELETE',
      body: JSON.stringify(data),
    })
      .then((response) => response.text())
      .then((data) => {
        eval(data)
      })
  }
}
