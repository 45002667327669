<template>
  <div>
    <h5
      class="card-title"
      v-html="
        i18n().t('automatic_bank_reconciliation.v2.transactions_not_matched', {
          postings: this.postings.length,
          bank_statement_items: this.bankStatementItems.length,
        })
      "
    ></h5>
    <p class="card-text">{{ i18n().t('automatic_bank_reconciliation.v2.click_any_item') }}</p>
    <FeeAndInterestModal type="interest" :initialDate="this.bankReconciliation.beginningOn" />
    <FeeAndInterestModal type="fee" :initialDate="this.bankReconciliation.beginningOn" />
    <div class="button-row">
      <button
        class="btn btn-outline-secondary"
        data-bs-toggle="modal"
        data-bs-target="#interest-modal"
        id="interest-modal-button"
      >
        <font-awesome-icon :icon="['far', 'coins']" />
        {{ i18n().t('bank_reconciliation.interest.title') }}
      </button>

      <button
        class="btn btn-outline-secondary"
        data-bs-toggle="modal"
        data-bs-target="#fee-modal"
        id="fee-modal-button"
      >
        <font-awesome-icon :icon="['far', 'coins']" />
        {{ i18n().t('bank_reconciliation.fee.title') }}
      </button>
      <a :href="massMatchUrl" class="btn btn-outline-secondary" data-method="post">
        <font-awesome-icon :icon="['far', 'magic']" />
        {{ i18n().t('automatic_bank_reconciliation.mass_match') }}
      </a>

      <button class="btn btn-primary" disabled>
        <font-awesome-icon :icon="['far', 'handshake']" />
        {{ i18n().t('bank_reconciliation.reconcile') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../../store'
import FeeAndInterestModal from './FeeAndInterestModal.vue'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    i18n() {
      return i18n
    },
  },
  components: {
    FeeAndInterestModal,
  },
  computed: {
    massMatchUrl() {
      return this.bankReconciliation?.massMatchUrl
    },
    ...mapState(useBankReconciliationStore, ['postings', 'bankStatementItems', 'bankReconciliation']),
  },
})
</script>
