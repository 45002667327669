import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['focusReceiver']

  declare hasFocusReceiverTarget: boolean
  declare focusReceiverTarget: HTMLInputElement

  connect() {
    if (this.hasFocusReceiverTarget) {
      let focusReceiverTarget = this.focusReceiverTarget
      this.element.addEventListener('shown.bs.dropdown', function () {
        focusReceiverTarget.focus()
      })
    }
  }
}
