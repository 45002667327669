import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkedContent', 'uncheckedContent', 'source']
  declare checkedContentTargets: Array<HTMLElement>
  declare uncheckedContentTargets: Array<HTMLElement>
  declare hasCheckedContentTarget: boolean
  declare hasUncheckedContentTarget: boolean
  declare sourceTarget: HTMLInputElement

  connect() {
    this.flipContent()
  }

  flipContent() {
    if (this.sourceTarget.checked) {
      if (this.hasCheckedContentTarget) {
        this._changeElementsVisibility(this.checkedContentTargets, true)
      }
      if (this.hasUncheckedContentTarget) {
        this._changeElementsVisibility(this.uncheckedContentTargets, false)
      }
    } else {
      if (this.hasCheckedContentTarget) {
        this._changeElementsVisibility(this.checkedContentTargets, false)
      }
      if (this.hasUncheckedContentTarget) {
        this._changeElementsVisibility(this.uncheckedContentTargets, true)
      }
    }
  }

  _changeElementsVisibility(elementsArray, display) {
    elementsArray.forEach((element) => {
      if (display) {
        element.classList.remove('d-none')
      } else {
        element.classList.add('d-none')
      }
    })
  }
}
