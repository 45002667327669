import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../../../classes/parse_decimal'
import Decimal from 'decimal.js'

export default class extends Controller {
  static targets = [
    'stockSelect',
    'bankAccountSelect',
    'noPurchasesWarning',
    'tradeType',
    'tradePartTotal',
    'brokerageFee',
    'total',
    'openingInventoryElement',
    'openingInventoryInfo',
  ]

  declare stockSelectTarget: HTMLSelectElement
  declare bankAccountSelectTarget: HTMLSelectElement
  declare noPurchasesWarningTarget: HTMLElement
  declare tradeTypeTarget: HTMLSelectElement
  declare hasNoPurchasesWarningTarget: boolean
  declare brokerageFeeTarget: HTMLInputElement
  declare tradePartTotalTargets: Array<HTMLInputElement>
  declare totalTarget: HTMLElement
  declare openingInventoryElementTargets: HTMLElement[]
  declare openingInventoryInfoTarget: HTMLElement

  emptyStockId: any

  connect() {
    this.addNoPurchasesWarningListeners()
    this.hideopeningInventorySections()
  }

  handleStockCreate(event) {
    const { model } = event
    const option = new Option(model.name, model.id, true, true)
    this.stockSelectTarget.append(option)
    event.preventDefault()
  }

  handleBankAccountCreate(event) {
    const { model } = event
    const option = new Option(`${model.name}  - ${model.number}`, model.id, true, true)
    this.bankAccountSelectTarget.append(option)
    event.preventDefault()
  }

  calculateTotal() {
    let total = parseDecimal(this.brokerageFeeTarget.value, new Decimal(0))
    if (this.tradeTypeTarget.value == 'sale') total = total.times(new Decimal(-1))

    this.tradePartTotalTargets.forEach((part) => {
      total = total.plus(parseDecimal(part.value, new Decimal(0)))
    })
    this.totalTarget.innerText = new Intl.NumberFormat('nb-NO', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(total.toNumber())
      // Use regular minus instead of the special minus that Intl.NumberFormat seems like use
      .replaceAll('\u2212', '-')
  }

  addNoPurchasesWarningListeners() {
    // We have only checked the stock that was preselected, if stock change we can hide the message
    this.emptyStockId = this.stockSelectTarget.selectedOptions[0].value
    this.stockSelectTarget.addEventListener('change', (event) => {
      this._hideNoPurchasesWarning(!this._isEmptyStockSelected() || this._isPurchaseSelected())
    })
    this.tradeTypeTarget.addEventListener('change', (event) => {
      if (!this._isEmptyStockSelected()) this._hideNoPurchasesWarning(true)
      this._hideNoPurchasesWarning(this._isPurchaseSelected())
      this.hideopeningInventorySections()
    })
  }

  hideopeningInventorySections() {
    this.openingInventoryElementTargets.forEach((el) => {
      el.style.display = this.tradeTypeTarget.value == 'opening_inventory' ? 'none' : 'block'
    })
    this.openingInventoryInfoTarget.style.display = this.tradeTypeTarget.value == 'opening_inventory' ? 'block' : 'none'
  }

  _hideNoPurchasesWarning(condition) {
    if (this.hasNoPurchasesWarningTarget) {
      if (condition) {
        this.noPurchasesWarningTarget.hidden = true
      } else {
        this.noPurchasesWarningTarget.removeAttribute('hidden')
      }
    }
  }

  _isEmptyStockSelected() {
    return this.stockSelectTarget.selectedOptions[0].value === this.emptyStockId
  }

  _isPurchaseSelected() {
    return this.tradeTypeTarget.selectedOptions[0].value === 'purchase'
  }
}
