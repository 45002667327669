import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import { format } from 'date-fns'
import FlatpickrController from 'controllers/flatpickr_controller'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = [
    'accountingFrom',
    'accountingFromModalNotice',
    'accountingFromModalWarning',
    'accountingFromModalFinalizedReconciliationsWarning',
    'bankAccountStartDate',
  ]

  declare accountingFromTarget: HTMLInputElement
  declare accountingFromModalNoticeTarget: HTMLElement
  declare accountingFromModalWarningTarget: HTMLElement
  declare bankAccountStartDateTargets: HTMLElement[]
  declare accountingFromModalFinalizedReconciliationsWarningTarget: HTMLElement

  connect() {
    let modal = document.getElementById(this.accountingFromTarget.dataset.accountingFromWarningModalId)
    modal?.addEventListener('hidden.bs.modal', (event) => {
      event.preventDefault()

      this.revertAccountingFromField()

      const modalId = this.accountingFromTarget.dataset.accountingFromWarningModalId
      const modal = document.getElementById(modalId)
      Modal.getOrCreateInstance(modal).hide()
    })
  }

  showAccountingFromWarningModal() {
    const modalId = this.accountingFromTarget.dataset.accountingFromWarningModalId
    const modal = document.getElementById(modalId)

    if (modal != null) {
      const newAccountingStartDate = new Date(this.accountingFromTarget.value)
      newAccountingStartDate.setHours(0, 0, 0)
      const firstBankAccountOpenedOnDate = new Date(this.bankAccountStartDateTargets[0].innerHTML)
      firstBankAccountOpenedOnDate.setHours(0, 0, 0)

      if (firstBankAccountOpenedOnDate.getTime() >= newAccountingStartDate.getTime()) {
        return
      }

      const anyFinalizedReconciliations = this.accountingFromTarget.dataset.anyFinalizedReconciliations

      const formattedDate = format(newAccountingStartDate, i18n.t('date.formats.frontend.datefns.short'))

      this.accountingFromModalNoticeTarget.innerText = i18n.t(
        'company.settings.accounting_from_warning_modal.body.notice',
        { date: formattedDate },
      )
      this.accountingFromModalWarningTarget.innerText = i18n.t(
        'company.settings.accounting_from_warning_modal.body.warning',
        { date: formattedDate },
      )

      if (anyFinalizedReconciliations === 'true') {
        this.accountingFromModalFinalizedReconciliationsWarningTarget.innerText = i18n.t(
          'company.settings.accounting_from_warning_modal.body.finalized_reconciliations_warning',
          { date: formattedDate },
        )
      }

      this.bankAccountStartDateTargets.forEach((bankAccountStartDateElement) => {
        const bankAccountStartDate = new Date(bankAccountStartDateElement.innerHTML)
        bankAccountStartDate.setHours(0, 0, 0)

        if (bankAccountStartDate.getTime() >= newAccountingStartDate.getTime()) {
          bankAccountStartDateElement.parentElement.classList.add('d-none')
        } else {
          bankAccountStartDateElement.parentElement.classList.remove('d-none')
        }
      })

      Modal.getOrCreateInstance(modal).show()
    }
  }

  revertAccountingFromField() {
    const flatpickrController = this.application.getControllerForElementAndIdentifier(
      this.accountingFromTarget,
      'flatpickr',
      // We cast to unknown since FlatpickrController doesn't cover Controller
      // There might be a better way in Typescript, but we dont know how
      // In any case, it is a FlatpickrController that should be returned.
    ) as unknown as FlatpickrController

    const date = new Date(this.accountingFromTarget.dataset.accountingFrom)
    flatpickrController.fp.setDate(date, true)
  }
}
