import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = ['addPaymentButton']

  declare hasAddPaymentButtonTarget: boolean
  declare addPaymentButtonTarget: HTMLButtonElement

  connect() {
    this.hidePreview()
  }

  hidePreview() {
    if (window.innerWidth < 900) {
      this._dispatch('supplier-invoice--edit:hideSplitView')
    }
  }

  _dispatch(eventName, params = {}) {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.element.dispatchEvent(cancelEvent)
  }

  updateCurrencyValueOnPayableForm(event) {
    const selectedCurrency = event.target.value

    const element = document.querySelector('[data-payment-payable-source-currency-value]')
    if (element) {
      element.setAttribute('data-payment-payable-source-currency-value', selectedCurrency)
    }
  }

  disablePaymentButton() {
    if (this.hasAddPaymentButtonTarget) {
      this.addPaymentButtonTarget.classList.add('disabled')
    }
  }

  fillSupplierSelectPlaceholder() {
    let elem = document.querySelector('.select2-search__field') as HTMLInputElement
    elem.placeholder = i18n.t('supplier.search_all_suppliers')
  }
}
