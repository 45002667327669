import { Controller } from '@hotwired/stimulus'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  static values = {
    filename: String,
    contentType: String,
  }

  filenameValue: string
  contentTypeValue: string

  connect() {
    let content = (this.element as HTMLElement).innerText
    let blob = new Blob([content], { type: this.contentTypeValue })
    let link = document.createElement('a')
    link.download = this.filenameValue
    link.innerHTML = i18n.t('navigation.download')
    link.href = window.URL.createObjectURL(blob)
    this.element.parentNode.insertBefore(link, this.element)
  }
}
