/* Ensures we get nicer bootstrap errors */

import { i18n } from '../../libraries/i18n'

const ERROR_MESSAGE_CLASS = 'invalid-feedback'

function removeExistingMessage(form) {
  const existingMessages = form.getElementsByClassName(ERROR_MESSAGE_CLASS)
  for (let i = 0; i < existingMessages.length; i++) {
    let existingMessage = existingMessages[i]
    existingMessage.parentElement.removeChild(existingMessages[i])
  }
}

function onSubmit(event) {
  const form = event.target

  removeExistingMessage(form)
  form.classList.add('was-validated')

  if (form.checkValidity()) {
    return
  }

  event.preventDefault()
  event.stopPropagation()

  for (const element of form.elements) {
    if (!element.willValidate || element.validity.valid) {
      continue
    }

    let message = element.validationMessage
    if (element.validity.valueMissing) {
      message = i18n.t('html5_validation.value_missing')
    } else if (element.validity.typeMismatch) {
      message = i18n.t('html5_validation.type_mismatch')
    }

    const div = document.createElement('div')
    div.appendChild(document.createTextNode(message))
    div.classList.add(ERROR_MESSAGE_CLASS)
    element.parentNode.insertBefore(div, element.nextSibling)
  }
}

document.addEventListener('turbo:load', function () {
  const forms = document.getElementsByClassName('needs-validation')
  for (const form of forms as any) {
    form.noValidate = true
    form.addEventListener('submit', onSubmit, false)
  }
})
