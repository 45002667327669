import { Controller } from '@hotwired/stimulus'
import Flatpickr from 'stimulus-flatpickr'
import $ from 'jquery'
import { Modal } from 'bootstrap'
import { parseDecimal } from '../../../classes/parse_decimal'
import Decimal from 'decimal.js'
import { amountStr } from '../../formatting_helper'
import { i18n } from '../../../libraries/i18n'

interface BalanceToTaxGroup {
  default: string
  allowed: Array<string>
}

export default class extends Controller {
  static values = { balanceAccountToTaxGroup: String, taxGroups: String }

  declare balanceAccountToTaxGroupValue: string
  declare taxGroupsValue: string

  static targets = [
    'purchasePrice',
    'accountingValue',
    'advancedData',
    'purchaseDate',
    'startDate',
    'startYear',
    'depreciationType',
    'depreciationRate',
    'depreciationRateWrapper',
    'balanceAccount',
    'depreciationAccount',
    'taxGroupSelector',
    'taxRate',
    'taxGroupDetails',
    'descriptionLinear',
    'descriptionPercent',
    'descriptionStartYearDate',
    'descriptionStartDate',
    'descriptionDuration',
    'descriptionPercentStr',
    'descriptionEndDate',
    'durationValue',
    'durationUnits',
    'durationWrapper',
    'descriptionAmount',
    'wreckValue',
    'submitButton',
    'postingId',
  ]

  declare purchasePriceTarget: HTMLInputElement
  declare accountingValueTarget: HTMLInputElement
  declare advancedDataTarget: HTMLInputElement
  declare purchaseDateTarget: HTMLInputElement
  declare startDateTarget: HTMLInputElement
  declare startYearTarget: HTMLInputElement
  declare depreciationTypeTarget: HTMLSelectElement
  declare depreciationRateTarget: HTMLInputElement
  declare depreciationRateWrapperTarget: HTMLInputElement
  declare balanceAccountTarget: HTMLInputElement
  declare depreciationAccountTarget: HTMLInputElement
  declare taxGroupSelectorTarget: HTMLSelectElement
  declare taxGroupDetailsTarget: HTMLElement
  declare taxRateTarget: HTMLInputElement
  declare descriptionLinearTarget: HTMLElement
  declare descriptionPercentTarget: HTMLElement
  declare descriptionStartDateTarget: HTMLElement
  declare descriptionStartYearDateTarget: HTMLElement
  declare descriptionDurationTarget: HTMLElement
  declare descriptionPercentStrTarget: HTMLElement
  declare descriptionEndDateTargets: Array<HTMLElement>
  declare durationValueTarget: HTMLInputElement
  declare durationUnitsTarget: HTMLSelectElement
  declare durationWrapperTarget: HTMLSelectElement
  declare descriptionAmountTarget: HTMLElement
  declare wreckValueTarget: HTMLInputElement
  declare submitButtonTarget: HTMLButtonElement
  declare postingIdTarget: HTMLInputElement

  year: number

  connect() {
    if (this.postingIdTarget.value !== '') {
      this.updateTaxRate()
      Modal.getOrCreateInstance(document.querySelector('#purchase-asset-new-modal')).show()
    }
    this.toggleAccountingDescription()
    this.addEventListenersForInvoiceAssetButtons()
  }

  addEventListenersForInvoiceAssetButtons() {
    document.querySelectorAll('[data-bs-target="#purchase-asset-new-modal"]').forEach((buttonElement) => {
      buttonElement.addEventListener('click', (event) => {
        const button = event.target as HTMLButtonElement
        if (button.dataset.fillNewAssetData === 'true') {
          this.fillNewAssetForm(event)
        }
      })
    })
  }

  fillNewAssetForm(event) {
    const button = event.target
    const flatpickrController = this.application.getControllerForElementAndIdentifier(
      this.purchaseDateTarget,
      'flatpickr',
    ) as Flatpickr
    if (button.dataset.posting === 'true') {
      const date = new Date(button.dataset.purchaseDate)
      this.purchasePriceTarget.value = button.dataset.purchasePrice
      flatpickrController.fp.setDate(date)
      let option = this.balanceAccountTarget.querySelector("option[value='" + button.dataset.balanceAccount + "']")
      if (option !== null) {
        $(this.balanceAccountTarget).val(button.dataset.balanceAccount).trigger('change')
      } else {
        this.balanceAccountTarget.dispatchEvent(
          Object.assign(new Event('set'), {
            id: button.dataset.balanceAccount,
            text: button.dataset.balanceAccountName,
          }),
        )
      }
      $(this.balanceAccountTarget).val(button.dataset.balanceAccount).trigger('change')
      this.postingIdTarget.value = button.dataset.postingId
      this.handleBalanceAccountChange()
    } else {
      flatpickrController.fp.clear()
      $(this.balanceAccountTarget).val(null).trigger('change')
      $(this.depreciationAccountTarget).val(null).trigger('change')
      this.postingIdTarget.value = ''
      this.purchasePriceTarget.value = ''
      this.depreciationAccountTarget.disabled = true
    }
    this.handlePurchaseDateChange()
    this.handlePurchasePriceChange()
  }

  handlePurchasePriceChange() {
    if (this.accountingValueTarget.getAttribute('data-modified') === 'false') {
      this.accountingValueTarget.value = this.purchasePriceTarget.value
      this.updateDepreciationAmount()
    }
    this.updateAdvancedDataVisibility()
  }

  handleAccountingValueChange() {
    if (this.accountingValueTarget.getAttribute('data-modified') === 'false') {
      this.accountingValueTarget.setAttribute('data-modified', 'true')
    }
    this.updateDepreciationAmount()
  }

  handleBalanceAccountChange() {
    this.updateAdvancedDataVisibility()
    this.depreciationAccountTarget.disabled = false
    this.updateTaxSelectBox()
    this.updateAccountingDepreciation()
  }

  handlePurchaseDateChange() {
    if (this.purchaseDateTarget.value !== '') {
      const date = new Date(this.purchaseDateTarget.value)
      this.year = date.getFullYear()
      this.startYearTarget.value = this.year.toString()

      this.updateAccountingStartDate(date)
      this.updateAdvancedDataVisibility()
      this.updateTaxSelectBox()
    }
  }

  updateAdvancedDataVisibility() {
    if (
      this.purchasePriceTarget.value !== '' &&
      this.purchaseDateTarget.value !== '' &&
      this.balanceAccountTarget.value !== '' &&
      this.depreciationAccountTarget.value !== ''
    ) {
      this.advancedDataTarget.classList.remove('d-none')
      this.submitButtonTarget.disabled = false
    } else {
      this.advancedDataTarget.classList.add('d-none')
      this.submitButtonTarget.disabled = true
    }
  }

  updateTaxSelectBox() {
    if (this.year === undefined || this.balanceAccountTarget.value == '') {
      return
    }

    let previouslySelectedOption = this.taxGroupSelectorTarget.value
    let balanceToTaxGroup = JSON.parse(this.balanceAccountToTaxGroupValue)[
      this.balanceAccountTarget.value
    ] as BalanceToTaxGroup
    let optionArrays = JSON.parse(this.taxGroupsValue)[this.year] as Array<Array<string>>
    if (optionArrays !== undefined) {
      optionArrays = optionArrays.filter((elem) => {
        return balanceToTaxGroup['allowed'].includes(elem[1])
      })
      let i,
        L = this.taxGroupSelectorTarget.options.length - 1
      for (i = L; i >= 0; i--) {
        this.taxGroupSelectorTarget.remove(i)
      }
      optionArrays.forEach((element) => {
        let option = document.createElement('option') as HTMLOptionElement
        option.text = element[0] as string
        option.value = element[1] as string
        option.dataset.rate = element[2]['data-rate']
        this.taxGroupSelectorTarget.add(option)
      })
      if (previouslySelectedOption) {
        this.taxGroupSelectorTarget.value
      } else {
        this.taxGroupSelectorTarget.value = balanceToTaxGroup['default']
      }
      this.updateTaxRate()
    }
  }

  updateAccountingDepreciation() {
    this.updateDepreciationType()
    // Linear description
    const date = new Date(this.startDateTarget.value)
    const durationValue = this.durationValueTarget.value === '' ? 0 : this.durationValueTarget.value
    const duration = `${durationValue} ${this.durationUnitsTarget.selectedOptions[0].innerText}`
    const startMonthYear = date.toLocaleDateString(i18n.locale, { month: 'long', year: 'numeric' })
    const endDate = this._calculateDepreciationEndDate(date)
    this.descriptionStartDateTarget.innerText = startMonthYear
    this.descriptionDurationTarget.innerText = duration
    this.descriptionEndDateTargets.forEach((element) => {
      element.innerText = endDate
    })

    // Percent description
    this.descriptionPercentStrTarget.innerText = this.depreciationRateTarget.value
    this.descriptionStartYearDateTarget.innerText = new Date(this.startDateTarget.value).getFullYear().toString()
  }

  toggleAccountingDescription() {
    if (this.depreciationTypeTarget.value == 'linear_depreciation') {
      this.descriptionLinearTarget.classList.remove('d-none')
      this.descriptionPercentTarget.classList.add('d-none')
      this.durationWrapperTarget.classList.remove('d-none')
      this.depreciationRateWrapperTarget.classList.add('d-none')
    } else {
      this.depreciationRateWrapperTarget.classList.remove('d-none')
      this.durationWrapperTarget.classList.add('d-none')
      this.descriptionLinearTarget.classList.add('d-none')
      this.descriptionPercentTarget.classList.remove('d-none')
    }
  }

  updateAccountingStartDate(date) {
    const flatpickrController = this.application.getControllerForElementAndIdentifier(
      this.startDateTarget,
      'flatpickr-month',
    ) as Flatpickr
    flatpickrController.fp.setDate(date)
    this.updateAccountingDepreciation()
  }

  updateTaxRate() {
    let selectedOption = this.taxGroupSelectorTarget.selectedOptions[0]
    let rate = ''
    if (selectedOption !== undefined) {
      rate = selectedOption.dataset.rate
    } else {
      rate = this.taxGroupSelectorTarget.options[0].dataset.rate
    }
    this.taxRateTarget.value = rate
    this.depreciationRateTarget.value = rate

    if (rate == 'null' || parseInt(rate) == 0) {
      this.taxGroupDetailsTarget.classList.add('d-none')
    } else {
      this.taxGroupDetailsTarget.classList.remove('d-none')
    }
  }

  updateDepreciationType() {
    let selectedOption = this.taxGroupSelectorTarget.selectedOptions[0].value
    let isImmaterial = selectedOption === 'immaterial_asset' || selectedOption === 'none'
    let type = isImmaterial ? 'linear_depreciation' : 'percent_depreciation'
    // Only switch if we have the option available.
    // At the moment only ENK have the option to use percent as depreciation method
    if (Array.prototype.slice.call(this.depreciationTypeTarget.options).find((el) => el.value == type)) {
      this.depreciationTypeTarget.value = type
      this.toggleAccountingDescription()
    }
  }

  _calculateDepreciationEndDate(date) {
    let endDate
    if (this.durationValueTarget.value === '') {
      endDate = date
    } else {
      if (this.durationUnitsTarget.value == 'year') {
        endDate = new Date(date.setFullYear(date.getFullYear() + parseInt(this.durationValueTarget.value)))
      } else {
        endDate = new Date(date.setMonth(date.getMonth() + parseInt(this.durationValueTarget.value)))
      }
    }
    return endDate.toLocaleDateString(i18n.locale, { month: 'long', year: 'numeric' })
  }

  updateDepreciationAmount() {
    let wreckAmount = new Decimal(0)
    let accountingAmount = parseDecimal(this.accountingValueTarget.value, new Decimal(0))
    let durationMonths = parseDecimal(this.durationValueTarget.value)
    let depreciationAmount
    if (this.wreckValueTarget.value !== '') {
      wreckAmount = parseDecimal(this.wreckValueTarget.value, new Decimal(0))
    }
    if (this.durationUnitsTarget.value == 'year') {
      durationMonths = durationMonths.times(12)
    }
    depreciationAmount = accountingAmount.minus(wreckAmount).div(durationMonths)
    const depreciationAmountText = amountStr(depreciationAmount)
    this.descriptionAmountTarget.innerText = depreciationAmountText
  }

  enableParent(event) {
    event.preventDefault()
    const inputElement = event.target.parentElement.parentElement.querySelector('input')
    inputElement.readOnly = false
    inputElement.dataset.modified = true
  }
}
