import { Controller } from '@hotwired/stimulus'
import { nextFrame } from '../rendering'

export default class extends Controller {
  click(e: Event) {
    e.preventDefault()
    if (this.element.getAttribute('data-nudge-target') !== null) {
      let target = document.querySelector(this.element.getAttribute('data-nudge-target'))
      target.classList.remove('attention-nudger')
      nextFrame(() => {
        target.classList.add('attention-nudger')
        target.scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    }
  }
}
