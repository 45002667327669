<template>
  <div v-show="showLines" class="bank-reconciliation__lines">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      style="position: absolute"
      v-bind:style="bankStatementsBounds"
    >
      <line v-for="line in bankLines" :key="line.id" :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style="position: absolute" v-bind:style="postingBounds">
      <line v-for="line in postingLines" :key="line.id" :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" />
    </svg>
  </div>
</template>
<script>
import { getAbsoluteBoundingRect, unionBoundingRectFromObject } from '../GeometricFunctions'
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { defineComponent, nextTick } from 'vue'

function boundingTopAndHeight(boundingRect, matchRect) {
  let bottom = Math.max(boundingRect.top + boundingRect.height, matchRect.top + matchRect.height)
  let top = Math.min(boundingRect.top, matchRect.top)
  return {
    top: top,
    height: bottom - top,
  }
}

function calcBankBounds(transactions) {
  if (Object.keys(transactions).length === 0) {
    return {}
  }
  let matchRect = calcMatchRect()
  let boundingRect = unionBoundingRectFromObject(transactions)
  let boundingRight = boundingRect.left + boundingRect.width
  const topAndHeight = boundingTopAndHeight(boundingRect, matchRect)

  return {
    top: topAndHeight.top,
    left: boundingRight - 1,
    width: matchRect.left - boundingRight + 1,
    height: topAndHeight.height,
  }
}

function calcPostingBounds(statements) {
  if (Object.keys(statements).length === 0) {
    return {}
  }
  let matchRect = calcMatchRect()
  let boundingRect = unionBoundingRectFromObject(statements)
  let matchRight = matchRect.left + matchRect.width
  const topAndHeight = boundingTopAndHeight(boundingRect, matchRect)

  return {
    top: topAndHeight.top,
    left: matchRight,
    width: boundingRect.left - matchRight + 1,
    height: topAndHeight.height,
  }
}

function calcMatchRect() {
  let matchCard = document.getElementById('bank-statement-match-card')
  let matchCardButton = matchCard.querySelector('button')
  return getAbsoluteBoundingRect(matchCardButton)
}

export default defineComponent({
  data() {
    return {
      showLines: false,
    }
  },
  watch: {
    showMatchButton(newVal) {
      // When showing the lines we have to wait until next update because othervise the "match" button is
      // not visible, and we will not get the correct rectangle bounds for it.
      if (newVal) {
        nextTick(() => {
          this.showLines = true
        })
      } else {
        this.showLines = false
      }
    },
  },

  computed: {
    ...mapState(useBankReconciliationStore, [
      'showMatchButton',
      'selectedBankTransactionCards',
      'selectedPostingCards',
    ]),
    bankStatementsBounds() {
      if (!this.showLines) {
        return null
      }
      return calcBankBounds(this.selectedBankTransactionCards)
    },
    postingBounds() {
      if (!this.showLines) {
        return null
      }
      return calcPostingBounds(this.selectedPostingCards)
    },

    bankLines() {
      if (!this.showLines || this.selectedBankTransactionCards.length === 0) {
        return null
      }

      let bounds = calcBankBounds(this.selectedBankTransactionCards)
      let matchRect = calcMatchRect()
      let maxY = Number.MIN_SAFE_INTEGER
      let minY = Number.MAX_SAFE_INTEGER
      // Bank statement lines
      let lines = Object.keys(this.selectedBankTransactionCards).map((index) => {
        let elem = this.selectedBankTransactionCards[index]
        let rect = getAbsoluteBoundingRect(elem)
        let midY = rect.top + rect.height / 2
        let y = midY - bounds.top
        maxY = Math.max(y, maxY)
        minY = Math.min(y, minY)

        return {
          id: index,
          x1: 0,
          x2: bounds.width / 2,
          y1: y,
          y2: y,
        }
      })
      // Match box line
      let y = matchRect.top + matchRect.height / 2 - bounds.top
      lines.push({
        id: 'match-line',
        x1: bounds.width / 2,
        x2: bounds.width,
        y1: y,
        y2: y,
      })
      maxY = Math.max(y, maxY)
      minY = Math.min(y, minY)

      // Vertical line
      lines.push({
        id: 'vertical',
        x1: bounds.width / 2,
        x2: bounds.width / 2,
        y1: maxY + 1,
        y2: minY - 1,
      })

      return lines
    },
    postingLines() {
      if (!this.showLines) {
        return null
      }

      let bounds = calcPostingBounds(this.selectedPostingCards)

      let matchRect = calcMatchRect()
      let maxY = Number.MIN_SAFE_INTEGER
      let minY = Number.MAX_SAFE_INTEGER
      // Posting statement lines
      let lines = Object.keys(this.selectedPostingCards).map((index) => {
        let elem = this.selectedPostingCards[index]
        let rect = getAbsoluteBoundingRect(elem)
        let midY = rect.top + rect.height / 2
        let y = midY - bounds.top
        maxY = Math.max(y, maxY)
        minY = Math.min(y, minY)

        return {
          id: index,
          x1: bounds.width / 2,
          x2: bounds.width,
          y1: y,
          y2: y,
        }
      })
      // Match box line
      let y = matchRect.top + matchRect.height / 2 - bounds.top
      lines.push({
        id: 'match-line',
        x1: 0,
        x2: bounds.width / 2,
        y1: y,
        y2: y,
      })
      maxY = Math.max(y, maxY)
      minY = Math.min(y, minY)

      // Vertical line
      lines.push({
        id: 'vertical',
        x1: bounds.width / 2,
        x2: bounds.width / 2,
        y1: maxY + 1,
        y2: minY - 1,
      })

      return lines
    },
  },
})
</script>
