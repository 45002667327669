import { Controller } from '@hotwired/stimulus'
import { ElementObserver } from '@stimulus/mutation-observers'
import JsFormError from '../../standalone/components/JsFormError'
import PartnerSelectController from '../partner/select_controller'
import $ from '../../libraries/jquery'
import { rails_fetch } from '../../util/rails_fetch'
import Rails from '@rails/ujs'
import { nextFrame } from '../../rendering'
import { parseDecimal } from '../../classes/parse_decimal'
import { i18n } from '../../libraries/i18n'

class SaveLineButtonObserver {
  controller: EditController

  constructor(controller) {
    this.controller = controller
  }

  matchElement(element) {
    return element.id == 'new-line-item-form'
  }

  matchElementsInTree(tree) {
    return []
  }

  elementMatched(element) {
    this.controller.updateSaveLineButtonLabel()
    this.controller.displayVatWarning()
  }
}

class EditController extends Controller {
  static targets = [
    'baseForm',
    'commentMirror',
    'comment',
    'customerLineItem',
    'addLineButton',
    'saveLineButton',
    'customerSelect',
    'sendMethodField',
    'vatSelect',
    'currency',
    'setAsDefaultSalesInvoiceBankAccountCheckbox',
    'paymentDays',
    'lineItemForm',
    'list',
    'totalSum',
    'vatWarning',
  ]

  declare listTarget: HTMLElement
  declare hasListTarget: boolean
  declare totalSumTarget: HTMLElement
  declare hasTotalSumTarget: boolean
  declare vatWarningTarget: HTMLElement
  declare hasVatWarningTarget: boolean
  declare customerLineItemTargets: HTMLElement[]
  declare hasSaveLineButtonTarget: boolean
  declare saveLineButtonTarget: HTMLButtonElement
  declare baseFormTarget: HTMLFormElement
  declare hasAddLineButtonTarget: boolean
  declare addLineButtonTarget: HTMLButtonElement
  declare hasLineItemFormTarget: boolean
  declare lineItemFormTarget: HTMLFormElement
  declare commentTarget: HTMLInputElement
  declare commentMirrorTarget: HTMLInputElement
  declare hasVatSelectTarget: boolean
  declare vatSelectTarget: HTMLSelectElement
  declare vatSelectTargets: HTMLSelectElement[]
  declare currencyTarget: HTMLElement
  declare hasCurrencyTarget: boolean
  declare customerSelectTarget: HTMLElement
  declare sendMethodFieldTarget: HTMLSelectElement
  declare hasSendMethodFieldTarget: boolean
  declare hasSetAsDefaultSalesInvoiceBankAccountCheckboxTarget: boolean
  declare setAsDefaultSalesInvoiceBankAccountCheckboxTarget: HTMLInputElement
  declare paymentDaysTarget: HTMLElement
  declare hasPaymentDaysTarget: boolean

  connect() {
    this.setupAutomaticInvoiceLineFormExpansion()
    this.hideSetAsDefaultPaymentCheckbox(true)
    this.showPaymentDaysField()
    this.observeTotalChange()

    this.addLineButtonTarget.addEventListener('click', () => {
      nextFrame(() => {
        this.displayVatWarning()
      })
    })
  }

  hideSendMethodField() {
    if (!this.hasSendMethodFieldTarget) {
      return
    }

    this.sendMethodFieldTarget.classList.add('d-none')
  }

  showSendMethodField() {
    if (!this.hasSendMethodFieldTarget) {
      return
    }

    this.sendMethodFieldTarget.classList.remove('d-none')
  }

  hideSetAsDefaultPaymentCheckbox(hide) {
    if (!this.hasSetAsDefaultSalesInvoiceBankAccountCheckboxTarget) {
      return
    }

    if (hide) {
      this.setAsDefaultSalesInvoiceBankAccountCheckboxTarget.parentElement.classList.add('d-none')
    } else {
      this.setAsDefaultSalesInvoiceBankAccountCheckboxTarget.parentElement.classList.remove('d-none')
    }

    this.setAsDefaultSalesInvoiceBankAccountCheckboxTarget.checked = false
  }

  showSetAsDefaultSalesInvoiceBankAccountCheckbox(event) {
    if (event.target.options[event.target.selectedIndex].getAttribute('selected_bank_account') === 'true') {
      this.hideSetAsDefaultPaymentCheckbox(true)
    } else {
      this.hideSetAsDefaultPaymentCheckbox(false)
    }
  }

  updateSaveLineButtonLabel() {
    let label

    if (this.customerLineItemTargets.length > 0) {
      label = (this.element as HTMLElement).dataset.labelWhenPresent
    } else {
      label = (this.element as HTMLElement).dataset.labelWhenEmpty
    }

    if (this.hasSaveLineButtonTarget) {
      this.saveLineButtonTarget.value = label
    }
  }

  handleSaveCustomerInvoiceLineItem() {
    this.updateCustomerInvoice()
  }

  updateCurrencySelect() {
    if (this.hasCurrencyTarget) {
      ;(this.currencyTarget as HTMLSelectElement).disabled = this.customerLineItemTargets.length !== 0
    }
  }

  saveLineItem() {
    if (!this.hasLineItemFormTarget) {
      return
    }

    Rails.fire(this.lineItemFormTarget, 'submit')
  }

  submitForm(event) {
    this._syncComment()
    this.baseFormTarget.submit()
    event.preventDefault()
  }

  updateAndSendInvoice(event) {
    const sendAndUpdate = document.createElement('input')
    sendAndUpdate.type = 'hidden'
    sendAndUpdate.name = 'send_and_update'
    sendAndUpdate.value = event.target.id
    this.baseFormTarget.appendChild(sendAndUpdate)

    this.submitForm(event)
  }

  setupAutomaticInvoiceLineFormExpansion() {
    new ElementObserver(this.element, new SaveLineButtonObserver(this)).start()

    // addLineButton isn't present when the invoice line item isn't expanded so
    // we need to check. Otherwise referring to addLineButtonTarget will raise
    // an exception.
    if (this.hasAddLineButtonTarget && this.customerLineItemTargets.length === 0) {
      this.addLineButtonTarget.click()
    }
  }

  showPaymentDaysField() {
    if (!this.hasPaymentDaysTarget || !this.hasTotalSumTarget) {
      return
    }

    if (parseDecimal(this.totalSumTarget.dataset.value).toNumber() < 0) {
      this.paymentDaysTarget.querySelector('input').value = '0'
      this.paymentDaysTarget.classList.add('d-none')
    } else {
      this.paymentDaysTarget.classList.remove('d-none')
    }
  }

  observeTotalChange() {
    if (!this.hasListTarget) {
      return
    }

    const targetNode = this.listTarget

    const config = { childList: true, subtree: true }

    const callback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.showPaymentDaysField()
        }
      }
    }

    const observer = new MutationObserver(callback)

    observer.observe(targetNode, config)
  }

  _syncComment() {
    if (this.targets.has('comment') && this.targets.has('commentMirror')) {
      this.commentTarget.value = this.commentMirrorTarget.value
    }
  }

  handlePartnerCreate(event) {
    const { partner } = event
    const controller = this.application.getControllerForElementAndIdentifier(
      this.customerSelectTarget,
      'partner--select',
    ) as PartnerSelectController
    controller.select(partner)
  }

  showSupportedSendMethods(partner) {
    if (!this.hasSendMethodFieldTarget) {
      return
    }

    this.showSendMethodField()

    let sendMethodField = this.sendMethodFieldTarget.querySelector('select')
    Array.from(sendMethodField.options).forEach((option) => {
      if (partner.send_methods.includes(option.value)) {
        option.classList.remove('d-none')
      } else {
        option.classList.add('d-none')
      }
    })
  }

  handlePartnerChange(event) {
    let { partner } = event

    this.showSupportedSendMethods(partner)
    this.displayVatWarning()
  }

  getDomestic() {
    if (this.customerSelectTarget.dataset['domestic'] === 'true') {
      return true
    } else if (this.customerSelectTarget.dataset['domestic'] === 'false') {
      return false
    } else {
      return undefined
    }
  }

  handleCurrencyChange() {
    this.updateCustomerInvoice()
  }

  async updateCustomerInvoice() {
    const data = new FormData(this.baseFormTarget)

    let response = await rails_fetch(this.baseFormTarget.action, {
      method: 'PATCH',
      body: data,
    })

    if (response.status != 200) {
      let data = await response.json()
      new JsFormError(data.model_name, data).updateForm()
    }
  }

  displayVatWarning() {
    if (!this.hasVatSelectTarget || !this.hasVatWarningTarget) {
      return
    }

    let domestic = this.getDomestic()

    this.hideVatWarning()

    if (domestic == undefined) {
      return
    }

    Array.from(this.vatSelectTargets).forEach((vatSelect) => {
      const selectedOption = vatSelect.selectedOptions[0]

      if (domestic) {
        if (selectedOption.getAttribute('international') === 'true') {
          this.showVatWarning(i18n.t('customer_invoice.domestic_customer_export_vat'))
          return
        }
      } else {
        if (selectedOption.getAttribute('international') === 'false') {
          this.showVatWarning(i18n.t('customer_invoice.foreign_customer_domestic_vat'))
          return
        }
      }
    })
  }

  showVatWarning(message) {
    this.vatWarningTarget.classList.remove('d-none')
    this.vatWarningTarget.innerHTML = message
  }

  hideVatWarning() {
    this.vatWarningTarget.classList.add('d-none')
  }
}

export default EditController
