import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../../classes/parse_decimal'
import Decimal from 'decimal.js'

export default class extends Controller {
  static targets = [
    'unitPriceExVat',
    'unitPriceIncVat',
    'vatCode',
    'hintExVat',
    'hintIncVat',
    'unitPriceIncVatCol',
    'unitPriceExVatCol',
  ]
  declare unitPriceExVatTarget: HTMLInputElement
  declare unitPriceIncVatTarget: HTMLInputElement
  declare vatCodeTarget: HTMLSelectElement
  declare hintExVatTarget: HTMLSpanElement
  declare hintIncVatTarget: HTMLSpanElement
  declare unitPriceIncVatColTarget: HTMLDivElement
  declare unitPriceExVatColTarget: HTMLDivElement
  declare hasVatCodeTarget: boolean

  connect() {
    this.updatePriceIncVatField()
  }

  switch(event) {
    event.preventDefault()

    if (this.unitPriceIncVatColTarget.classList.contains('d-none')) {
      this.unitPriceIncVatColTarget.classList.remove('d-none')
      this.unitPriceExVatColTarget.classList.add('d-none')
    } else {
      this.unitPriceIncVatColTarget.classList.add('d-none')
      this.unitPriceExVatColTarget.classList.remove('d-none')
    }
  }

  updatePriceExVatField() {
    let vatRate = new Decimal(0)
    if (this.hasVatCodeTarget) {
      vatRate = parseDecimal(this.vatCodeTarget.options[this.vatCodeTarget.selectedIndex].getAttribute('vat_code_rate'))
    }
    let priceIncVat = parseDecimal(this.unitPriceIncVatTarget.value, new Decimal(0))
    let priceExVat = parseDecimal(this.formatAmount(priceIncVat.div(vatRate.add(1))))

    this.unitPriceExVatTarget.value = this.formatAmount(priceExVat)
    this.hintExVatTarget.innerText = this.formatAmount(priceExVat)
    this.hintIncVatTarget.innerText = this.unitPriceIncVatTarget.value
  }

  updatePriceIncVatField() {
    let vatRate = new Decimal(0)
    if (this.hasVatCodeTarget) {
      vatRate = parseDecimal(this.vatCodeTarget.options[this.vatCodeTarget.selectedIndex].getAttribute('vat_code_rate'))
    }
    let priceExcVat = parseDecimal(this.unitPriceExVatTarget.value, new Decimal(0))
    let vat = parseDecimal(this.formatAmount(priceExcVat.mul(vatRate)))

    this.unitPriceIncVatTarget.value = this.formatAmount(priceExcVat.add(vat))
    this.hintIncVatTarget.innerText = this.formatAmount(priceExcVat.add(vat))
    this.hintExVatTarget.innerText = this.unitPriceExVatTarget.value
  }

  handleVatChange() {
    this.updatePriceIncVatField()

    let vatRate = new Decimal(0)
    if (this.hasVatCodeTarget) {
      vatRate = parseDecimal(this.vatCodeTarget.options[this.vatCodeTarget.selectedIndex].getAttribute('vat_code_rate'))

      if (vatRate.eq(0)) {
        this.unitPriceIncVatColTarget.classList.add('d-none')
        this.unitPriceExVatColTarget.classList.remove('d-none')
      }
    }
  }

  private formatAmount(amount: Decimal) {
    return (
      new Intl.NumberFormat('nb-NO', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .format(amount.toNumber())
        // Use regular minus instead of the special minus that Intl.NumberFormat seems like use
        .replaceAll('\u2212', '-')
    )
  }
}
