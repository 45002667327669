import { Controller } from '@hotwired/stimulus'

const EXTENSION_WHITELIST = ['jpg', 'jpeg', 'png']
let mouseClicked = false

function fileExtension(url: string): string {
  return url.split(/\?/)[0].split('.').pop().trim().toLowerCase()
}

function getImageType(url: string): string {
  let extension = fileExtension(url)
  if (!EXTENSION_WHITELIST.includes(extension)) return null

  if (extension === 'jpg') {
    return 'image/jpeg'
  } else {
    return `image/${extension}`
  }
}

export default class extends Controller {
  static targets = [
    'buttonRow',
    'separator',
    'left',
    'right',
    'splitView',
    'showButton',
    'preview',
    'forceWrap',
    'forceHide',
    'hidden',
  ]
  declare hasPreviewTarget: boolean
  declare hasHiddenTarget: boolean
  declare hasShowButtonTarget: boolean
  declare leftTarget: HTMLElement
  declare previewTarget: HTMLObjectElement | HTMLIFrameElement
  declare rightTarget: HTMLElement
  declare separatorTarget: HTMLElement
  declare showButtonTarget: HTMLElement
  declare splitViewTarget: HTMLElement
  declare forceWrapTargets: Array<HTMLElement>
  declare forceHideTargets: Array<HTMLElement>

  connect() {
    if (!this.hasPreviewTarget) {
      return
    }
    this.adjustFlashAlert()

    this.handleKeyDown = this.handleKeyDown.bind(this)
    document.addEventListener('keydown', this.handleKeyDown)
    if (!this.hasHiddenTarget) {
      this.show()
    } else {
      this.hide()
    }
  }

  show(): void {
    this.resetPreview()
    this.rightTarget.classList.remove('d-none')
    if (this.hasShowButtonTarget) {
      this.showButtonTarget.classList.add('d-none')
    }
    this.leftTarget.classList.add('split-view__left')
    this.leftTarget.style.width =
      this.separatorTarget.getBoundingClientRect().left - this.leftTarget.getBoundingClientRect().left + 'px'
    this.handleSmallLeftWidth()
  }

  hide(): void {
    this.rightTarget.classList.add('d-none')
    if (this.hasShowButtonTarget) {
      this.showButtonTarget.classList.remove('d-none')
    }
    this.leftTarget.classList.remove('split-view__left')
    this.leftTarget.style.width = ''
    this.handleSmallLeftWidth()
  }

  handleKeyDown(event): void {
    if (event.key === 'Escape') {
      this.toggle()
      event.preventDefault()
    }
  }

  toggle(): void {
    if (this.rightTarget.classList.contains('d-none')) {
      this.show()
    } else {
      this.hide()
    }
  }

  dragstart(): void {
    mouseClicked = true
    this.previewTarget.classList.add('split-view--prevent-events')
    this.leftTarget.classList.add('split-view--prevent-events')
    this.splitViewTarget.classList.add('split-view--prevent-marking')
  }

  dragend(): void {
    if (!this.hasPreviewTarget) {
      return
    }
    mouseClicked = false
    this.previewTarget.classList.remove('split-view--prevent-events')
    this.leftTarget.classList.remove('split-view--prevent-events')
    this.splitViewTarget.classList.remove('split-view--prevent-marking')
  }

  drag(event): void {
    if (mouseClicked) {
      let leftWidth = this.separatorTarget.getBoundingClientRect().left - this.leftTarget.getBoundingClientRect().left
      let rightWidth = window.innerWidth - event.clientX - this.separatorTarget.getBoundingClientRect().width * 1.5
      this.leftTarget.style.width = leftWidth + 'px'
      this.rightTarget.style.width = rightWidth + 'px'
      this.handleSmallLeftWidth()
    }
  }

  setPreviewType(): void {
    if (!this.hasPreviewTarget) {
      return
    }
    let imageType = getImageType(this.urlFromPreviewTarget())

    if (imageType) {
      this.previewTarget.setAttribute('type', imageType)
      this.previewTarget.classList.add('preview__image')
    } else if (this.isSafari()) {
      this.createSafariFriendlyIFrame()
    } else {
      this.previewTarget.setAttribute('type', 'application/pdf')
      this.previewTarget.classList.add('preview__pdf')
    }
  }

  handleSmallLeftWidth(): void {
    let leftWidth = this.separatorTarget.getBoundingClientRect().left - this.leftTarget.getBoundingClientRect().left
    let bootstrapMdWidth = parseFloat(
      getComputedStyle(this.leftTarget).getPropertyValue('--breakpoint-md').replace(/\D/g, ''),
    )

    if (leftWidth < bootstrapMdWidth && this.leftTarget.style.width) {
      for (let wrapTarget in this.forceWrapTargets) {
        this.forceWrapTargets[wrapTarget].classList.add('flex-column')
      }

      for (let targetToHide in this.forceHideTargets) {
        this.forceHideTargets[targetToHide].classList.add('d-none')
      }
    } else {
      for (let wrapTarget in this.forceWrapTargets) {
        this.forceWrapTargets[wrapTarget].classList.remove('flex-column')
      }

      for (let targetToHide in this.forceHideTargets) {
        this.forceHideTargets[targetToHide].classList.remove('d-none')
      }
    }
  }

  adjustFlashAlert(): void {
    const flashAlert = document.querySelector('#flash_alert')
    if (flashAlert) {
      this.leftTarget.insertBefore(flashAlert, this.leftTarget.firstChild)
    }
  }

  isSafari(): boolean {
    return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1
  }

  createSafariFriendlyIFrame(): void {
    let iframeSafari = document.createElement('iframe')
    iframeSafari.setAttribute('src', this.urlFromPreviewTarget())
    iframeSafari.classList.add('preview__pdf-safari')
    iframeSafari.setAttribute('data-split-view-target', 'preview')
    iframeSafari.setAttribute('data-preview-target', 'preview')
    this.previewTarget.parentNode.removeChild(this.previewTarget)
    let elementNow = document.getElementsByClassName('split-view__preview-wrapper')[0]
    elementNow.append(iframeSafari)
  }

  removeHighlights(): void {
    let highlightedElements = document.querySelectorAll('a.fw-bold[data-action="inbox#preview"]')
    Array.from(highlightedElements).forEach((highlightedElement) => {
      highlightedElement.classList.remove('fw-bold')
    })
  }

  resetPreview(): void {
    this.previewTarget.className = ''
    this.removeHighlights()
    this.hide()
    this.setPreviewType()
  }

  urlFromPreviewTarget(): string {
    let url: string
    if (this.previewTarget.nodeName === 'IFRAME') {
      url = (this.previewTarget as HTMLIFrameElement).src
    } else {
      url = (this.previewTarget as HTMLObjectElement).data
    }
    return url
  }
}
