import { Controller } from '@hotwired/stimulus'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  static targets = ['collapsibleRow']

  declare collapsibleRowTargets: Array<HTMLElement>

  connect() {
    const tables = new Set(this.collapsibleRowTargets.map((element) => element.closest('table')))
    tables.forEach((table) => {
      this.updateLastStaticRowClass(table, 'collapse')
    })
  }

  toggleRows(event: Event) {
    event.preventDefault()

    const button = event.target as HTMLButtonElement
    const collapseGroup = button.getAttribute('data-collapse-group')
    const table = this.findTable(collapseGroup)
    if (!table) return

    const toggleRows = table.querySelectorAll('tr[data-collapse-group]:not([data-collapse-group=""])')
    if (toggleRows.length === 0) return

    const action: 'expand' | 'collapse' = this.determineAction(toggleRows)
    this.toggleRowVisibility(toggleRows, action)
    this.updateButtonLabel(button, action)
    this.updateLastStaticRowClass(table, action)
  }

  findTable(collapseGroup: string | null): HTMLTableElement | null {
    return (
      this.collapsibleRowTargets
        .find((el) => el.getAttribute('data-collapse-group') === collapseGroup)
        ?.closest('table') || null
    )
  }

  determineAction(toggleRows: NodeListOf<Element>): 'expand' | 'collapse' {
    return toggleRows[0].classList.contains('d-none') ? 'expand' : 'collapse'
  }

  toggleRowVisibility(rows: NodeListOf<Element>, action: 'expand' | 'collapse') {
    rows.forEach((row) => {
      row.classList.toggle('d-none', action === 'collapse')
      row.classList.toggle('d-table-row', action === 'expand')
    })
  }

  updateButtonLabel(button: HTMLButtonElement, action: 'expand' | 'collapse') {
    if (button.dataset.hasOwnProperty('alternative')) return

    const label = action === 'expand' ? i18n.t('navigation.hide_all') : i18n.t('navigation.show_all')
    button.innerHTML = label
  }

  // Mark the last visible row in the table to apply the correct border styling
  updateLastStaticRowClass(table: HTMLTableElement, action: 'expand' | 'collapse') {
    const staticRows = table.querySelectorAll('tr:not([data-collapse-group]), tr[data-collapse-group=""]')
    if (staticRows.length > 0) {
      const lastStaticRow = staticRows[staticRows.length - 1] as HTMLTableRowElement
      lastStaticRow.classList.toggle('last-collapsable-table-row', action === 'collapse')
    }
  }
}
