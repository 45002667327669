export default class Charts {
  constructor() {}

  static formatTooltips(tooltipItem, data, axis) {
    let label = data.datasets[tooltipItem.datasetIndex].label || ''
    if (label) {
      label += ': '
    }
    label += Math.round((axis === 'X' ? tooltipItem.xLabel : tooltipItem.yLabel) / 100)
    return label
  }

  static formatAxis(value, index, values) {
    let number = value / 100
    // This works only for numbers without decimal part, cause otherwise it will use delimiter from Russian locale
    // Returns number in format '1 000 000'
    return number.toLocaleString('ru-RU')
  }
}
