import $ from 'jquery'
import { i18n } from '../../libraries/i18n'
/*
Example usage:

s2 = Select2Builder.new()
s2.createParams('/my_endpoint')
  {
    ajax: {
      url:
    }
  }
)

 */

export const DEFAULT_STATIC_PARMS = {
  theme: 'bootstrap',
  width: '100%',
  minimumInputLength: 3, // PS Minimum input length hides the default options
}

const DEFAULT_PARAMS = {
  /* This formats what is shown in the selection options */
  templateResult(repo) {
    // Don't modify the text on the loading indicator and group headers.
    if (repo.loading || repo.children) {
      return repo.text
    }
    if (repo.name) {
      return repo.name
    }
    if (repo.text) {
      return `${repo.text} (${i18n.t('navigation.new')})`
    }
  },
  /* How a selected result will look when clicked (or selected) */
  templateSelection(selected) {
    if (selected.text) {
      return selected.text
    }
    return selected.name
  },
}

export default class Select2Builder {
  constructor() {
    // Code below allow us to use select2 native actions for triggering actions in stimulus js
    // Original solution can be found by link https://gist.github.com/kaspermeyer/7fe28bb7c55c2810e7b5f3d5e67c1a44
    const delegate = function (eventName, { parameters }) {
      const handler = function (...args) {
        const data = {}
        for (let index = 0; index < parameters.length; index++) {
          const name = parameters[index]
          data[name] = args[index]
        }
        const delegatedEvent = new CustomEvent(`jquery:${eventName}`, {
          bubbles: true,
          cancelable: true,
          detail: data,
        })
        return data['event'].target.dispatchEvent(delegatedEvent)
      }
      return $(document).on(eventName, handler)
    }

    delegate('select2:select', { parameters: ['event'] })
    delegate('select2:open', { parameters: ['event'] })
  }

  createStaticParams(options = {}) {
    return Object.assign({}, DEFAULT_STATIC_PARMS, options)
  }

  // Some standard options are
  // placeholder
  createParams(url, options = {}) {
    return Object.assign(
      {},
      DEFAULT_STATIC_PARMS,
      DEFAULT_PARAMS,
      this.createTagOptions(),
      this.createAjaxOptions(url),
      options,
    )
  }

  createTagOptions(options = {}) {
    const defaultOptions = {
      tags: true,
      selectOnClose: true,
      insertTag: function (data, tag) {
        // Insert the tag at the end of the results
        data.push(tag)
      },

      createTag: function (params) {
        const term = $.trim(params.term)

        if (term === '') {
          return null
        }

        return {
          id: term,
          text: term,
          newTag: true,
        }
      },
    }
    return Object.assign({}, defaultOptions, options)
  }

  createAjaxOptions(url, options = {}) {
    const defaultOptions = {
      ajax: {
        url: url,
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            term: params.term,
            page: params.page,
          }
        },
        processResults(data, params) {
          //  parse the results into the format expected by Select2
          //  since we are using custom formatting functions we do not need to
          //  alter the remote JSON data, except to indicate that infinite
          //  scrolling can be used
          params.page = params.page || 1
          return {
            results: data,
            pagination: {
              more: params.page * 25 < data.total_count,
            },
          }
        },
        cache: true,
      },
    }
    return Object.assign({}, defaultOptions, options)
  }
}
