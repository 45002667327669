import TurbolinksAdapter from 'vue-turbolinks'
import './../libraries/fontawesome'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import Amount from './components/Amount.vue'
import BootstrapModal from './components/BootstrapModal.vue'

import bankReconciliationEdit from './bank_reconciliations/edit/index'
import accountSelector from './account_selector/index'
import foundCompanyShareCapital from './found_company/submissions/edit/index'

class VueApp extends HTMLElement {
  constructor() {
    super()
    let App = this.getApp(this.dataset.component)
    let data = JSON.parse(this.dataset.store)
    const app = App(data)

    app.component('font-awesome-icon', FontAwesomeIcon)
    app.component('font-awesome-layers', FontAwesomeLayers)
    app.component('amount', Amount)
    app.component('b-modal', BootstrapModal)

    app.use(TurbolinksAdapter)

    app.mount(this)
  }

  getApp(component) {
    let rootComponents = {
      bankReconciliationEdit: bankReconciliationEdit,
      foundCompanyShareCapital: foundCompanyShareCapital,
      accountSelector: accountSelector,
    }
    return rootComponents[component]
  }
}

window.customElements.define('vue-app', VueApp)
