export default class Sortable {
  constructor() {
    document.querySelectorAll('[data-order]').forEach((el) =>
      el.addEventListener('click', (event) => {
        const target = event.target as HTMLElement
        const sortable = target.closest('.sortable') as HTMLElement
        const form = document.querySelector('.sortable-form') as HTMLFormElement
        const orderInput = form.querySelector(sortable.dataset.target) as HTMLInputElement
        const resetInput = form.querySelector(sortable.dataset.reset) as HTMLInputElement

        orderInput.value = target.dataset.order
        if (resetInput) {
          resetInput.value = '1'
        }

        form.submit()
        event.preventDefault()
        return false
      }),
    )
  }
}
