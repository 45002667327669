<template>
  <b-modal
    :id="`match-invoice-modal-${transaction.id}`"
    :title="i18n().t(`bank_transaction.match_with_invoice`)"
    :showCancelButton="false"
    sizeClass="modal-xl modal-dialog-scrollable"
  >
    <div class="section-header">
      {{ i18n().t('bank_transaction.transaction_to_match') }}
    </div>
    <div class="badge badge-info w-100 text-start mt-2 mb-1">
      <h6 class="fw-bold">
        <div>
          {{ i18n().t('bank_transaction.description_text') }}
          <span v-html="description()" />
        </div>
      </h6>
      <h6 class="fw-bold">
        <div>
          {{ i18n().t('bank_transaction.payment_date') }}
          {{ date() }}
        </div>
      </h6>

      <h6 class="fw-bold">
        <div>
          {{ i18n().t('bank_transaction.amount_field') }}
          <amount :amountCents="transaction.amountCents" />
        </div>
      </h6>
    </div>
    <br />
    <div class="section-header mt-4">
      {{ i18n().t('bank_transaction.possible_matches.header') }}
    </div>
    <div></div>

    <div v-if="showUnpaid && !noMatches">
      {{ i18n().t('bank_transaction.possible_matches.body') }}
      <a href="#" v-on:click.stop="showAllInvoices" class="ps-0 pb-2">
        {{ i18n().t('bank_transaction.possible_matches.show_all_invoices') }}
      </a>
    </div>
    <div v-else-if="showUnpaid && noMatches">
      <div>
        <span v-html="i18n().t('bank_transaction.no_matches_top')" />
        <a href="#" v-on:click.stop="showAllInvoices">
          {{ i18n().t('bank_transaction.link') }}
        </a>
        {{ i18n().t('bank_transaction.no_matches_bottom') }}
        <a :href="newCustomerInvoice()" class="ps-0 pb-2">
          {{ i18n().t('bank_transaction.showing_all_invoices.set_as_paid') }}
        </a>
      </div>
    </div>
    <div v-else>
      <div>
        {{ i18n().t('bank_transaction.showing_all_invoices.body_top') }}

        <a href="#" v-on:click.stop="getCustomerInvoices" class="ps-0 pb-2">
          {{ i18n().t('bank_transaction.showing_all_invoices.link') }}
        </a>
        {{ i18n().t('bank_transaction.showing_all_invoices.body_bottom') }}
        <a :href="newCustomerInvoice()" class="ps-0 pb-2">
          {{ i18n().t('bank_transaction.showing_all_invoices.set_as_paid') }}
        </a>
      </div>
    </div>

    <template v-if="showUnpaid">
      <div v-html="unpaidCustomerInvoices" />
    </template>
    <template v-else-if="!showUnpaid">
      <br />
      <div v-html="allCustomerInvoices" />
    </template>
    <template v-else>
      <div v-html="unpaidCustomerInvoices" />
    </template>
  </b-modal>
</template>
<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../../store'
import format from '../../../../../libraries/datefnt'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapState(useBankReconciliationStore, ['unpaidCustomerInvoices', 'bankReconciliation', 'allCustomerInvoices']),

    noMatches(): boolean {
      return this.showUnpaid ? !(this.unpaidCustomerInvoices?.length > 0) : !(this.allCustomerInvoices?.length > 0)
    },
  },
  data() {
    return {
      showUnpaid: true,
    }
  },
  props: {
    transaction: {
      type: Object,
    },
    stopFunction: {
      type: Function,
    },
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, {
      fetchUnpaidCustomerInvoices: 'fetchUnpaidCustomerInvoices',
      fetchAllCustomerInvoices: 'fetchAllCustomerInvoices',
    }),
    newCustomerInvoice() {
      return `/${this.bankReconciliation.companyId}/customer_invoices/new`
    },

    date() {
      return this.transaction.transactionOn
        ? format(this.transaction.transactionOn, i18n.t('date.formats.frontend.datefns.short'))
        : ''
    },

    description() {
      return this.transaction.description.replace(/\<br\>/g, ' ')
    },

    getCustomerInvoices(e) {
      e.preventDefault()

      this.fetchUnpaidCustomerInvoices(this.transaction.id)
      this.showUnpaid = true
    },

    showAllInvoices(e) {
      e.preventDefault()
      this.fetchAllCustomerInvoices(this.transaction.id)
      this.showUnpaid = false
    },
  },
})
</script>
