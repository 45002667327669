import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card']

  declare cardTarget: HTMLElement

  gdprClose(): void {
    this.hide()
  }

  gdprConsent(): void {
    document.cookie = 'gdpr_consent=yes; path=/'
    this.hide()
    location.reload()
  }

  gdprDissent(): void {
    document.cookie = 'gdpr_consent=no; path=/'
    this.hide()
  }

  hide(): void {
    this.cardTarget.classList.add('d-none')
  }
}
