import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'employees']

  declare employeesTargets: Array<HTMLInputElement>
  declare submitButtonTarget: HTMLButtonElement

  employeeTicked() {
    if (this.employeesTargets.some((input) => input.checked)) {
      this.submitButtonTarget.classList.remove('disabled')
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.classList.add('disabled')
      this.submitButtonTarget.disabled = true
    }
  }
}
