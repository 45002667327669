import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { polyFillEventSubmitter } from '../../polyfills'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [
    'customerEmail',
    'overWriteEmailCheckBox',
    'attachmentField',
    'attachmentItem',
    'attachmentsContainer',
  ]
  declare customerEmailTarget: HTMLInputElement
  declare overWriteEmailCheckBoxTarget: HTMLElement
  declare attachmentFieldTarget: HTMLInputElement
  declare attachmentItemTargets: HTMLElement[]
  declare attachmentsContainerTarget: HTMLElement
  declare hasAttachmentFieldTarget: boolean

  attachedFiles: File[]
  existingAttachments: string[]
  originalValue: string
  fileNames: string[]

  connect() {
    // Needed for Safari
    polyFillEventSubmitter()

    this.originalValue = this.customerEmailTarget.value

    if (this.hasAttachmentFieldTarget) {
      this.attachedFiles = []
      this.fileNames = []
      this.existingAttachments = this.getExistingAttachments()

      this.attachmentFieldTarget.addEventListener('change', this.listSelectedFiles.bind(this))
      this.attachmentFieldTarget.form.addEventListener('submit', this.handleSubmission.bind(this))
      for (let itemTarget of this.attachmentItemTargets) {
        itemTarget.querySelector('.btn-close').addEventListener('click', this.removeFile.bind(this))
      }
    }
    this.renderCheckBox()
  }

  renderCheckBox() {
    const $div = $(this.overWriteEmailCheckBoxTarget).closest('div')
    if (this.customerEmailTarget.value === this.originalValue) {
      $div.hide()
    } else {
      $div.show()
    }
  }

  listSelectedFiles() {
    let selectedFiles = Array.from(this.attachmentFieldTarget.files)
    selectedFiles.forEach((el: File, i) => {
      if (this.fileNames.includes(el.name)) {
        return
      }
      this.attachmentsContainerTarget.appendChild(this.addAttachmentItemRow(el))
    })
  }

  handleSubmission(event) {
    // Since we are filtering and adding files directly as FormData,
    // Make sure we exclude the files currently attached to the hidden file input as well.
    this.attachmentFieldTarget.removeAttribute('name')

    const attribute_name = document.getElementById('new_offer_form_object_send_form_object')
      ? 'offer_form_object_send_form_object'
      : 'customer_invoice_form_object_send_form_object'
    let form = document.getElementById(`new_${attribute_name}`) as HTMLFormElement

    if (form === null || (!this.attachedFiles.length && !this.existingAttachments.length)) {
      return
    }
    event.preventDefault()
    let send_method = event.submitter.value
    let fileFormData = new FormData(form)
    for (let attachedFile of this.attachedFiles) {
      fileFormData.append(`${attribute_name}[email_attachments][]`, attachedFile)
    }
    if (this.existingAttachments.length) {
      fileFormData.append(
        `${attribute_name}[existing_attachments][]`,
        // @ts-ignore
        this.existingAttachments,
      )
    }
    fileFormData.append('extra_attachments', 'true')
    fileFormData.append('send_method', send_method)

    if (
      send_method === 'ehf' &&
      this.attachedFiles.length > 0 &&
      !$('#customer-invoice-new-send-ehf-modal').hasClass('show')
    ) {
      document.querySelector('#customer-invoice-new-send-ehf-modal').addEventListener('hidden.bs.modal', function () {
        $(Rails.formEnableSelector).each(function () {
          Rails.enableElement(this)
        })
      })
      Modal.getOrCreateInstance(document.querySelector('#customer-invoice-new-send-ehf-modal')).show()
      return
    }

    let xhr = new XMLHttpRequest()
    xhr.open('POST', this.attachmentFieldTarget.form.action, true)
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // This part basically does the same as remote: true. We do it this way to be able to send a multipart form
          // And redirect correctly after.
          let script = document.createElement('script')
          script.text = xhr.responseText
          document.head.appendChild(script).parentNode.removeChild(script)
        } else {
          ;(document.getElementById(`new_${attribute_name}`).parentNode as HTMLElement).innerHTML = xhr.responseText
        }
      }
    }
    xhr.send(fileFormData)
  }

  removeFile(e) {
    let el = e.target.closest('.removable-list-item')
    if ('attachmentId' in el.dataset) {
      this.existingAttachments.splice(this.existingAttachments.indexOf(el.dataset.attachmentId), 1)
    } else {
      this.attachedFiles.splice(el.dataset.filesIndex, 1)
    }
    this.fileNames.splice(this.fileNames.indexOf(el.dataset.attachmentFilename), 1)
    el.parentNode.removeChild(el)
  }

  getExistingAttachments(): string[] {
    let existingEmailAttachments: string[] = []
    for (let item of this.attachmentItemTargets) {
      existingEmailAttachments.push(item.dataset.attachmentId)
    }
    return existingEmailAttachments
  }

  addAttachmentItemRow(file: File) {
    const filename = file.name

    let node = document.createElement('div')
    node.id = `attachment_item_${filename}`
    node.classList.add('removable-list-item')
    node.dataset.target = 'customer-invoices--send-email.attachmentItem'
    node.dataset.filesIndex = String(this.attachedFiles.length)
    node.dataset.attachmentFilename = filename

    this.fileNames.push(filename)
    this.attachedFiles.push(file)

    node.innerHTML = `<a href="${window.URL.createObjectURL(file)}" download="${filename}">${filename}</a>
                      <button class="btn-close" type="button" aria-label="Close"></button>`
    node.querySelector('.btn-close').addEventListener('click', this.removeFile.bind(this))
    return node
  }
}
