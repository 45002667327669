export default class KidValidator {
  validKidNumber(inputValue) {
    if (!this.containsOnlyValidChars(inputValue)) {
      return false
    }
    let kidNumber = inputValue
    let controlDigit = kidNumber.charAt(kidNumber.length - 1)
    if (controlDigit === '-') controlDigit = '10'
    let isMod11 = parseInt(controlDigit, 10) === this.mod11OfNumberWithControlDigit(kidNumber)
    let isLuhn = parseInt(controlDigit, 10) === this.luhnChecksumOfNumberWithControlDigit(kidNumber)
    return isMod11 || isLuhn
  }

  mod11OfNumberWithControlDigit(input) {
    let controlNumber = 2,
      sumForMod = 0,
      i

    for (i = input.length - 2; i >= 0; --i) {
      sumForMod += input.charAt(i) * controlNumber
      if (++controlNumber > 7) controlNumber = 2
    }
    let result = 11 - (sumForMod % 11)

    return result === 11 ? 0 : result
  }

  luhnChecksumOfNumberWithControlDigit(number) {
    let sum = 0
    for (let i = number.length - 2; i >= 0; i -= 2) {
      let dbl = (parseInt(number.charAt(i), 10) * 2).toString()
      sum += parseInt(dbl.charAt(0), 10) + parseInt(dbl.charAt(1) || '0', 10)
    }
    for (let i = number.length - 3; i >= 0; i -= 2) {
      sum += parseInt(number.charAt(i), 10)
    }
    let checkDigit = 10 - (sum % 10)
    return checkDigit === 10 ? 0 : checkDigit
  }

  containsOnlyValidChars(input) {
    return /^\d+(\d|-)$/.test(input)
  }
}
