<template>
  <div :class="{ required: required }">
    <label class="form-control-label date_picker float-start" :class="{ required: required }">
      {{ label }}<abbr v-if="required" title="required" />
    </label>
    <flat-pickr v-model="date" :config="config" class="form-control" @on-close="datePickerClosed" />
  </div>
</template>
<script>
import { english } from 'flatpickr/dist/l10n/default'
import { Norwegian } from 'flatpickr/dist/l10n/no'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { i18n } from '../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { flatPickr },
  props: {
    required: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    flatPickrConfig: {
      required: false,
      type: Object,
    },
    modelValue: null,
  },
  data() {
    return {
      date: this.modelValue,
      defaultConfig: {
        altFormat: i18n.t('date.formats.frontend.flatpickr.short'),
        altInput: true,
        allowInput: true,
        dateFormat: 'Y-m-d', // ISO8601 date
        locale: this.locale(),
      },
    }
  },
  emits: ['update:modelValue'],
  watch: {
    date(newDate, oldDate) {
      this.$emit('update:modelValue', newDate)
    },
  },

  computed: {
    config() {
      return { ...this.defaultConfig, ...this.flatPickrConfig }
    },
  },
  methods: {
    locale() {
      const locales = {
        nb: Norwegian,
        en: english,
      }
      return locales[I18n.locale]
    },
    // This ensures we set the date on "blur", ie when the user clicks outside the input and not only on
    // pressing enter which is flatpickrs unfortunate default.
    datePickerClosed: function (date, value, instance) {
      instance.setDate(instance.altInput.value, true, instance.config.altFormat)
    },
  },
})
</script>
