import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../../util/rails_fetch'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = ['activating', 'main', 'error']
  static values = { purchaseUrl: String, callbackUrl: String }

  declare purchaseUrlValue: string
  declare callbackUrlValue: string
  declare activatingTarget: HTMLElement
  declare mainTarget: HTMLElement
  declare errorTarget: HTMLElement

  async activate(event) {
    event.preventDefault()
    this.errorTarget.classList.add('d-none')
    this.mainTarget.classList.add('d-none')
    this.activatingTarget.classList.remove('d-none')

    try {
      let activateResponse = await rails_fetch(this.purchaseUrlValue, {
        method: 'POST',
      })

      if (activateResponse.status === 200) {
        let response = await rails_fetch(this.callbackUrlValue, {
          method: 'POST',
        })

        let result = await response.json()

        if (result.redirect) {
          window.location.href = result.redirect
        } else {
          this.showError(result.error ?? i18n.t('generic.error_message'))
        }
      } else {
        let result = await activateResponse.json()
        this.showError(result.error ?? i18n.t('generic.error_message'))
      }
    } catch (err) {
      this.showError(i18n.t('generic.error_message'))
      console.error(err)
    }
  }

  private showError(msg) {
    this.errorTarget.innerHTML = msg
    this.errorTarget.classList.remove('d-none')
    this.activatingTarget.classList.add('d-none')
    this.mainTarget.classList.remove('d-none')
  }
}
