<template>
  <form @submit.prevent="onSubmit" ref="form">
    <b-modal :id="id" :title="title" sizeClass="modal-lg">
      <SignatoryForm
        :key="signatory.hashid || '0'"
        :index="signatory.hashid || '0'"
        :signatory="signatory"
        @submit="updateSignatory"
        type="person"
        :ref="`signatoryForm${signatory.hashid || '0'}`"
      />
      <template v-slot:buttons>
        <button class="btn btn-primary" :id="`${id}-submit-button`" type="submit">
          {{ i18n().t(`found_company.share_capital.save`) }}
        </button>
      </template>
    </b-modal>
  </form>
</template>
<script lang="ts">
import DatePicker from './../../../../components/DatePicker.vue'
import AmountInput from './../../../../components/AmountInput.vue'
import FormInput from './../../../../components/FormInput.vue'
import JsFormError from './../../../../../standalone/components/JsFormError'
import SignatoryForm from './SignatoryForm.vue'
import { Modal } from 'bootstrap'
import { mapActions, mapState } from 'pinia'
import { useFoundCompanySubmissionStore } from '../store'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { FormInput, DatePicker, AmountInput, SignatoryForm },
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    owner: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      signatory: {
        hashid: this.owner?.signatories[0].hashid || '0',
        full_name: this.owner?.signatories[0].full_name || '',
        nnin: this.owner?.signatories[0].nnin || '',
        email: this.owner?.signatories[0].email || '',
        phone_number: this.owner?.signatories[0].phone_number || '',
        post_address_street_one: this.owner?.signatories[0].post_address_street_one || '',
        post_address_zip: this.owner?.signatories[0].post_address_zip || '',
      },
    }
  },
  computed: {
    ...mapState(useFoundCompanySubmissionStore, ['submission_id']),
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useFoundCompanySubmissionStore, {
      addOwner: 'addOwner',
      updateOwner: 'updateOwner',
    }),

    form(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement
    },
    async onSubmit() {
      let response
      if (this.owner === null || this.owner === undefined) {
        response = await this.addOwner({
          found_company_submission_id: this.submission_id,
          company_name: null,
          found_company_signatories_attributes: {
            '0': this.signatoryJson(),
          },
        })
      } else {
        let attributes = {}
        attributes[this.owner.signatories[0].hashid] = this.signatoryJson()
        response = await this.updateOwner({
          id: this.owner.id,
          company_name: null,
          found_company_submission_id: this.owner.found_company_submission_id,
          found_company_signatories_attributes: attributes,
        })
      }
      if (response.status === 200) {
        this.form().reset()
        Modal.getOrCreateInstance(document.querySelector(`#${this.id}`)).hide()
      } else {
        let data = await response.json()
        new JsFormError('found_company_owner', data)
      }
    },
    signatoryJson() {
      return {
        hashid: this.owner?.signatories[0].hashid.toString(),
        full_name: this.signatory.full_name,
        nnin: this.signatory.nnin,
        phone_number: this.signatory.phone_number,
        email: this.signatory.email,
        post_address_street_one: this.signatory.post_address_street_one,
        post_address_zip: this.signatory.post_address_zip,
      }
    },
    updateSignatory(payload) {
      this.signatory = payload.signatory
    },
  },
})
</script>
