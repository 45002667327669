<template>
  <b-modal
    id="transaction-data-not-finalized-modal"
    sizeClass="modal-lg"
    :title="i18n().t(`bank_transaction.in_progress.header`)"
  >
    <div class="section-body">
      {{ i18n().t('bank_transaction.in_progress.body', { days: daysBeforeFinalization() }) }}
    </div>
    <template v-slot:buttons> </template>
  </b-modal>
</template>
<script lang="ts">
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapState(useBankReconciliationStore, ['bankReconciliation']),
  },
  methods: {
    i18n() {
      return i18n
    },
    daysBeforeFinalization() {
      if (this.bankReconciliation.daysBeforeFinalization == null) {
        return 0
      }

      return this.bankReconciliation.daysBeforeFinalization.toString().replace('.', ',')
    },
  },
})
</script>
