import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../util/rails_fetch'

export default class extends Controller {
  static targets = ['target']
  static values = { url: { type: String, default: '' } }

  declare urlValue: string
  declare targetTargets: Array<HTMLElement>

  connect() {
    if (this.urlValue !== '') {
      this._fetchNumbers()
    } else {
      this._removeTargetElements()
    }
  }

  async _fetchNumbers() {
    let response = await rails_fetch(this.urlValue)
    let data = await response.json()
    this.targetTargets.forEach((target) => {
      let number = data[target.dataset.counterKey]
      if (number > 0) {
        // This is a specific case for inbox items
        if (target.dataset.counterKey === 'inbox_items') {
          target.innerText = `(${number})`
        } else {
          target.innerText = `${number}`
          target.classList.remove('d-none')
        }
      } else {
        target.remove()
      }
    })
  }

  _removeTargetElements() {
    this.targetTargets.forEach((targetElement) => {
      targetElement.remove()
    })
  }
}
