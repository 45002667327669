import { Controller } from '@hotwired/stimulus'
import KidValidator from '../classes/kid_validator'

export default class extends Controller {
  static targets = ['warning', 'input']
  inputTarget: HTMLInputElement
  warningTarget: HTMLElement

  connect() {
    this.element.addEventListener('input', this.validate.bind(this))
  }

  validate() {
    let validator = new KidValidator()
    if (validator.validKidNumber(this.inputTarget.value)) {
      this.warningTarget.style.display = 'none'
    } else {
      this.warningTarget.style.display = 'block'
    }
  }
}
