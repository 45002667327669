import { Controller } from '@hotwired/stimulus'
import Select2Builder from '../../standalone/components/Select2Builder'
import $ from 'jquery'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = ['productSelect', 'newProduct', 'unitPrice', 'vatCode', 'description']

  declare private productSelectTarget: HTMLInputElement
  declare private hasNewProductTarget: boolean
  declare private newProductTarget: HTMLInputElement
  declare private unitPriceTarget: HTMLInputElement
  declare private hasVatCodeTarget: HTMLSelectElement
  declare private vatCodeTarget: HTMLSelectElement
  declare private descriptionTarget: HTMLInputElement

  connect() {
    const elem = $(this.productSelectTarget)
    const params = new Select2Builder().createParams(`/${(window as any).current_company_id}/products/search.json`, {
      minimumInputLength: 0,
      placeholder: i18n.t('simple_form.placeholders.customer_invoice_line_item.product'),
    })
    params.ajax.processResults = function (data, params) {
      const total_count = data.total_count

      if (!params.term && data.length > 0) {
        data = [
          {
            text: elem.data('last-used-header'),
            children: data,
          },
        ]
      }

      params.page = params.page || 1
      return {
        results: data,
        pagination: {
          more: params.page * 25 < total_count,
        },
      }
    }
    elem.select2(params)

    elem.on('select2:select', (event) => {
      const data = event.params.data as any

      if (data.newTag) {
        this.newProductTarget.value = 'true'
      } else {
        this.newProductTarget.value = 'false'
      }
      if (data.unit_price) {
        this.unitPriceTarget.value = data.unit_price
        this.updateVatCode(data.vat_code)

        this._dispatch('product--search:changeUnitPrice', { unit_price: data.unit_price })
      } else {
        this.unitPriceTarget.value = ''
      }
      if (data.description) {
        this.descriptionTarget.value = data.description
      }
    })
  }

  updateVatCode(productVatCode) {
    if (!this.hasVatCodeTarget) {
      return
    }

    const options = Array.from(this.vatCodeTarget.options)
    const desireOption = options.filter(function (option) {
      return option.value === productVatCode
    })[0]

    if (desireOption.disabled) {
      return
    }

    this.vatCodeTarget.value = productVatCode
  }

  _dispatch(eventName, params = {}) {
    const event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
    Object.assign(event, params)
    this.element.dispatchEvent(event)
  }
}
