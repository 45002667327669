import { i18n } from '../../libraries/i18n'

export default class SupplierInvoiceDeleteButton {
  targetNode

  constructor() {
    this.targetNode = document.getElementById('payment-block')
    this.observePaymentList()
  }

  observePaymentList() {
    const buttonWrapper = document.getElementById('delete-supplier-invoice-wrapper')
    const deleteButton = buttonWrapper?.querySelector('a[data-bs-toggle="modal"]')
    if (this.targetNode === null || deleteButton === null || buttonWrapper === null) {
      return
    }

    const callback = function (mutationsList, observer) {
      const paymentList = document.getElementById('payment-list')
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (paymentList === null || paymentList.childElementCount === 2) {
            buttonWrapper.removeAttribute('title')
            deleteButton.classList.remove('disabled')
          } else {
            buttonWrapper.setAttribute('title', i18n.t('supplier_invoice.tooltip.delete_button'))
            deleteButton.classList.add('disabled')
          }
        }
      }
    }
    const observer = new MutationObserver(callback)
    observer.observe(this.targetNode, { attributes: false, childList: true, subtree: true })
  }
}
