import './jquery'
import select2 from 'select2/dist/js/select2.full'
// import 'select2/dist/js/i18n/nb'

select2((window as any).jQuery)

// The following  code hard codes in Norwegian language as the default
// We have to do this since we were not able to get import 'select2/dist/js/i18n/nb' to work properly with Vite.
// We will need to fix this in the future, but one way might be when we drop
// select2 altogether for another option (like tom-select)
$.fn.select2.defaults.set('language', {
  errorLoading: function () {
    return 'Kunne ikke hente resultater.'
  },
  inputTooLong: function (args) {
    var overChars = args.input.length - args.maximum

    return 'Vennligst fjern ' + overChars + ' tegn'
  },
  inputTooShort: function (args) {
    var remainingChars = args.minimum - args.input.length

    return 'Vennligst skriv inn ' + remainingChars + ' tegn til'
  },
  loadingMore: function () {
    return 'Laster flere resultater…'
  },
  maximumSelected: function (args) {
    return 'Du kan velge maks ' + args.maximum + ' elementer'
  },
  noResults: function () {
    return 'Ingen treff'
  },
  searching: function () {
    return 'Søker…'
  },
  removeAllItems: function () {
    return 'Fjern alle elementer'
  },
})

// Select2 and Turbo don't play nicely together and creates double dropdowns
// when using the back button in the browser. See this StackOverflow discussion for details:
// https://stackoverflow.com/questions/36497723/select2-with-ajax-gets-initialized-several-times-with-rails-turbolinks-events
// NB: This code is not really testable, so before you decide to change it, make double sure it works in other places
// than the one you are currently working on. A couple of good places to use the back button are:
// * Supplier invoice filter (status and partner boxes)
// * New customer invoice and edit draft customer invoice
// * Supplier invoice
$(document).on('turbo:before-cache', function () {
  // This corrects the select2 to be duplicated when clicking the back button.
  $('.select2-hidden-accessible').select2('destroy')
})

// This fixes a bug where the search input in a select2 is not selected on open when using jQuery 3.6.0 and later
// See more info here:
// https://forums.select2.org/t/search-being-unfocused/1203/12
// If you want to check if this can be removed, check https://lucalabs.atlassian.net/browse/IN-8317 or just
// open the Supplier Invoice Index page and then click the supplier filter. You should be able to automatically
// start typing
$(document).on('select2:open', () => {
  ;(document.querySelector('.select2-container--open .select2-search__field') as HTMLElement).focus()
})
