import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  checkUserPresence(event) {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', `/customer_invitations/check_user_presence?email=${event.target.value}`, true)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const parsedResponse = JSON.parse(xhr.response)
          const hint = document.getElementById('email-hint')
          if (parsedResponse.companies === null) {
            hint.innerText = ''
          } else {
            hint.innerText = `${i18n.t('user.companies')}: ${parsedResponse.companies}`
          }
        }
      }
    }
    xhr.send()
  }
}
