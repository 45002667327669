import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['appliesTo', 'remark']

  declare hasAppliesToTarget: boolean
  declare appliesToTarget: HTMLSelectElement
  declare remarkTarget: HTMLSelectElement

  connect() {
    this.appliesToChanged()
  }

  appliesToChanged() {
    if (!this.hasAppliesToTarget) return

    let appliesToOption = this.appliesToTarget.selectedOptions[0]
    let appliesTo = appliesToOption.value === 'mva-melding' ? 'mva-melding' : 'mva kode'
    let allowedRemarks = JSON.parse(appliesToOption.dataset.allowedRemarks)
    Array.from(this.remarkTarget.options).forEach((option) => {
      let optionAppliesTo = option.dataset.appliesTo === undefined || option.dataset.appliesTo === appliesTo
      if (optionAppliesTo) {
        let disabled = !allowedRemarks.includes(option.value)
        option.disabled = disabled
        option.classList.toggle('d-none', disabled)
      } else {
        option.disabled = true
        option.classList.toggle('d-none', true)
      }
    })
    if (this.remarkTarget.selectedOptions[0].disabled) {
      this.remarkTarget.selectedIndex = Array.from(this.remarkTarget.options).findIndex((option) => !option.disabled)
      this.remarkTarget.dispatchEvent(new Event('change'))
    }
  }
}
