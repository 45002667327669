import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitWithModal', 'submitWithoutModal']

  declare submitWithModalTarget: HTMLElement
  declare hasSubmitWithModalTarget: boolean
  declare submitWithoutModalTarget: HTMLElement
  declare hasSubmitWithoutModalTarget: boolean

  handleChange() {
    if (this.hasSubmitWithModalTarget && this.hasSubmitWithoutModalTarget) {
      this.submitWithoutModalTarget.classList.add('d-none')
      this.submitWithModalTarget.classList.remove('d-none')
    }
  }
}
