import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect(): void {
    if (typeof (window as any).Luca.Controllers['CustomerInvoices'] === 'undefined') {
      ;(window as any).Luca.Controllers['CustomerInvoices'] = {}
    }
    ;(window as any).Luca.Controllers['CustomerInvoices'].SettledCardController = this
  }

  update(newSettledCard: string) {
    this.element.innerHTML = newSettledCard
  }
}
