import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import PartnerFormController from './form_controller'
import { Modal } from 'bootstrap'
import PartnerSearchController from './search_controller'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static values = {
    reloadAfterSubmit: Boolean,
    modalOpenButtonId: String,
    parentModalId: String,
    type: { type: String, default: 'customer' },
  }

  declare hasReloadAfterSubmitValue: boolean
  declare reloadAfterSubmitValue: boolean
  declare hasModalOpenButtonIdValue: boolean
  declare modalOpenButtonIdValue: string
  declare parentModalIdValue: string
  declare typeValue: String

  static targets = [
    'name',
    'organisationNumber',
    'addressStreet',
    'addressZip',
    'addressCity',
    'phoneNumber',
    'email',
    'formController',
    'modal',
    'hint',
    'radioButton',
    'name',
    'nameBlock',
    'searchInput',
    'searchCompanyField',
    'searchPersonField',
    'partnerSearchController',
    'warning',
    'manualModalOpenButton',
    'manualModalCancelButton',
    'searchModalCancelButton',
  ]

  declare searchInputTarget: HTMLInputElement
  declare organisationNumberTarget: HTMLElement
  declare hasOrganisationNumberTarget: Boolean
  declare nameTarget: HTMLElement
  declare hasNameTarget: Boolean
  declare addressStreetTarget: HTMLElement
  declare addressZipTarget: HTMLElement
  declare addressCityTarget: HTMLElement
  declare phoneNumberTarget: HTMLElement
  declare emailTarget: HTMLElement
  declare formControllerTargets: HTMLElement[]
  declare modalTarget: HTMLElement
  declare hintTargets: HTMLElement[]
  declare radioButtonTargets: HTMLElement[]
  declare nameBlockTarget: HTMLElement
  declare searchCompanyFieldTarget: HTMLElement
  declare searchPersonFieldTarget: HTMLElement
  declare warningTargets: HTMLElement[]
  declare manualModalOpenButtonTarget: HTMLButtonElement
  declare manualModalCancelButtonTarget: HTMLButtonElement
  declare searchModalCancelButtonTarget: HTMLButtonElement
  declare hasSearchModalCancelButtonTarget: boolean
  declare hasManualModalOpenButtonTarget: boolean
  declare hasManualModalCancelButtonTarget: boolean

  isPrivate: boolean
  timeout: NodeJS.Timeout

  initialize() {
    this.resetModal()

    super.initialize()
  }

  connect() {
    this.switchPrivate({ isPrivate: false })

    this.element.addEventListener('shown.bs.modal', () => {
      this._dispatch('partner--new-partner:open')

      if (this.isCustomer()) {
        this.element.addEventListener('shown.bs.modal', () => {
          this.handleIsPrivateChange(false)
        })

        if (this.hasModalOpenButtonIdValue) {
          document.getElementById(this.modalOpenButtonIdValue).addEventListener('click', () => {
            this.resetModal()
          })
        }

        if (this.hasSearchModalCancelButtonTarget && this.hasManualModalCancelButtonTarget) {
          Array<HTMLButtonElement>(this.manualModalCancelButtonTarget, this.searchModalCancelButtonTarget).forEach(
            (button) => {
              button.addEventListener('click', () => {
                this.resetModal()
              })
            },
          )
        }

        if (this.hasManualModalOpenButtonTarget) {
          Array<HTMLButtonElement>(this.manualModalOpenButtonTarget).forEach((button) => {
            button.addEventListener('click', () => {
              this.resetForm()
            })
          })
        }
      }
    })
  }

  handleIsPrivateChange(updateModal = true) {
    this.radioButtonTargets.forEach((radioButton) => {
      let input = radioButton.getElementsByTagName('input')[0]
      let label = radioButton.getElementsByTagName('label')[0]

      if (input.checked) {
        if (updateModal) {
          $(label).addClass('selected')
          $(this.nameBlockTarget).addClass('d-none')
          $(this.searchCompanyFieldTarget).removeClass('d-none')
          $(this.searchPersonFieldTarget).addClass('d-none')
        }

        this.switchToSearchingCorporations()
      } else {
        if (updateModal) {
          $(label).removeClass('selected')
          $(this.nameBlockTarget).removeClass('d-none')
          $(this.searchCompanyFieldTarget).addClass('d-none')
          $(this.searchPersonFieldTarget).removeClass('d-none')
        }

        this.switchToSearchingPrivate()
      }
    })
  }

  _dispatch(eventName, params = {}) {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.element.dispatchEvent(cancelEvent)
  }

  handleSuccessSubmit(event) {
    Modal.getOrCreateInstance(this.modalTarget.children[0]).hide()

    if (this.parentModalIdValue != '') {
      Modal.getOrCreateInstance(document.getElementById(this.parentModalIdValue)).show()
    }

    if (this.hasReloadAfterSubmitValue && this.reloadAfterSubmitValue) {
      document.location.reload()
    } else {
      this.resetModal()
    }
  }

  resetModal() {
    this.removeDuplicateWarnings()

    this.formControllerTargets.forEach((form) => {
      const partnerFormController = this.application.getControllerForElementAndIdentifier(
        form,
        'partner--form',
      ) as PartnerFormController
      if (partnerFormController) {
        partnerFormController.resetForm()
      }
    })

    $(this.searchInputTarget).val('')

    this.element.querySelector('#prefilled-block').classList.add('d-none')
    this.element.querySelector('#manual-modal').classList.remove('d-none')

    if (this.isCustomer()) {
      this.resetCustomerModal()
    }
  }

  switchPrivate(value) {
    this.formControllerTargets.forEach((form) => {
      const partnerFormController = this.application.getControllerForElementAndIdentifier(
        form,
        'partner--form',
      ) as PartnerFormController
      if (partnerFormController) {
        partnerFormController.switchPrivate(value.isPrivate)
      }
    })

    this.hintTargets.forEach(
      (hint) =>
        (hint.innerHTML = value.isPrivate
          ? i18n.t('partner.customer.manual_entry')
          : i18n.t('simple_form.hints.partner.manual_entry')),
    )
  }

  updatePrefilledBlock(partner) {
    $(this.searchInputTarget).val(partner.name)
    $(this.addressStreetTarget).text(partner.address_street)
    $(this.addressZipTarget).text(partner.address_zip)
    $(this.addressCityTarget).text(partner.address_city)
    if (this.hasOrganisationNumberTarget) {
      $(this.organisationNumberTarget).text(partner.organisation_number)
    } else if (this.hasNameTarget) {
      $(this.nameTarget).text(partner.name)
    }
    $(this.phoneNumberTarget).text(partner.phone_number || '-')
    $(this.emailTarget).text(partner.email || '-')
  }

  handlePartnerEditing(event) {
    let partner = event.partner
    this.updatePrefilledBlock(partner)
  }

  handleSearchFieldChange() {
    if (this.searchInputTarget.value.length === 0 && !this.isCustomer()) {
      this.resetModal()
    }
  }

  isCustomer() {
    return this.typeValue === 'customer'
  }

  handlePartnerChange(event) {
    const partner = event.partner

    this.updatePrefilledBlock(partner)

    this.element.querySelector('#prefilled-block').classList.remove('d-none')
    this.element.querySelector('#manual-modal').classList.add('d-none')

    this.formControllerTargets.forEach((form) => {
      const partnerFormController = this.application.getControllerForElementAndIdentifier(
        form,
        'partner--form',
      ) as PartnerFormController
      partnerFormController.handlePartnerChange(event)
    })

    if (this.isCustomer()) {
      $(this.nameBlockTarget).addClass('d-none')
      $(this.searchPersonFieldTarget).addClass('d-none')
      $(this.searchCompanyFieldTarget).addClass('d-none')
      $(this.radioButtonTargets[0] as HTMLElement)
        .parent()
        .addClass('d-none')
    }
  }

  handlePartnerNameChange(event) {
    this.formControllerTargets.forEach((form) => {
      const partnerFormController = this.application.getControllerForElementAndIdentifier(
        form,
        'partner--form',
      ) as PartnerFormController

      let partner = null
      if (this.isCustomer()) {
        partner = { partner: { name: this.nameBlockTarget.querySelector('input').value } }
      }
      partnerFormController.handlePartnerChange(partner || event)
    })
  }

  resetCustomerModal() {
    this.removeDuplicateWarnings()

    this.nameBlockTarget.querySelector('input').value = ''

    let radioButton1 = this.radioButtonTargets[0]
    let radioButton2 = this.radioButtonTargets[1]
    $(radioButton1.getElementsByTagName('label')[0]).addClass('selected')
    $(radioButton2.getElementsByTagName('label')[0]).removeClass('selected')
    radioButton1.getElementsByTagName('input')[0].checked = true
    this.switchToSearchingPrivate()

    $(this.radioButtonTargets[0] as HTMLElement)
      .parent()
      .removeClass('d-none')
    $(this.nameBlockTarget).removeClass('d-none')
    $(this.searchCompanyFieldTarget).addClass('d-none')
    $(this.searchPersonFieldTarget).removeClass('d-none')
  }

  resetForm() {
    this.removeDuplicateWarnings()

    this.formControllerTargets.forEach((form) => {
      const partnerFormController = this.application.getControllerForElementAndIdentifier(
        form,
        'partner--form',
      ) as PartnerFormController
      if (partnerFormController) {
        partnerFormController.resetForm()
        partnerFormController.setPartnerName(this.nameBlockTarget.querySelector('input').value)
      }
    })
  }

  switchToSearchingCorporations() {
    this.isPrivate = false

    const partnerSearchController = this.application.getControllerForElementAndIdentifier(
      this.searchInputTarget,
      'partner--search',
    ) as PartnerSearchController
    if (partnerSearchController) {
      partnerSearchController.switchToSearchingCorporations()
    }

    this.switchPrivate({ isPrivate: false })
  }

  switchToSearchingPrivate() {
    this.isPrivate = true

    const partnerSearchController = this.application.getControllerForElementAndIdentifier(
      this.searchInputTarget,
      'partner--search',
    ) as PartnerSearchController
    if (partnerSearchController) {
      partnerSearchController.switchToSearchingPrivate()
    }

    this.switchPrivate({ isPrivate: true })
  }

  removeDuplicateWarnings() {
    this.warningTargets.forEach((target) => target.classList.add('d-none'))
  }

  checkSimilar(event) {
    let name = event.target.value
    this.removeDuplicateWarnings()

    if (name === '') {
      return
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      $.ajax({
        type: 'GET',
        url: `/${(window as any).current_company_id}/partners/search.json`,
        data: { term: name, type: this.typeValue, similar: true },
        dataType: 'json',
        success: (result) => {
          if (result && result.length !== 0) {
            let names = result.map((e) => e.name).join(', ')
            this.warningTargets.forEach((target) => {
              console.log(this.typeValue)
              target.innerHTML = i18n.t(`${this.typeValue}.similar`, { names: names })
              target.classList.remove('d-none')
            })
          }
        },
      })
    }, 250)
  }
}
