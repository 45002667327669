import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card']

  declare cardTargets: HTMLFormElement[]

  connect() {
    if (this.cardTargets.length > 0) {
      let checkedCard = this.cardTargets.find((e) => e.querySelector('input').checked)
      if (checkedCard) {
        checkedCard.checked = true
        checkedCard.classList.add('border-primary', 'border-2')
      } else {
        this.cardTargets[0].checked = true
        this.cardTargets[0].classList.add('border-primary', 'border-2')
      }
    }
  }

  onChecked(event) {
    this.cardTargets.forEach((e) => e.classList.remove('border-primary', 'border-2'))
    event.target.parentElement.classList.add('border-primary', 'border-2')
  }
}
