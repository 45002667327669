import { Controller } from '@hotwired/stimulus'
import Typesense, { Client } from 'typesense'

export default class extends Controller {
  static targets = ['resultList', 'template']

  client: Client
  declare resultListTarget: HTMLElement
  declare templateTarget: HTMLTemplateElement

  CATEGORY_TO_NAME = { help_articles: 'Hjelp', account_descriptions: 'Kontoplan' }
  CATEGORY_TO_COLOUR = { help_articles: 'primary', account_descriptions: 'secondary' }

  connect() {
    this.hideResults()
  }

  initialize() {
    this.client = new Typesense.Client((window as any).typesenseConfig)
  }

  showResults() {
    this.resultListTarget.classList.remove('d-none')
  }

  hideResults() {
    this.resultListTarget.classList.add('d-none')
  }

  async search(event: Event) {
    const query = (event.target as HTMLInputElement).value

    if (query.length == 0) {
      this.hideResults()
    } else {
      this.showResults()
    }

    const collectionOrder = ['help_articles', 'account_descriptions']

    // See https://typesense.org/docs/0.21.0/api/documents.html#federated-multi-search for example params

    let collectionParameters = collectionOrder.map((collection) => {
      return {
        q: query,
        collection: collection,
      }
    })

    let searchParameters = {
      searches: collectionParameters,
    }

    let commonSearchParams = {
      query_by: 'title',
      per_page: 5,
    }

    // @ts-ignore
    const result = await this.client.multiSearch.perform(searchParameters, commonSearchParams)

    this.resultListTarget.innerHTML = ''

    result.results.forEach((categoryResult) => {
      // @ts-ignore
      let collectionName = categoryResult.request_params.collection_name
      // @ts-ignore
      let res = Math.min(5, categoryResult.found)
      for (let i = 0; i < res; i++) {
        // @ts-ignore
        let hit = categoryResult.hits[i]
        this.createSearchResult(collectionName, hit['document']['title'], hit['document']['path'])
      }
    })
  }

  createSearchResult(collectionName, title, path) {
    let newNode = this.templateTarget.content.firstChild.cloneNode(true) as HTMLElement
    this.resultListTarget.appendChild(newNode)
    newNode.querySelector('.search-title').innerHTML = title
    const categoryNode = newNode.querySelector('.search-category')
    categoryNode.innerHTML = this.CATEGORY_TO_NAME[collectionName]
    categoryNode.classList.add(`bg-${this.CATEGORY_TO_COLOUR[collectionName]}`)
    ;(newNode as HTMLAnchorElement).href = `https://www.lucaregnskap.no${path}`
  }
}
