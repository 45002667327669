import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  declare submitButtonTarget: HTMLButtonElement

  acceptTerms(event) {
    this.submitButtonTarget.disabled = !event.currentTarget.checked
  }
}
