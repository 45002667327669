<template>
  <div>
    <div class="page-title">
      <div class="page-title__main">
        {{ i18n().t('automatic_bank_reconciliation.page_header') }}
      </div>
      <div class="button-row">
        <a :href="backUrl" class="btn btn-outline-secondary">
          <font-awesome-icon :icon="['far', 'arrow-left']" />
          {{ i18n().t('navigation.back') }}
        </a>
        <div class="dropdown">
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ i18n().t('navigation.more') }}
          </button>
          <ul class="dropdown-menu">
            <li>
              <a :href="excelExportUrl" class="dropdown-item">
                <font-awesome-icon :icon="['far', 'upload']" class="fa-fw" />
                {{ i18n().t('export_data.button') }}
              </a>
            </li>
            <li>
              <a :href="swithToManualUrl" class="dropdown-item" data-method="patch">
                <font-awesome-icon :icon="['far', 'file-alt']" class="fa-fw" />
                {{ i18n().t('bank_reconciliation.change_type.change_to_manual') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="page-title__subtitle">
        {{ i18n().t('automatic_bank_reconciliation.page_description') }}
      </div>
    </div>
    <div class="section-header mt-4 mb-4">
      {{ bankAccountName }}
    </div>
    <div
      v-show="showDataInProgressAlert"
      class="alert alert-info mt-4 mb-4"
      v-html="i18n().t('bank_reconciliation.data_in_progress_alert', { time: finalDataTimeString })"
    ></div>
    <div
      v-show="showReconnectIntegrationNotification"
      class="alert alert-warning mt-4 mb-4"
      v-html="i18n().t('bank_reconciliation.reconnect_alert', { link: reconnectIntegrationLink })"
    ></div>
    <TransactionDataNotFinalizedModal />
    <TopCard />
    <StatementSummaryRow />
    <div v-if="matchItemsPresent">
      <div class="row mt-5">
        <div class="col-4">
          <BankStatementList />
        </div>
        <div class="col-4 position-relative">
          <MatchCard />
        </div>
        <div class="col-4">
          <PostingList />
        </div>
        <Lines />
      </div>
    </div>
    <MatchedSection />
  </div>
</template>

<script lang="ts">
import BankStatementList from './BankStatementList.vue'
import PostingList from './PostingList.vue'
import MatchCard from './MatchCard.vue'
import Lines from './Lines.vue'
import TopCard from './top_card/TopCard.vue'
import MatchedSection from './MatchedSection.vue'
import StatementSummaryRow from './StatementSummaryRow.vue'
import TransactionDataNotFinalizedModal from './TransactionDataNotFinalizedModal.vue'
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    i18n() {
      return i18n
    },
  },
  components: {
    TransactionDataNotFinalizedModal,
    StatementSummaryRow,
    BankStatementList,
    PostingList,
    MatchCard,
    Lines,
    MatchedSection,
    TopCard,
  },
  computed: {
    ...mapState(useBankReconciliationStore, [
      'monthFinished',
      'bankStatementItems',
      'bankReconciliation',
      'postings',
      'showDataInProgressAlert',
      'showReconnectIntegrationNotification',
      'reconnectIntegrationLink',
    ]),

    matchItemsPresent() {
      return this.postings.length !== 0 || this.bankStatementItems.length !== 0
    },
    excelExportUrl() {
      return this.bankReconciliation?.excelExportUrl
    },
    swithToManualUrl() {
      return this.bankReconciliation?.swithToManualUrl
    },
    backUrl() {
      return this.bankReconciliation?.backUrl
    },
    bankAccountName() {
      if (this.bankReconciliation) {
        return i18n.t('bank.account.bank_account', {
          account_info: this.bankReconciliation.bankAccountName,
        })
      } else {
        return ''
      }
    },
    finalDataTimeString: function (): string {
      if (this.bankReconciliation === null) {
        return ''
      } else {
        let time = this.bankReconciliation.finalizedTransactionsOn
        return time.toLocaleDateString(i18n.locale, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour12: false,
          hour: '2-digit',
          minute: 'numeric',
        })
      }
    },
  },
})
</script>
