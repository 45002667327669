import { i18n } from '../libraries/i18n'

export function amountStr(amount) {
  if (i18n.locale === 'en') {
    return i18n.numberToCurrency(amount, { unit: '', delimiter: ',' }).trim()
  } else if (i18n.locale === 'nb' || i18n.locale === 'pl') {
    return i18n.numberToCurrency(amount, { unit: '', delimiter: ' ' }).trim().replace('.', ',')
  } else {
    return i18n.numberToCurrency(amount, { unit: '', delimiter: ' ' }).trim().replace('.', ',')
  }
}
