import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../util/rails_fetch'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = { anchor: String, property: String, newValue: String }

  declare anchorValue: string
  declare propertyValue: string
  declare newValueValue: string

  static targets = ['action', 'alert']

  declare actionTarget: HTMLElement
  declare hasActionTarget: boolean
  declare alertTarget: HTMLElement
  declare hasAlertTarget: boolean

  async update() {
    const url = `/companies/${(window as any).current_company_id}`
    const data = {
      current_company_hashid: (window as any).current_company_id,
      company: {
        anchor: this.anchorValue,
        [this.propertyValue]: this.newValueValue,
      },
    }

    await rails_fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => this.onSuccess(data))
  }

  onSuccess(data) {
    if (this.hasAlertTarget) {
      this.alertTarget.classList.remove('d-none')

      if (this.hasActionTarget) {
        this.actionTarget.classList.add('d-none')
      }
    } else {
      window.location.reload()
    }
  }
}
