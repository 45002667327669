import Select2Builder from '../components/Select2Builder'
import $ from 'jquery'

export default class CustomerInvoiceLine {
  newLineFormElement
  buttonRow
  addLineLink
  totalRow
  addListItem
  editLineElement
  editForm
  cancelButton
  selectProductSearchField

  constructor(editId) {
    this.newLineFormElement = $('#new-line-item-form')
    this.buttonRow = $('#button-row')
    this.addLineLink = $('#add-line-link')
    this.totalRow = $('#total-line')
    this.addListItem = $('#add-list-item')

    if (editId) {
      this.editLineElement = $(`#customer-line-item-${editId}`)
      this.editForm = $(`#customer-line-item-edit-form-${editId}`)
    }
  }

  editLine(formHTML, editHashId) {
    this.editLineElement.hide()
    this.editForm = $(formHTML)
    this.editForm.hide()
    this.editLineElement.after(this.editForm)
    this.selectProductSearchField = $(`#product_search_field_${editHashId}`)
    this.editForm.slideDown()
    const cancelButton = document.getElementById('close-edit-line')
    if (cancelButton !== null) {
      cancelButton.addEventListener('click', this.cancelEditClick.bind(this))
    }
  }

  cancelEditClick(event) {
    event.preventDefault()
    this.editForm.slideUp()
    this.editLineElement.show()
  }

  updateLine(lineHtml) {
    this.editLineElement.replaceWith(lineHtml)
    this.editForm.slideUp(400, () => {
      this.editForm.remove()
      this.editLineElement.show()
    })
  }

  showNewLine(form) {
    this.newLineFormElement.hide()
    this.newLineFormElement.html(form)
    this.selectProductSearchField = $('#product_search_field_')
    this.newLineFormElement.show()

    this.cancelButton = document.getElementById('close-new-line')
    if (this.cancelButton !== null) {
      this.cancelButton.addEventListener('click', this.cancelClick.bind(this))
    }
    this.addLineLink.hide()
    this.buttonRow.hide()
  }

  cancelClick(event) {
    event.preventDefault()
    this.hideNewLine()
  }

  hideNewLine() {
    this.newLineFormElement.hide()
    this.addLineLink.show()
    this.buttonRow.show()
  }

  removeLine() {
    this.editLineElement.remove()
  }

  addLine(newLine) {
    this.hideNewLine()
    $(newLine).insertBefore(this.addListItem)
  }

  updateTotal(total) {
    this.totalRow.replaceWith(total)
  }

  updateButtonRow(buttonRow) {
    this.buttonRow.replaceWith(buttonRow)
  }
}
