import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['deleteName', 'confirmButton']

  declare deleteNameTarget: HTMLInputElement
  declare confirmButtonTarget: HTMLElement

  checkCompanyName() {
    if (this.deleteNameTarget.value === this.deleteNameTarget.dataset['name']) {
      this.confirmButtonTarget.classList.remove('disabled')
    } else {
      this.confirmButtonTarget.classList.add('disabled')
    }
  }

  handleConfirmClick() {
    this.confirmButtonTarget.classList.add('disabled')
  }
}
