import { Controller } from '@hotwired/stimulus'
import { isWithinInterval, max } from 'date-fns'

export default class extends Controller {
  static targets = [
    'select', // Select to filter out options
    'beginningOn', // Used in dateRange filter
    'endingOn', // Used in dateRange filter
  ]

  static values = { type: String }

  declare beginningOnTarget: HTMLInputElement
  declare endingOnTarget: HTMLInputElement
  declare selectTarget: HTMLSelectElement
  declare typeValue: string

  connect() {
    this.filter()
  }

  filter() {
    switch (this.typeValue) {
      case 'between':
        this.filterOnDateRangeBetween()
        break
      case 'dateRangeIntersect':
        this.filterOnDateRangeIntersect()
        break
    }
  }

  filterOnDateRangeIntersect() {
    for (let i = 0; i < this.selectTarget.options.length; i++) {
      let beginningOnDate = new Date(this.beginningOnTarget.value || new Date(0, 1, 1))
      let endingOnDate = new Date(this.endingOnTarget.value || new Date(9999, 12, 31))
      let optionBeginningOnDate = new Date(this.selectTarget.options[i].dataset.beginningOn || new Date(0, 1, 1))
      let optionEndingOnDate = new Date(this.selectTarget.options[i].dataset.endingOn || new Date(9999, 12, 31))

      if (beginningOnDate <= optionEndingOnDate && endingOnDate >= optionBeginningOnDate) {
        this.selectTarget.options[i].style.display = 'block'
      } else {
        this.selectTarget.options[i].style.display = 'none'
      }
    }
  }

  filterOnDateRangeBetween() {
    Array.from(this.selectTarget.options).forEach((option) => {
      let beginningOnDate = new Date(this.beginningOnTarget.value || new Date(0, 1, 1))
      let endingOnDate = new Date(this.endingOnTarget.value || max([new Date(), beginningOnDate]))
      let optionBeginningOnDate = new Date(option.dataset.beginningOn || new Date(0, 1, 1))
      let optionEndingOnDate = new Date(option.dataset.endingOn || new Date(9999, 12, 31))

      const interval = { start: optionBeginningOnDate, end: optionEndingOnDate }
      if (isWithinInterval(beginningOnDate, interval) && isWithinInterval(endingOnDate, interval)) {
        option.style.display = 'block'
      } else {
        option.style.display = 'none'
      }
    })
  }
}
