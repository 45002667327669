import OwnerService from '../../../submissions/edit/services/OwnerService'
import SubmissionService from '../../../submissions/edit/services/SubmissionService'
import { parseDecimal } from '../../../../../classes/parse_decimal'
import Decimal from 'decimal.js'
import { defineStore } from 'pinia'

export const useFoundCompanySubmissionStore = defineStore('foundCompanySubmission', {
  state: () => ({
    submission_id: '',
    total_share_capital_cents: 30_000_00,
    company_name: '',
    owners: [],
  }),
  getters: {
    totalAmountCents: (state) => {
      return state.owners.reduce((a, b) => a + b.share_capital_cents, 0)
    },
    totalPercent: (state) => {
      let total_cents = state.owners.reduce(
        (a, b) => parseDecimal(b.share_capital_cents.toString()).plus(a),
        new Decimal(0),
      )
      return total_cents.div(parseDecimal(state.total_share_capital_cents.toString())).times(100).toNumber()
    },
  },
  actions: {
    async setTotalShareCapitalCents(amountCents) {
      let response = await SubmissionService.update({
        found_company_submission: {
          total_share_capital_cents: amountCents,
          id: this.submission_id,
          current_page: 'share_capital',
        },
      })

      if (response.status == 200) {
        this.total_share_capital_cents = amountCents
        this.owners.forEach((owner) => {
          owner.share_capital_cents = amountCents * (owner.percent / 100)
          this.updateOwner(owner)
        })
      }

      return response
    },
    async addOwner(owner) {
      let responce = await OwnerService.create(owner)
      if (responce.status == 200) {
        let { owner } = await responce.json()
        this.owners.push(owner)
        this.updateOwners()
      }

      return responce
    },
    async updateOwner(owner) {
      let responce = await OwnerService.update(owner)
      if (responce.status == 200) {
        let index = this.owners.findIndex((obj, index) => obj.id === owner.id)
        let data = await responce.json()
        this.owners[index] = data.owner
      }

      return responce
    },
    async destroyOwner(owner) {
      const ans = await OwnerService.destroy(owner)
      if (ans.status === 200) {
        this.owners.splice(this.owners.indexOf(owner), 1)
        this.updateOwners()
      } else {
        // TODO: Handle errors
        let { errors } = await ans.json()
        console.log(errors)
      }
    },
    updateOwners() {
      let total_amount_cents = this.total_share_capital_cents
      let owner_count = this.owners.length
      this.owners.forEach((owner) => {
        owner.share_capital_cents = total_amount_cents / owner_count
        owner.percent = Number(((owner.share_capital_cents / total_amount_cents) * 100).toFixed(3))
        this.updateOwner(owner)
      })
    },
    async nextStep(payload) {
      let ans = await SubmissionService.update({ commit: 'commit', ...payload })
      if (ans.status == 200) {
        window.location.href = `/found_company/submissions/${payload.found_company_submission.id}/edit`
      }
    },
  },
})
