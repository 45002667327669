import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'

export default class extends Controller {
  initialize() {
    let popover = new Popover(this.element)
    if ((this.element as HTMLElement).dataset.show === 'true') {
      popover.show()
    }
  }

  hidePopovers() {
    Array.from(document.getElementsByClassName('popover')).forEach((el) => {
      Popover.getOrCreateInstance(el).hide()
    })
  }

  toggleToggleablePopovers() {
    this.hidePopovers()

    Array.from(document.querySelectorAll('.show-popover:not(.selected)')).forEach((el: HTMLElement) => {
      // There have been issues with collapse animations distorting the positioning of popovers.
      // To keep this from happening, we trigger a page scroll after the collapse animation is finished.
      // The scroll event repositions the popovers.
      setTimeout(function () {
        // We double check that we have not been selected the last 150ms, this can happen during testing
        if (!el.classList.contains('selected')) {
          let popover = new Popover(el)
          popover.show()
          window.scrollTo(window.scrollX, window.scrollY - 1)
          window.scrollTo(window.scrollX, window.scrollY + 1)
        }
      }, 150)
    })
  }
}
