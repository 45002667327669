import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['extraFields']

  declare extraFieldsTarget: HTMLInputElement

  toggleExtraFields(event: Event): void {
    const val = (event.target as HTMLInputElement).value
    if (val == 'yes') {
      this.extraFieldsTarget.classList.remove('d-none')
    } else {
      this.extraFieldsTarget.classList.add('d-none')
    }
  }
}
