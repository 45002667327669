import FoundCompanySubmissionApp from './components/App.vue'
import { useFoundCompanySubmissionStore } from './store'
import { createPinia } from 'pinia'
import { App, createApp } from 'vue'

export default (data): App<Element> => {
  const pinia = createPinia()

  const app = createApp(FoundCompanySubmissionApp)
  app.use(pinia)

  const store = useFoundCompanySubmissionStore()

  store.submission_id = data.submission.id
  store.total_share_capital_cents = data.submission.total_share_capital_cents
  store.company_name = data.submission.company_name
  store.owners = data.submission.owners

  store.owners.forEach((owner) => {
    owner.percent = Number(((owner.share_capital_cents / store.total_share_capital_cents) * 100).toFixed(3))
  })

  return app
}
