import { Controller } from '@hotwired/stimulus'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default class extends Controller {
  static values = { email: String }

  declare emailValue: string

  static targets = ['newEmail', 'password', 'alert']

  declare newEmailTarget: HTMLFormElement
  declare passwordTarget: HTMLInputElement
  declare alertTarget: HTMLElement

  originalInputValue: string

  connect() {
    this.originalInputValue = this.newEmailTarget.value
    this.passwordTarget.form.addEventListener('submit', (event) => {
      this.verify(event)
    })
  }

  verify(event: Event) {
    if (this.originalInputValue == this.newEmailTarget.value) {
      return
    }

    event.preventDefault()

    const auth = getAuth()
    signInWithEmailAndPassword(auth, this.emailValue, this.passwordTarget.value)
      .then(() => {
        this.passwordTarget.form.submit()
      })
      .catch(() => {
        this.showAlert()
        // This is a custom event that is triggered when ajax request is completed to unlock submit button
        this.fireAjaxCompleteEvent()
      })
  }

  showAlert() {
    this.alertTarget.classList.remove('d-none')
  }

  fireAjaxCompleteEvent() {
    const event = new CustomEvent('ajax:complete', { bubbles: true, cancelable: true })
    this.passwordTarget.form.dispatchEvent(event)
  }
}
