import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../../util/rails_fetch'

export default class extends Controller {
  static targets = ['bankAccount', 'period']

  declare bankAccountTarget: HTMLSelectElement
  declare periodTarget: HTMLInputElement

  async switchReconciliation() {
    let response = await rails_fetch(
      `/${(window as any).current_company_id}/bank_accounts/${
        this.bankAccountTarget.value
      }/bank_reconciliations/search?period=${this.periodTarget.value}`,
    )
    const { path } = await response.json()
    window.location.href = path
  }
}
