import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['payrunOn', 'checkbox']

  declare payrunOnTarget: HTMLInputElement
  declare originalValue: string
  declare checkboxTargets: Array<HTMLElement>

  connect() {
    this.originalValue = this.payrunOnTarget.value
    this.hidePayrunSubmit()
  }

  dateChange(event) {
    if (isNaN(Date.parse(event.target.value)) || event.target.value === this.originalValue) {
      return
    }

    let companyHashId = window.location.pathname.split('/')[1]
    window.location.href = `/${companyHashId}/salary/payruns/new?payrun_on=${event.target.value}`
  }

  hidePayrunSubmit() {
    if (this.checkboxTargets.some((checkbox) => (checkbox as HTMLInputElement).checked)) {
      $('#create-empty-payrun-button').addClass('d-none')
      $('#create-payrun-button').removeClass('d-none')
    } else {
      $('#create-payrun-button').addClass('d-none')
      $('#create-empty-payrun-button').removeClass('d-none')
    }
  }
}
