import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['showGetStartedLinksButton', 'hideGetStartedLinksButton', 'punchLine', 'getStartedCard']

  declare showGetStartedLinksButtonTarget: HTMLButtonElement
  declare hideGetStartedLinksButtonTarget: HTMLButtonElement
  declare punchLineTarget: HTMLElement
  declare getStartedCardTarget: HTMLElement
  declare hasGetStartedCardTarget: boolean
  declare hasShowGetStartedLinksButtonTarget: boolean
  declare hasHideGetStartedLinksButtonTarget: boolean
  declare hasPunchLineTarget: boolean

  connect() {
    if (localStorage.getItem('getStartedLinks') === null) {
      this.showGetStartedLinks()
    }
    const params = new URL((window as any).location).searchParams

    if (!this.hasGetStartedCardTarget) {
      this.hideGetStartedSection()
    }
  }

  hideGetStartedSection() {
    this.hideGetStartedLinks()
    if (this.hasHideGetStartedLinksButtonTarget) {
      this.hideGetStartedLinksButtonTarget.classList.add('d-none')
    }
  }

  showGetStartedLinks() {
    localStorage.removeItem('getStartedLinks')
    if (this.hasShowGetStartedLinksButtonTarget) {
      this.showGetStartedLinksButtonTarget.classList.remove('d-none')
    }
    if (this.hasHideGetStartedLinksButtonTarget) {
      this.hideGetStartedLinksButtonTarget.classList.add('d-none')
    }
    document.getElementById('get-started-links').classList.remove('d-none')
    if (this.hasPunchLineTarget) {
      this.punchLineTarget.classList.remove('d-none')
    }
  }

  hideGetStartedLinks() {
    localStorage.setItem('getStartedLinks', 'hidden')
    if (this.hasShowGetStartedLinksButtonTarget) {
      this.showGetStartedLinksButtonTarget.classList.add('d-none')
    }
    if (this.hasHideGetStartedLinksButtonTarget) {
      this.hideGetStartedLinksButtonTarget.classList.remove('d-none')
    }
    document.getElementById('get-started-links').classList.add('d-none')
    if (this.hasPunchLineTarget) {
      this.punchLineTarget.classList.add('d-none')
    }
  }
}
