import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../../../util/rails_fetch'
import JsFormError from '../../../standalone/components/JsFormError'

export default class extends Controller {
  static targets = ['preview', 'submit']

  declare previewTarget: HTMLElement
  declare submitTarget: HTMLButtonElement

  async preview(event) {
    event.preventDefault()

    const formData = new FormData()
    formData.append('sale_invoice_import[file]', event.target.files[0])

    const response = await rails_fetch(`/${(window as any).current_company_id}/sale/previews`, {
      method: 'POST',
      body: formData,
    })

    if (response.ok) {
      let data = await response.json()
      this.previewTarget.innerHTML = data.html
      this.submitTarget.disabled = false
    } else {
      let data = await response.json()
      new JsFormError('sale_invoice_import', data).updateForm()
    }
  }
}
