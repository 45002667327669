import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['target']

  declare targetTargets: Array<HTMLElement>

  toggle(e: Event) {
    e.preventDefault()

    this.targetTargets.forEach((target) => {
      if (target.classList.contains('d-none')) {
        target.classList.remove('d-none')
      } else {
        target.classList.add('d-none')
      }
    })
  }
}
