import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { i18n } from '../../libraries/i18n'

// IMPORTANT: You MUST define data-partner-type on the controlled element. Its
// value is passed as the value of the type query parameter to the search
// endpoint.
export default class extends Controller {
  $element

  static values = { allowClear: Boolean }

  declare allowClearValue: Boolean

  connect() {
    this.$element = $(this.element)

    const partnerType = this.$element.data('partner-type')
    if (!partnerType) {
      console.error('You must define data-partner-type on the controller element')
      return
    }

    let dropdownParent = this.element.closest('.modal') || document.body

    this.$element.select2({
      dropdownParent: dropdownParent,
      theme: 'bootstrap',
      width: '100%',
      allowClear: this.allowClearValue,
      minimumInputLength: 0,
      placeholder: i18n.t(['partner.existing_search_placeholder', partnerType]),

      templateResult: (item) => {
        // The loading indicator (Searching ...) is provided by Select2 which
        // stores the text under `text`. Partners returned by the server don't
        // have `text` and use `name` instead.
        if (item.text) {
          return item.text
        }

        if (item.name) {
          switch (item.type) {
            case 'PhoneSearch::PhoneSearchResult':
              return `${item.phone_number} - ${item.name} (${i18n.t([partnerType, 'phone_search'])})`
            case 'Partner':
              if (partnerType === 'customer') {
                return this._templateNewCustomer(item)
              } else {
                return item.name
              }
            case 'CompanyLookup':
              return `${item.name} (${i18n.t([partnerType, 'norwegian_company_lookup_search'])})`
            default:
              return `${item.name} (${i18n.t([partnerType, 'not_found_in_register'])})`
          }
        }

        throw 'item and text are both undefined'
      },

      // We need to try `text` first because this is the property that Select2
      // uses on dynamically-defined items. If we referenced `named` only then
      // the selection would be blank for items added progamatically.
      templateSelection: (selectedItem) => selectedItem.text || selectedItem.name,

      ajax: {
        url: `/${(window as any).current_company_id}/partners/search.json`,
        dataType: 'json',
        delay: 250,
        cache: true,
        data(params) {
          return {
            term: params.term,
            page: params.page,
            type: partnerType,
          }
        },
        processResults: (data, params) => {
          const total_count = data.total_count
          const lastUsedHeader = $(this.element).data('last-used-header')

          if (lastUsedHeader) {
            if (!params.term && data.length > 0) {
              data = [
                {
                  text: lastUsedHeader,
                  children: data,
                },
              ]
            }
          }

          //  parse the results into the format expected by Select2
          //  since we are using custom formatting functions we do not need to
          //  alter the remote JSON data, except to indicate that infinite
          //  scrolling can be used
          params.page = params.page || 1
          return {
            results: data,
            pagination: {
              more: params.page * 25 < total_count,
            },
          }
        },
      },
    })
    let eventName = 'partner--select:change'
    this.$element.on('change', (event) => {
      let partner = Object.assign({}, this.currentPartner, event.partner || {})

      this.updateSelectorData(partner)

      this._dispatch(eventName, { partner: partner })
    })
  }

  select(partner): void {
    const option = new Option(partner.name, partner.id, true, true)

    this.$element.append(option).trigger({
      type: 'change',
      partner: partner,
    })
  }

  get currentPartner() {
    const [currentPartner] = this.$element.select2('data')
    // An item is ALWAYS select even if it's blank. However, in that case the
    // attributes are going to be empty. If `id` is blank it means no partner
    // has been selected.
    if (!currentPartner.id) {
      return null
    }

    // Pre-existing selections use `text`. Selections made by the user use
    // `name`. See the comments near templateResult and templateSelection.
    return {
      id: currentPartner.id,
      name: currentPartner.text || currentPartner.name,
      country: currentPartner.country || '',
      domestic: currentPartner.domestic,
      send_methods: currentPartner.send_methods,
      default_debit_account_id: currentPartner.default_debit_account_id || null,
      default_debit_account_code_and_description: currentPartner.default_debit_account_code_and_description || null,
      default_purchase_type: currentPartner.default_purchase_type || null,
      default_vat_code: currentPartner.default_vat_code || null,
      available_vat_codes: currentPartner.available_vat_codes || null,
      bank_account_number: currentPartner.bank_account_number || null,
      supplier_currency: currentPartner.supplier_currency || 'NOK',
    }
  }

  updateSelectorData(partner) {
    this.$element.attr('data-country', partner.country || '')
    this.$element.attr('data-domestic', partner.domestic)
  }

  get isSelected(): boolean {
    return !!this.$element.val()
  }

  _dispatch(eventName, params = {}): void {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.element.dispatchEvent(cancelEvent)
  }

  _templateNewCustomer(item): JQuery<HTMLElement> | string {
    const leftSide = [item.address_street, item.address_zip, item.address_city].filter((x) => x).join(', ')
    const rightSide = `${item.email}`

    if (!leftSide.replace(/[, ]+/g, ' ').trim() && !rightSide.replace(/[, ]+/g, ' ').trim()) {
      return item.name
    }

    return $(`<div>
        <div>${item.name}</div>
        <div class="d-flex justify-content-between">
          <div><small>${leftSide.replace(/[, ]+/g, ' ').trim() ? leftSide : ''}</small></div>
          <div><small>${rightSide}</small></div>
        </div>
      </div>`)
  }
}
