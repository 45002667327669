import { Controller } from '@hotwired/stimulus'
import Flatpickr from 'stimulus-flatpickr'

export default class extends Controller {
  static targets = ['invoiceWillRepeat', 'frequency', 'frequencyUnit', 'endingOption', 'endingOn']

  declare private invoiceWillRepeatTarget: HTMLSelectElement
  declare private hasInvoiceWillRepeatTarget: boolean
  declare private frequencyTarget: HTMLSelectElement
  declare private frequencyUnitTarget: HTMLInputElement
  declare private endingOptionTarget: HTMLSelectElement
  declare private endingOnTarget: HTMLInputElement

  connect() {
    this.toggleFrequencyOptions()
    this.toggleEndingOnDate()
  }

  toggleFrequencyOptions() {
    if (!this.hasInvoiceWillRepeatTarget) {
      return
    }

    const selectedOption = this.invoiceWillRepeatTarget.options[this.invoiceWillRepeatTarget.selectedIndex].value

    if (this.invoiceWillRepeatTarget.selectedIndex === this.invoiceWillRepeatTarget.options.length - 1) {
      this.showFrequencyOptions()
    } else {
      this.hideFrequencyOptions()
      this.updateFrequencyOptions(selectedOption)
    }
  }

  showFrequencyOptions() {
    this.frequencyTarget.closest('.col').classList.remove('d-none')
    this.frequencyUnitTarget.closest('.col').classList.remove('d-none')
  }

  hideFrequencyOptions() {
    this.frequencyTarget.closest('.col').classList.add('d-none')
    this.frequencyUnitTarget.closest('.col').classList.add('d-none')
  }

  updateFrequencyOptions(selectedOption) {
    this.frequencyTarget.value = this.frequencyTarget.options[0].value
    this.frequencyUnitTarget.value = selectedOption
  }

  toggleEndingOnDate() {
    const selectedOption = this.endingOptionTarget.options[this.endingOptionTarget.selectedIndex].value
    let flatpickr: Flatpickr = this.application.getControllerForElementAndIdentifier(this.endingOnTarget, 'flatpickr')
    if (selectedOption === 'never') {
      this.endingOnTarget.closest('.col').classList.add('d-none')
      this.endingOnTarget.value = null
    } else {
      this.endingOnTarget.closest('.col').classList.remove('d-none')
      if (flatpickr) {
        flatpickr.fp.open()
      }
    }
  }
}
