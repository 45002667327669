import { Controller } from '@hotwired/stimulus'
import Lottie from 'lottie-web/build/player/lottie_light'

export default class extends Controller {
  connect() {
    Lottie.loadAnimation({
      container: this.element,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: (this.element as HTMLElement).dataset.source,
    })
  }
}
