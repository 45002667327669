<template>
  <div :class="{ required: required }">
    <label :for="id" class="form-control-label required"
      >{{ label }}<abbr v-if="required" title="required"></abbr>
    </label>
    <div class="input-group">
      <slot />
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :id="id"
        :name="name"
        class="form-control string"
        @change="onChange"
        :class="{ required: required }"
        :placeholder="placeholder"
        type="text"
      />
    </div>
    <small class="form-text text-muted float-start" v-if="hint !== undefined">{{ hint }}</small>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:modelValue', 'change'],
  props: {
    required: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    hint: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    modelValue: null,
  },
  methods: {
    onChange() {
      this.$emit('change')
    },
  },
})
</script>
