import { Controller } from '@hotwired/stimulus'
import { isSameDay, parseISO } from 'date-fns'

export default class extends Controller {
  static targets = ['date', 'dateWarning']

  declare dateWarningTarget: HTMLElement
  declare dateTarget: HTMLElement

  connect() {
    this.toggleWarning({ target: this.dateTarget })
  }

  toggleWarning(event) {
    if (this.data.get('warningEnabled') === 'true') {
      const invoiceDate = parseISO(event.target.value)

      if (isSameDay(invoiceDate, new Date())) {
        this.dateWarningTarget.classList.add('d-none')
      } else {
        this.dateWarningTarget.classList.remove('d-none')
      }
    }
  }
}
