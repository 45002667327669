import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['code', 'city']

  declare hasCityTarget: boolean
  declare codeTarget: HTMLInputElement
  declare cityTarget: HTMLInputElement

  find() {
    if (this.codeTarget.value.length === 4) {
      $.ajax({
        type: 'GET',
        url: `/norwegian_zip_codes/${this.codeTarget.value}.json`,
        dataType: 'json',
        success: (result) => {
          if (this.hasCityTarget && result.city != null) {
            this.cityTarget.value = result.city
          }
        },
      })
    }
  }
}
