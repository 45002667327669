import { Controller } from '@hotwired/stimulus'
import { easepick, PresetPlugin, RangePlugin } from '@easepick/bundle'
import { addParamToUrl } from './helpers'
import {
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  format,
  formatISO,
  parse,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import Rails from '@rails/ujs'
// @ts-ignore
import css from '../entrypoints/easepick.scss?inline'
import { i18n } from '../libraries/i18n'

function dateToISO(str: string) {
  return formatISO(parse(str.trim(), i18n.t('date.formats.frontend.datefns.short'), new Date()), {
    representation: 'date',
  })
}

export default class extends Controller {
  hiddenInputElement: HTMLInputElement
  form: HTMLFormElement
  static values = {
    companyCreatedAt: String,
    hasCompanyCreatedAtValue: Boolean,
  }
  declare companyCreatedAtValue: string
  declare hasCompanyCreatedAtValue: boolean

  connect() {
    this._addHiddenElement()
    this._updateHiddenInput()
    this.form = this.element.closest('form')

    const picker = new easepick.create({
      element: this.element as HTMLElement,
      css: css.toString(),
      lang: i18n.locale,
      format: i18n.t('date.formats.frontend.easepick.short'),
      readonly: false,
      plugins: [RangePlugin, PresetPlugin],
      calendars: 2,
      grid: 2,
      RangePlugin: {
        tooltip: false,
      },
      zIndex: 100,
      PresetPlugin: {
        position: 'left',
        customPreset: this._ranges(),
      },
    })

    picker.on('select', (e) => {
      this._updateHiddenInput()
      if (this.form.dataset.remote) {
        addParamToUrl('period', (this.form.querySelector('input[name=period]') as HTMLInputElement).value)
        Rails.fire(this.form, 'submit')
      } else {
        this.form.submit()
      }
    })

    this._addStyle(picker)
    this._addManualInputListener()
  }

  _addStyle(picker) {
    // Available options for colouring:
    //     --color-bg-default: #3f51b5;
    //     --color-fg-primary: #ffc107;
    //     --color-fg-default: #fff;
    //     --color-fg-accent: #ff9800;
    //     --color-bg-inrange: #333;
    //     --day-width: 35px;
    //     --day-height: 30px;

    // TODO: We currently borrow the inrange colour from our previous datepicker
    // We should instead set an own one
    // This might be easier once we have gotten to Bootstrap 5 which introduces many more CSS variables
    let style = document.createElement('style')
    style.textContent = `
     :host {
        --color-fg-primary: var(--bs-primary);
        --color-bg-inrange: #ebf4f8;
      }
      .preset-plugin-container {
        justify-content: start;
        gap: 10px;
      }
      `
    picker.ui.shadowRoot.appendChild(style)
  }

  _ranges() {
    const now = new Date()
    const lastYear = addYears(now, -1)
    const lastMonth = addMonths(now, -1)
    const companyCreatedAt = new Date(this.companyCreatedAtValue)
    companyCreatedAt.setHours(0, 0, 0, 0)
    const endDate = addYears(now, 10)

    let ranges = {}
    ranges[i18n.t('date.picker.ytd')] = [startOfYear(now), now]
    ranges[`${format(now, 'yyyy')} (${i18n.t('date.picker.full_year')})`] = [startOfYear(now), endOfYear(now)]
    ranges[format(lastYear, 'yyyy')] = [startOfYear(lastYear), endOfYear(lastYear)]
    ranges[i18n.t('date.picker.this_month')] = [startOfMonth(now), endOfMonth(now)]
    ranges[i18n.t('date.picker.last_month')] = [startOfMonth(lastMonth), endOfMonth(lastMonth)]
    if (this.hasCompanyCreatedAtValue) {
      ranges[i18n.t('date.picker.all')] = [companyCreatedAt, endDate]
    }

    return ranges
  }

  _addHiddenElement() {
    this.hiddenInputElement = document.createElement('input')
    this.hiddenInputElement.setAttribute('type', 'hidden')
    this.hiddenInputElement.setAttribute('name', (this.element as HTMLInputElement).name)
    this.element.parentNode.insertBefore(this.hiddenInputElement, this.element.nextSibling)
    this.element.removeAttribute('name')
  }

  _updateHiddenInput() {
    const str = (this.element as HTMLInputElement).value
    if (str.trim() === '') {
      return
    }
    let [begin, end] = str.split('-')
    let formInput = this.element.closest('form').querySelector('input[name=period]') as HTMLInputElement
    formInput.value = `${dateToISO(begin)}_${dateToISO(end)}`
  }

  _addManualInputListener() {
    const inputElement = this.element as HTMLInputElement
    inputElement.addEventListener('change', () => {
      this._updateHiddenInput()
      this.updateUrlParams()
      if (this.form.dataset.remote) {
        Rails.fire(this.form, 'submit')
      } else {
        this.form.submit()
      }
    })
  }

  updateUrlParams() {
    const str = (this.element as HTMLInputElement).value
    if (str.trim() === '') {
      return
    }
    let [begin, end] = str.split(' - ')
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('period', `${dateToISO(begin)}_${dateToISO(end)}`)
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
  }
}
