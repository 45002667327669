import { Controller } from '@hotwired/stimulus'
import SplitViewController from './split_view_controller'

export default class extends Controller {
  static targets = ['preview']

  declare previewTarget: any

  toggle(url) {
    if ((this.previewTarget.data === url || this.previewTarget.src === url) && this.isVisible) {
      this.close()
    } else {
      this.open(url)
    }
  }

  open(url) {
    this.resetPreviewElement()
    this.previewTarget.data = url
    this.previewTarget.src = url
    this.splitViewController.show()
  }

  close() {
    this.previewTarget.data = ''
    this.previewTarget.src = ''
    this.splitViewController.hide()
  }

  resetPreviewElement() {
    // Switching the preview data type from image to pdf sometimes caused the window to display
    // data from a previously selected pdf inbox item.
    // We avoid this issue by rebuilding the previewElement.
    const oldPreviewElement = document.getElementsByClassName('split-view__preview-wrapper')[0]
    const parent = oldPreviewElement.parentElement
    parent.append(oldPreviewElement.cloneNode(true))
    parent.removeChild(oldPreviewElement)
  }

  get isVisible() {
    return !this.element.classList.contains('d-none')
  }

  get splitViewController(): SplitViewController {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById('split-view'),
      'split-view',
    ) as SplitViewController
  }
}
