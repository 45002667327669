import ConfirmationController from './confirmation_controller'
import { i18n } from '../libraries/i18n'

export default class extends ConfirmationController {
  initialize() {
    super.initialize()

    let elem = this.element as HTMLElement
    elem.dataset.title = elem.dataset.title ?? i18n.t('navigation.delete_confirmation')
    elem.dataset.confirmationYes = elem.dataset.confirmationYes ?? i18n.t('navigation.delete')
    elem.dataset.confirmationNo = elem.dataset.confirmationNo ?? i18n.t('navigation.cancel')
    this.confirmBtn.classList.remove('btn-primary')
    this.confirmBtn.classList.add('btn-danger')
  }
}
