import $ from 'jquery'

document.addEventListener('turbo:load', function () {
  $('a[data-bs-toggle=collapse].collapse-link').on('click', function (event) {
    if (event.target.dataset.hasOwnProperty('alternative')) {
      let previousText = event.target.innerHTML
      event.target.innerHTML = event.target.dataset.alternative
      event.target.dataset.alternative = previousText
    } else if (event.target.parentElement.dataset.hasOwnProperty('alternative')) {
      let parentTarget = event.target.parentElement
      parentTarget.click()
    }
  })
})
