import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['submit', 'modal']

  declare submitTarget: HTMLButtonElement
  declare modalTarget: HTMLElement

  connect() {
    // track the opening and closing of the window to change the icon
    this.modalTarget.addEventListener('shown.bs.modal', () => {
      this._dispatch('partner--manual-modal:change', { modal: 'shown' })
    })
    this.modalTarget.addEventListener('hidden.bs.modal', () => {
      this._dispatch('partner--manual-modal:change', { modal: 'hidden' })
    })
  }

  _dispatch(eventName, params = {}) {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.element.dispatchEvent(cancelEvent)
  }

  handleSuccessSubmit(event) {
    Modal.getOrCreateInstance(this.modalTarget.children[0]).hide()
  }

  handleSubmitAvailability(event) {
    this.submitTarget.disabled = !event.canSubmit
  }
}
