<template>
  <div class="input-group mb-3">
    <input
      :value="modelValue"
      @input="inputUpdate"
      @change="updatePercent"
      :id="id"
      ref="input"
      class="form-control string amount"
      type="text"
    />
    <div class="me-2">
      <span class="form-control input-group-text rounded-end card fw-bold">%</span>
    </div>
    <span class="align-self-center">{{ label }}</span
    ><abbr v-if="required" title="required"></abbr>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:modelValue', 'change'],
  props: {
    required: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    hint: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    modelValue: null,
  },

  methods: {
    inputUpdate(event) {
      let val = event.target.value
        .toString()
        .replace(/,/g, '.')
        .replace(/[^0-9.]/g, '')
      const fromDecimalSeparator = val.slice(val.lastIndexOf('.'))
      const beforeDecimalSeparator = val.slice(0, val.lastIndexOf('.')).replace(/\./g, '')
      this.$emit('update:modelValue', beforeDecimalSeparator + fromDecimalSeparator)
    },

    updatePercent(event) {
      this.$emit('change', event)
    },
  },
})
</script>
