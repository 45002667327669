import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = ['customerPhoneNumber', 'overWritePhoneNumberCheckBox']

  declare customerPhoneNumberTarget: HTMLInputElement
  declare overWritePhoneNumberCheckBoxTarget: HTMLElement

  originalValue: string

  connect() {
    this.originalValue = this.customerPhoneNumberTarget.value
    this.renderCheckBox()
  }

  renderCheckBox() {
    const $div = $(this.overWritePhoneNumberCheckBoxTarget).closest('div')
    if (this.customerPhoneNumberTarget.value === this.originalValue) {
      $div.hide()
    } else {
      $div.show()
    }
  }

  checkVipps() {
    $('#vipps-div').remove()
    let $input = $(this.customerPhoneNumberTarget)
    if (this.customerPhoneNumberTarget.value.length >= 8) {
      $input.after(`<div id="vipps-div">${i18n.t('vipps.checking')}</div>`)
      let $div = $('#vipps-div')
      $.ajax({
        type: 'GET',
        url: '/vipps/check_number',
        data: { msisdn: this.customerPhoneNumberTarget.value },
        success: function () {
          $input.removeClass('is-invalid')
          $div.remove()
        },
        error: function () {
          $input.addClass('is-invalid')
          $div.text(i18n.t('vipps.failed'))
        },
      })
    }
  }
}
