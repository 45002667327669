import Select2Builder from '../../standalone/components/Select2Builder'
import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import Rails from '@rails/ujs'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  connect() {
    let $element = $(this.element)
    let dropdownParent = this.element.closest('.modal') || document.body

    const options = new Select2Builder().createParams(`/${(window as any).current_company_id}/partners/search.json`, {
      width: '100%',
      minimumInputLength: 0,
      tags: false,
      placeholder: this._allPartners(),
      dropdownParent: dropdownParent,
    })
    options.ajax.data = (params) => ({
      term: $.trim(params.term),
      type: this._type(),
    })
    options.ajax.processResults = (data, params) => {
      return {
        results: [{ name: this._allPartners(), id: 'All' }, ...data],
      }
    }

    $element.select2(options)

    if (this._autoCommit()) {
      $element.on('select2:select', (event) => {
        let form = this.element.closest('form')
        Rails.fire(form, 'submit')
      })
    }
  }

  _type(): string {
    return this.data.has('type') ? this.data.get('type') : 'customer'
  }

  _addDefaultOption(): boolean {
    // Default is true
    return !this.data.has('addDefaultOption') && this.data.get('addDefaultOption') === 'true'
  }

  _autoCommit(): boolean {
    // Default is true
    return !this.data.has('autoCommit') || this.data.get('autoCommit') === 'true'
  }

  _allPartners(): string {
    return this._type() === 'customer' ? i18n.t('customer.all_customers') : i18n.t('supplier.all_suppliers')
  }
}
