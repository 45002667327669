import { Controller } from '@hotwired/stimulus'
import Form_switcher_controller from 'controllers/form_switcher_controller'
import { nextFrame } from '../../../rendering'

export default class extends Controller {
  static targets = ['typeSelect']

  declare hasTypeSelectTarget: boolean
  declare typeSelectTarget: HTMLSelectElement

  connect() {
    if (this.hasTypeSelectTarget) {
      let options: Array<HTMLOptionElement> = Array.from(this.typeSelectTarget.options)
      options.forEach((option) => {
        let optionKey = option.value
        let elements = document.querySelectorAll('[data-controller*="bookkeeping--year-end--repeating-row"]')
        let elementsAdded = Array.from(elements).filter(
          (e: HTMLElement) => e.dataset['bookkeeping-YearEnd-RepeatingRowKeyValue'] === optionKey && e !== this.element,
        )

        let rowsAdded = elementsAdded.length
        if (option.dataset.maxRows) {
          if (rowsAdded >= parseInt(option.dataset.maxRows)) {
            option.classList.add('d-none')
          } else {
            option.classList.remove('d-none')
          }
        }
      })
      this.typeSelectTarget.selectedIndex = options.findIndex((e) => !e.classList.contains('d-none'))
      nextFrame(() => {
        const controller = this.application.getControllerForElementAndIdentifier(
          this.element,
          'form-switcher',
        ) as Form_switcher_controller
        controller.toggle({ target: { value: this.typeSelectTarget.selectedOptions[0].value } })
      })
    }
  }
}
