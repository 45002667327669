import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../../../classes/parse_decimal'
import { i18n } from '../../../libraries/i18n'

export default class extends Controller {
  static targets = [
    'rentalValueBasis',
    'rentalValue',
    'rentalValueSection',
    'useMarketValue',
    'marketValueSection',
    'submitBtn',
  ]

  declare rentalValueBasisTargets: [HTMLInputElement]
  declare useMarketValueTarget: HTMLInputElement
  declare rentalValueTarget: HTMLElement
  declare rentalValueSectionTarget: HTMLElement
  declare marketValueSectionTarget: HTMLElement
  declare submitBtnTarget: HTMLButtonElement

  rentalValueBasisOriginalValues: Object

  connect() {
    this.showMarketValueSection()
    this.showRentalValueSection(parseDecimal(this.rentalValueTarget.innerText).isPositive())
  }

  showRentalValueSection(show: boolean) {
    if (show) {
      this.rentalValueSectionTarget.classList.remove('d-none')
      this.submitBtnTarget.name = 'commit'
      this.submitBtnTarget.value = i18n.t('navigation.save')
    } else {
      this.rentalValueSectionTarget.classList.add('d-none')
      this.submitBtnTarget.value = i18n.t('bookkeeping.year_end.income_and_tax_report.v2.property.fetch_rental_value')
      this.submitBtnTarget.name = 'fetch_rental_value'
    }
  }

  showMarketValueSection() {
    if (this.useMarketValueTarget.checked) this.marketValueSectionTarget.classList.remove('d-none')
    else this.marketValueSectionTarget.classList.add('d-none')
  }

  basisAssetValueChanged() {
    if (this.rentalValueBasisOriginalValues === undefined) {
      this.rentalValueBasisOriginalValues = {}
      this.rentalValueBasisTargets.forEach(
        (target) => (this.rentalValueBasisOriginalValues[target.name] = target.value),
      )
    }

    let basisChanged = this.rentalValueBasisTargets.find(
      (target) => this.rentalValueBasisOriginalValues[target.name] !== target.value,
    )
    this.showRentalValueSection(basisChanged !== undefined)
  }
}
