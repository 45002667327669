/* eslint no-console:0 */
import './../polyfills'
import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import 'jquery'
import 'bootstrap'
import '../rollbar/rollbarInit'
import '../standalone/manifest'
import LucaLogin from '../authentication/LucaLogin'
import './../vue/app'
import 'chart.js'
import 'trix'
import '@rails/actiontext'

import SetupStimulus from './../setupStimulus'
import './../libraries/fontawesome'
import './../libraries/turbo/stream_actions'

// Set up legacy JavaScript.
document.addEventListener('turbo:load', () => {
  new LucaLogin()
})

SetupStimulus.start()
if (!(window as any)._rails_loaded) {
  Rails.start()
}
ActiveStorage.start()

import { Crisp } from 'crisp-sdk-web'
// Crisp automatically sets window.$crisp as well
// @ts-ignore
window.crispApp = Crisp
