import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../../libraries/i18n'

export default class extends Controller {
  static targets = ['amount']

  declare amountTarget: HTMLInputElement

  typeChanged(event: Event): void {
    const val = (event.target as HTMLInputElement).value
    const amountLabel = this.amountTarget.querySelector('label')

    switch (val) {
      case 'fixed_salary':
        this.amountTarget.classList.remove('d-none')
        amountLabel.innerHTML = i18n.t('salary.wage_type.yearly_salary')
        break
      case 'hour_salary':
        this.amountTarget.classList.remove('d-none')
        amountLabel.innerHTML = i18n.t('activerecord.attributes.salary/recurring_payslip_line_item.rate')
        break
      case 'none':
        this.amountTarget.classList.add('d-none')
        break
      default:
        throw new Error(`Unknown salary type ${val}`)
    }
  }
}
