import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = ['projectId', 'projectHeader', 'projectSelector']

  declare projectIdTarget: HTMLInputElement
  declare projectHeaderTarget: HTMLElement
  declare projectSelectorTarget: HTMLInputElement

  handleProjectChange(event) {
    this.projectIdTarget.value = event.target.value
    if (event.target.value === '') {
      this.projectHeaderTarget.innerHTML = i18n.t('customer_invoice.unassign_project')
    } else {
      this.projectHeaderTarget.innerHTML = i18n.t('customer_invoice.assign_project', {
        project_name: event.target.selectedOptions[0].text,
      })
    }
    Modal.getOrCreateInstance(document.querySelector('#customer-invoice-project-modal')).show()
  }

  cancelProjectChange() {
    let selectedValue = this.projectSelectorTarget.dataset.value

    if (selectedValue === undefined) {
      this.projectSelectorTarget.value = ''
    } else {
      this.projectSelectorTarget.value = selectedValue
    }
    this.projectSelectorTarget.dispatchEvent(new Event('change'))

    Modal.getOrCreateInstance(document.querySelector('#customer-invoice-project-modal')).hide()
  }
}
