import { Controller } from '@hotwired/stimulus'
import Inputmask from 'inputmask'

export default class BankAccountNumberController extends Controller {
  initialize() {
    this.addMask()
  }

  removeMask() {
    Inputmask.remove(this.element)
  }

  addMask() {
    Inputmask('9999.99.99999').mask(this.element)
  }
}
