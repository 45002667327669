import { Controller } from '@hotwired/stimulus'
import { BulkActionParams } from '../../bulk_action_table_controller'

export default class extends Controller {
  static targets = ['alert', 'paymentsLimit']

  declare alertTarget: HTMLElement
  declare paymentsLimitTargets

  checkPaymentsLimit(params: BulkActionParams) {
    let minimumPaymentsNumber = 1000
    let bankAccountNumber = ''
    let currentPaymentsLimit = 10
    const actionsSelector = params.bulkActionSelector
    const checkedRows = params.checkedRows

    checkedRows.forEach((row) => {
      bankAccountNumber = (row.querySelector('div[data-account-number]') as HTMLDivElement).dataset.accountNumber
      currentPaymentsLimit = (window as any).paymentLimitsByAccountNumber[bankAccountNumber]
      if (currentPaymentsLimit < minimumPaymentsNumber) {
        minimumPaymentsNumber = currentPaymentsLimit
      }
    })
    if (checkedRows.length > minimumPaymentsNumber) {
      this.paymentsLimitTargets.forEach((element) => {
        element.innerHTML = minimumPaymentsNumber.toString()
      })
      this.alertTarget.classList.remove('d-none')
      if (actionsSelector !== null) {
        actionsSelector.disabled = true
      }
    } else {
      this.alertTarget.classList.add('d-none')
      if (actionsSelector !== null) {
        actionsSelector.disabled = false
      }
    }
  }
}
