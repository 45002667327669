<template>
  <div>
    <transition-group name="list" tag="div" data-test-id="unmatched-postings" v-on:before-leave="beforeLeave">
      <PostingItemCard
        v-for="posting in postings"
        :key="posting.id"
        :id="'posting-' + posting.id"
        :destroyable="posting.destroyable"
        :posting="posting"
        :suggestionList="bankTransactionSuggestedMatch.candidates"
        :suggestion="postingSuggestedMatch.candidates.find((candidate) => candidate.id === posting.id)"
        @toggleSelect="togglePosting"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import PostingItemCard from './PostingItemCard.vue'
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapState(useBankReconciliationStore, [
      'postings',
      'bankTransactionSuggestedMatch',
      'postingSuggestedMatch',
      'selectedPostingId',
    ]),
  },
  mounted() {
    if (document.referrer.includes('manual_journals/new')) {
      document.getElementById(`posting-${this.selectedPostingId}`).click()
      let bankTransactionId = this.bankTransactionSuggestedMatch.candidates[0].id
      document.getElementById(`bank-transaction-${bankTransactionId}`).click()
    }
  },
  components: {
    PostingItemCard,
  },
  methods: {
    ...mapActions(useBankReconciliationStore, { togglePosting: 'togglePosting' }),

    beforeLeave(el) {
      el.style.width = el.offsetWidth + 'px'
    },
  },
})
</script>
<style scoped>
.list-leave-active {
  transition: all 0.5s ease-in;
  z-index: 100;
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  border-color: grey;
  transform: translateY(25px);
}
</style>
