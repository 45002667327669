import { Controller } from '@hotwired/stimulus'
import Decimal from 'decimal.js'
import { parseDecimal } from '../../../classes/parse_decimal'
import { WageType } from '../payslip-line-items/edit_controller'
import { i18n } from '../../../libraries/i18n'

export default class extends Controller {
  static targets = ['rate', 'amountFormGroup', 'amountInputGroup', 'purchaseCar', 'wageType']

  declare rateTarget: HTMLInputElement
  declare purchaseCarTarget: HTMLSelectElement
  declare hasPurchaseCarTarget: boolean
  declare amountFormGroupTarget: HTMLDivElement
  declare amountInputGroupTarget: HTMLDivElement
  declare hasWageTypeTarget: boolean
  declare wageTypeTarget: HTMLSelectElement

  selectedWageType: WageType
  amount

  connect() {
    this.setWageTypeData(JSON.parse(this.wageTypeTarget.dataset.definition)[0])
    this.reverseCalculateRate()
    this.showPurchaseCarInputField()
  }

  setWageType(event: CustomEvent) {
    this.setWageTypeData(event.detail.event.params.data)
  }

  amountChanged(event: Event) {
    this.amount = (event.target as HTMLInputElement).value
    this.calculateRate()
  }

  private setWageTypeData(data) {
    this.selectedWageType = data
    this.showPurchaseCarInputField()
    this.setVisibility()
    this.setLabelNames(data)
    this.setDeductionPrepend(data)
    this.calculateRate()
  }

  private calculateRate() {
    if (!this.amount) {
      return
    }

    if (this.selectedWageType.name === 'fixed_salary') {
      this.rateTarget.value = this.formatAmount(parseDecimal(this.amount).div(12))
    } else {
      if (this.selectedWageType.deduction) {
        this.rateTarget.value = this.formatAmount(parseDecimal(this.amount).neg())
      } else {
        this.rateTarget.value = this.amount
      }
    }
  }

  // This is needed when we do "edit" since we then get the rate and not the amount
  private reverseCalculateRate() {
    const amountInput = this.amountInputGroupTarget.querySelector('input')
    const rate = this.rateTarget.value
    if (this.selectedWageType.name === 'fixed_salary') {
      // In this case we round to the nearest whole amount
      // This might give a wrong cent amount, but it is almost always the right thing to do to show
      // a correct yearly amount.
      amountInput.value = this.formatAmount(parseDecimal(rate).times(12), false)
    } else {
      if (this.selectedWageType.deduction) {
        amountInput.value = this.formatAmount(parseDecimal(rate).neg())
      } else {
        amountInput.value = rate
      }
    }
  }

  private setVisibility() {
    this.amountFormGroupTarget.classList.remove('d-none')
    const inputColumn = this.rateTarget.closest('.d-none')
    if (inputColumn) {
      inputColumn.classList.remove('d-none')
    }
  }

  private setLabelNames(data: WageType) {
    const amountLabel = this.amountFormGroupTarget.querySelector('label')
    const rateLabel = this.rateTarget.parentNode.querySelector('label')
    // Ensure both are shown. We hide them below if necessary
    this.amountFormGroupTarget.parentElement.style.display = 'block'
    this.rateTarget.parentElement.style.display = 'block'
    switch (data.name) {
      case 'fixed_salary':
        amountLabel.innerHTML = i18n.t('salary.wage_type.yearly_salary')
        rateLabel.innerHTML = i18n.t('salary.wage_type.monthly_salary')
        break
      default:
        switch (data.quantityType) {
          case 'hidden':
            this.amountFormGroupTarget.parentElement.style.display = 'none'
            this.rateTarget.parentElement.style.display = 'none'
            break
          case 'percent':
            amountLabel.innerHTML = i18n.t('accounting.amount')
            rateLabel.innerHTML = i18n.t('salary.wage_type.totals')
            break
          default:
            amountLabel.innerHTML = i18n.t(`activerecord.attributes.salary/recurring_payslip_line_item.rate`)
            rateLabel.innerHTML = i18n.t('activerecord.attributes.salary/recurring_payslip_line_item.rate')
            this.rateTarget.parentElement.style.display = 'none'
        }
    }
    if (!data.showAmount) {
      this.amountFormGroupTarget.parentElement.style.display = 'none'
      this.rateTarget.parentElement.style.display = 'none'
    }
  }

  private setDeductionPrepend(data: WageType) {
    const prependDiv = this.amountFormGroupTarget.querySelector('.input-group-text')
    if (data.deduction) {
      this.amountInputGroupTarget.classList.add('input-group')
      prependDiv.classList.remove('d-none')
    } else {
      this.amountInputGroupTarget.classList.remove('input-group')
      prependDiv.classList.add('d-none')
    }
  }

  private formatAmount(amount: Decimal, decimals: boolean = true) {
    return (
      new Intl.NumberFormat('nb-NO', {
        minimumFractionDigits: decimals ? 2 : 0,
        maximumFractionDigits: decimals ? 2 : 0,
      })
        .format(amount.toNumber())
        // Use regular minus instead of the special minus that Intl.NumberFormat seems like use
        .replaceAll('\u2212', '-')
    )
  }

  private showPurchaseCarInputField() {
    if (!this.hasPurchaseCarTarget || !this.hasWageTypeTarget) {
      return
    }

    if (
      this.selectedWageType.extraAltinnInfo == 'car_licence_plate' ||
      this.selectedWageType.extraAltinnInfo == 'car_license_plate_and_price'
    ) {
      this.purchaseCarTarget.style.display = 'block'
    } else {
      this.purchaseCarTarget.style.display = 'none'
    }
  }
}
