import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { fathomToken: String, eventLabel: String, email: String }

  declare fathomTokenValue: string
  declare eventLabelValue: string
  declare emailValue: string

  createCompany(event) {
    const type = event.target.dataset.demo === 'true' ? 'demo' : 'real'
    this.fireGoogleTagManagerCustomEvent('company', 'create', type, 1, this.emailValue)
    this.fireFathomGoal()
  }

  extendTrial() {
    this.fireGoogleTagManagerCustomEvent('trial_period', 'extend', null, 1, this.emailValue)
    this.fireFathomGoal()
  }

  activateSubscription() {
    this.fireGoogleTagManagerCustomEvent('subscription', 'activate', this.eventLabelValue, 1, this.emailValue)
    this.fireFathomGoal()
  }

  foundCompanyStepReached() {
    const availableLabels = ['user_created', 'found_document_signed', 'sent_to_altinn']
    if (availableLabels.includes(this.eventLabelValue)) {
      this.fireGoogleTagManagerCustomEvent('found_company', 'step_reached', this.eventLabelValue, 1, this.emailValue)
      this.fireFathomGoal()
    } else {
      console.error(`We don't support ${this.eventLabelValue} label`)
    }
  }

  private fireGoogleTagManagerCustomEvent(eventCategory, eventAction, eventLabel, eventValue, email) {
    let dataLayer = (window as any).dataLayer
    // Only run if GoogleTagManager is enabled:
    if (dataLayer) {
      let data = {
        event: 'customEvent',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel,
        eventValue: eventValue,
        email: email,
      }
      dataLayer.push(data)
    }
  }

  private fireFathomGoal() {
    let fathom = (window as any).fathom
    if (fathom && this.fathomTokenValue) {
      fathom.trackGoal(this.fathomTokenValue, 0)
    }
  }
}
