<template>
  <div class="dropdown" v-if="show">
    <button
      class="btn btn-sm rounded-circle px-2"
      :class="buttonClasses"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-test-id="dropdown-button"
    >
      <font-awesome-icon :icon="['far', 'ellipsis-h']" />
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <slot></slot>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    dropdownType: {
      type: String,
      required: true,
    },
  },
  computed: {
    show: function (): boolean {
      switch (this.dropdownType) {
        case 'secondary':
          return true
        case 'debug':
          let roles = (window as any).current_user_roles
          let allowed_roles = ['help_desk', 'system_admin']
          return roles.some((r) => allowed_roles.includes(r))
        default:
          throw new Error(`Unknown dropdown type ${this.dropdownType}`)
      }
    },
    buttonClasses: function (): string {
      switch (this.dropdownType) {
        case 'secondary':
          return 'btn-outline-secondary'
        case 'debug':
          return 'bg-super-user text-white border-white'
        default:
          throw new Error(`Unknown dropdown type ${this.dropdownType}`)
      }
    },
  },
})
</script>
