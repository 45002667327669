import { Controller } from '@hotwired/stimulus'
import PartnerSelectController from './partner/select_controller'

export default class extends Controller {
  static targets = ['input']

  declare inputTarget: HTMLInputElement

  selectPartner(event: MouseEvent) {
    event.preventDefault()
    let target = event.target as HTMLElement
    const controller = this.application.getControllerForElementAndIdentifier(
      this.inputTarget,
      'partner--select',
    ) as PartnerSelectController
    controller.select({ id: -314, name: target.dataset.name })
  }
}
