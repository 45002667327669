import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['navItem']

  declare navItemTargets: Array<HTMLElement>

  connect() {
    this._updateNavigationMenuItems()
  }

  _updateNavigationMenuItems() {
    this.navItemTargets.forEach((item) => {
      if (this._hasSubItems(item)) {
        this._updateNavItemWithSubitems(item)
      } else {
        this._updateStandaloneNavItem(item)
      }
    })
  }

  _hasSubItems(item): boolean {
    return item.getElementsByTagName('a').length > 1
  }

  _updateNavItemWithSubitems(item) {
    const mainItem = item.children[0]
    const collapseParentElement = item.children[1]
    let subItemsArray = [...item.getElementsByTagName('ul')[0].children]
    let isActive = false
    let subLink = null
    subItemsArray.forEach((subItem) => {
      subLink = subItem.getElementsByTagName('a')[0]

      if (document.URL.split('?')[0].match(new RegExp(`${subLink.href}$`))) {
        isActive = true
        subLink.classList.add('active')
        subLink.children[0].classList.add('sidebar__active-submenu-item')
      } else {
        subLink.classList.remove('active')
        subLink.children[0].classList.remove('sidebar__active-submenu-item')
      }
    })
    if (isActive) {
      mainItem.classList.add('active')
      collapseParentElement.classList.add('show', 'text-muted')
    } else {
      mainItem.classList.remove('active')
      collapseParentElement.classList.remove('show', 'text-muted')
    }
  }

  _updateStandaloneNavItem(item) {
    const linkElement = item.getElementsByTagName('a')[0]
    if (document.URL.includes(linkElement.href)) {
      linkElement.classList.add('active')
    } else {
      linkElement.classList.remove('active')
    }
  }
}
