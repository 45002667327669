import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  static targets = ['source', 'alert']

  declare sourceTarget: HTMLInputElement
  declare alertTarget: HTMLElement
  declare hasAlertTarget: boolean

  connect() {
    if (this.hasAlertTarget) {
      this.alertTarget.classList.add('d-none')
    }
  }

  copy(_event) {
    const value = this.sourceTarget.getAttribute('value')
    navigator.clipboard.writeText(value)
  }

  showAlert(event) {
    const tooltip = new Tooltip(event.target, {
      title: i18n.t('company.referral_program.page.link_copied'),
      placement: 'right',
      trigger: 'manual',
      customClass: 'clipboard-tooltip',
    })
    tooltip.show()
    setInterval(function () {
      tooltip.hide()
    }, 1000)
  }
}
