import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import Rails from '@rails/ujs'
import { BulkActionParams } from '../bulk_action_table_controller'
import { getModelHashIds } from '../../util/get_model_hash_ids'

export default class extends Controller {
  static targets = ['modal', 'submitButton', 'form']

  declare modalTarget: HTMLElement
  declare submitButtonTarget: HTMLButtonElement
  declare formTarget: HTMLFormElement

  hashIds: Array<string> = []
  checkedRows: Array<HTMLTableRowElement> = []

  showMergeModal(params: BulkActionParams) {
    this.updateHashids(params.checkedRows)
    Modal.getOrCreateInstance(this.modalTarget).show()
  }

  submit() {
    this.updateFormWithCheckedPartners()

    Rails.fire(this.formTarget, 'submit')
  }

  updateFormWithCheckedPartners() {
    this.formTarget.querySelectorAll('input[name="partners[partners_to_be_merged][]"]').forEach((input) => {
      input.remove()
    })

    this.hashIds.forEach((hashId) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', 'partners[partners_to_be_merged][]')
      input.setAttribute('value', hashId)
      this.formTarget.appendChild(input)
    })
  }

  updateHashids(checkedRows: Array<HTMLTableRowElement>) {
    this.checkedRows = checkedRows
    this.hashIds = getModelHashIds(checkedRows)
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
  }
}
