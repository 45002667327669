<template>
  <div>
    <div v-for="group in groupedAccounts">
      <GroupedAccounts
        :key="group.text"
        :accounts="group"
        :hover-account="hoverAccount"
        :cursor-enabled="cursorEnabled"
        @accountSelected="accountSelected"
        @accountHover="accountHover"
      />
    </div>
  </div>
</template>

<script lang="ts">
import GroupedAccounts from './GroupedAccounts.vue'
import { defineComponent, PropType } from 'vue'
import { Account, GroupedAccount } from '../module'

export default defineComponent({
  components: {
    GroupedAccounts,
  },
  props: {
    groupedAccounts: {
      required: true,
      type: Array<GroupedAccount>,
    },
    cursorEnabled: {
      required: true,
      type: Boolean,
    },
    hoverAccount: {
      required: true,
      type: Object as PropType<Account>,
    },
  },
  methods: {
    accountHover(account) {
      this.$emit('accountHover', account)
    },
    accountSelected(account) {
      this.$emit('accountSelected', account)
    },
  },
})
</script>
