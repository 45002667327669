import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import Rails from '@rails/ujs'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = { submitButtonId: String, parentModalId: String }

  declare submitButtonIdValue: string
  declare parentModalIdValue: string

  static targets = ['modal', 'form']

  declare modalTarget: HTMLElement
  declare formTarget: HTMLFormElement

  initialize() {
    $('#' + this.submitButtonIdValue).on('click', () => {
      Rails.fire(this.formTarget, 'submit')
    })
  }

  handleSubmitAvailability(event) {
    $('#' + this.submitButtonIdValue).prop('disabled', !event.canSubmit)
  }

  handleFormSuccess() {
    if (!this.parentModalIdValue) {
      document.location.reload()
    }
  }

  handleFormError(event) {
    if (this.parentModalIdValue) {
      Modal.getOrCreateInstance(document.getElementById(this.parentModalIdValue)).hide()
      Modal.getOrCreateInstance(this.modalTarget.children[0]).show()
    }
  }
}
