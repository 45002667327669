import $ from '../libraries/jquery'
import { i18n } from '../libraries/i18n'

export default class BankAccount {
  findBankNameByCode(accountNumberElem: HTMLInputElement, type: string = 'standard') {
    let elem = $(accountNumberElem)
    let inputNumberValue = accountNumberElem.value.replace(/\D/g, '')
    if (inputNumberValue.length >= 11) {
      $.ajax({
        type: 'GET',
        url: '/bank/account_ranges/search.json',
        data: { term: inputNumberValue },
        dataType: 'json',
        success: (result) => {
          elem.removeClass('is-invalid')
          $('#bank-name').remove()
          $('.invalid-feedback').remove()
          if (result.bank_present) {
            if (type != 'international') {
              elem.after('<div id="bank-name">' + result.bank_name + '</div>')
            } else {
              this.showInvalidInternationalAccountMessage(type, elem)
            }
          } else {
            if (type != 'international') {
              elem.addClass('is-invalid')
              elem.after('<div class="invalid-feedback">' + result.bank_name + '</div>')
            } else {
              this.showInvalidInternationalAccountMessage(type, elem)
            }
          }
        },
      })
    } else {
      this.removeError(elem)
      this.showInvalidInternationalAccountMessage(type, elem)
    }
  }

  validateBankAccountNumber(number) {
    const regex = /^[A-Z]{2}\d{2}[A-Z]{4}\d{10}$/
    return regex.test(number)
  }

  validateUSBankAccountNumber(number) {
    const regex = /^USZZ\d+$/
    return regex.test(number)
  }

  removeError(elem) {
    elem.removeClass('is-invalid')
    $('#bank-name').remove()
    $('.invalid-feedback').remove()
  }

  showInvalidInternationalAccountMessage(type, elem) {
    if (
      type == 'international' &&
      !(this.validateBankAccountNumber(elem.val()) || this.validateUSBankAccountNumber(elem.val()))
    ) {
      elem.addClass('is-invalid')
      elem.after('<div class="invalid-feedback">' + i18n.t('bank.account.use_iban') + '</div>')
    }
  }
}
