import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'email',
    'clientId',
    'output',
    'redirectURI',
    'URI',
    'culture',
    'selectAllAccounts',
    'bankAccountNumbers',
    'scope',
    'providerId',
  ]

  declare URITarget
  declare emailTarget
  declare clientIdTarget
  declare redirectURITarget
  declare cultureTarget
  declare selectAllAccountsTarget
  declare bankAccountNumbersTarget
  declare providerIdTarget
  declare scopeTarget
  declare outputTarget: HTMLLinkElement

  connect() {
    this.change()
  }

  change() {
    const clientID = encodeURIComponent(this.clientIdTarget.value)
    const email = encodeURIComponent(this.emailTarget.value)
    const redirectURI = encodeURIComponent(this.redirectURITarget.value)
    const scope = encodeURIComponent(this.scopeTarget.value)
    const culture = encodeURIComponent(this.cultureTarget.value)
    const bankAccountNumbers = encodeURIComponent(this.bankAccountNumbersTarget.value)
    const providerId = encodeURIComponent(this.providerIdTarget.value)
    let output = `${this.URITarget.value}/v1/oauth/connect?email=${email}&providerId=${providerId}&client_id=${clientID}&redirect_uri=${redirectURI}&scope=${scope}&culture=${culture}&response_type=code`
    if (bankAccountNumbers.trim() != '') {
      output += `&bank_account_numbers=${bankAccountNumbers}`
    }

    if (this.selectAllAccountsTarget.checked) {
      output += '&selectAllAccounts=true'
    }
    this.outputTarget.href = output
    this.outputTarget.textContent = output
  }
}
//  \
//   -d client_id=<CLIENT_ID> \
//   -d redirect_uri=<REDIRECT_URL> \
//   -d scope="accounts offline_access" \
//   -d response_type=code \
//   -d accountsGroupName=<optional> \
//   -d accountsGroupId=<optional>
