<template>
  <li v-if="show">
    <button v-if="href == ''" type="button" :class="buttonClasses">
      <slot></slot>
    </button>
    <a :href="href" v-else :class="buttonClasses">
      <slot></slot>
    </a>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    dropdownType: {
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: true,
    },
  },
  computed: {
    show: function (): boolean {
      switch (this.dropdownType) {
        case 'debug':
          let roles = (window as any).current_user_roles
          let allowed_roles = ['help_desk', 'system_admin']
          return roles.some((r) => allowed_roles.includes(r))
        default:
          return true
      }
    },
    buttonClasses: function (): string {
      switch (this.dropdownType) {
        case 'debug':
          return 'bg-super-user text-white border-white dropdown-item'
        default:
          return 'dropdown-item'
      }
    },
  },
})
</script>
