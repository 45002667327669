import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from 'fontawesome-luca/pro-regular-svg-icons'
import { Modal } from 'bootstrap'
import { pluralize } from '@capaj/pluralize'
import { i18n } from '../libraries/i18n'

export default class CrudTableController extends Controller<HTMLElement> {
  modelName: string

  static targets = ['table']

  declare tableTarget: HTMLElement
  declare hasTableTarget: Boolean

  table: HTMLElement

  connect(): void {
    if (this.hasTableTarget) {
      this.table = this.tableTarget
    } else {
      this.table = this.element
    }

    if (this.table.getAttribute('data-model-name') === null) {
      this.modelName = 'crud-table'
    } else {
      this.modelName = this.table.getAttribute('data-model-name')
    }
    this.tablePolling()

    // Change model name to plural with capital letter (PascalCase),
    // eg 'product' => 'Products' or 'salary-employees' => 'SalaryEmployees'
    const pluralName = pluralize(this.modelName)
    const camelCaseName = this.toCamel(pluralName)
    const controllerName = camelCaseName[0].toUpperCase() + camelCaseName.slice(1)

    if (typeof (window as any).Luca.Controllers[controllerName] === 'undefined') {
      ;(window as any).Luca.Controllers[controllerName] = {}
    }
    ;(window as any).Luca.Controllers[controllerName].CrudTableController = this

    this.connectAjaxListeners()
  }

  new(modal_id: string, html: string) {
    let elem = document.querySelector(`[data-bs-target="${modal_id}"]`)
    if (elem === null) {
      let modal = document.createElement('div')
      modal.innerHTML = html
      document.body.appendChild(modal)
    } else {
      elem.outerHTML = html
    }
    Modal.getOrCreateInstance(document.querySelector(`[data-bs-target="${modal_id}"]`)).show()
  }

  edit(html: string): void {
    // We use HTML ids since we use Bootstrap modals.
    // Find the target id to open the modal
    let div = document.createElement('div')
    div.innerHTML = html
    let modal_id = (div.querySelector('.modal') as HTMLElement).dataset.bsTarget
    let elem = this.table.querySelector(`[data-bs-target="${modal_id}"]`)
    if (elem === null) {
      let modal = document.createElement('div')
      modal.innerHTML = html
      this.table.insertAdjacentElement('beforeend', modal)
    } else {
      elem.outerHTML = html
    }
    Modal.getOrCreateInstance(this.table.querySelector(`[data-bs-target="${modal_id}"]`)).show()
  }

  create(html: string, hideModal: boolean = true, position: InsertPosition = 'afterbegin'): void {
    if (this.targets.has('table')) {
      this.table.querySelector('tbody').insertAdjacentHTML(position, html)
    }
    if (hideModal) {
      this.hideModal()
    }
  }

  update(hashid: string, html: string): void {
    let element = this.table.querySelector(`[data-model-hashid="${hashid}"`)
    if (element == null) {
      // This is mostly for debugging, but very practical
      console.warn(`Could not find element: data-model-hashid="${hashid}"`)
    } else {
      element.outerHTML = html
    }
    this.hideModal()
  }

  destroy(hashid: string): void {
    let el = this.table.querySelector(`[data-model-hashid="${hashid}"`)
    if (el !== null) {
      el.parentNode.removeChild(el)
    }
  }

  updateTable(html: string): void {
    this.table.innerHTML = html
  }

  tablePolling() {
    let poll = setInterval(function () {
      // We can not use target here as that will not be updated on each poll.
      if (document.querySelector('[data-polling-url]') === null) {
        clearInterval(poll)
      } else {
        document.querySelector('#flash_alert')?.remove()
        $.ajax({
          type: 'GET',
          url: (document.querySelector('[data-polling-url]') as HTMLElement).dataset.pollingUrl,
          dataType: 'script',
        })
      }
    }, 3000)
  }

  hideModal() {
    const modal = document.querySelector('.modal.show')
    if (modal !== null) {
      Modal.getInstance(modal).hide()
    } else {
      document.querySelector('.modal-backdrop.show')?.remove()
    }
  }

  toCamel(s): string {
    return s.replace(/([-_][a-z0-9])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  connectAjaxListeners(): void {
    // We only listen to the first form which is expected to be the search form in a table
    // If this causes some issues we should add support for conditionally setting a form target
    const form = this.element.querySelector('form')
    if (form == null) return

    form.addEventListener('ajax:beforeSend', (event: CustomEvent) => {
      this.table.style.position = 'relative'
      // Replace only the parameters to ensure we request the same page when refreshing.
      if (event.detail[1].data !== null) {
        const url = new URL(window.location.href)
        let search = new URLSearchParams(event.detail[1].data)
        search.forEach((value, key) => url.searchParams.set(key, value))
        Turbo.navigator.history.push(url)
      }
      const spinner = icon(faSpinner, { classes: ['fa-spin', 'fa-3x'] }).node[0]
      const table = this.table
      let outerSpinner = document.createElement('div')
      outerSpinner.classList.add('busy-indicator')
      outerSpinner.appendChild(spinner)
      table.appendChild(outerSpinner)
    })
    form.addEventListener('ajax:success', () => {
      // If we have a remote link in the table it will trigger the spinner but never remove it.
      // This is links such as edit which renders modal and delete which removes table item.
      this.table.querySelector('.fa-spinner')?.parentElement?.remove()
    })
    form.addEventListener('ajax:error', (event: CustomEvent) => {
      console.error(`Got an error with the following data:`)
      console.error(event.detail)
      // We do a quite crude error handling here, this should be improved later
      // However it is much better than just ignoring it
      this.table.innerHTML = i18n.t('generic.error_message')
    })
  }
}
