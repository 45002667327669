<template>
  <select>
    <slot></slot>
  </select>
</template>

<script>
import $ from 'jquery'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    url: {
      required: true,
      type: String,
    },
    options: {
      type: Array,
    },
    modelValue: {
      type: String,
    },
  },
  mounted() {
    let $element = $(this.$el)
    let dropdownParent = this.$el.closest('.modal') || document.body

    let vm = this
    $element
      .select2({
        theme: 'bootstrap',
        width: '100%',
        data: this.options,
        dropdownParent: dropdownParent,
        ajax: {
          url: this.url,
          dataType: 'json',
          delay: 250,
          data(params) {
            return {
              term: params.term,
              page: params.page,
            }
          },
          processResults(data, params) {
            //  parse the results into the format expected by Select2
            //  since we are using custom formatting functions we do not need to
            //  alter the remote JSON data, except to indicate that infinite
            //  scrolling can be used
            params.page = params.page || 1
            return {
              results: data,
              pagination: {
                more: params.page * 25 < data.total_count,
              },
            }
          },
          cache: true,
        },
        templateResult: vm.template,
        templateSelection(selected) {
          vm.$emit('select', selected)
          return selected.name
        },
      })
      .val(this.value)
      .trigger('change')
      .on('change', function () {
        vm.$emit('update:modelValue', this.value)
      })
  },
  watch: {
    modelValue: function (value) {
      $(this.$el).val(value).trigger('change')
    },
    options: function (options) {
      $(this.$el).empty().select2({ data: options })
    },
  },
  unmounted: function () {
    $(this.$el).off().select2('destroy')
  },
  methods: {
    template(repo) {
      if (repo.name) {
        return repo.name
      }
      return ''
    },
  },
})
</script>

<style scoped></style>
