import BankReconciliationService from '../services/BankReconciliationService'
import {
  BankReconciliation,
  BankTransaction,
  BankTransactionAndElem,
  Match,
  Posting,
  PostingAndCardElem,
  SuggestedMatch,
} from '../module'
import BankReconciliationLineItemService from '../services/BankReconciliationLineItemService'
import CustomerInvoiceService from '../services/CustomerInvoiceService'
import { defineStore } from 'pinia'
import { i18n } from '../../../../libraries/i18n'

export const useBankReconciliationStore = defineStore('bankReconciliation', {
  state: () => ({
    bankReconciliation: null as BankReconciliation,
    showReconnectIntegrationNotification: false,
    showDataInProgressAlert: false,
    reconnectIntegrationLink: null,
    matchedItems: [],
    suggestedMatchings: {
      postingToBankTransaction: [] as Array<SuggestedMatch>,
      bankTransactionToPosting: [] as Array<SuggestedMatch>,
    },
    postings: [],
    bankStatementItems: [],
    // unpaidCustomerInvoices is HTML of a invoice table if there are available unpaid invoices
    unpaidCustomerInvoices: undefined,
    // allCustomerInvoices is HTML of a invoice table if there are invoices
    allCustomerInvoices: undefined,
    selectedPostings: [] as Array<PostingAndCardElem>,
    selectedBankTransactions: [] as Array<BankTransactionAndElem>,
    matchCardBounds: { top: 0, left: 0, height: 0, width: 0 },
    selectedPostingId: null,
  }),
  actions: {
    togglePosting(selected: boolean, posting: Posting, cardElem: Element) {
      if (selected) {
        let elem = { posting, cardElem }
        this.selectedPostings.push(elem)
      } else {
        this.selectedPostings.splice(
          this.selectedPostings.findIndex((item) => item.posting.id === posting.id),
          1,
        )
      }
    },
    toggleBankStatement(selected: boolean, bankStatementItem: BankTransaction, cardElem: Element) {
      if (selected) {
        this.selectedBankTransactions.push({ bankStatementItem, cardElem })
      } else {
        this.selectedBankTransactions.splice(
          this.selectedBankTransactions.findIndex((item) => item.bankStatementItem.id === bankStatementItem.id),
          1,
        )
      }
    },
    async match() {
      const postings = this.selectedPostings.map((item) => {
        return item.posting
      })
      const bankTransactions = this.selectedBankTransactions.map((item) => {
        return item.bankStatementItem
      })

      const result = await BankReconciliationService.match(this.bankReconciliation, bankTransactions, postings)

      let data = await result.json()

      this.matchedItems.push({
        id: data.id,
        postings: postings,
        bankTransactions: bankTransactions,
      })

      this.selectedPostings.forEach((posting) => {
        this.postings.splice(
          this.postings.findIndex((item) => item.id === posting.posting.id),
          1,
        )
      })

      this.selectedBankTransactions.forEach((bankTransaction) => {
        this.bankStatementItems.splice(
          this.bankStatementItems.findIndex((item) => item.id === bankTransaction.bankStatementItem.id),
          1,
        )
      })
      this.selectedPostings = []
      this.selectedBankTransactions = []
    },

    async unMatch(payload: Match) {
      await BankReconciliationService.unMatch(this.bankReconciliation, payload)
      this.matchedItems.splice(
        this.matchedItems.findIndex((item) => item.id === payload.id),
        1,
      )
      this.postings.push(...payload.postings)
      this.bankStatementItems.push(...payload.bankTransactions)
    },
    async fetchUnpaidCustomerInvoices(bankTransactionId: string) {
      const ans = await CustomerInvoiceService.match_payments_index(
        this.bankReconciliation.bankAccountId,
        this.bankReconciliation.companyId,
        bankTransactionId,
      )

      let data = await ans.json()
      this.unpaidCustomerInvoices = data.html
    },
    async fetchAllCustomerInvoices(bankTransactionId: string) {
      const ans = await CustomerInvoiceService.customer_invoices(
        this.bankReconciliation.bankAccountId,
        this.bankReconciliation.companyId,
        bankTransactionId,
      )
      let data = await ans.json()
      this.allCustomerInvoices = data.html
    },
    async setReconciled(reconciled: boolean) {
      await BankReconciliationService.setReconciled(this.bankReconciliation, reconciled)
      this.bankReconciliation.finalized = reconciled
      if (!reconciled) {
        this.bankReconciliation.canBeReconciled = true
      }
    },
    async addFeeOrInterest(postingOn: Date, amountCents: number, type: string) {
      const ans = await BankReconciliationLineItemService.create(this.bankReconciliation, postingOn, amountCents, type)

      let data = await ans.json()

      const newPosting = {
        id: data.id,
        postingDate: postingOn,
        description: i18n.t(`posting.source_description.bank_reconciliation.${type}`),
        amountCents: type == 'fee' ? -amountCents : amountCents,
        destroyable: true,
      }

      this.postings.push(newPosting)

      return newPosting
    },
    async addFeeOrInterestAndMatchItem(
      bankStatementItem: BankTransaction,
      postingOn: Date,
      amountCents: number,
      type: string,
    ) {
      let feeOrInterestPosting = await this.addFeeOrInterest(postingOn, Math.abs(amountCents), type)

      this.togglePosting(true, feeOrInterestPosting, null)
      this.toggleBankStatement(true, bankStatementItem, null)

      await this.match()
    },
    async destroyPosting(posting: Posting) {
      let res = await BankReconciliationLineItemService.destroy(posting.id)

      this.selectedPostings = []

      this.postings.splice(
        this.postings.findIndex((item) => item.id === posting.id),
        1,
      )

      // if posting was reversed then we display reversed posting and match it with the original one
      let data = await res.json()
      if (data.status == 'ok' && data.reversed) {
        const reversedPosting = {
          id: data.id,
          postingDate: posting.postingDate,
          description: i18n.t('posting.reverse.description'),
          amountCents: -posting.amountCents,
          destroyable: false,
        }

        posting.destroyable = false
        this.postings.push(posting)
        this.postings.push(reversedPosting)

        this.togglePosting(true, reversedPosting, null)
        this.togglePosting(true, posting, null)

        await this.match()
      }
    },
  },
  getters: {
    showMatchButton(): boolean {
      const postingsLength = this.selectedPostings.length
      const bankTransactionsLength = this.selectedBankTransactions.length
      switch (postingsLength) {
        case 0:
          return false
        case 1:
          return bankTransactionsLength > 0
        default:
          return true
      }
    },
    selectedPostingCards(): Element[] {
      return this.selectedPostings.map((item) => {
        return item.cardElem
      })
    },
    selectedBankTransactionCards(): Element[] {
      return this.selectedBankTransactions.map((item) => {
        return item.cardElem
      })
    },
    selectedPostingSum(): number {
      return this.selectedPostings.reduce((a, b) => a + b.posting.amountCents, 0)
    },
    selectedBankTransactionSum(): number {
      return this.selectedBankTransactions.reduce((a, b) => a + b.bankStatementItem.amountCents, 0)
    },
    postingsSum(): number {
      const active = this.postings.reduce((a: number, b: Posting) => a + b.amountCents, 0)

      return (
        active +
        this.matchedItems.reduce((sum: number, match: Match) => {
          return (
            sum +
            match.postings.reduce((sumInner: number, b: Posting) => {
              return sumInner + b.amountCents
            }, 0)
          )
        }, 0)
      )
    },
    bankTransactionsSum(): number {
      const active = this.bankStatementItems.reduce((a: number, b: BankTransaction) => a + b.amountCents, 0)

      return (
        active +
        this.matchedItems.reduce((sum: number, match: Match) => {
          return (
            sum +
            match.bankTransactions.reduce((sumInner: number, b: BankTransaction) => {
              return sumInner + b.amountCents
            }, 0)
          )
        }, 0)
      )
    },
    monthFinished(): boolean {
      return true
    },
    reconciledState(): string {
      if (this.bankReconciliation?.finalized) {
        return 'Reconciled'
      }
      if (this.postings.length > 0 || this.bankStatementItems.length > 0) {
        return 'OpenItems'
      }
      return 'ReadyForReconciliation'
    },
    bankTransactionSuggestedMatch(): SuggestedMatch {
      // We only return suggestions if a single posting is chosen
      if (this.selectedPostings.length !== 1) {
        return {
          id: '',
          candidates: [],
        }
      } else {
        let suggestions = this.suggestedMatchings.postingToBankTransaction.find(
          (suggestion) => suggestion.id === this.selectedPostings[0].posting.id,
        )
        if (!suggestions) {
          return {
            id: '',
            candidates: [],
          }
        } else {
          return suggestions
        }
      }
    },
    postingSuggestedMatch(): SuggestedMatch {
      // We only return suggestions if a single bank transaction is chosen
      if (this.selectedBankTransactions.length !== 1) {
        return {
          id: '',
          candidates: [],
        }
      } else {
        let suggestions = this.suggestedMatchings.bankTransactionToPosting.find(
          (suggestion) => suggestion.id === this.selectedBankTransactions[0].bankStatementItem.id,
        )
        if (!suggestions) {
          return {
            id: '',
            candidates: [],
          }
        } else {
          return suggestions
        }
      }
    },
  },
})
