import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../util/rails_fetch'

export default class extends Controller {
  static values = { url: String }

  declare urlValue: string

  connect() {
    this.element.querySelectorAll('a').forEach((linkElement) => {
      linkElement.addEventListener('click', (event) => {
        event.preventDefault()
        this.dismiss(event)
      })
    })
  }

  async dismiss(event) {
    rails_fetch(this.urlValue, {
      method: 'POST',
    }).then(() => {
      this.element.parentNode.removeChild(this.element)
      window.location.href = event.target.href
    })
  }
}
