<template>
  <div class="row">
    <div class="col-4">
      <StatementSummaryCard
        :ingoing-cents="bankIncomingCents"
        :outgoing-cents="bankOutgoingAmountCents"
        :ingoing-sums-match="ingoingSumsMatch"
        :outgoing-sums-match="outgoingSumsMatch"
        :header="i18n().t('activerecord.attributes.bank_reconciliation.statement')"
        :header-icon="'file-alt'"
      />
    </div>
    <div class="offset-4 col-4">
      <StatementSummaryCard
        :ingoing-cents="accountingIncomingAmountCents"
        :outgoing-cents="accountingOutgoingAmountCents"
        :ingoing-sums-match="ingoingSumsMatch"
        :outgoing-sums-match="outgoingSumsMatch"
        :header="i18n().t('accounting.accounting')"
        :header-icon="'file-check'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import StatementSummaryCard from './StatementSummaryCard.vue'
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    i18n() {
      return i18n
    },
  },
  components: {
    StatementSummaryCard,
  },
  computed: {
    ...mapState(useBankReconciliationStore, ['bankReconciliation', 'bankTransactionsSum', 'postingsSum']),

    accountingOutgoingAmountCents() {
      return this.accountingIncomingAmountCents + this.postingsSum
    },

    bankOutgoingAmountCents() {
      return this.bankIncomingCents + this.bankTransactionsSum
    },
    ingoingSumsMatch() {
      return this.accountingIncomingAmountCents === this.bankIncomingCents
    },
    outgoingSumsMatch() {
      return this.accountingOutgoingAmountCents === this.bankOutgoingAmountCents
    },
    accountingIncomingAmountCents() {
      return this.bankReconciliation ? this.bankReconciliation.incomingAccountingAmountCents : 0
    },
    bankIncomingCents() {
      return this.bankReconciliation ? this.bankReconciliation.incomingBankAmountCents : 0
    },
  },
})
</script>
