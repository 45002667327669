import { Controller } from '@hotwired/stimulus'
import PreviewController from './preview_controller'

export default class extends Controller {
  static targets = ['preview']

  declare previewTarget: HTMLElement

  preview(event) {
    event.preventDefault()
    this.previewController.toggle(event.currentTarget.href)
    event.currentTarget.classList.add('fw-bold')

    return false
  }

  get previewController(): PreviewController {
    return this.application.getControllerForElementAndIdentifier(this.previewTarget, 'preview') as PreviewController
  }
}
