<template>
  <div class="card-luca mb-4">
    <div class="card-header d-flex align-items-center" v-bind:class="stateClass">
      <font-awesome-icon :icon="['far', 'handshake']" />
      <div class="ms-1 text-capitalize">{{ this.month }}</div>
    </div>
    <div class="card-body">
      <ReadyForReconciliation v-if="this.reconciledState === 'ReadyForReconciliation'" />
      <OpenItems v-if="this.reconciledState === 'OpenItems'" />
      <Reconciled v-if="this.reconciledState === 'Reconciled'" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../../store'
import ReadyForReconciliation from './ReadyForReconciliation.vue'
import OpenItems from './OpenItems.vue'
import Reconciled from './Reconciled.vue'
import { formatInterval } from '../../../../../libraries/datefnt'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { OpenItems, ReadyForReconciliation, Reconciled },
  computed: {
    ...mapState(useBankReconciliationStore, [
      'postings',
      'bankStatementItems',
      'bankReconciliation',
      'reconciledState',
    ]),
    header() {
      switch (this.reconciledState) {
        case 'OpenPosts':
          return i18n.t('automatic_bank_reconciliation.v2.transactions_not_matched', {
            postings: this.postings.length,
            bank_statement_items: this.bankStatementItems.length,
          })
        case 'ReadyForReconciliation':
          return
        default:
          throw new Error(`Unknown reconciled state ${this.reconciledState}`)
      }
    },
    body() {
      return i18n.t('automatic_bank_reconciliation.v2.click_any_item')
    },
    month() {
      if (this.bankReconciliation === null) {
        return ''
      }

      return formatInterval({ start: this.bankReconciliation.beginningOn, end: this.bankReconciliation.endingOn })
    },
    stateClass() {
      return this.reconciledState === 'OpenItems' ? 'card-warning' : 'card-success'
    },
  },
})
</script>
