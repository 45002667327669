import { Controller } from '@hotwired/stimulus'
import autoComplete from '../../standalone/components/AutoComplete'
import { i18n } from '../../libraries/i18n'
// IMPORTANT: You MUST define data-partner-type on the controlled element. Its
// value is passed as the value of the type query parameter to the search
// endpoint.
export default class extends Controller {
  static targets = ['searchInput']

  declare searchInputTarget: HTMLInputElement

  searchCorporation: boolean

  connect() {
    const partnerType = this.searchInputTarget.getAttribute('data-partner-type')
    if (!partnerType) {
      console.error('You must define data-partner-type on the controller element')
      return
    }
    this.searchCorporation = false
    this.autoCompleteSearch(this, partnerType)
    this.updatePlaceholderText()
  }

  autoCompleteSearch(self, partnerType) {
    let xhr
    new autoComplete({
      selector: `input[id="${this.searchInputTarget.id}"]`,
      minChars: 1,
      delay: 250,
      offsetTop: 10,
      source: function (term, response) {
        // Abort if the last call did not finish before new call
        try {
          xhr.abort()
        } catch (e) {}
        xhr = $.getJSON(
          `/${(window as any).current_company_id}/partners/search.json`,
          { term: term, type: partnerType, corporation: self.searchCorporation },
          function (data) {
            response(data)
          },
        )
      },
      renderItem: function (item, search) {
        // escape special characters
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
        let re = new RegExp('(' + search.split(' ').join('|') + ')', 'gi')
        let encodedPartner = encodeURIComponent(JSON.stringify(item))
        return `<div id="${item.organisation_number}" 
                                data-json="${encodedPartner}" 
                                class="row autocomplete-suggestion">
                    <div class="autocomplete__partner-name">${item.name.replace(re, '<b>$1</b>')}</div>
                    <div class="autocomplete__org-number">
                        ${self.partnerIdentifier(item)} 
                    </div>
                </div>`
      },
      onSelect: function (e, term, item) {
        if (e.type === 'keydown') {
          e.preventDefault()
        }
        let selected = document.getElementsByClassName('autocomplete-suggestion selected')[0]
        let parsedJSON = JSON.parse(decodeURIComponent(selected.getAttribute('data-json')))
        self._dispatch('partner--search:change', { partner: parsedJSON })
      },
    })
  }

  partnerIdentifier(item) {
    if (this.isSearchingCorporations && item.organisation_number) {
      return `<div>${i18n.t('company.organisation_number_abbreviation')}</div>
              <div>${item.organisation_number}</div>`
    } else if (!this.isSearchingCorporations && item.phone_number) {
      return `<div>${i18n.t('partner.phone_number_abbreviation')}</div>
              <div>${item.phone_number}</div>`
    } else return ''
  }

  select(partner) {
    const option = new Option(partner.name, partner.id, true, true)
    $(this.element).append(option).trigger('change')
  }

  switchToSearchingCorporations() {
    this.searchCorporation = true
    this.updatePlaceholderText()
  }

  switchToSearchingPrivate() {
    this.searchCorporation = false
    this.updatePlaceholderText()
  }

  get isSearchingCorporations() {
    return this.searchCorporation
  }

  get isSelected() {
    return !!(this.element as HTMLInputElement).value
  }

  updatePlaceholderText() {
    let badge = this.searchInputTarget.nextSibling.firstChild as HTMLElement
    if (this.isSearchingCorporations) {
      this.searchInputTarget.placeholder = i18n.t('company.new_search_placeholder')
      badge.innerHTML = i18n.t('company.new_search_badge')
    } else {
      this.searchInputTarget.placeholder = i18n.t('partner.new_search_placeholder')
      badge.innerHTML = i18n.t('partner.new_search_badge')
    }
    badge.style.right = `${badge.getBoundingClientRect().width}px`
  }

  _dispatch(eventName, params = {}) {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.searchInputTarget.dispatchEvent(cancelEvent)
  }
}
