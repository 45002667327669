<template>
  <div>
    <span class="badge badge-info">{{ code() }}</span>
    <span class="ms-2 align-middle">{{ account.description }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Account } from '../module'

export default defineComponent({
  methods: {
    code() {
      return this.account.sub_code ? `${this.account.code}:${this.account.sub_code}` : this.account.code
    },
  },
  props: {
    account: {
      required: true,
      type: Object as PropType<Account>,
    },
  },
})
</script>
