import 'regenerator-runtime/runtime'

// Polyfill for Event.submitter, needed for Safari on places we use multiple buttons in one form
// This Polyfill function must be called to be enabled to avoid adding this global click listener on all our pages
export function polyFillEventSubmitter() {
  let lastBtn = null
  document.addEventListener(
    'click',
    function (e) {
      const target = e.target as HTMLElement
      if (!target.closest) return
      lastBtn = target.closest('button, input[type=submit]')
    },
    true,
  )
  document.addEventListener(
    'submit',
    function (e: any) {
      if (e.submitter) return
      var canditates = [document.activeElement, lastBtn]
      for (var i = 0; i < canditates.length; i++) {
        var candidate = canditates[i]
        if (!candidate) continue
        if (!candidate.form) continue
        if (!candidate.matches('button, input[type=button], input[type=image]')) continue
        e.submitter = candidate
        return
      }
      e.submitter = (e.target as HTMLElement).querySelector('button, input[type=button], input[type=image]')
    },
    true,
  )
}
