import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radio', 'target']

  declare radioTargets: Array<HTMLInputElement>
  declare targetTargets: Array<HTMLInputElement>

  connect() {
    this.reveal()
  }

  reveal() {
    this.radioTargets.forEach((radio) => {
      if (radio.checked) {
        this.targetTargets.forEach((target) => {
          if (target.dataset.radioShowValue) {
            this.show(target, target.dataset.radioShowValue === radio.value)
          } else if (target.dataset.radioHideValue) {
            this.show(target, !(target.dataset.radioHideValue == radio.value))
          } else {
            this.show(target, false)
          }
        })
      }
    })
  }

  private show(target: HTMLElement, value: boolean) {
    if (value) {
      target.classList.remove('d-none')
    } else {
      target.classList.add('d-none')
    }
  }
}
