<template>
  <div class="mt-2">
    <font-awesome-layers class="me-1">
      <font-awesome-icon :icon="['fas', 'square']" :class="matchQualityClass" transform="" />
      <font-awesome-icon
        :icon="['fas', 'square']"
        class="layered-duotone-icon"
        :class="matchQualityClass"
        transform="shrink-4"
      />
    </font-awesome-layers>
    <b>{{ this.quantity }}</b> {{ str }}
  </div>
</template>
<script>
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    level: {
      type: String,
    },
    quantity: {
      type: Number,
    },
  },
  computed: {
    str() {
      let level = i18n.t(this.level, { scope: 'automatic_bank_reconciliation.v2.suggestion_quality' })
      let suggestion = i18n.t('automatic_bank_reconciliation.v2.suggestion', { count: this.quantity })
      return `${level} ${suggestion}`
    },
    matchQualityClass() {
      return this.level === 'exact' || this.level === 'very_high' ? 'text-success' : 'text-warning'
    },
  },
})
</script>
