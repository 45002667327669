function toggleChevron(event) {
  let collapsable = event.target
  let buttonIcon = collapsable.parentElement.querySelector(`[data-bs-target="#${collapsable.id}"] svg`)
  if (buttonIcon) {
    buttonIcon.classList.toggle('fa-chevron-up')
    buttonIcon.classList.toggle('fa-chevron-down')
  }
}

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.acc-collapsable').forEach((accElem) => {
    accElem.addEventListener('hide.bs.collapse', (event) => {
      toggleChevron(event)
    })
    accElem.addEventListener('show.bs.collapse', (event) => {
      toggleChevron(event)
    })
    accElem.addEventListener('shown.bs.collapse', (event) => {
      ;((event.target as HTMLElement).parentNode as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'start',
      })
    })
  })

  document.querySelectorAll('.detail-collapsable').forEach((detailElem) => {
    detailElem.addEventListener('hide.bs.collapse', (event) => {
      toggleChevron(event)
    })
    detailElem.addEventListener('show.bs.collapse', (event) => {
      toggleChevron(event)
    })
  })
})
