import {
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  isEqual,
  isSameMonth,
  isSameYear,
  startOfMonth,
  startOfYear,
  Interval,
} from 'date-fns'
import { enUS, nb } from 'date-fns/locale'
import { i18n } from '../libraries/i18n'

const locales = { enUS, nb }

// Ensure datefns formatting can work with our locales
// Based on their suggestion on https://github.com/date-fns/date-fns/blob/master/docs/i18n.md
// Only really useful for formatting where we spell out names such as MMMM (which would give Februar in Norwegian)
// For normal formatting you should just use datefns format directly with our I18n such as
// format(date, i18n.t('date.formats.frontend.datefns.short'))  (which will give 31.12.2021 in Norwegian,
// 12/31/2021 in English)
export default function (date, formatStr) {
  let localeStr = (window as any).I18n.locale
  localeStr = localeStr.replace('en', 'enUS')
  return format(date, formatStr, { locale: locales[localeStr] })
}

export function formatInterval(interval: Interval): string {
  let localeStr = (window as any).I18n.locale
  localeStr = localeStr.replace('en', 'enUS')

  if (isExactMonth(interval)) {
    return format(interval.start, 'MMMM yyyy', { locale: locales[localeStr] })
  } else if (isExactYear(interval)) {
    return format(interval.start, 'yyyy', { locale: locales[localeStr] })
  } else {
    return (
      `${format(interval.start, i18n.t('date.formats.frontend.datefns.short'), { locale: locales[localeStr] })} - ` +
      `${format(interval.end, i18n.t('date.formats.frontend.datefns.short'), { locale: locales[localeStr] })}`
    )
  }
}

function isExactMonth(interval: Interval): boolean {
  return (
    isEqual(endOfMonth(interval.end), endOfDay(interval.end)) &&
    isEqual(startOfMonth(interval.start), interval.start) &&
    isSameMonth(interval.start, interval.end)
  )
}

function isExactYear(interval: Interval): boolean {
  return (
    isEqual(endOfYear(interval.end), endOfDay(interval.end)) &&
    isEqual(startOfYear(interval.start), interval.start) &&
    isSameYear(interval.start, interval.end)
  )
}
