import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['customerAddressZip', 'customerAddressCity', 'customerAddressStreetOne', 'overWriteAddressCheckBox']

  declare customerAddressZipTarget: HTMLInputElement
  declare customerAddressCityTarget: HTMLInputElement
  declare customerAddressStreetOneTarget: HTMLInputElement
  declare overWriteAddressCheckBoxTarget: HTMLElement

  originalValueZip: string
  originalValueCity: string
  originalValueStreetOne: string

  connect() {
    this.originalValueZip = this.customerAddressZipTarget.value
    this.originalValueCity = this.customerAddressCityTarget.value
    this.originalValueStreetOne = this.customerAddressStreetOneTarget.value
    this.renderCheckBox()
  }

  renderCheckBox() {
    const $div = $(this.overWriteAddressCheckBoxTarget).closest('div')
    if (
      this.customerAddressZipTarget.value === this.originalValueZip &&
      this.customerAddressCityTarget.value === this.originalValueCity &&
      this.customerAddressStreetOneTarget.value === this.originalValueStreetOne
    ) {
      $div.hide()
    } else {
      $div.show()
    }
  }
}
