import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = ['companySelector', 'bankAccountSelector', 'userSelector']

  declare companySelectorTarget: HTMLElement
  declare bankAccountSelectorTarget: HTMLElement
  declare userSelectorTarget: HTMLElement

  connect() {
    this._setupSelect2()
  }

  _setupSelect2(): void {
    const params = {
      theme: 'bootstrap',
      width: '100%',
      minimumInputLength: 3, // PS Minimum input length hides the default options
      tags: true,
      insertTag: function (data, tag) {
        // Insert the tag at the end of the results
        data.push(tag)
      },
      createTag: function (params) {
        const term = $.trim(params.term)

        if (term === '') {
          return null
        }

        return {
          id: term,
          text: term,
          newTag: true,
        }
      },
      ajax: {
        url: '/bank/integration/requests/company_data_search.json',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            term: params.term,
          }
        },
        processResults(data, params) {
          //  parse the results into the format expected by Select2
          //  since we are using custom formatting functions we do not need to
          //  alter the remote JSON data, except to indicate that infinite
          //  scrolling can be used
          return {
            results: data,
          }
        },
        cache: true,
      },
      /* This formats what is shown in the selection options */
      templateResult: this._templateCompany,
      /* How a selected result will look when clicked (or selected) */
      templateSelection(selected) {
        if (selected.text) {
          return selected.text
        }
        return selected.name
      },
      placeholder: i18n.t('company.new_search_placeholder'),
    }
    const elem = $(this.companySelectorTarget).select2(params)

    $(this.companySelectorTarget).on('select2:select', (event) => {
      const data = event.params.data as any
      this._updateUsersSelector(data.users)
      this._updateBankAccountSelector(data.bank_accounts)
    })
  }

  _templateCompany(repo): string {
    if (repo.loading) {
      return repo.text
    }
    if (repo.name) {
      return repo.name
    }
    return ''
  }

  _updateUsersSelector(data) {
    this._removeOptions(this.userSelectorTarget)
    data.forEach((user) => {
      let opt = document.createElement('option')
      opt.value = user.id
      opt.innerHTML = user.name
      this.userSelectorTarget.appendChild(opt)
    })
  }

  _updateBankAccountSelector(data) {
    this._removeOptions(this.bankAccountSelectorTarget)
    data.forEach((bankAccount) => {
      let opt = document.createElement('option')
      opt.value = bankAccount.id
      opt.innerHTML = bankAccount.number
      this.bankAccountSelectorTarget.appendChild(opt)
    })
  }

  _removeOptions(selectElement) {
    let i,
      L = selectElement.options.length - 1
    for (i = L; i >= 0; i--) {
      selectElement.remove(i)
    }
  }
}
