import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  confirmBtn: HTMLButtonElement

  initialize() {
    super.initialize()
    this.confirmBtn = document.createElement('button')
    this.confirmBtn.classList.add('btn', 'btn-danger')
  }

  connect() {
    // If we use eventlistener on the element we do not get the context of the stimulus controller, and therefore can
    // not do access any variables or methods, we do however get this if we use 'data-action'. To make the controller
    // easy to use we automatically add 'data-action' to the element.
    this.element.setAttribute('data-action', `${this.context.module.identifier}#confirm`)
  }

  confirm(event: MouseEvent) {
    let target = event.currentTarget as HTMLElement

    if (target.dataset.confirmed === 'true') {
      Popover.getOrCreateInstance(target).hide()
      return // we are already confirmed, just return to allow default action to proceed
    }

    this.confirmBtn.innerText = target.dataset.confirmationYes ?? i18n.t('simple_form.yes')
    this.confirmBtn.addEventListener('click', () => {
      target.dataset.confirmed = 'true'
      target.click()
    })

    let rejectBtn = document.createElement('button')
    rejectBtn.innerText = target.dataset.confirmationNo ?? i18n.t('simple_form.no')
    rejectBtn.classList.add('btn', 'btn-outline-secondary')
    rejectBtn.addEventListener('click', () => {
      Popover.getOrCreateInstance(target).hide()
    })

    let popoverContent = document.createElement('div')
    popoverContent.classList.add('d-flex', 'justify-content-end', 'gap-2')
    popoverContent.appendChild(rejectBtn)
    popoverContent.appendChild(this.confirmBtn)

    Popover.getOrCreateInstance(target, {
      title: target.dataset.title ?? i18n.t('navigation.confirmation'),
      template:
        '<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header pt-3 pb-1 bg-transparent border-0"></h3><div class="popover-body"></div></div>',
      html: true,
      trigger: 'manual',
      sanitize: false,
      content: popoverContent,
    }).show()

    event.preventDefault()
    event.stopImmediatePropagation()
  }
}
