import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['entries', 'pagination']

  declare entriesTarget: HTMLElement
  declare paginationTarget: HTMLElement

  isLoading = false

  scroll() {
    let nextPage = this.paginationTarget.querySelector("a[rel='next'") as HTMLAnchorElement
    if (nextPage) {
      let url = nextPage.href
      let body = document.body,
        html = document.documentElement
      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      )

      if (window.pageYOffset >= height - window.innerHeight - 50) {
        this.loadMore(url)
      }
    }
  }

  loadMore(url) {
    if (!this.isLoading) {
      this.isLoading = true
      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
          if (data.pagination) {
            this.paginationTarget.innerHTML = data.pagination
          } else {
            this.paginationTarget.innerHTML = ''
          }
          this.isLoading = false
        },
      })
    }
  }
}
