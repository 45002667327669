import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../classes/parse_decimal'
import Decimal from 'decimal.js'

// This controller can be used to compute a total of items and store the result
// in another item. This is most useful when computing totals on invoices,
// financial statements, etc.
//
// LIMITATIONS
//
//   - Currently itemTargets MUST be INPUT tags.
export default class extends Controller {
  static targets = [
    // Items whose total is computed.
    'item',

    // The item containing the computed total.
    'total',
  ]
  declare itemTargets: Array<HTMLInputElement>
  declare totalTargets: Array<HTMLElement>
  total: number

  connect() {
    this.update()
  }

  update() {
    this.totalTargets.forEach((totalTarget) => this.calculateTarget(totalTarget))
  }

  calculateTarget(totalTarget) {
    // If group is not specified we assume only one total target is defined
    let type = totalTarget.getAttribute('data-total-amount-type')
    let items
    if (totalTarget.getAttribute('data-total-amount-group') === null) {
      items = this.itemTargets
    } else {
      // The first target defined is the group to sum/multiply, the others is used by other total targets.
      let group = totalTarget.getAttribute('data-total-amount-group').split(' ')[0]
      // If you need to use the total element as an item as well we exclude the total target as it will calulate wrong
      // And if the element is part of multiple groups we separate them with ' '
      items = this.itemTargets.filter(
        (elem) => elem.getAttribute('data-total-amount-group').split(' ').includes(group) && elem != totalTarget,
      )
    }
    if (type === undefined || type === null || type === 'sum') {
      this.sumTargets(totalTarget, items)
    } else if (type === 'multiply') {
      this.multiplyTargets(totalTarget, items)
    }
    // If the total target is part of multiple groups we update those groups as well
    let groups = totalTarget.getAttribute('data-total-amount-group')
    if (groups !== null && groups.split(' ').length > 1) {
      for (let i = 1; i < groups.split(' ').length; i++) {
        let target = this.totalTargets.find(
          (t) => t.getAttribute('data-total-amount-group').split(' ')[0] === groups[i],
        )
        if (target != null) {
          this.calculateTarget(target)
        }
      }
    }
  }

  sumTargets(totalTarget, items) {
    this.total = items
      .reduce((subTotal, inputTag) => subTotal.plus(parseDecimal(inputTag.value, new Decimal(0))), new Decimal(0))
      .toNumber()

    this._setTargetValues(totalTarget, this.total)
  }

  // This will multiply all items. Useful when working with f.ex. units and unit price.
  multiplyTargets(totalTarget, items) {
    let total = items.reduce(
      (subTotal, inputTag) => subTotal.times(parseDecimal(inputTag.value), new Decimal(1)),
      new Decimal(1),
    )

    this._setTargetValues(totalTarget, total)
  }

  _setTargetValues(totalTarget, value) {
    const formattedTotal = new Intl.NumberFormat('nb-NO', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(value)
      // Use regular minus instead of the special minus that Intl.NumberFormat seems like use
      .replaceAll('\u2212', '-')

    // We need trim to remove the whitespace added by toCurrency at the end.
    totalTarget.value = formattedTotal
    totalTarget.innerHTML = formattedTotal
  }
}
