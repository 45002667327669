import { Controller } from '@hotwired/stimulus'
import { amountStr } from '../formatting_helper'

export default class extends Controller {
  static targets = [
    'entryRow',
    'matchAmount',
    'infoBox',
    'closeEntriesButton',
    'openEntriesButton',
    'openCloseWarningInfoBox',
    'accountIdsWarningInfoBox',
    'form',
    'selectAllAccounts',
    'centsDifferenceNotice',
  ]

  declare matchAmountTarget: HTMLInputElement
  declare infoBoxTarget: HTMLInputElement
  declare closeEntriesButtonTarget: HTMLInputElement
  declare openEntriesButtonTarget: HTMLInputElement
  declare openCloseWarningInfoBoxTarget: HTMLInputElement
  declare accountIdsWarningInfoBoxTarget: HTMLInputElement
  declare formTarget: HTMLInputElement
  declare selectAllAccountsTargets: Array<HTMLInputElement>
  declare entryRowTargets: Array<HTMLInputElement>
  declare centsDifferenceNoticeTarget: HTMLElement

  static values = { maxCentsRoundingValue: Number }

  declare maxCentsRoundingValueValue: number

  updateInfoBox() {
    const rows = this.entryRowTargets
    let openedSelectedRows = []
    let closedSelectedRowsNumber = 0
    let accountIds = []

    rows.forEach((item) => {
      let entryCheckbox = item.getElementsByClassName('entry-checked')[0] as HTMLInputElement
      if (entryCheckbox.checked) {
        if (item.getElementsByClassName('entry-status')[0].getAttribute('value') === 'true') {
          closedSelectedRowsNumber += 1
        } else {
          openedSelectedRows.push(item)
        }
        let accountInputElem = item.querySelector('input[name$="[account_hashid]"]') as HTMLInputElement
        accountIds.push(accountInputElem.value)
      }
    })

    if (closedSelectedRowsNumber > 0 && openedSelectedRows.length > 0) {
      // show warning info box
      this.infoBoxTarget.classList.add('d-none')
      this.openCloseWarningInfoBoxTarget.classList.remove('d-none')
      this.accountIdsWarningInfoBoxTarget.classList.add('d-none')
    } else if (closedSelectedRowsNumber === 0 && openedSelectedRows.length === 0) {
      // hide all info boxes
      this.infoBoxTarget.classList.add('d-none')
      this.openCloseWarningInfoBoxTarget.classList.add('d-none')
      this.accountIdsWarningInfoBoxTarget.classList.add('d-none')
    } else if (accountIds.length > 0 && !accountIds.every((val, i, arr) => val === accountIds[0])) {
      // Got different account ids
      this.infoBoxTarget.classList.add('d-none')
      this.openCloseWarningInfoBoxTarget.classList.add('d-none')
      this.accountIdsWarningInfoBoxTarget.classList.remove('d-none')
    } else if (closedSelectedRowsNumber > 0) {
      // show open entries info box
      this.openCloseWarningInfoBoxTarget.classList.add('d-none')
      this.accountIdsWarningInfoBoxTarget.classList.add('d-none')
      this.closeEntriesButtonTarget.classList.add('d-none')
      this.openEntriesButtonTarget.classList.remove('d-none')
      this.infoBoxTarget.classList.remove('alert-success')
      this.infoBoxTarget.classList.add('alert-info')
      this.infoBoxTarget.classList.remove('d-none')
    } else {
      // show close entries info box
      this.updateTotalAmount(openedSelectedRows)
      this.openCloseWarningInfoBoxTarget.classList.add('d-none')
      this.accountIdsWarningInfoBoxTarget.classList.add('d-none')
      this.openEntriesButtonTarget.classList.add('d-none')
      this.closeEntriesButtonTarget.classList.remove('d-none')
      this.infoBoxTarget.classList.remove('d-none')
    }
  }

  updateTotalAmount(selectedRows) {
    const totalAmount = selectedRows.reduce((subTotal, element) => {
      let amountCents = parseInt(element.getElementsByClassName('entry-amount')[0].dataset.amountCents)
      if (isNaN(amountCents)) {
        return subTotal
      } else {
        return subTotal + amountCents
      }
    }, 0)

    const totalAmountText = amountStr(totalAmount / 100)

    this.matchAmountTarget.innerHTML = totalAmountText

    this.centsDifferenceNoticeTarget.classList.add('d-none')
    const canRoundCents = this.canRoundCents(totalAmount)

    if (totalAmount === 0 || (canRoundCents && selectedRows.length > 1)) {
      if (canRoundCents) {
        this.centsDifferenceNoticeTarget.classList.remove('d-none')
      }
      this.infoBoxTarget.classList.remove('alert-info')
      this.infoBoxTarget.classList.add('alert-success')
      this.closeEntriesButtonTarget.getElementsByTagName('button')[0].disabled = false
    } else {
      this.infoBoxTarget.classList.remove('alert-success')
      this.infoBoxTarget.classList.add('alert-info')
      this.closeEntriesButtonTarget.getElementsByTagName('button')[0].disabled = true
    }
  }

  canRoundCents(totalAmount) {
    return totalAmount != 0 && Math.abs(totalAmount) <= this.maxCentsRoundingValueValue
  }

  selectAccounts(event) {
    let accountTable = event.target.closest('table')
    $('td input:checkbox', accountTable).prop('checked', event.target.checked)
    this.updateInfoBox()
  }
}
