export default class Vat {
  // Takes in a value including VAT, and returns a tuple with [value_ex_vat, vat]
  //  The sum of the tuple is guaranteed to be the same as the value coming in
  //  This adheres to the normal rules of how to decompose VAT.
  //  Expects integer input (cents), and outputs cents as well
  //  This method will return in exact cents, never decimals.
  inc_to_ex(val, rate) {
    const ex = Math.round(val / (1 + rate))
    const vat = val - ex

    return [ex, vat]
  }

  // This method can return decimal values. This is since many times this calculation will be used by
  // other methods, which might need the decimals in a bigger calculation before rounding to whole cents
  ex_to_inc(val, rate) {
    return val + this.vat(val, rate)
  }

  // This method rounds the value before calculating VAT. This is the correct way of calculating VAT
  vat(val, rate) {
    return Math.round(Math.round(val) * rate)
  }
}
