import { Controller } from '@hotwired/stimulus'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  initialize() {
    super.initialize()
    this.element.setAttribute('disabled', '')

    // If this input has a hint, we will need to insert it in the same element to make it look nice,
    // otherwise, create a form-text div to hold our link
    let existingFormText = this.existingFormText()
    if (existingFormText == null) {
      this.element.parentNode.insertBefore(this.createFormTextDiv(), this.element.nextSibling)
    } else {
      existingFormText.append(this.createHref('float-end'))
    }
  }

  private existingFormText(): Element | null {
    let sibling = this.element.nextElementSibling
    if (sibling.classList.contains('form-text')) {
      return sibling
    } else {
      return null
    }
  }

  private createFormTextDiv() {
    let div = document.createElement('div')
    div.classList.add('form-text')
    div.append(this.createHref('d-flex', 'justify-content-end'))
    return div
  }

  private createHref(...tokens: string[]): HTMLAnchorElement {
    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement
    a.classList.add(...tokens)
    a.href = '#'
    a.onclick = (ev) => {
      ev.preventDefault()
      this.element.removeAttribute('disabled')
      a.hidden = true
    }
    let text = document.createTextNode(i18n.t('generic.change'))
    a.appendChild(text)
    return a
  }
}
