import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static values = {
    url: String,
    label: String,
    id: String,
    allowClear: Boolean,
    placeholder: String,
    dropdownParent: String,
  }

  declare hasUrlValue: boolean
  declare urlValue: string
  declare hasLabelValue: boolean
  declare labelValue: string
  declare hasIdValue: boolean
  declare idValue: string
  declare hasAllowClearValue: boolean
  declare allowClearValue: boolean
  declare hasPlaceholderValue: boolean
  declare placeholderValue: string
  declare hasDropdownParentValue: boolean
  declare dropdownParentValue: string

  connect() {
    this.element.classList.add('select2-input')
    let options: any = {
      theme: 'bootstrap',
      width: '100%',
      minimumInputLength: 0,
    }

    if (this.hasDropdownParentValue) {
      options.dropdownParent = $(`#${this.dropdownParentValue}`)
    }

    if (this.hasAllowClearValue) {
      options.allowClear = this.allowClearValue
      if (!this.hasPlaceholderValue) {
        options.placeholder = ''
      }
    }

    if (this.hasPlaceholderValue) {
      options.placeholder = this.hasPlaceholderValue
    }

    options = this.setupAjax(options)
    $(this.element).select2(options)

    $(this.element).on('change', (event) => {
      this._dispatch('select2:change')
    })
  }

  setupAjax(options: any) {
    if (!this.hasUrlValue) return options
    if (!this.hasLabelValue || !this.hasIdValue) console.error('Label and/or value is not defined')

    options.ajax = {
      url: this.urlValue,
      dataType: 'json',

      data: (params) => {
        return { term: params.term }
      },
      processResults: (data) => {
        return {
          results: data.map((item) => {
            return { id: item[this.idValue], text: item[this.labelValue] }
          }),
        }
      },
    }
    return options
  }

  _dispatch(eventName, params = {}) {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.element.dispatchEvent(cancelEvent)
  }
}
