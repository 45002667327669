<template>
  <div>
    <h5 class="card-title" v-if="monthNotFinished">
      {{ i18n().t('automatic_bank_reconciliation.v2.month_not_finished_header', { month: this.month }) }}
    </h5>
    <h5 class="card-title" v-else>
      {{ i18n().t('automatic_bank_reconciliation.v2.all_items_reconciled', { month: this.month }) }}
    </h5>
    <p class="card-text" v-if="canBeReconciled">
      {{ i18n().t('automatic_bank_reconciliation.v2.you_can_mark_as_reconciled') }}
    </p>
    <p class="card-text" v-else-if="monthNotFinished">
      {{ i18n().t('automatic_bank_reconciliation.v2.month_not_finished') }}
    </p>
    <p class="card-text" v-else-if="!showDataInProgressAlert">
      {{ i18n().t('automatic_bank_reconciliation.v2.reconcile_previous_months') }}
    </p>

    <div class="button-row">
      <button v-on:click="reconcile()" id="reconcile-button" class="btn btn-primary" :disabled="!canBeReconciled">
        <font-awesome-icon :icon="['far', 'handshake']" />
        {{ i18n().t('bank_reconciliation.reconcile') }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../../store'
import format from '../../../../../libraries/datefnt'
import { isAfter, isSameDay } from 'date-fns'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, { setReconciled: 'setReconciled' }),
    reconcile() {
      this.setReconciled(true)
    },
  },
  computed: {
    ...mapState(useBankReconciliationStore, ['bankReconciliation', 'showDataInProgressAlert']),
    month() {
      if (this.bankReconciliation === null) {
        return ''
      }
      return format(this.bankReconciliation.beginningOn, 'MMMM')
    },
    canBeReconciled() {
      return this.bankReconciliation?.canBeReconciled
    },
    monthNotFinished() {
      if (this.bankReconciliation === null || this.bankReconciliation.endingOn === null) {
        return false
      }
      const now = new Date()
      return isSameDay(this.bankReconciliation.endingOn, now) || isAfter(this.bankReconciliation.endingOn, now)
    },
  },
})
</script>
