import { Controller } from '@hotwired/stimulus'
import Select2Builder from '../../standalone/components/Select2Builder'
import $ from 'jquery'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  initialize() {
    const date = (this.element as HTMLSelectElement).dataset.date

    let dropdownParent = this.element.closest('.modal') || document.body

    $(this.element).select2(
      new Select2Builder().createParams(`/${(window as any).current_company_id}/salary/wage_types.json?date=${date}`, {
        minimumInputLength: 0,
        tags: false,
        placeholder: i18n.t('salary.wage_type.choose_wage_type'),
        dropdownParent: dropdownParent,

        templateResult(wage_type) {
          // Don't modify the text on the loading indicator and group headers.
          if (wage_type.loading || wage_type.children) {
            return wage_type.text
          }
          if (wage_type.i18nName) {
            return $(`<div>${wage_type.i18nName}</div>`)
          }
        },
        templateSelection(selected) {
          if (selected.text === i18n.t('salary.wage_type.choose_wage_type')) return selected.text

          return selected.text
        },
      }),
    )
  }
}
