<template>
  <div>
    <strong v-html="descriptionHeader" />
    <br />
    <p class="mt-3" v-html="descriptionBody" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    descriptionHeader: String,
    descriptionBody: String,
  },
})
</script>
