// Set up Stimulus
/// <reference types="vite/client" />
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

export default class SetupStimulus {
  static start() {
    // @ts-ignore
    const controllers = import.meta.glob('./controllers/**/*_controller.*s', { eager: true })
    registerControllers(Application.start(), controllers)
  }
}
