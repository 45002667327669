import $ from 'jquery'

export default class Toggle {
  constructor() {
    $(document)
      .on('click', "[data-bs-toggle='toggle']", function (e) {
        const $currentTarget = $(e.currentTarget)
        const selector = $currentTarget.data('target')
        $(selector).toggleClass('d-none')

        e.preventDefault()
        return false
      })
      .on('click', '[data-hide]', function (e) {
        const $currentTarget = $(e.currentTarget)
        $($currentTarget.data('hide')).addClass('d-none')
        e.preventDefault()
        return false
      })
      .on('click', '[data-show]', function (e) {
        const $currentTarget = $(e.currentTarget)
        $($currentTarget.data('show')).removeClass('d-none')
        e.preventDefault()
        return false
      })
  }
}
