import $ from '../libraries/jquery'

export default class CompanyLookup {
  validate_organisation_number(organisation_number, warningTarget) {
    $.ajax({
      type: 'GET',
      url: '/company_lookups/show.json',
      data: { organisation_number: organisation_number },
      dataType: 'json',
      success: function (result) {
        if (result.received_response === true) {
          warningTarget.style.display = 'none'
        } else {
          warningTarget.style.display = 'block'
        }
      },
    })
  }
}
