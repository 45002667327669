import { Controller } from '@hotwired/stimulus'
import BankAccount from '../classes/bank_account'
import $ from 'jquery'
import { Modal } from 'bootstrap'
import { ConfigurationOptions } from 'typesense/src/Typesense/Configuration'
import { format, parseISO } from 'date-fns'
import { rails_fetch } from '../util/rails_fetch'
import JsFormError from '../standalone/components/JsFormError'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  static values = { chosenSubscriptionTier: String }

  declare chosenSubscriptionTierValue: String

  static targets = [
    'companySelector',
    'companySelectedBlock',
    'termsRow',
    'organisationNumber',
    'organisationNotRegistered',
    'street',
    'city',
    'municipality',
    'vatRegistered',
    'organisationNumberNew',
    'streetNew',
    'cityNew',
    'zipNew',
    'submitButton',
    'accountNumber',
    'zip',
    'warningModal',
    'companyName',
    'termsAccepted',
    'demoCompanyCard',
    'letsGetStartedCards',
    'tierSubscriptionCards',
    'chosenSubscriptionTier',
    'yearEndStandalone',
    'yearEndStandaloneSubscriptionCard',
  ]

  declare companySelectorTarget
  declare companySelectedBlockTarget: HTMLElement
  declare termsRowTarget: HTMLElement
  declare organisationNotRegisteredTarget
  declare organisationNumberTarget
  declare bankAccount: BankAccount
  declare streetTarget
  declare cityTarget
  declare zipTarget
  declare municipalityTarget
  declare hasMunicipalityTarget: boolean
  declare cityNewTarget
  declare zipNewTarget
  declare streetNewTarget
  declare organisationNumberNewTarget
  declare companyNameTarget
  declare termsAcceptedTarget: HTMLInputElement
  declare demoCompanyCardTarget
  declare hasDemoCompanyCardTarget: Boolean
  declare vatRegisteredTargets: Array<HTMLElement>
  declare accountNumberTarget: HTMLInputElement
  declare submitButtonTarget: HTMLButtonElement
  declare letsGetStartedCardsTarget: HTMLElement
  declare hasTierSubscriptionCardsTarget: boolean
  declare tierSubscriptionCardsTarget: HTMLElement
  declare chosenSubscriptionTierTarget: HTMLElement
  declare yearEndStandaloneTargets: Array<HTMLElement>
  declare yearEndStandaloneSubscriptionCardTarget: HTMLElement
  declare hasYearEndStandaloneSubscriptionCardTarget: boolean

  connect() {
    this._setupSelect2()
  }

  async submit(event) {
    event.preventDefault()

    const form = this.element.querySelector('form')
    const formData = new FormData(form)

    Modal.getOrCreateInstance(document.querySelector('#company-waiting-modal')).show()

    let response = await rails_fetch(form.action, {
      method: form.method,
      body: formData,
    })

    if (response.status != 200) {
      Modal.getOrCreateInstance(document.querySelector('#company-waiting-modal')).hide()
      let data = await response.json()
      new JsFormError(data.model_name, data)
    } else {
      let data = await response.json()
      window.location = data.path
    }
  }

  _setupSelect2(): void {
    const params = {
      theme: 'bootstrap',
      width: '100%',
      delay: 100,
      minimumInputLength: 1, // PS Minimum input length hides the default options
      tags: true,
      insertTag: function (data, tag) {
        // Insert the tag at the end of the results
        data.push(tag)
      },
      createTag: function (params) {
        const term = $.trim(params.term)

        if (term === '') {
          return null
        }

        return {
          id: term,
          text: term,
          newTag: true,
          organisation_number: i18n.t('company.organisation_number_unknown'),
        }
      },
      ajax: {
        url: this.typesenseUrl(),
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8', // avoid preflight check
        headers: { 'X-TYPESENSE-API-KEY': this.typesenseConfig.apiKey },
        data(params) {
          return {
            q: params.term,
            page: params.page,
            per_page: '8',
            query_by: 'name,organisation_number',
          }
        },
        processResults(data, params) {
          return {
            results: data.hits.map((hit) => {
              return {
                id: hit.document.organisation_number,
                name: hit.document.name,
                organisation_number: hit.document.organisation_number,
                business_address_street: hit.document.business_address_street,
                business_address_zip: hit.document.business_address_zip,
                business_address_city: hit.document.buisness_address_city,
                business_address_municipality: hit.document.business_address_municipality,
                vat_registered: hit.document.vat_registered,
              }
            }),
            pagination: {
              more: false,
            },
          }
        },
        cache: true,
      },
      /* This formats what is shown in the selection options */
      templateResult: this._templateNewCompany,
      /* How a selected result will look when clicked (or selected) */
      templateSelection(selected) {
        if (selected.text) {
          return selected.text
        }
        return selected.name
      },
      placeholder: i18n.t('company.new_search_placeholder'),
    }
    $(this.companySelectorTarget).select2(params)

    $(this.companySelectorTarget).on('select2:select', (event) => {
      const data = event.params.data as any
      if (this.hasDemoCompanyCardTarget) {
        this.demoCompanyCardTarget.classList.add('d-none')
      }
      this.companyNameTarget.value = data.name || ''
      this.organisationNumberTarget.value = data.organisation_number || ''
      this.streetTarget.value = data.business_address_street || ''
      this.zipTarget.value = data.business_address_zip || ''
      this.cityTarget.value = data.business_address_city || ''
      if (this.hasMunicipalityTarget) {
        const data_municipality = data.business_address_municipality || ''
        const municipality = this.findMunicipalityHashIdByName(this.municipalityTarget.options, data_municipality)
        this.municipalityTarget.value = municipality
      }
      if (data.vat_registered === undefined) {
        $(this.vatRegisteredTargets).removeClass('d-none')
      } else {
        $(this.vatRegisteredTargets).addClass('d-none')
      }

      this.showYearEndStandaloneSubscriptionCard(!this.unknownOrganisationNumber(data.organisation_number))

      if (data.newTag) {
        // This will happen if user register company that is not present in CompanyLookup
        this.organisationNotRegisteredTarget.value = 'true'
        this.companyNameTarget.parentElement.classList.remove('d-none')
        this.companyNameTarget.value = data.text
        Modal.getOrCreateInstance(document.querySelector('#warning-modal')).show()
        this.companySelectedBlockTarget.classList.remove('d-none')
      } else {
        this.companyNameTarget.parentElement.classList.add('d-none')
        this.organisationNotRegisteredTarget.value = 'false'
        this.companySelectedBlockTarget.classList.add('d-none')
      }

      this.letsGetStartedCardsTarget.classList.add('d-none')
      if (this.chosenSubscriptionTierValue !== '') {
        this.chosenSubscriptionTierTarget.classList.remove('d-none')
        this.showTierFields()
      } else if (this.hasTierSubscriptionCardsTarget) {
        this.tierSubscriptionCardsTarget.classList.remove('d-none')
        this.showTierFields()
      }
      this.termsRowTarget.classList.remove('d-none')
    })
  }

  showYearEndStandaloneSubscriptionCard(show) {
    if (!this.hasYearEndStandaloneSubscriptionCardTarget) {
      return
    }

    if (show) {
      this.yearEndStandaloneSubscriptionCardTarget.classList.remove('d-none')
      this.yearEndStandaloneSubscriptionCardTarget.querySelector('input').disabled = false
    } else {
      this.yearEndStandaloneSubscriptionCardTarget.classList.add('d-none')
      this.yearEndStandaloneSubscriptionCardTarget.querySelector('input').disabled = true
    }
  }

  unknownOrganisationNumber(organisation_number): boolean {
    return organisation_number == i18n.t('company.organisation_number_unknown')
  }

  showDeletedCompanyInfo(data) {
    let existingAlertElement = document.getElementById('alert-info-id')
    if (existingAlertElement) {
      existingAlertElement.remove()
    }

    if (data.deleted_on == null) {
      return
    }

    let alertElement = document.createElement('div')
    alertElement.className = 'alert alert-info'
    alertElement.id = 'alert-info-id'
    let date = format(parseISO(data.deleted_on), i18n.t('date.formats.frontend.datefns.short'))
    alertElement.textContent = i18n.t('company.deleted_company_info', { deleted_at: date })

    document.getElementById('prefilled-block').prepend(alertElement)
  }

  termsAccepted(): void {
    this.submitButtonTarget.disabled = !this.termsAcceptedTarget.checked
  }

  showTierFields() {
    this.yearEndStandaloneTargets.forEach((el: HTMLInputElement) => {
      let show = this.chosenSubscriptionTierValue === 'Subscription::Tier::YearEndStandalone'
      el.disabled = !show
      show ? el.classList.remove('d-none') : el.classList.add('d-none')
    })
  }

  setChosenTierklass(event) {
    this.chosenSubscriptionTierValue = event.params.klass
    this.showTierFields()
  }

  showTierCards(event) {
    event.preventDefault()
    this.chosenSubscriptionTierTarget.classList.add('d-none')
    this.tierSubscriptionCardsTarget.classList.remove('d-none')
  }

  updateVisibleCompanyInfo(event): void {
    event?.preventDefault()
    this.streetNewTarget.innerHTML = this.streetTarget.value
    this.cityNewTarget.innerHTML = this.cityTarget.value
    this.zipNewTarget.innerHTML = this.zipTarget.value
    this.organisationNumberNewTarget.innerHTML = this.organisationNumberTarget.value
  }

  _templateNewCompany(repo): JQuery<HTMLElement> | string {
    if (repo.loading) {
      return repo.text
    }
    // This is the case where we found a result:
    if (repo.name) {
      if (repo.disabled) {
        return $(`<div>
                    <div>${repo.name} (${i18n.t('company.already_registered')})</div>
                    <div class="d-flex justify-content-between">
                      <div><small>${repo.business_address_municipality || ''}</small></div>
                      <div><small>${repo.organisation_number}</small></div>
                    </div>
                  </div>`)
      } else {
        return $(`<div>
                    <div>${repo.name}</div>
                    <div class="d-flex justify-content-between">
                      <div><small>${repo.business_address_municipality || ''}</small></div>
                      <div><small>${repo.organisation_number}</small></div>
                    </div>
                  </div>`)
      }
    }
    if (repo.text) {
      return `${repo.text} ${i18n.t('company.not_found_in_register')}`
    }
    return ''
  }

  findBankName(): void {
    this.bankAccount = new BankAccount()
    this.bankAccount.findBankNameByCode(this.accountNumberTarget)
  }

  findMunicipalityHashIdByName(options, name) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].label === name) {
        return options[i].value
      }
    }
    return null
  }

  get typesenseConfig(): ConfigurationOptions {
    return (window as any).typesenseConfig
  }

  typesenseUrl(): string {
    const firstNode = this.typesenseConfig.nodes[0] as any
    return `${firstNode.protocol}://${firstNode.host}:${firstNode.port}/collections/company_lookups/documents/search`
  }
}
