import { Controller } from '@hotwired/stimulus'
import PartnerSelectController from './partner/select_controller'
// IMPORTANT:
//
// * Point customerSelect to the element controlled by partner-select.
// * Point submit to the form submit button.
export default class extends Controller {
  static targets = ['customerSelect', 'submit']

  declare submitTarget: HTMLButtonElement
  declare customerSelectTarget: HTMLElement

  handlePartnerModalCreate(event) {
    this._partnerSelectController.select(event.partner)
  }

  handlePartnerSelectChange(event) {
    this.submitTarget.disabled = !this._partnerSelectController.isSelected
  }

  get _partnerSelectController() {
    return this.application.getControllerForElementAndIdentifier(
      this.customerSelectTarget,
      'partner--select',
    ) as PartnerSelectController
  }
}
