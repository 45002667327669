<template>
  <ItemCard
    :class="cardClasses"
    :header="bankStatementItem.transactionOn"
    :description="bankStatementItem.description"
    :amountCents="bankStatementItem.amountCents"
    v-on:click="toggleSelectClick"
    v-bind="disabledAttributes"
    ref="card"
  >
    <SuggestionList v-if="selected" :suggestionList="suggestionList" />

    <div v-if="!isDataNotFinalized" class="d-flex justify-content-end mt-3">
      <TableMoreDropdown dropdown-type="secondary" class="mr-2" v-on:click.stop>
        <DropdownItem :href="manualJournalUrl()">
          <font-awesome-icon :icon="['far', 'plus']" class="me-2 ms-0" />
          {{ i18n().t('bank_reconciliation.manual_journal.title') }}
        </DropdownItem>

        <DropdownItem
          href=""
          v-on:click="
            addFeeOrInterestAndMatchItem(
              bankStatementItem,
              bankReconciliation.beginningOn,
              bankStatementItem.amountCents,
              bankStatementItem.amountCents < 0 ? 'fee' : 'interest',
            )
          "
          class="d-flex justify-content-between"
        >
          <font-awesome-icon :icon="['far', 'coins']" class="me-2 ms-0" />
          {{
            bankStatementItem.amountCents < 0
              ? i18n().t('bank_reconciliation.fee.title')
              : i18n().t('bank_reconciliation.interest.title')
          }}
        </DropdownItem>

        <DropdownItem
          href=""
          v-if="bankStatementItem.amountCents > 0"
          v-on:click="openMatchInvoiceModal(bankStatementItem.id)"
          data-test-id="view-button"
          :id="`match-invoice-modal-button-${bankStatementItem.id}`"
          data-action="click->popover#hidePopovers"
          class="d-flex justify-content-between"
        >
          <font-awesome-icon :icon="['far', 'eye']" class="me-2 ms-0" />
          {{ i18n().t('customer_invoice.check_invoices') }}
        </DropdownItem>

        <DropdownItem :href="transactionUrl()" dropdown-type="debug">
          <font-awesome-icon :icon="['far', 'eye']" class="me-2 ms-0" />
          See transaction
        </DropdownItem>
      </TableMoreDropdown>
    </div>
    <MatchInvoiceWithoutPayment :transaction="bankStatementItem" />
  </ItemCard>
</template>
<script lang="ts">
import { Turbo } from '@hotwired/turbo-rails'
import SuggestionList from './item_card/SuggestionList.vue'
import MatchInvoiceWithoutPayment from './top_card/MatchInvoiceWithoutPayment.vue'
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { Modal, Popover } from 'bootstrap'
import TableMoreDropdown from '../../../components/bootstrap/TableMoreDropdown.vue'
import DropdownItem from '../../../components/bootstrap/DropdownItem.vue'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent, type PropType } from 'vue'
import createOrUpdateCandidatePopover from './CandidatePopover'
import ItemCard from './ItemCard.vue'
import { BankTransaction, Candidate } from '../module'

export default defineComponent({
  components: { ItemCard, DropdownItem, TableMoreDropdown, SuggestionList, MatchInvoiceWithoutPayment },
  props: {
    bankStatementItem: {
      type: Object as PropType<BankTransaction>,
      required: true,
    },
    suggestion: {
      type: Object as PropType<Candidate>,
      default: null,
    },
    suggestionList: {
      type: Array<Candidate>,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      selected: false as boolean,
      popover: Popover,
    }
  },
  mounted() {
    let urlSearchParams = new URLSearchParams(window.location.search)
    let params = Object.fromEntries(urlSearchParams.entries())
    let cleanUrl = `${location.pathname}?period=${params['period']}`

    if (this.bankStatementItem.id === params['bank_transaction_id']) {
      this.toggleSelectClick()

      if (
        Object.keys(this.selectedBankTransactions).length > 0 &&
        Object.keys(this.selectedPostings).length > 0 &&
        this.selectedPostingSum !== this.selectedBankTransactionSum
      ) {
        Turbo.navigator.history.push(cleanUrl)
        return
      } else if (this.selectedPostingSum !== this.selectedBankTransactionSum) {
        return
      } else if (
        Object.keys(this.selectedBankTransactions).length == 0 ||
        Object.keys(this.selectedPostings).length == 0
      ) {
        return
      }

      Turbo.navigator.history.push(cleanUrl)

      this.match()
    }
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, {
      match: 'match',
      addFeeOrInterestAndMatchItem: 'addFeeOrInterestAndMatchItem',
      fetchUnpaidCustomerInvoices: 'fetchUnpaidCustomerInvoices',
    }),
    toggleSelectClick(): void {
      if (this.isDataNotFinalized) {
        return
      }
      this.selected = !this.selected
      this.$emit('toggleSelect', this.selected, this.bankStatementItem, this.$el)
    },

    openMatchInvoiceModal(transactionId): void {
      this.fetchUnpaidCustomerInvoices(transactionId)
      Modal.getOrCreateInstance(document.querySelector(`#match-invoice-modal-${this.bankStatementItem.id}`)).show()
    },

    manualJournalUrl() {
      return `/${this.bankReconciliation.companyId}/manual_journals/new?bank_transaction_id=${this.bankStatementItem.id}`
    },
    transactionUrl() {
      return `/${this.bankReconciliation.companyId}/bank_accounts/${this.bankReconciliation.bankAccountId}/super_user/bank_transactions/${this.bankStatementItem.id}`
    },
  },

  updated() {
    createOrUpdateCandidatePopover((this.$refs.card as any).$el, this.selected, this.suggestionLevel, 'right')
  },

  computed: {
    ...mapState(useBankReconciliationStore, [
      'bankReconciliation',
      'selectedPostingSum',
      'selectedBankTransactionSum',
      'selectedBankTransactions',
      'selectedPostings',
      'matchedItems',
    ]),

    suggestionLevel(): string {
      if (this.suggestion == null) {
        return 'none'
      } else {
        return this.suggestion.level
      }
    },

    disabledAttributes(): object {
      if (this.isDataNotFinalized) {
        return { 'data-bs-toggle': 'modal', 'data-bs-target': '#transaction-data-not-finalized-modal' }
      } else {
        return {}
      }
    },
    cardClasses: function (): object {
      return {
        'card-dashed': this.isDataNotFinalized,
        selectable: 'true',
        selected: this.selected,
        'show-popover': this.suggestionLevel !== 'none',
        'selectable-success': this.suggestionLevel === 'very_high' || this.suggestionLevel === 'exact',
        'selectable-warning': this.suggestionLevel === 'medium' || this.suggestionLevel === 'high',
      }
    },

    isDataNotFinalized: function (): boolean {
      return this.bankStatementItem.transactionOn > this.bankReconciliation.finalizedTransactionsOn
    },
  },
})
</script>

<style scoped lang="scss">
@import '../../../build';
</style>
