<template>
  <span>{{ amountStr }}</span>
</template>
<script lang="ts">
import { amountStr } from '../../controllers/formatting_helper'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    amountCents: {
      required: true,
      type: Number,
    },
  },
  computed: {
    amountStr() {
      return amountStr(this.amountCents / 100.0)
    },
  },
})
</script>
