import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [
    'bankCard',
    'confirmBankButton',
    'sendToProviderButton',
    'bankBic',
    'userDnbElement',
    'userNotDnbElement',
    'connectBankButton',
    'userSsn',
    'debugSsn',
  ]

  declare bankCardTargets: Array<HTMLElement>
  declare confirmBankButtonTarget: HTMLButtonElement
  declare sendToProviderButtonTarget: HTMLLinkElement
  declare bankBicTarget: HTMLInputElement
  declare userDnbElementTargets: Array<HTMLElement>
  declare userNotDnbElementTargets: Array<HTMLElement>
  declare userSsnTarget: HTMLInputElement
  declare hasBankBicTarget: boolean
  declare hasSendToProviderButtonTarget: boolean
  declare debugSsnTargets: Array<HTMLElement>
  declare hasDebugSsnTarget: boolean

  connect() {
    const params = new URL((window as any).location).searchParams
    if (Boolean(params.get('new_bank_account')).valueOf()) {
      Modal.getOrCreateInstance(document.querySelector('#bank-account-new-modal-step-one')).show()
    }
    if (this.hasBankBicTarget) {
      this._displayCorrectHint(this.bankBicTarget.value)
      this.displayDebugSsn(this.bankBicTarget.value)
    }
  }

  handleCardSelect(event) {
    const bankCard = event.target.closest('div.card-luca')
    const bankKey = bankCard.dataset.bankKey
    const bankBic = bankCard.dataset.bankBic
    this.confirmBankButtonTarget.classList.remove('disabled')
    this.bankCardTargets.forEach((card) => {
      card.classList.remove('selected')
    })
    bankCard.classList.add('selected')
    const bankParam = bankBic !== undefined ? bankBic : bankKey
    if (this.hasSendToProviderButtonTarget) {
      this.sendToProviderButtonTarget.href =
        this.sendToProviderButtonTarget.href.split('bank=')[0] + `bank=${bankParam}`
    } else if (this.hasBankBicTarget) {
      this.bankBicTarget.value = bankParam
      this._displayCorrectHint(bankParam)
      this._preFillUserSsn(bankBic)
      this.displayDebugSsn(this.bankBicTarget.value)
    }
    this.confirmBankButtonTarget.click()
  }

  handleCustomBankSelect(event) {
    const bankBic = event.target.selectedOptions[0].value
    this.confirmBankButtonTarget.classList.remove('disabled')
    this.bankCardTargets.forEach((card) => {
      card.classList.remove('selected')
    })
    this.bankBicTarget.value = bankBic
    this._displayCorrectHint(bankBic)
    this._preFillUserSsn(bankBic)
  }

  _displayCorrectHint(bankBic) {
    if (bankBic === 'DNBANOKK') {
      this.userDnbElementTargets.forEach((elem) => elem.classList.remove('d-none'))
      this.userNotDnbElementTargets.forEach((elem) => elem.classList.add('d-none'))
    } else {
      this.userDnbElementTargets.forEach((elem) => elem.classList.add('d-none'))
      this.userNotDnbElementTargets.forEach((elem) => elem.classList.remove('d-none'))
    }
  }
  _preFillUserSsn(bankBic) {
    if (bankBic === 'DNBANOKK') {
      this.userSsnTarget.value = this.userSsnTarget.dataset.dnbClientId
    } else {
      this.userSsnTarget.value = this.userSsnTarget.dataset.userNnin
    }
  }

  private displayDebugSsn(bankBic: string) {
    if (!this.hasDebugSsnTarget) {
      return
    }

    let foundTarget = false
    if (this.hasDebugSsnTarget) {
      this.debugSsnTargets.forEach((debugDiv) => {
        if (debugDiv.dataset.bic == bankBic) {
          foundTarget = true
          debugDiv.classList.remove('d-none')
        } else {
          debugDiv.classList.add('d-none')
        }
      })
    }
    if (!foundTarget) {
      this.debugSsnTargets.find((div) => div.dataset.bic === 'NOBIC').classList.remove('d-none')
    }
  }
}
