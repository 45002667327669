import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fee', 'interest']

  declare feeTargets: Array<HTMLInputElement>
  declare interestTargets: Array<HTMLInputElement>

  updateFee(event) {
    this.feeTargets.forEach((fee) => {
      fee.value = event.target.value
    })
  }

  updateInterest(event) {
    this.interestTargets.forEach((element) => {
      element.checked = event.target.checked
    })
  }
}
