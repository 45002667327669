import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['passwordInput']

  declare passwordInputTarget: HTMLInputElement

  toggle(event) {
    const button = event.target as HTMLButtonElement
    const icon = button.querySelector('svg')

    if (this.passwordInputTarget.type === 'password') {
      this.passwordInputTarget.type = 'text'
      icon.classList.remove('fa-eye')
      icon.classList.add('fa-eye-slash')
    } else {
      this.passwordInputTarget.type = 'password'
      icon.classList.remove('fa-eye-slash')
      icon.classList.add('fa-eye')
    }
  }
}
