import { BankReconciliation } from '../module'
import { formatISO } from 'date-fns'
import { rails_fetch } from '../../../../util/rails_fetch'

export default {
  async create(bankReconciliation: BankReconciliation, postingOn: Date, amountCents: Number, type: String) {
    const path = `/${(window as any).current_company_id}/bank_reconciliations/${bankReconciliation.id}/bank_reconciliation_line_items`
    return await rails_fetch(path, {
      method: 'POST',
      body: JSON.stringify({
        bank_reconciliation_line_item_form_object_base: {
          posting_date: formatISO(postingOn, { representation: 'date' }),
          amount_cents: amountCents,
          type: type,
          skip_transaction_matching: true,
        },
      }),
    })
  },
  async destroy(bankReconciliationLineItemId: String) {
    const path = `/${(window as any).current_company_id}/bank_reconciliation_line_items/${bankReconciliationLineItemId}`
    return await rails_fetch(path, {
      method: 'DELETE',
    })
  },
}
