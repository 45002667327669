<template>
  <div>
    <strong>{{ description() }}</strong>
    <table class="table">
      <tbody>
        <tr v-for="account in accountsFromGroup()" v-bind:key="account.id">
          <td
            :class="{ 'table-item': account.id === hoverAccount.id }"
            :ref="`account-table-item-${account.id}`"
            @mouseover="accountHover(account)"
            @click="accountClick(account)"
          >
            <Item :key="account.id" :account="account" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
@import '../../build';
.table-item {
  --bs-table-bg-state: var(--bs-primary);
  cursor: pointer;
  color: var(--bs-body-bg);
}
</style>

<script lang="ts">
import Item from './Item.vue'
import { i18n } from '../../../libraries/i18n'
import { defineComponent, PropType } from 'vue'
import { Account, GroupedAccount } from '../module'

export default defineComponent({
  components: { Item },
  props: {
    accounts: {
      required: true,
      type: Object as PropType<GroupedAccount>,
    },
    cursorEnabled: {
      required: true,
      type: Boolean,
    },
    hoverAccount: {
      required: true,
      type: Object as PropType<Account>,
    },
  },
  methods: {
    description() {
      if (this.accounts.text) {
        return this.accounts.text
      }

      const code = this.accounts[0].code.slice(0, 2)
      return `${code} ${i18n.t('norwegian_account_plan.level_2')[code]}:`
    },
    accountsFromGroup() {
      return this.accounts.children
    },
    accountHover(account) {
      this.cursorEnabled && this.$emit('accountHover', account)
    },
    mouseOver(account) {
      this.$emit('accountHover', account)
    },
    accountClick(account) {
      this.$emit('accountSelected', account)
    },
  },
  watch: {
    hoverAccount: function (_) {
      let ref = this.$refs[`account-table-item-${this.hoverAccount.id}`]
      if (!this.cursorEnabled && typeof ref === 'object' && ref[0]) {
        ref[0].scrollIntoView({ block: 'nearest' })
      }
    },
  },
})
</script>
