<template>
  <form @submit.prevent="onSubmit">
    <b-modal :id="`${type}-modal`" :title="i18n().t(`bank_reconciliation.${type}.title`)">
      <date-picker
        v-model="date"
        :id="`${type}-date`"
        :label="i18n().t(`bank_reconciliation.posting_date`)"
        :required="true"
      />
      <amount-input
        v-model="amountStr"
        :id="`${type}-amount`"
        :label="i18n().t(`bank_reconciliation.${type}.amount`)"
        :required="true"
        :hint="i18n().t(`bank_reconciliation.${type}.hint`)"
      />
      <template v-slot:buttons>
        <button class="btn btn-primary" :id="`${type}-submit-button`">
          <font-awesome-icon :icon="['far', 'coins']" />
          {{ submitLabel }}
        </button>
      </template>
    </b-modal>
  </form>
</template>
<script lang="ts">
import DatePicker from '../../../../components/DatePicker.vue'
import AmountInput from '../../../../components/AmountInput.vue'
import { parseISO } from 'date-fns'
import { Modal } from 'bootstrap'
import { parseDecimal } from '../../../../../classes/parse_decimal'
import { mapActions } from 'pinia'
import { useBankReconciliationStore } from '../../store'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { DatePicker, AmountInput },
  data() {
    return {
      date: this.initialDate,
      amountStr: '',
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    initialDate: Date.now(),
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, { addFeeOrInterest: 'addFeeOrInterest' }),

    onSubmit(): void {
      const amountCents = parseDecimal(this.amountStr).times(100).toNumber()

      // When the date picker sets the date it sets it as a ISO-formatted string, so we need to check if that has
      // happened and parse it
      if (typeof this.date === 'string' || this.date instanceof String) {
        this.date = parseISO(this.date)
      }
      this.addFeeOrInterest(this.date, amountCents, this.type)

      // Reset the modal:
      this.date = this.initialDate
      this.amountStr = ''
      Modal.getOrCreateInstance(document.querySelector(`#${this.type}-modal`)).hide()
    },
  },
  computed: {
    submitLabel(): string {
      return i18n.t(`bank_reconciliation.${this.type}.save`)
    },
  },
})
</script>
