<template>
  <div>
    <transition name="fade">
      <div class="section-header" v-show="matchedItems.length > 0">
        {{ i18n().t('automatic_bank_reconciliation.v2.matched_items') }}
      </div>
    </transition>
    <transition-group tag="div" name="list" data-test-id="matched-items">
      <MatchedRow
        v-for="(matching, index) in matchedItems"
        :key="matchedKey(matching)"
        :matching="matching"
        :class="index != 0 ? 'border-top pt-3' : ''"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import MatchedRow from './MatchedRow.vue'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { MatchedRow },
  computed: {
    ...mapState(useBankReconciliationStore, ['matchedItems']),
  },
  methods: {
    i18n() {
      return i18n
    },
    matchedKey: (match) => {
      let str = ''
      match.postings.forEach((posting) => {
        str += posting.id + ':'
      })
      return str
    },
  },
})
</script>
<style scoped>
.fade-enter-active {
  transition: all 0.5s ease-in;
}
.fade-enter {
  opacity: 1;
}

.list-enter-active {
  transition: all 0.5s ease-in;
  z-index: 1;
}
.list-enter {
  opacity: 1;
  transform: translateY(50px);
}
</style>
