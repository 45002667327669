import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'destination']
  static values = { forceOverwrite: Boolean }

  declare destinationTargets: Array<HTMLInputElement>
  declare hasForceOverwriteValue: boolean
  declare forceOverwriteValue: boolean

  copy(event) {
    this.destinationTargets.forEach((destinationElement) => {
      if (destinationElement.value.trim() === '' || (this.forceOverWrite() && event.target != destinationElement)) {
        destinationElement.value = event.target.value
        destinationElement.dispatchEvent(new InputEvent('input'))
      }
    })
  }

  forceOverWrite(): boolean {
    return this.hasForceOverwriteValue && this.forceOverwriteValue
  }
}
