import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['swappable']

  declare swappableTargets: Array<HTMLElement>

  swap(event) {
    event.preventDefault()
    this.swappableTargets.forEach((swappable) => {
      if (swappable.classList.contains('d-none')) {
        swappable.classList.remove('d-none')
      } else {
        swappable.classList.add('d-none')
      }
    })
  }
}
