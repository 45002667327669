import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { parseDecimal } from '../../classes/parse_decimal'
import Decimal from 'decimal.js'
import { formatISO, isSameDay, parseISO } from 'date-fns'
import { rails_fetch } from '../../util/rails_fetch'

export default class extends Controller {
  static targets = [
    'dates',
    'addDateLink',
    'addDebitItem',
    'addCreditItem',
    'debitFields',
    'creditFields',
    'debitAmount',
    'creditAmount',
    'createJournalButton',
    'vatCodeSelect',
  ]

  declare addDateLinkTarget
  declare vatCodeSelectTargets
  declare datesTarget: HTMLElement
  declare createJournalButtonTarget: HTMLElement

  connect() {
    this.updateVatCodes = this.updateVatCodes.bind(this)

    document.querySelectorAll('input.date_picker').forEach((datePickerElement) => {
      this.addEventListenerOnDatePickerElement(datePickerElement)
    })
  }

  addEventListenerOnDatePickerElement(datePickerElement) {
    datePickerElement.addEventListener('change', (event) => {
      const posting = (event.target as HTMLInputElement).closest('.posting-item')
      this.updateAllVatCodesInPosting(posting)
    })
  }

  updateAllVatCodesInPosting(posting) {
    this.vatCodeSelectTargets.forEach((codeSelector) => {
      let vatCodeSelector = posting.querySelector(`#${codeSelector.id}`)

      if (!vatCodeSelector) {
        return
      }

      let accountSelector = posting.querySelector(`#${vatCodeSelector.dataset.vat}`)

      let accountAdditionalData = JSON.parse(accountSelector.dataset.accountAdditionalData)

      if (!accountAdditionalData.available_vat_codes) {
        return
      }

      let available_vat_codes = accountAdditionalData.available_vat_codes
      let default_vat_code = accountAdditionalData.default_vat_code
      let sub_code = accountAdditionalData.sub_code

      this.updateVatCodes(posting, vatCodeSelector, accountSelector, available_vat_codes, default_vat_code, sub_code)
    })
  }

  accountSelectHandler(event) {
    let vatCodeSelector = this.vatCodeSelectTargets.find((codeSelector) => {
      return (event.target as HTMLSelectElement).closest('.subline-item').querySelector(`#${codeSelector.id}`)
    })
    let accountSelector = event.target
    let posting = event.target.closest('.posting-item')

    this.fillAccountAdditionalDataAttribute(event)

    this.updateVatCodes(
      posting,
      vatCodeSelector,
      accountSelector,
      event.available_vat_codes,
      event.default_vat_code,
      event.sub_code,
    )
  }

  fillAccountAdditionalDataAttribute(event) {
    let accountAdditionalData = {
      available_vat_codes: event.available_vat_codes,
      sub_code: event.sub_code,
      default_vat_code: event.default_vat_code,
    }
    event.target.dataset.accountAdditionalData = JSON.stringify(accountAdditionalData)
  }

  updateVatCodes(posting, vatCodeSelector, accountSelector, available_vat_codes, default_vat_code, sub_code) {
    let id = accountSelector.id

    if (sub_code !== null) {
      this.changeVatSelectOptions([0], '0', posting, vatCodeSelector)
      vatCodeSelector.disabled = true
      if (!$(`#hidden-select-${id}`).length) {
        let hiddenInput = document.createElement('input')
        hiddenInput.id = `hidden-select-${id}`
        hiddenInput.name = vatCodeSelector.getAttribute('name')
        hiddenInput.type = 'hidden'
        hiddenInput.value = '0'
        vatCodeSelector.parentNode.insertBefore(hiddenInput, vatCodeSelector.nextSibling)
      }
    } else {
      if (vatCodeSelector.dataset.fixed !== undefined && vatCodeSelector.dataset.fixed === 'true') {
        if (available_vat_codes[0] !== null) {
          this.changeVatSelectOptions(available_vat_codes, parseInt(vatCodeSelector.value), posting, vatCodeSelector)
        }
      } else {
        vatCodeSelector.disabled = false
        const hiddenSelect = document.querySelector(`#hidden-select-${id}`)
        if (hiddenSelect !== null) {
          hiddenSelect.parentNode.removeChild(hiddenSelect)
        }
        if (available_vat_codes[0] !== null) {
          this.changeVatSelectOptions(available_vat_codes, default_vat_code, posting, vatCodeSelector)
        }
      }
    }
  }

  async changeVatSelectOptions(available_vat_codes, default_vat_code, posting, vatSelect) {
    let postingDate = new Date(posting.querySelector('input[data-bs-target="date"]').value)
    if (postingDate === null || isNaN(postingDate.getFullYear())) {
      postingDate = new Date()
    }
    let currentVatSelectOption = vatSelect.value

    const allowedVatCodes = available_vat_codes.map((code) => code.toString())
    let vatCodesByDates = (window as any).vatCodesByDates
    let dateIso = formatISO(postingDate, { representation: 'date' })

    if (!(dateIso in vatCodesByDates)) {
      let response = await rails_fetch(`/${(window as any).current_company_id}/vat/rates?date=${dateIso}`)
      // Store results per date, in manual journal we have many dates
      ;(window as any).vatCodesByDates[dateIso] = await response.json()
    }

    let vatCodes = []
    if ('rates' in vatCodesByDates[dateIso]) {
      vatCodes = vatCodesByDates[dateIso]['rates']
    } else {
      vatCodes = vatCodesByDates[dateIso]
    }

    const availableVatOptions = vatCodes.filter(([_label, code]) => allowedVatCodes.includes(code))

    // remove all children elements
    while (vatSelect.firstChild) {
      vatSelect.removeChild(vatSelect.firstChild)
    }

    availableVatOptions.forEach(([label, code]) => {
      const option = new Option(label, code, false, false)
      vatSelect.appendChild(option)
    })

    if (availableVatOptions.some(([_label, code]) => currentVatSelectOption.toString() === code.toString())) {
      vatSelect.value = currentVatSelectOption
    } else if (availableVatOptions.some(([_label, code]) => default_vat_code.toString() === code.toString())) {
      vatSelect.value = default_vat_code
    } else if (availableVatOptions.length > 0) {
      vatSelect.value = availableVatOptions[0][1]
    }
  }

  addDates(event) {
    const postingRegexp = new RegExp(this.addDateLinkTarget.dataset.postingId, 'g')
    const debitSublineTime = new Date().getTime()
    const debitSublineRegexp = new RegExp(this.addDateLinkTarget.dataset.debitSublineId, 'g')
    const creditSublineTime = new Date().getTime() + 1
    const creditSublineRegexp = new RegExp(this.addDateLinkTarget.dataset.creditSublineId, 'g')
    this.datesTarget.insertAdjacentHTML(
      'beforeend',
      this.addDateLinkTarget.dataset.fields
        .replace(postingRegexp, new Date().getTime() + 2)
        .replace(debitSublineRegexp, debitSublineTime)
        .replace(creditSublineRegexp, creditSublineTime)
        .replace('hidden="hidden"', ''),
    )
    this.createJournalButtonTarget.classList.remove('d-none')

    let lastDatePicker =
      document.querySelectorAll('input.date_picker')[document.querySelectorAll('input.date_picker').length - 1]
    this.addEventListenerOnDatePickerElement(lastDatePicker)

    return event.preventDefault()
  }

  addItem(event) {
    const addItem = event.target
    const Items = addItem.closest('.items-block').querySelector('.items')
    const regexp = new RegExp(addItem.dataset.id, 'g')
    const time = new Date().getTime()
    Items.insertAdjacentHTML('beforeend', addItem.dataset.fields.replace(regexp, time))
    this.removeVatLock(addItem)
    return event.preventDefault()
  }

  removeDate(event) {
    const postingItem = event.target.closest('.posting-item')
    postingItem.parentNode.removeChild(postingItem)
    if (document.querySelectorAll('.posting-item').length === 0) {
      this.createJournalButtonTarget.classList.add('d-none')
    }
    return event.preventDefault()
  }

  removeItem(event) {
    this.removeVatLock(event.target)
    this.updatePostingAmount(event, true)
    return event.preventDefault()
  }

  removeVatLock(item) {
    const itemsBlock = item.closest('.posting-item')
    itemsBlock.querySelectorAll('.vat-code').forEach((elem) => {
      if (elem.disabled && elem.dataset.fixed === 'true') {
        if (elem.dataset.subcode === 'false') {
          elem.disabled = false
        }
        elem.dataset.fixed = false
        if (elem.getAttribute('type') === 'hidden' && !elem.disabled) {
          elem.parentNode.removeChild(elem)
        }
      }
    })
    itemsBlock.querySelectorAll('.subline-amount').forEach((elem) => {
      if (elem.dataset.locked === 'false') {
        elem.disabled = false
        if (elem.getAttribute('type') === 'hidden') {
          elem.parentNode.removeChild(elem)
        }
      }
    })
    itemsBlock.querySelectorAll('.account-id').forEach((elem) => {
      if (elem.dataset.fixed === 'true' && !elem.disabled) {
        elem.dataset.fixed = false
      }
    })
  }

  sanitizeNumber(event) {
    let { target } = event
    target.value = target.value.replace(/\s+/g, '')
    this.updatePostingAmount(event, undefined)
  }

  updatePostingAmount(event, remove) {
    const itemBlock = event.target.closest('.items-block')
    if (remove !== undefined) {
      const sublineItem = event.target.closest('.subline-item')
      sublineItem.parentNode.removeChild(sublineItem)
    }
    const amounts = itemBlock.querySelectorAll('.subline-amount')
    const totalAmount = Array.from(amounts).reduce((subTotal: Decimal, element: HTMLInputElement) => {
      let amount = parseDecimal(element.value, new Decimal(0))
      if (amount.isNaN() || element.getAttribute('type') === 'hidden') {
        return subTotal
      } else {
        return subTotal.plus(amount)
      }
    }, new Decimal(0)) as Decimal
    itemBlock.querySelector('.items-total').textContent = new Intl.NumberFormat('nb-NO', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(totalAmount.toNumber())

    this.checkAmounts(itemBlock.closest('.posting-item'))
  }

  updatePostingDate(event) {
    const dateItem = event.target
    const chosenDate = dateItem._flatpickr.latestSelectedDateObj
    let elem = event.target.closest('.posting-item')

    Array.from($(elem).find('select.select2-input')).forEach((elem) => {
      if ($(elem).select2('data').length > 0) {
        let data = {
          data: Object.assign(JSON.parse($(elem).attr('data-account-additional-data')), $(elem).select2('data')[0]),
        }
        let event = jQuery.Event('select2:select', { params: data })
        $(elem).trigger(event)
      }
    })
  }

  checkAmounts(posting) {
    const dateItem = posting.closest('.posting-item')
    let totalDebitItem = dateItem.querySelectorAll('.items-total')[0]
    let totalCreditItem = dateItem.querySelectorAll('.items-total')[1]
    const totalDebitAmount = parseDecimal(totalDebitItem.textContent, new Decimal(0))
    const totalCreditAmount = parseDecimal(totalCreditItem.textContent, new Decimal(0))
    if (!totalDebitAmount.eq(totalCreditAmount)) {
      totalDebitItem.classList.add('text-danger')
      totalCreditItem.classList.add('text-danger')
    } else {
      totalDebitItem.classList.remove('text-danger')
      totalCreditItem.classList.remove('text-danger')
    }
  }
}
