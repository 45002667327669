import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { DEFAULT_STATIC_PARMS } from '../standalone/components/Select2Builder'
import Rails from '@rails/ujs'
import '~/libraries/select2'
import { addParamToUrl } from './helpers'

// Index filter is a select2 select box that auto submits on change and looks like a select2

export default class extends Controller {
  initialize() {
    let select2Element = $(this.element)
    select2Element.select2({
      ...DEFAULT_STATIC_PARMS,
      minimumInputLength: 0,
    })

    if (this._autoCommit()) {
      select2Element.on('select2:select', (event) => {
        let form = this.element.closest('form')
        let selectElement = select2Element[0] as HTMLSelectElement
        addParamToUrl(selectElement.name, selectElement.value)
        Rails.fire(form, 'submit')
      })
    }
  }

  _autoCommit(): boolean {
    // Default is true
    return !this.data.has('autoCommit') || this.data.get('autoCommit') === 'true'
  }
}
