import Rollbar from 'rollbar'

// @ts-ignore
let env = import.meta.env['VITE_RUBY_MODE']
if (env !== 'development' && env !== 'test') {
  // @ts-ignore
  window.Rollbar = new Rollbar({
    accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
    environment: env,
    captureUncaught: true,
    captureUnhandledRejections: true,
    ignoredMessages: [
      'Error: A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
    ],
    payload: {
      client: {
        javascript: {
          source_map_enabled: true, //this is now true by default
          code_version: '1.0.0',
          // Optionally have Rollbar guess which frames the error was thrown from
          // when the browser does not provide line and column numbers.
          guess_uncaught_frames: true,
        },
      },
    },
  })
}
