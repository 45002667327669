import { rails_fetch } from '../../../../../util/rails_fetch'

export default {
  async update(params) {
    const url = `/found_company/submissions/${params.found_company_submission.id}`

    return await rails_fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(params),
    })
  },
}
