import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { Collapse } from 'bootstrap'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = [
    'companyName',
    'businessCheckBox',
    'businessForm',
    'email',
    'noBankCheckbox',
    'selectedBank',
    'updateSignatories',
    'reopenLink',
    'signatoriesTable',
  ]

  // Company name
  declare companyNameTarget: HTMLFormElement

  // About you
  declare businessCheckBoxTarget: HTMLFormElement
  declare businessFormTarget: HTMLFormElement
  declare emailTarget: HTMLFormElement

  // Select bank
  declare noBankCheckboxTarget: HTMLFormElement
  declare selectedBankTarget: HTMLFormElement

  // Awaiting Signatures
  declare updateSignatoriesTarget: HTMLElement
  declare reopenLinkTarget: HTMLElement
  declare signatoriesTableTarget: HTMLElement

  select() {
    if (this.businessCheckBoxTarget.checked) {
      this._addClass(this.businessFormTarget, 'd-none')
    } else {
      this._removeClass(this.businessFormTarget, 'd-none')
    }
  }

  selectBank(event) {
    let bankCards = document.querySelectorAll('[data-bank-name]')
    let targetCard = event.target.closest('[data-bank-name]')
    if (!targetCard) {
      if (this.noBankCheckboxTarget.checked) {
        this.selectedBankTarget.value = 'NO_BANK'
      } else {
        this.selectedBankTarget.value = ''
      }
    }
    bankCards.forEach((target: HTMLElement) => {
      let bankName = target.getAttribute('data-bank-name')
      let descTarget = document.querySelector(`[data-bank-description="${bankName}"]`)
      if (target === targetCard) {
        if (target.classList.contains('selected')) {
          target.classList.remove('selected')
          descTarget.classList.add('d-none')
          this.selectedBankTarget.value = ''
        } else {
          target.classList.add('selected')
          descTarget.classList.remove('d-none')
          this.selectedBankTarget.value = bankName
        }
      } else {
        target.classList.remove('selected')
        descTarget.classList.add('d-none')
      }
    })
  }

  updateDescription(event) {
    $.ajax({
      type: 'PATCH',
      url: `/found_company/submissions/${event.target.dataset.submissionId}`,
      data: { found_company_submission: { current_page: 'company_type', description: event.target.value } },
      dataType: 'json',
    })
  }

  async updateEnvelope(event) {
    if (this.targets.has('updateSignatories')) {
      this.updateSignatoriesTarget.classList.add('fa-spin')
    }
    if (this.targets.has('signatoriesTable')) {
      this.signatoriesTableTarget.childNodes.forEach((row) => {
        let text = $(row).find('[data-text-id]')
        text.html(i18n.t('found_company.awaiting_signatures.updating'))
        $(row).find('svg').removeClass('fa-times-circle')
        $(row).find('svg').addClass('fa-sync fa-spin')
      })
    }

    let result = await $.ajax({
      type: 'GET',
      url: `/found_company/submissions/${event.target.dataset.submissionId}/update_envelope`,
    })
    let model = JSON.parse(result)
    model.signatories.forEach((signatory) => {
      let row = document.querySelector(`[data-hashid="signatory-${signatory.id}"]`)
      let text = $(row).find('[data-text-id]')
      $(row).find('svg').removeClass('fa-sync fa-spin')
      if (signatory.signature_status == 'signed') {
        text.html(i18n.t('found_company.awaiting_signatures.signed'))
        $(row).find('svg').addClass('fa-check-circle')
        $(row).find('svg').removeClass('text-danger')
        $(row).find('svg').addClass('text-success')
      } else if (signatory.signature_status == 'rejected') {
        text.html(i18n.t('found_company.awaiting_signatures.rejected'))
        $(row).find('td.text-center').html(signatory.signature_comment)
        $(row).find('svg').addClass('fa-exclamation-triangle')
        this.reopenLinkTarget.classList.remove('d-none')
        this.updateSignatoriesTarget.parentElement.classList.add('d-none')
      } else {
        text.html(i18n.t('found_company.awaiting_signatures.not_signed'))
        $(row).find('svg').addClass('fa-times-circle')
      }
    })
    if (model.envelope_status == 'signed') {
      window.location.href = `/found_company/submissions/${event.target.dataset.submissionId}/edit`
    }
    if (this.targets.has('updateSignatories')) {
      this.updateSignatoriesTarget.classList.remove('fa-spin')
    }
  }

  setEmailForSignIn() {
    let path = '/found_company/authentications'
    document.cookie = `found_company_sign_in_email=${this.emailTarget.value}; path=${path}`
    // We set the sign up cookie so we can redirect the user to the found company sign in page.
    document.cookie = `signup=found_company; path=/`
  }

  showContinueSection() {
    let section = Collapse.getOrCreateInstance(document.querySelector('#continueSection'))
    if (this.companyNameTarget.value.trim() !== '' && this.companyNameTarget.value.trim().length >= 3) {
      section.show()
    } else {
      section.hide()
    }
  }

  _addClass(target, classes) {
    target.classList.add(classes)
  }

  _removeClass(target, classes) {
    target.classList.remove(classes)
  }
}
