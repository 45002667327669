<template>
  <div class="mt-2 mb-2" :class="destroy ? 'd-none' : ''">
    <div
      v-if="type === 'company'"
      :id="`company-signatory-${signatory.hashid || index}`"
      data-bs-toggle="collapse"
      :data-bs-target="`#company-signatory-form-${signatory.hashid || index}`"
      aria-expanded="true"
      :aria-controls="`company-signatory-form-${signatory.hashid || index}`"
      data-parent="#signatoriesAccordion"
    >
      <div class="card-luca card-body mt-2 mb-2 d-flex flex-row justify-content-between align-items-center">
        <div>{{ full_name }}</div>
        <font-awesome-icon :icon="['far', 'chevron-down']" />
      </div>
    </div>
    <form
      ref="subForm"
      @submit.prevent
      :id="`${type}-signatory-form-${signatory.hashid || index}`"
      :class="classes"
      :aria-labelledby="`${type}-signatory-${signatory.hashid || index}`"
    >
      <div class="row mb-3">
        <FormInput
          v-model="full_name"
          class="col-6"
          :id="`found_company_owner_signatories_full_name_${signatory.hashid || index}`"
          :name="`found_company_owner[found_company_signatories_attributes][${signatory.hashid || index}][full_name]`"
          @change="submitForm"
          :rules="formRule"
          :label="nameLabel"
        />
        <FormInput
          v-model="nnin"
          class="col-6"
          :id="`found_company_owner_signatories_nnin_${signatory.hashid || index}`"
          :name="`found_company_owner[found_company_signatories_attributes][${signatory.hashid || index}][nnin]`"
          @change="submitForm"
          :rules="formRule"
          :label="i18n().t('activerecord.attributes.found_company/signatory.nnin')"
        />
      </div>
      <div class="row mb-3">
        <FormInput
          v-model="phone_number"
          class="col-6"
          :id="`found_company_owner_signatories_phone_number_${signatory.hashid || index}`"
          :name="`found_company_owner[found_company_signatories_attributes][${
            signatory.hashid || index
          }][phone_number]`"
          @change="submitForm"
          :rules="formRule"
          :label="i18n().t('activerecord.attributes.found_company/signatory.phone_number')"
        />
        <FormInput
          class="col-6"
          v-model="email"
          :id="`found_company_owner_signatories_email_${signatory.hashid || index}`"
          :name="`found_company_owner[found_company_signatories_attributes][${signatory.hashid || index}][email]`"
          @change="submitForm"
          :rules="formRule"
          :label="i18n().t('activerecord.attributes.found_company/signatory.email')"
        />
      </div>
      <div class="row">
        <FormInput
          v-model="post_address_street_one"
          class="col-6"
          :id="`found_company_owner_signatories_post_address_street_one_${signatory.hashid || index}`"
          :name="`found_company_owner[found_company_signatories_attributes][${
            signatory.hashid || index
          }][post_address_street_one]`"
          @change="submitForm"
          :rules="formRule"
          :label="i18n().t('activerecord.attributes.found_company/signatory.post_address_street_one')"
        />
        <FormInput
          v-model="post_address_zip"
          class="col-6"
          :id="`found_company_owner_signatories_post_address_zip_${signatory.hashid || index}`"
          :name="`found_company_owner[found_company_signatories_attributes][${
            signatory.hashid || index
          }][post_address_zip]`"
          @change="submitForm"
          :rules="formRule"
          :label="i18n().t('activerecord.attributes.found_company/signatory.post_address_zip')"
        />
      </div>
      <div class="button-row" v-if="type === 'company' && showRemove">
        <button class="btn btn-outline-secondary mt-3" @click="removeSignatory">
          {{ i18n().t('found_company.share_capital.modal.remove') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import FormInput from './../../../../components/FormInput.vue'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { FormInput },
  props: {
    index: {
      required: true,
      type: String,
    },
    signatory: {
      required: true,
      type: Object,
    },
    classes: {
      required: false,
      type: String,
    },
    showRemove: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      formRule: [(v) => !!v || i18n.t('errors.messages.blank')],
      full_name: this.signatory.full_name,
      email: this.signatory.email,
      nnin: this.signatory.nnin,
      hashid: this.signatory.hashid,
      phone_number: this.signatory.phone_number,
      post_address_street_one: this.signatory.post_address_street_one,
      post_address_zip: this.signatory.post_address_zip,
      destroy: false,
    }
  },
  computed: {
    nameLabel(): String {
      if (this.type === 'person') {
        return i18n.t('activerecord.attributes.found_company/signatory.full_name')
      } else {
        return i18n.t('found_company.share_capital.full_name_company')
      }
    },
  },
  methods: {
    i18n() {
      return i18n
    },
    form() {
      return this.$refs.subForm
    },
    submitForm() {
      let signatory = {
        hashid: this.hashid,
        full_name: this.full_name,
        email: this.email,
        nnin: this.nnin,
        phone_number: this.phone_number,
        post_address_street_one: this.post_address_street_one,
        post_address_zip: this.post_address_zip,
      }
      this.$emit('submit', { index: this.index, signatory })
    },
    removeSignatory() {
      this.$emit('remove', { index: this.index, hashid: this.signatory?.hashid })
      this.destroy = true
    },
  },
})
</script>
