import { Controller } from '@hotwired/stimulus'
import { nextFrame } from '../rendering'

export default class extends Controller {
  static targets = ['thead', 'tbody']
  static values = { selector: String }

  declare theadTarget: HTMLElement
  declare tbodyTarget: HTMLElement
  declare selectorValue: string

  connect() {
    this.hideOnEmptyTable()
  }

  hideOnEmptyTable() {
    // This can be called from an element that is being removed, wait until next frame to not include removed elements
    nextFrame(() => {
      if (this.tbodyTarget.querySelectorAll(`:scope > ${this.selectorValue}`).length > 0) {
        this.theadTarget.classList.remove('d-none')
      } else {
        this.theadTarget.classList.add('d-none')
      }
    })
  }
}
