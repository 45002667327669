// This controller is used to show an element when the input value changes

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'show']

  declare inputTarget: HTMLInputElement
  declare showTargets: Array<HTMLElement>

  originalValue: string

  connect() {
    this.originalValue = this.inputTarget.value
    this.inputTarget.addEventListener('input', this.handleInputChange.bind(this))
  }

  handleInputChange() {
    if (this.originalValue !== this.inputTarget.value) {
      this.showTargets.forEach((element) => element.classList.remove('d-none'))
    } else {
      this.showTargets.forEach((element) => element.classList.add('d-none'))
    }
  }
}
