import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['uploadButton', 'fileInput']

  declare uploadButtonTarget: HTMLButtonElement
  declare fileInputTarget: HTMLElement

  connect() {
    this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this)
    this.handleFileInputChange = this.handleFileInputChange.bind(this)
    this.setupAutoSubmit()
  }

  setupAutoSubmit() {
    this.uploadButtonTarget.addEventListener('click', this.handleUploadButtonClick)
    this.fileInputTarget.addEventListener('change', this.handleFileInputChange)
  }

  handleUploadButtonClick(event) {
    this.fileInputTarget.click()
    event.preventDefault()
    return false
  }

  handleFileInputChange(event) {
    ;(this.element as HTMLFormElement).submit()
  }
}
