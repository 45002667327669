import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['navItem', 'content']
  static values = { defaultContent: String }

  declare navItemTargets: Array<HTMLElement>
  declare contentTargets: Array<HTMLElement>
  declare hasDefaultContentValue: boolean
  declare defaultContentValue: string

  connect() {
    if (this.hasDefaultContentValue) {
      this.showContent(this.defaultContentValue)
    } else {
      this.showContent(this.navItemTargets[0].dataset.contentId)
    }

    window.addEventListener('popstate', (event) => {
      if (event.state.content !== undefined) {
        this.showContent(event.state.content, false)
      }
    })
  }

  show(event) {
    this.showContent(event.target.value)
  }

  private showContent(name, pushState = true) {
    let tab = this.navItemTargets.find((tab) => tab.dataset.contentId === name)
    if (tab instanceof Option) {
      tab.selected = true
    }
    this.contentTargets.forEach((content) => {
      if (content.dataset.contentId === name) {
        content.classList.add('show')
        content.style.display = 'block'
      } else {
        content.classList.remove('show')
        content.style.display = 'none'
      }
    })

    if (pushState) {
      let url = new URL(window.location.href)
      let segments = url.pathname.split('/')
      segments[segments.length - 1] = name
      url.pathname = segments.join('/')
      history.pushState({ content: name }, '', url)
    }
  }
}
