import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['target', 'source']
  static values = { hide: String, show: String, disableInputs: { type: Boolean, default: false } }

  declare targetTargets: [HTMLElement]
  declare sourceTarget: HTMLInputElement
  declare hasTargetTarget: boolean
  declare hasSourceTarget: boolean
  declare hideValue: string
  declare hasHideValue: boolean
  declare showValue: string
  declare hasShowValue: string
  declare disableInputsValue: boolean

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    if (!this.hasTargetTarget || !this.hasSourceTarget) {
      return
    }
    if (this.hasHideValue && this.hasShowValue) {
      console.log('Please define only hideValue or showValue, not both')
    }

    if (this.hasShowValue) {
      if (this.showValue.split(';').includes(this.sourceTarget.value)) {
        this.showTargets(this.targetTargets)
      } else {
        this.hideTargets(this.targetTargets)
      }
      return
    } else if (this.targetTargets.find((e) => e.dataset.visibilityToggleTargetShowValue !== undefined)) {
      this.targetTargets.forEach((e) => {
        if (e.dataset.visibilityToggleTargetShowValue.split(';').includes(this.sourceTarget.value.toString())) {
          e.classList.remove('d-none')
        } else {
          e.classList.add('d-none')
        }
      })
      return
    } else if (this.sourceTarget.type == 'checkbox') {
      this.toggleCheckBox()
      return
    } else {
      let hideValue = ''
      if (this.hasHideValue) {
        hideValue = this.hideValue
      }
      if (hideValue.split(';').includes(this.sourceTarget.value)) {
        this.hideTargets(this.targetTargets)
      } else {
        this.showTargets(this.targetTargets)
      }
      return
    }
  }

  toggleCheckBox() {
    if (this.sourceTarget.checked) {
      this.showTargets(this.targetTargets)
    } else {
      this.hideTargets(this.targetTargets)
    }
  }

  private hideTargets(targets: Array<HTMLElement>) {
    targets.forEach((e) => e.classList.add('d-none'))
  }

  private showTargets(targets: Array<HTMLElement>) {
    targets.forEach((e) => e.classList.remove('d-none'))
  }
}
