import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js/pure'
import { rails_fetch } from '../../util/rails_fetch'
import { i18n } from '../../libraries/i18n'

// When performing an instant payment the following flow happens:
// stripe_checkout_controller.ts     Stripe form    CreatePaymentIntent  UpdatePaymentIntent  Stripe
//              |     Open form          |                 |                     |              |
//              +------------------------>                 |                     |              |
//              |                        |   Submit        |                     |              |
//              |                        +----------------->                     |              |
//              |                        |                 |       createIntent  |              |
//              |                        |                 +---------------------+-------------->
//              |                        |                 |       response      |              |
//              |                        |                 <---------------------+--------------+
//              |                        |                 |                     |              |
//              <------------------------+-----------------+                     |              |
//              |                        |           confirmIntent               |              |
//              +------------------------+---------------------------------------+-------------->
//              |                        |           response                    |              |
//              <------------------------+---------------------------------------+--------------+
//              |                        |           redirect                    |              |
//              +------------------------+-----------------+--------------------->              |
//              |                        |                 |                     |              |

export default class extends Controller {
  static values = {
    mount: String,
    amountCents: Number,
    companyHashid: String,
    paymentIntentEndpoint: String,
    callbackUrl: String,
  }
  static targets = [
    'alert',
    'errorMessage',
    'paymentElement',
    'paymentForm',
    'submitButton',
    'useCardInFuture',
    'totalAmount',
  ]

  declare mountValue: string
  declare amountCentsValue: string
  declare paymentIntentEndpointValue: string
  declare companyHashidValue: string
  declare callbackUrlValue: string
  declare stripe: any
  declare alertTarget: HTMLElement
  declare errorMessageTarget: HTMLElement
  declare paymentElementTarget: HTMLElement
  declare paymentFormTarget: HTMLFormElement
  declare submitButtonTarget: HTMLButtonElement
  declare useCardInFutureTarget: HTMLInputElement
  declare hasUseCardInFutureTarget: boolean
  declare hasTotalAmountTarget: boolean
  declare totalAmountTarget: HTMLElement

  async connect() {
    if ((window as any).stripePublicKey !== undefined) {
      this.stripe = await loadStripe((window as any).stripePublicKey)
    }
    this.element.addEventListener('shown.bs.modal', () => {
      switch (this.mountValue) {
        case 'mountStripeCardSetup':
          this.mountStripeCardSetup()
          return
        case 'mountStripePayment':
          this.mountStripePayment()
          return
        default:
          console.error('Mount action not defined')
          this.disconnect()
      }
    })
  }

  async mountStripeCardSetup() {
    this.mountStripeElements(false)
  }

  async mountStripePayment() {
    this.mountStripeElements(true)
  }

  handleCheckBoxToggling() {
    let callbackUrl = new URL(this.callbackUrlValue)
    let urlParams = callbackUrl.searchParams
    urlParams.set('use_card_in_future', this.useCardInFutureTarget.checked.toString())
    callbackUrl.search = urlParams.toString()
    let updatedUrl = callbackUrl.toString()
    this.callbackUrlValue = updatedUrl
  }

  private async createStripeSetupIntent() {
    let response = await rails_fetch(`/${this.companyHashidValue}/subscription/stripe/setup_intents`, {
      method: 'POST',
    })
    let { client_secret } = await response.json()
    return client_secret
  }

  private async createStripeCustomer() {
    await rails_fetch(`/${this.companyHashidValue}/subscription/stripe/customers`, {
      method: 'POST',
    })
  }

  private async createStripePaymentIntent() {
    let response = await rails_fetch(
      `/${this.companyHashidValue}/subscription/stripe/payment_intents/${this.paymentIntentEndpointValue}`,
      { method: 'POST' },
    )
    let { client_secret } = await response.json()
    return client_secret
  }

  private async mountStripeElements(performPayment: boolean) {
    const options = {
      locale: i18n.locale,
      appearance: {
        /*...*/
      },
    } as any

    await this.createStripeCustomer()
    if (performPayment) {
      options.setup_future_usage = 'off_session'
      options.mode = 'payment'
      options.amount = this.amountCentsValue
      options.currency = 'nok'
    } else {
      options.clientSecret = await this.createStripeSetupIntent()
    }
    const elements = this.stripe.elements(options)
    const paymentElement = elements.create('payment')
    paymentElement.mount(this.paymentElementTarget)
    this.alertTarget.classList.remove('d-none')
    if (this.hasUseCardInFutureTarget) {
      this.useCardInFutureTarget.parentElement.classList.remove('d-none')
    }
    if (this.hasTotalAmountTarget) {
      this.totalAmountTarget.classList.remove('d-none')
    }
    this.submitButtonTarget.classList.remove('disabled', 'd-none')

    this.paymentFormTarget.addEventListener('submit', async (event) => {
      event.preventDefault()
      let originalText = this.submitButtonTarget.textContent
      this.submitButtonTarget.textContent = i18n.t('generic.please_wait')
      this.submitButtonTarget.classList.add('disabled')
      const { error: submitError } = await elements.submit() // Load the form
      if (submitError) {
        this.handleError(submitError)
        return
      }

      let error = null
      if (performPayment) {
        let clientSecret = await this.createStripePaymentIntent()
        error = await this.stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          clientSecret,
          confirmParams: {
            return_url: this.callbackUrlValue,
          },
        })
      } else {
        error = await this.stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: this.callbackUrlValue,
          },
        })
      }

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        this.errorMessageTarget.textContent = error.error.message
        this.errorMessageTarget.classList.remove('d-none')
        this.submitButtonTarget.textContent = originalText
        this.submitButtonTarget.classList.remove('disabled')
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    })
  }

  handleError(error) {
    this.errorMessageTarget.textContent = error.message
    this.errorMessageTarget.classList.remove('d-none')
    this.submitButtonTarget.classList.remove('disabled')
    this.submitButtonTarget.disabled = false
  }
}
