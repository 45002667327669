import { Controller } from '@hotwired/stimulus'

// This controller allows for sortable tables
// It should be used in combination with Luca::Component::StimulusSortableTable

export default class extends Controller {
  static targets = ['form']

  declare formTarget: HTMLFormElement
  currentOrderAttribute: string

  reloadTable() {
    this.formTarget.dispatchEvent(new Event('submit', { bubbles: true }))
  }

  order(event) {
    event.preventDefault()
    this.currentOrderAttribute = event.target.dataset.orderAttribute
    this._findOrCreateOrderInput().value = this.currentOrderAttribute
    this.reloadTable()
  }

  _findOrCreateOrderInput(): HTMLInputElement {
    let input = this.formTarget.querySelector('[name=order]') as HTMLInputElement
    if (!input) {
      input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', 'order')
      this.formTarget.appendChild(input)
    }
    return input
  }
}
