// Libraries
import Dropzone from 'dropzone'
import './components/Accordion'
import './components/BootstrapVerification'
import './components/Collapsable'
import JsFormError from './components/JsFormError'
import './components/Select2Builder'
import Sortable from './components/Sortable'
import Toggle from './components/Toggle'
import ClampableAlert from './components/ClampableAlert'
import TooltipInitializer from './components/TooltipInitializer'
import PopoverInitializer from './components/PopoverInitializer'
import SubmitButtonHandler from './components/SubmitButtonHandler'

import CompanyRole from './views/CompanyRole'
import CustomerInvoiceLine from './views/CustomerInvoiceLine'
import SupplierInvoiceDeleteButton from './views/SupplierInvoiceDeleteButton'
import Charts from './components/Charts'
import Rails from '@rails/ujs'

// We are currently using different methods of invoking these components
// Some are invoked from the view (via javascript:), and others from turbo:load + jquery
// We should find a unified method of doing this
import { Modal } from 'bootstrap'
;(window as any).CustomerInvoiceLine = CustomerInvoiceLine
;(window as any).CompanyRole = CompanyRole
;(window as any).JsFormError = JsFormError
;(window as any).Bootstrap = {}
;(window as any).Bootstrap.Modal = Modal
;(window as any).Charts = Charts

if ((window as any).Luca === undefined) {
  ;(window as any).Luca = {}
}
;(window as any).Luca.Controllers = {}

document.addEventListener('turbo:load', function () {
  new Sortable()
  new Toggle()
  new ClampableAlert()
  new TooltipInitializer()
  new PopoverInitializer()
  new SupplierInvoiceDeleteButton()
  new SubmitButtonHandler()
})

// Dropzone
//
// We need to disable auto-discovery because it doesn't work with Turbo.
// Dropzones are auto-discovered after loading the page but navigating with
// Turbo doesn't trigger another load event.
Dropzone.autoDiscover = false

document.addEventListener('turbo:load', function () {
  document.querySelectorAll('.dropzone').forEach((element: HTMLElement) => {
    let uploadMultiple = true
    if (element.dataset.uploadMultiple !== undefined) {
      uploadMultiple = element.dataset.uploadMultiple === 'true'
    }
    let parallelUploads = uploadMultiple ? 50 : 1
    let method = 'post'
    if (element.getAttribute('method') !== undefined) {
      method = element.getAttribute('method')
    }
    new Dropzone(element, {
      timeout: 180000,
      uploadMultiple: uploadMultiple,
      method: method.toLowerCase(),
      parallelUploads: parallelUploads,
      // The request Dropzone sends to the server is interpreted as a JavaScript
      // request but is not evaluated by Dropzone. We need to evaluate it
      // manually to make redirects, which are implemented via Turbo, work.
      //
      // The issue was also reported here:
      // https://github.com/enyo/dropzone/issues/1595
      init: function () {
        // stops event propagation for all links and buttons inside drop zone
        document
          .querySelectorAll('.dz-message:not(.dz-default) a, .dz-message:not(.dz-default) button')
          .forEach((element) => {
            element.addEventListener('click', (event) => {
              event.stopPropagation()
            })
          })

        this.on('success', (file, response) => {
          eval(response)
        })
      },

      dictDefaultMessage: element.dataset.dictDefaultMessage,
      paramName: element.dataset.paramName || 'file',

      // We need to include the CSRF token in order to avoid triggering a CSRF
      // token error on the server.
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
        Accept: 'application/javascript',
      },
    })
  })
})
