import $ from 'jquery'

export default class ClampableAlert {
  constructor() {
    let alerts = document.querySelectorAll('.alert') as NodeListOf<HTMLElement>
    let clampedProp = getComputedStyle(document.documentElement).getPropertyValue('--clamped-max-height')
    let clampedMaxHeight = clampedProp ? parseInt(clampedProp) : 160
    for (let el in Array.prototype.slice.call(alerts)) {
      let alert = alerts[el]
      if (alert.offsetHeight > clampedMaxHeight && alert.dataset.clamped != 'false') {
        mobileClamp(alert)
        alert.addEventListener('mouseup', collapseAlert.bind(this))
      }
    }

    function collapseAlert(event) {
      let alertBox = event.target.closest('.alert')
      if (alertBox.classList.contains('clamped')) {
        mobileUnclamp(alertBox)
      } else {
        mobileClamp(alertBox)
      }
    }

    function mobileClamp(el) {
      el.classList.add('clamped')
      $(el).find('*:not(:has("*")):not(:empty)').addClass('clamped-child')
    }

    function mobileUnclamp(el) {
      el.classList.remove('clamped')
      $(el).find('*:not(:has("*")):not(:empty)').removeClass('clamped-child')
    }
  }
}
