import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../classes/parse_decimal'
import Decimal from 'decimal.js'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  static targets = [
    'excludeFeesAndInterestsCheckbox',
    'account',
    'negativeAmountWarning',
    'foreignAmountInput',
    'domesticAmountInput',
  ]
  static values = {
    sourceCurrency: String,
    defaultCurrency: String,
    editableForm: Boolean,
    sourceOutstandingAmountIncludingFeesAndInterests: String,
    sourceOutstandingAmountExcludingFeesAndInterests: String,
  }

  declare sourceCurrencyValue: string
  declare defaultCurrencyValue: string
  declare editableFormValue: boolean
  declare sourceOutstandingAmountIncludingFeesAndInterestsValue: string
  declare sourceOutstandingAmountExcludingFeesAndInterestsValue: string
  declare negativeAmountWarningTarget: HTMLElement
  declare hasNegativeAmountWarningTarget: boolean

  declare hasExcludeFeesAndInterestsCheckboxTarget: boolean
  declare accountTarget: HTMLSelectElement
  declare excludeFeesAndInterestsCheckboxTarget: HTMLInputElement
  declare domesticAmountInputTarget: HTMLInputElement
  declare foreignAmountInputTarget: HTMLInputElement

  connect() {
    this.showAmountInputFieldsByCurrency()
  }

  handlePayPartialAmount(event) {
    event.preventDefault()

    if (this.sourceCurrencyValue === this.defaultCurrencyValue) {
      this.domesticAmountInputTarget.removeAttribute('readonly')
    } else {
      this.foreignAmountInputTarget.removeAttribute('readonly')
    }
  }

  showAmountInputFieldsByCurrency() {
    const selectedAccount = this.accountTarget.options[this.accountTarget.selectedIndex]
    if (!selectedAccount) return

    const bankAccountCurrency = selectedAccount.getAttribute('currency')
    const isSourceInDefaultCurrency = this.sourceCurrencyValue === this.defaultCurrencyValue
    const isBankAccountInDefaultCurrency = bankAccountCurrency === this.defaultCurrencyValue

    if (isSourceInDefaultCurrency && isBankAccountInDefaultCurrency) {
      this.handleDomesticCurrency()
    } else if (!isSourceInDefaultCurrency && !isBankAccountInDefaultCurrency) {
      this.handleForeignCurrency()
    } else if (!isSourceInDefaultCurrency && isBankAccountInDefaultCurrency) {
      this.handleMixedCurrency(isSourceInDefaultCurrency, bankAccountCurrency)
    } else if (isSourceInDefaultCurrency && !isBankAccountInDefaultCurrency) {
      this.handleMixedCurrency(isSourceInDefaultCurrency, bankAccountCurrency)
    }
  }

  showNegativeAmountAlert() {
    if (!this.hasNegativeAmountWarningTarget) {
      return
    }

    const foreignAmount = parseDecimal(this.foreignAmountInputTarget.value, new Decimal(0))
    const domesticAmount = parseDecimal(this.domesticAmountInputTarget.value, new Decimal(0))

    if (foreignAmount.isNegative() || domesticAmount.isNegative()) {
      this.negativeAmountWarningTarget.classList.remove('d-none')
    } else {
      this.negativeAmountWarningTarget.classList.add('d-none')
    }
  }

  handleDomesticCurrency() {
    this.setLabel(this.domesticAmountInputTarget, i18n.t('posting.amount_in', { currency: this.sourceCurrencyValue }))
    this.showAndEnableElement(this.domesticAmountInputTarget, { readonly: !this.editableFormValue })
    this.hideAndDisableElement(this.foreignAmountInputTarget)
    if (!this.editableFormValue) {
      this.setHint(this.domesticAmountInputTarget, this.createPaidPartialLink())
    }
  }

  handleForeignCurrency() {
    this.setLabel(this.foreignAmountInputTarget, i18n.t('posting.amount_in', { currency: this.sourceCurrencyValue }))
    this.showAndEnableElement(this.foreignAmountInputTarget, { readonly: !this.editableFormValue })
    this.hideAndDisableElement(this.domesticAmountInputTarget)
    if (!this.editableFormValue) {
      this.setHint(this.foreignAmountInputTarget, this.createPaidPartialLink())
    }
  }

  handleMixedCurrency(isSourceInDefaultCurrency, bankAccountCurrency) {
    this.swapAmountInputs(this.foreignAmountInputTarget, this.domesticAmountInputTarget, isSourceInDefaultCurrency)

    const mainAmountInput = isSourceInDefaultCurrency ? this.domesticAmountInputTarget : this.foreignAmountInputTarget
    const secondaryAmountInput = isSourceInDefaultCurrency
      ? this.foreignAmountInputTarget
      : this.domesticAmountInputTarget
    const secondaryInputCurrency = isSourceInDefaultCurrency ? bankAccountCurrency : this.defaultCurrencyValue

    this.setLabel(mainAmountInput, i18n.t('posting.amount_in', { currency: this.sourceCurrencyValue }))
    this.setLabel(secondaryAmountInput, i18n.t('posting.amount_in', { currency: secondaryInputCurrency }))
    this.showAndEnableElement(mainAmountInput, { readonly: !this.editableFormValue })
    this.showAndEnableElement(secondaryAmountInput)
    if (!this.editableFormValue) {
      this.setHint(mainAmountInput, this.createPaidPartialLink())
    }
  }

  setLabel(inputElement: HTMLInputElement, labelText: string) {
    const labelElement = inputElement.parentElement.querySelector('label')
    if (labelElement) {
      labelElement.innerText = labelText
    }
  }

  setHint(inputElement: HTMLInputElement, hintContent: HTMLElement) {
    const hintElement = inputElement.parentElement.querySelector('.form-text')
    if (hintElement) {
      hintElement.innerHTML = ''
      hintElement.appendChild(hintContent)
    }
  }

  hideAndDisableElement(element: HTMLInputElement) {
    element.parentElement.classList.add('d-none')
    element.setAttribute('disabled', '')
  }

  showAndEnableElement(element: HTMLInputElement, options: { readonly?: boolean } = { readonly: false }) {
    element.parentElement.classList.remove('d-none')
    element.removeAttribute('disabled')
    if (options.readonly) {
      element.setAttribute('readonly', '')
    } else {
      element.removeAttribute('readonly')
    }
  }

  createPaidPartialLink() {
    const paid_partial_link = document.createElement('a')
    paid_partial_link.setAttribute('id', 'paid-partial')
    paid_partial_link.setAttribute('data-action', 'payment-receivable#handlePayPartialAmount')
    paid_partial_link.setAttribute('href', '#')
    paid_partial_link.classList.add('d-flex', 'justify-content-end')
    paid_partial_link.innerText = i18n.t('activerecord.attributes.payment.edit_amount')
    return paid_partial_link
  }

  swapAmountInputs(
    domesticAmountInput: HTMLInputElement,
    foreignAmountInput: HTMLInputElement,
    isSourceInDefaultCurrency: boolean,
  ) {
    const excludeFeesCheckboxElement = this.hasExcludeFeesAndInterestsCheckboxTarget
      ? this.excludeFeesAndInterestsCheckboxTarget.closest('fieldset')
      : null
    const domesticAmountElement = domesticAmountInput.parentElement
    const foreignAmountElement = foreignAmountInput.parentElement
    const destinationElement = domesticAmountElement.parentElement

    if (isSourceInDefaultCurrency) {
      destinationElement.innerHTML = ''
      destinationElement.appendChild(foreignAmountElement)
      destinationElement.appendChild(domesticAmountElement)
    } else {
      destinationElement.innerHTML = ''
      destinationElement.appendChild(domesticAmountElement)
      destinationElement.appendChild(foreignAmountElement)
    }

    if (excludeFeesCheckboxElement) {
      destinationElement.appendChild(excludeFeesCheckboxElement)
    }
  }

  showExcludeFeesAndInterestsCheckbox() {
    if (!this.hasExcludeFeesAndInterestsCheckboxTarget) {
      return
    }

    let paymentAmount = new Decimal(0)
    if (this.sourceCurrencyValue === this.defaultCurrencyValue) {
      paymentAmount = parseDecimal(this.domesticAmountInputTarget.value)
    } else {
      paymentAmount = parseDecimal(this.foreignAmountInputTarget.value)
    }
    const outstandingAmountExcludingFees = parseDecimal(this.sourceOutstandingAmountExcludingFeesAndInterestsValue)
    const outstandingAmountIncludingFees = parseDecimal(this.sourceOutstandingAmountIncludingFeesAndInterestsValue)
    const checkBoxWrapper = this.excludeFeesAndInterestsCheckboxTarget.parentNode.parentNode as HTMLElement

    if (
      outstandingAmountExcludingFees.lessThanOrEqualTo(paymentAmount) &&
      paymentAmount.lessThan(outstandingAmountIncludingFees)
    ) {
      checkBoxWrapper.classList.remove('d-none')
      this.excludeFeesAndInterestsCheckboxTarget.disabled = false
    } else {
      checkBoxWrapper.classList.add('d-none')
      this.excludeFeesAndInterestsCheckboxTarget.disabled = true
    }
  }
}
