import { Controller } from '@hotwired/stimulus'
import FlatpickrController from './flatpickr_controller'

export default class extends Controller {
  static targets = ['input']

  declare inputTarget: HTMLInputElement

  transferToInput(event: MouseEvent) {
    event.preventDefault()
    let target = event.target as HTMLElement

    if (target.hasAttribute('data-value')) {
      this.inputTarget.value = target.getAttribute('data-value')
      this.inputTarget.dispatchEvent(new Event('input'))
    } else {
      this.inputTarget.value = (event.target as HTMLElement).innerText
      this.inputTarget.dispatchEvent(new Event('input'))
      // since date_picker creates one hidden input, on the 17 line we set into hidden and beneath into that user actually see
      if (this.inputTarget.classList.contains('date_picker')) {
        const flatpickrController = this.application.getControllerForElementAndIdentifier(
          this.inputTarget,
          'flatpickr',
          // We cast to unknown since FlatpickrController doesn't cover Controller
          // There might be a better way in Typescript, but we dont know how
          // In any case, it is a FlatpickrController that should be returned.
        ) as unknown as FlatpickrController
        const date = flatpickrController.fp.parseDate(target.innerHTML, (flatpickrController.config as any).altFormat)
        flatpickrController.fp.setDate(date, true)
      }
    }
  }
}
