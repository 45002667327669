import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['identificationField']

  declare identificationFieldTarget: HTMLInputElement

  enableIdentification() {
    this.identificationFieldTarget.removeAttribute('readonly')
  }
}
