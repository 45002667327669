import { Controller } from '@hotwired/stimulus'
import BankAccount from '../classes/bank_account'
import BankAccountNumberController from 'controllers/input/bank_account_number_controller'
import { nextFrame } from '../rendering'

export default class extends Controller {
  static targets = ['accountNumber', 'accountType', 'accountBic']

  declare accountNumberTarget: HTMLInputElement
  declare hasAccountTypeTarget: boolean
  declare accountTypeTarget: HTMLSelectElement
  declare bankAccount: BankAccount
  declare accountBicTarget: HTMLInputElement

  connect() {
    nextFrame(() => {
      this.accountTypeHandler()
      this.accountNumberTarget.value = this.accountNumberTarget.getAttribute('value')
    })
  }

  find() {
    this.bankAccount = new BankAccount()
    this.bankAccount.findBankNameByCode(this.accountNumberTarget, this.accountTypeTarget.value)
  }

  accountTypeHandler() {
    if (!this.hasAccountTypeTarget) {
      return
    }

    const bankAccountNumberController = this.application.getControllerForElementAndIdentifier(
      this.accountNumberTarget,
      'input--bank-account-number',
    ) as BankAccountNumberController

    if (this.accountTypeTarget.value != 'international') {
      bankAccountNumberController.addMask()
      const debugHint = this.accountNumberTarget.parentElement.querySelector('div.form-text')
      if (debugHint) {
        debugHint.classList.remove('d-none')
      }
      this.accountBicTarget.classList.add('d-none')
      this.accountBicTarget.querySelector('input').value = ''
    } else {
      bankAccountNumberController.removeMask()
      this.accountNumberTarget.placeholder = ''
      const debugHint = this.accountNumberTarget.parentElement.querySelector('div.form-text')
      if (debugHint) {
        debugHint.classList.add('d-none')
      }
      this.accountBicTarget.classList.remove('d-none')
    }
  }
}
