export function getAbsoluteBoundingRect(elem) {
  let offsetX = window.scrollX,
    offsetY = window.scrollY,
    rect = elem.getBoundingClientRect()

  if (elem !== document.body) {
    var parent = elem.parentNode

    // The element's rect will be affected by the scroll positions of
    // *all* of its scrollable parents, not just the window, so we have
    // to walk up the tree and collect every scroll offset. Good times.
    while (parent !== document.body) {
      offsetX += parent.scrollLeft
      offsetY += parent.scrollTop
      parent = parent.parentNode
    }
  }

  return {
    left: rect.left + offsetX,
    top: rect.top + offsetY,
    width: rect.width,
    height: rect.height,
  }
}

export function unionBoundingRect(elems) {
  if (elems.length == 0) {
    return { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0 }
  }

  let resultRect = getAbsoluteBoundingRect(elems[0])
  let bottom = resultRect.top + resultRect.height
  let right = resultRect.left + resultRect.width

  for (let i = 1; i < elems.length; i++) {
    let rect = getAbsoluteBoundingRect(elems[i])
    bottom = Math.max(bottom, rect.top + rect.height)
    right = Math.max(right, rect.left + rect.width)
    resultRect.top = Math.min(resultRect.top, rect.top)
    resultRect.left = Math.min(resultRect.left, rect.left)
  }
  resultRect.height = bottom - resultRect.top
  resultRect.width = right - resultRect.left

  return resultRect
}

export function unionBoundingRectFromObject(object) {
  const array = Object.keys(object).map(function (key) {
    return object[key]
  })
  return unionBoundingRect(array)
}
