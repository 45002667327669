<template>
  <div>
    <div class="card-luca mb-5">
      <div class="card-header card-info d-flex align-items-center">
        <font-awesome-icon :icon="['far', headerIcon]" />
        <div class="ms-1">{{ header }}</div>
      </div>
      <div class="card-body">
        <div class="card-text">
          <table class="table align-middle m-0">
            <tbody>
              <tr>
                <th scope="row" class="p-0 border-0 fw-normal">
                  {{ i18n().t('bank_reconciliation.opening_balance') }}
                </th>
                <td class="text-end border-0 p-0">
                  <font-awesome-layers v-if="ingoingSumsMatch" class="fa-lg me-2">
                    <font-awesome-icon :icon="['far', 'check']" class="text-success" transform="shrink-6" />
                    <font-awesome-icon icon="circle" class="opacity-low text-success" />
                  </font-awesome-layers>
                  <font-awesome-layers v-else class="fa-lg me-2">
                    <font-awesome-icon
                      :icon="['far', 'exclamation-triangle']"
                      class="text-warning"
                      transform="shrink-6 left-1 up-1"
                    />
                    <font-awesome-icon icon="circle" class="opacity-low text-warning" />
                  </font-awesome-layers>
                </td>
                <td class="text-end border-0 td-shrink-1 p-0">
                  <amount class="fw-bold" :amountCents="ingoingCents" />
                </td>
              </tr>
              <tr>
                <th scope="row" class="p-0 border-0 fw-normal">
                  {{ i18n().t('bank_reconciliation.closing_balance') }}
                </th>
                <td class="text-end border-0 p-0">
                  <font-awesome-layers v-if="outgoingSumsMatch" class="fa-lg me-2">
                    <font-awesome-icon :icon="['far', 'check']" class="text-success" transform="shrink-6" />
                    <font-awesome-icon icon="circle" class="opacity-low text-success" />
                  </font-awesome-layers>
                  <font-awesome-layers v-else class="fa-lg me-2">
                    <font-awesome-icon
                      :icon="['far', 'exclamation-triangle']"
                      class="text-warning"
                      transform="shrink-6 left-1 up-1"
                    />
                    <font-awesome-icon icon="circle" class="opacity-low text-warning" />
                  </font-awesome-layers>
                </td>
                <td class="text-end border-0 td-shrink-1 p-0">
                  <amount class="fw-bold" :amountCents="outgoingCents" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    i18n() {
      return i18n
    },
  },
  props: {
    ingoingCents: {
      required: true,
      type: Number,
    },
    outgoingCents: {
      required: true,
      type: Number,
    },
    ingoingSumsMatch: {
      required: true,
      type: Boolean,
    },
    outgoingSumsMatch: {
      required: true,
      type: Boolean,
    },
    header: {
      required: true,
      type: String,
    },
    headerIcon: {
      required: true,
      type: String,
    },
  },
})
</script>
