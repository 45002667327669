<template>
  <div>
    <h5 class="card-title">
      {{ i18n().t('automatic_bank_reconciliation.v2.all_items_reconciled', { month: this.month }) }}
    </h5>
    <div class="button-row">
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#reopen-modal">
        <font-awesome-icon :icon="['far', 'box-open']" />
        {{ i18n().t('bank_reconciliation.reopen') }}
      </button>
      <b-modal id="reopen-modal" :title="i18n().t('navigation.confirmation')">
        {{ i18n().t('automatic_bank_reconciliation.v2.are_you_sure_you_want_to_reopen') }}
        <template v-slot:buttons>
          <button v-on:click="reopen()" class="btn btn-primary">
            <font-awesome-icon :icon="['far', 'box-open']" />
            {{ reopenText }}
          </button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../../store'
import format from '../../../../../libraries/datefnt'
import { Modal } from 'bootstrap'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, { setReconciled: 'setReconciled' }),
    reopen() {
      Modal.getOrCreateInstance(document.querySelector('#reopen-modal')).hide()
      this.setReconciled(false)
    },
  },
  computed: {
    ...mapState(useBankReconciliationStore, ['bankReconciliation']),
    month() {
      if (this.bankReconciliation.beginningOn === null) {
        return ''
      }
      return format(this.bankReconciliation.beginningOn, 'MMMM')
    },
    reopenText() {
      return i18n.t('bank_reconciliation.reopen')
    },
  },
})
</script>
