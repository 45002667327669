import { Popover, Tooltip } from 'bootstrap'
import { i18n } from '../../../../libraries/i18n'

export default function createOrUpdateCandidatePopover(
  element,
  selected: boolean,
  suggestionLevel: string,
  placement: Tooltip.PopoverPlacement,
) {
  Popover.getOrCreateInstance(element)?.dispose()
  if (!selected && suggestionLevel !== 'none') {
    Popover.getOrCreateInstance(element, {
      container: element,
      content: popoverContent(suggestionLevel, placement == 'left' ? 'right' : 'left'),
      customClass: popoverClass(suggestionLevel),
      html: true,
      trigger: 'manual',
      placement: placement,
    }).show()
  }
}

function popoverClass(suggestionLevel: string): string {
  switch (suggestionLevel) {
    case 'exact':
    case 'very_high':
      return 'popover-success'
    case 'high':
    case 'medium':
      return 'popover-warning'
    default:
      return 'popover-info'
  }
}

function popoverContent(suggestionLevel: string, fingerDirection: string): string {
  let level = i18n.t(suggestionLevel, { scope: 'automatic_bank_reconciliation.v2.suggestion_quality' })
  let suggestion = i18n.t('automatic_bank_reconciliation.v2.suggestion.one')
  let text = `${level} ${suggestion}`

  if (fingerDirection === 'left') {
    let icon = `<i class="far fa-hand-point-left me-2"></i>`
    return `${icon} ${text}`
  } else {
    let icon = `<i class="far fa-hand-point-right ms-2"></i>`
    return `${text} ${icon}`
  }
}
