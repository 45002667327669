<template>
  <div class="card-luca rounded mb-4 bank-reconciliation__list-item">
    <div class="card-body">
      <h6 class="card-title float-end"><amount :amountCents="amountCents" /></h6>
      <h6 class="card-title">{{ formatDate(header) }}</h6>
      <p class="card-text mb-0" v-html="description" />
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { format } from 'date-fns'
import { i18n } from '../../../../libraries/i18n'

export default defineComponent({
  props: {
    header: {
      type: Date,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    amountCents: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatDate(date: Date) {
      return format(date, i18n.t('date.formats.frontend.datefns.short'))
    },
  },
})
</script>

<style scoped lang="scss">
@import '../../../build';
</style>
