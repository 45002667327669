import { rails_fetch } from '../../../util/rails_fetch'

export default {
  async pullAccounts(favorite, searchValue, filters, abortSignal): Promise<Array<any> | null> {
    let url = `/${(window as any).current_company_id}/accounts/search.json?favourite=${favorite}&group=true`

    if (searchValue) {
      url += `&term=${searchValue}`
    }

    const purchaseType = () => {
      const purchase_type_selector = document.querySelector('#supplier_invoice_purchase_type') as HTMLSelectElement

      if (purchase_type_selector && filters.onlyAccrual != true) {
        return (purchase_type_selector[purchase_type_selector.selectedIndex] as HTMLOptionElement).value
      }
      return null
    }

    if (filters.onlyDepreciationAccounts) {
      url += `&only_depreciation_accounts=true`
    }
    if (filters.onlyBalanceAccounts) {
      url += `&only_balance_accounts=true`
    }
    if (filters.onlyCostAccounts) {
      url += `&only_cost_accounts=true`
    }
    if (filters.onlyWithVatCodes) {
      url += `&only_with_vat_codes=true`
    }
    if (purchaseType()) {
      url += `&purchase_type=${purchaseType()}`
    }
    if (filters.onlyAccrual) {
      url += `&only_accrual=true`
    }

    const response = await rails_fetch(url, {
      method: 'GET',
      signal: abortSignal,
    })

    if (response.ok) {
      if (searchValue != '' && searchValue != new URL(response.url).searchParams.get('term')) {
        return []
      }

      const data = await response.json()

      if (data.length === 0) {
        return []
      } else {
        return data[0].text ? data : data[0].children
      }
    } else {
      const data = await response.json()
      console.error(data)
      return []
    }
  },
}
