import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['destination']

  declare destinationTargets: Array<HTMLElement>

  update(event) {
    let source = event.target
    this.findTargetElements(source.dataset.dataBindingName).forEach((target) => {
      let property = target.dataset.dataBindingProperty
      target[property] = this.valueFromElement(source)
    })
  }

  findTargetElements(name): Array<HTMLElement> {
    return this.destinationTargets.filter((target) => target.dataset.dataBindingName == name)
  }

  valueFromElement(source) {
    switch (source.nodeName) {
      case 'SELECT':
        return source.selectedOptions[0][source.dataset.dataBindingProperty || 'text']
      default:
        return source[source.dataset.dataBindingProperty || 'value']
    }
  }
}
