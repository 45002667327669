import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import confetti from 'canvas-confetti'

export default class extends Controller {
  initialize() {
    if (!this.data.has('enabled') || this.data.get('enabled') === 'true') {
      new Modal(this.element).show()

      if (this.data.get('confetti') === 'true') {
        confetti({
          zIndex: 11000, // ensure we are above the bootstrap modals which are at 10050
          disableForReducedMotion: true, // be nice to people who don't like motion
          spread: 90,
          particleCount: 150,
          origin: { x: 0.5, y: 0.7 }, // 0.7 works nice for a modal
        })
      }
    }
  }
}
