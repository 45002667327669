<template>
  <div class="row">
    <div class="col-4">
      <ItemCard
        v-for="bankTransaction in matchedBankTransactions"
        :key="bankTransaction.id"
        :id="'bank-transaction-' + bankTransaction.id"
        class="disabled"
        :header="bankTransaction.transactionOn"
        :description="bankTransaction.description"
        :amountCents="bankTransaction.amountCents"
      />
    </div>
    <div class="col-4">
      <div class="row h-100 justify-content-center align-items-center">
        <UnMatchCard :matching="matching" v-if="reconciledState !== 'Reconciled'" />
      </div>
    </div>
    <div class="col-4">
      <ItemCard
        v-for="posting in matchedPostings"
        :key="posting.id"
        :id="'posting-' + posting.id"
        :header="posting.postingDate"
        :description="posting.description"
        :amountCents="posting.amountCents"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ItemCard from './ItemCard.vue'
import UnMatchCard from './UnMatchCard.vue'
import { mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { format } from 'date-fns'
import { Match } from '../module'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  props: {
    matching: {
      type: Object as PropType<Match>,
      required: true,
    },
  },
  components: {
    UnMatchCard,
    ItemCard,
  },
  methods: {
    formatDate(date: Date) {
      return format(date, i18n.t('date.formats.frontend.datefns.short'))
    },
  },
  computed: {
    ...mapState(useBankReconciliationStore, ['reconciledState']),
    matchedBankTransactions() {
      return this.matching.bankTransactions
    },
    matchedPostings() {
      return this.matching.postings
    },
  },
})
</script>
