import { Controller } from '@hotwired/stimulus'
import CompanyLookup from '../../classes/company_lookup'
import $ from 'jquery'
import JsFormError from '../../standalone/components/JsFormError'
import { nextFrame } from '../../rendering'

const NORWEGIAN_ORGANISATION_NUMBER_REGEXP = /^\d{9}$/

export default class FormController extends Controller {
  static targets = [
    'name',
    'organisationNumber',
    'addressStreet',
    'addressZip',
    'addressCity',
    'phoneNumber',
    'email',
    'countryNumber',
    'customer',
    'organisationNumberWarning',
    'isPrivate',
    'submit',
  ]

  declare nameTarget: HTMLInputElement
  declare addressStreetTarget: HTMLInputElement
  declare addressZipTarget: HTMLInputElement
  declare addressCityTarget: HTMLInputElement
  declare phoneNumberTarget: HTMLInputElement
  declare emailTarget: HTMLInputElement
  declare countryNumberTarget: HTMLSelectElement
  declare customerTarget: HTMLInputElement
  declare hasOrganisationNumberTarget: boolean
  declare organisationNumberTarget: HTMLInputElement
  declare organisationNumberWarningTarget: HTMLElement
  declare hasOrganisationNumberWarningTarget: boolean
  declare isPrivateTarget: HTMLInputElement
  declare hasSubmitTarget: boolean
  declare submitTarget: HTMLButtonElement

  form: HTMLFormElement
  companyLookup: CompanyLookup
  defaultCountryNumber: string
  submitDisallowed: boolean

  connect() {
    this.organisationNumberWarningTarget.style.display = 'none'

    // If the submit is disabled server side, we should never make it enabled
    this.submitDisallowed = this.hasSubmitTarget ? this.submitTarget.disabled : false

    // We first need to let Stimulus mount ALL controllers. If we don't use
    // nextFrame then partner-search won't be mounted at the time fo the first
    // rendering.
    nextFrame(() => this.handleCorporationChange())

    this.form = this.nameTarget.form
    this.defaultCountryNumber = this.countryNumberTarget.value

    this._handleAjaxError = this._handleAjaxError.bind(this)
    this._handleAjaxSuccess = this._handleAjaxSuccess.bind(this)

    this.form.addEventListener('ajax:error', this._handleAjaxError)
    this.form.addEventListener('ajax:success', this._handleAjaxSuccess)
    this.updateSubmitAvailability()

    $(this.countryNumberTarget).on('select2:select', () => {
      this.handleOrganisationNumberChange()
      this.handleFormChange()
      this.updateSubmitAvailability()
    })
  }

  _handleAjaxError(event) {
    const data = event.detail[0]
    new JsFormError(data.model_name, data).updateForm()
    this._dispatch('partner--form:error', { partner: data })
  }

  _handleAjaxSuccess(event) {
    const data = event.detail[0]
    this._dispatch('partner--form:create', { partner: data })
  }

  _dispatch(eventName, params = {}) {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.element.dispatchEvent(cancelEvent)
  }

  switchPrivate(value) {
    this.isPrivateTarget.value = value

    if (value) {
      this.organisationNumberTarget.parentElement.classList.add('d-none')
    } else {
      this.organisationNumberTarget.parentElement.classList.remove('d-none')
    }
  }

  handleOrganisationNumberChange() {
    this.organisationNumberWarningTarget.style.display = 'none'

    if (
      this.hasOrganisationNumberTarget &&
      this.countryNumberTarget.value === '578' &&
      this.hasOrganisationNumberWarningTarget &&
      this.organisationNumberTarget.value.length >= 9
    ) {
      this.companyLookup = new CompanyLookup()
      this.companyLookup.validate_organisation_number(
        this.organisationNumberTarget.value,
        this.organisationNumberWarningTarget,
      )
    }
  }

  setPartnerName(name) {
    $(this.nameTarget).val(name)
    this.handleFormChange()
  }

  resetForm() {
    $(this.nameTarget).val('')
    $(this.addressStreetTarget).val('')
    $(this.addressZipTarget).val('')
    $(this.addressCityTarget).val('')
    $(this.organisationNumberTarget).val('')
    $(this.phoneNumberTarget).val('')
    $(this.emailTarget).val('')

    $(this.countryNumberTarget).val(this.defaultCountryNumber)
    $(this.countryNumberTarget).trigger('change')

    JsFormError.removePreviousErrors()
    this.updateSubmitAvailability()
  }

  handleFormChange() {
    let partner = {
      name: this.nameTarget.value,
      address_street: this.addressStreetTarget.value,
      address_zip: this.addressZipTarget.value,
      address_city: this.addressCityTarget.value,
      organisation_number: this.organisationNumberTarget.value,
      phone_number: this.phoneNumberTarget.value,
      email: this.emailTarget.value,
    }

    this._dispatch('partner--form:change', { partner: partner })
  }

  handlePartnerChange(event) {
    const partner = event.partner

    $(this.nameTarget).val(partner.name)
    $(this.phoneNumberTarget).val(partner.phone_number)
    $(this.emailTarget).val(partner.email)
    $(this.addressStreetTarget).val(partner.address_street)
    $(this.addressZipTarget).val(partner.address_zip)
    $(this.addressCityTarget).val(partner.address_city)
    $(this.organisationNumberTarget).val(partner.organisation_number)

    this.updateSubmitAvailability()
  }

  updateSubmitAvailability() {
    let canSubmit = (name, organisationNumber, countryNumber, privatePartner) => {
      return (
        name &&
        $('.partner-exists').length === 0 &&
        (privatePartner ||
          organisationNumber === '' ||
          this.isNorwegianOrganisationNumber(organisationNumber) ||
          // 578 is a number of Norway
          countryNumber !== '578')
      )
    }

    let isSubmitAvailable = canSubmit(
      this.nameTarget.value,
      this.organisationNumberTarget.value,
      this.countryNumberTarget.value,
      this.privatePartner,
    )

    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !isSubmitAvailable || this.submitDisallowed
    }

    this._dispatch('partner--form:submitAvailabilityChange', {
      canSubmit: isSubmitAvailable,
    })
  }

  handleCorporationChange() {
    // The hint under the phone number is shown to private partners.
    const $privateElements = $(this.phoneNumberTarget).next('div.form-text')

    const $corporationElements = $([this.organisationNumberTarget]).closest('div')

    if (this.privatePartner) {
      $privateElements.show()
      $corporationElements.hide()
    } else {
      $privateElements.hide()
      $corporationElements.show()
    }

    this.updateSubmitAvailability()
  }

  isNorwegianOrganisationNumber(string) {
    return NORWEGIAN_ORGANISATION_NUMBER_REGEXP.exec(string.replace(/\s/g, '')) !== null
  }

  get privatePartner() {
    return this.isPrivateTarget.checked
  }
}
