import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  connect() {
    this.element.addEventListener('ajax:beforeSend', (event: CustomEvent) => {
      const url = this.removeEmptyQueryParams(event.detail[1].url)
      Turbo.navigator.history.push(url)
    })
  }

  private removeEmptyQueryParams(path): URL {
    try {
      // Path might or might not contain the origin (http://host:port), lets add it if it doesn't
      // The reason is that new URL() only accepts full urls, not only paths
      if (!path.startsWith('http')) {
        path = `${window.location.origin}${path}`
      }

      let url = new URL(path)
      url.searchParams.forEach((value, key) => {
        if (value == '') {
          url.searchParams.delete(key)
        }
      })
      return url
    } catch (error) {
      console.error(error)
      // In case of error we just give up and return the same path
      // This will show up as ugly URLs with empty parameters
      return new URL(path)
    }
  }
}
