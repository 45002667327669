import { Controller } from '@hotwired/stimulus'

/*
 This controller enables/disables the a target element based on targets value.
 */
export default class extends Controller {
  static targets = ['source', 'target']

  declare sourceTargets: [HTMLInputElement]
  declare targetTarget: HTMLFormElement
  declare hasSourceTarget: Boolean
  declare hasTargetTarget: Boolean

  connect() {
    this.toggle()
  }

  toggle() {
    if (!this.hasSourceTarget || !this.hasTargetTarget) {
      console.error('Both source and target must be defined.')
      return
    }
    this.targetTarget.disabled = this.isDisabled()
  }

  isDisabled(): Boolean {
    let allSources = this.sourceTargets.map((source) => {
      if (source.type == 'checkbox') {
        return !source.checked
      } else {
        console.error(`Unknown element type: ${source.type}. Please add the evaluation in 'isDisabled'`)
        return true
      }
    })
    return !allSources.every((v) => v === false)
  }
}
