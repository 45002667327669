<template>
  <form @submit.prevent="onSubmit" ref="form">
    <b-modal :id="id" :title="title" sizeClass="modal-lg">
      <label>{{ i18n().t('found_company.share_capital.find_company') }}</label>
      <Select2
        v-if="owner === undefined"
        v-model="selectedCompanyId"
        url="/company_lookups"
        v-on:select="setSelectedCompany"
      />
      <div v-if="ownerTemplate.company_name !== undefined">
        <div class="card-luca card-body mt-2 mb-2 d-flex flex-row align-items-center">
          <font-awesome-icon :icon="['far', 'building']" />
          <abbr class="ms-2">{{ `${ownerTemplate.company_name} (org.no: ${ownerTemplate.organisation_number})` }}</abbr>
        </div>
        <div
          id="signatoriesAccordion"
          data-bs-toggle="collapse"
          aria-labelledby="collapseSignatories"
          data-bs-target="#collapseSignatories"
          role="button"
          aria-expanded="false"
          aria-controls="collapseSignatories"
        >
          <SignatoryForm
            v-for="(signatory, index) in ownerTemplate.signatories"
            :key="index.toString()"
            :index="index.toString()"
            :signatory="signatory"
            classes="collapse acc-collapsable show card-luca card-body"
            @submit="addCompanyContact"
            @remove="removeCompanyContact"
            type="company"
            :showRemove="ownerTemplate.signatories.length > 1"
            :ref="`signatoryForm${index}`"
          />
        </div>
        <div @click="addTemplateContact" class="btn btn-link text-primary">
          {{ i18n().t('found_company.share_capital.add_company_contact') }}
        </div>
      </div>
      <template v-slot:buttons>
        <button class="btn btn-primary" :id="`${id}-submit-button`" type="submit">
          <font-awesome-icon :icon="['far', 'coins']" />
          {{ i18n().t(`found_company.share_capital.save`) }}
        </button>
      </template>
    </b-modal>
  </form>
</template>

<script lang="ts">
import FormInput from './../../../../components/FormInput.vue'
import Select2 from './../../../../components/Select2.vue'
import SignatoryForm from './../../../../found_company/submissions/edit/components/SignatoryForm.vue'
import JsFormError from './../../../../../standalone/components/JsFormError'
import { Collapse, Modal } from 'bootstrap'
import { mapActions, mapState } from 'pinia'
import { useFoundCompanySubmissionStore } from '../store'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { FormInput, Select2, SignatoryForm },
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    owner: {
      required: false,
      type: Object,
    },
  },
  mounted() {
    if (this.owner === undefined) {
      this.ownerTemplate.signatories = [
        {
          full_name: '',
          email: '',
          phone_number: '',
          nnin: '',
          post_address_street_one: '',
          post_address_zip: '',
        },
      ]
    }
  },
  data() {
    return {
      ownerTemplate: {
        id: this.owner?.id?.toString(),
        found_company_submission_id: null,
        organisation_number: this.owner?.organisation_number,
        company_name: this.owner?.company_name,
        business_address_street_one: this.owner?.business_address_street_one,
        business_address_zip: this.owner?.business_address_zip,
        business_address_city: this.owner?.business_address_city,
        vat_registered: this.owner?.vat_registered,
        signatories: this.owner?.signatories,
        found_company_signatories_attributes: {},
      },
      selectedCompanyId: null,
      selectedCompany: null,
      formRule: [(v) => !!v || i18n.t('errors.messages.blank')],
    }
  },
  computed: {
    ...mapState(useFoundCompanySubmissionStore, ['submission_id']),
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useFoundCompanySubmissionStore, {
      addOwner: 'addOwner',
      updateOwner: 'updateOwner',
    }),

    form(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement
    },
    async onSubmit() {
      let response
      this.ownerTemplate.found_company_submission_id = this.submission_id
      this.ownerTemplate.found_company_signatories_attributes = {}
      if (this.ownerTemplate.id === undefined) {
        this.ownerTemplate.signatories.forEach((signatory, index) => {
          this.ownerTemplate.found_company_signatories_attributes[index] = signatory
        })
        response = await this.addOwner(this.ownerTemplate)
      } else {
        this.ownerTemplate.signatories.forEach((signatory, index) => {
          if (signatory.hashid !== undefined) {
            signatory.hashid = signatory.hashid.toString()
          }
          this.ownerTemplate.found_company_signatories_attributes[signatory.hashid] = signatory
        })
        response = await this.updateOwner(this.ownerTemplate)
      }
      if (response.status === 200) {
        this.form().reset()
        Modal.getOrCreateInstance(document.querySelector(`#${this.id}`)).hide()
      } else {
        let data = await response.json()
        new JsFormError('found_company_owner', data)
        // Toggle accordions we have error on
        let attributes = data.errors.found_company_signatories_attributes
        Object.keys(attributes).forEach((id) => {
          if (Object.keys(attributes[id]).length > 0) {
            Collapse.getOrCreateInstance(document.querySelector(`#signatory-form-${id}`)).show()
          }
        })
      }
    },
    setSelectedCompany(data) {
      this.ownerTemplate.company_name = data.name
      this.ownerTemplate.organisation_number = data.organisation_number
      this.ownerTemplate.business_address_street_one = data.business_address_street_one
      this.ownerTemplate.business_address_zip = data.business_address_zip
      this.ownerTemplate.business_address_city = data.business_address_city
      this.ownerTemplate.vat_registered = data.vat_registered
    },
    addCompanyContact(payload) {
      this.ownerTemplate.signatories[payload.index] = payload.signatory
    },
    removeCompanyContact(payload) {
      if (payload.hashid === undefined) {
        this.ownerTemplate.signatories.splice(payload.index, 1)
      } else {
        this.ownerTemplate.signatories[payload.index]._destroy = true
      }
    },
    addTemplateContact() {
      this.ownerTemplate.signatories.forEach((signatory, index) => {
        Collapse.getOrCreateInstance(document.querySelector(`#company-signatory-form-${index}`)).hide()
      })
      this.ownerTemplate.signatories.push({
        full_name: '',
        email: '',
        phone_number: '',
        nnin: '',
        post_address_street_one: '',
        post_address_zip: '',
      })
    },
  },
})
</script>
