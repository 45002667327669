<template>
  <div data-controller="formatted-amount" class="amount" :class="{ required: required }">
    <label class="form-control-label amount required float-start">
      {{ label }}<abbr v-if="required" title="required"></abbr>
    </label>
    <input
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      class="form-control string amount"
      :class="{ required: required }"
      placeholder="0,00"
      @change="$emit('change')"
      :name="name"
      data-formatted-amount-target="amountInput"
      data-action="blur->formatted-amount#parseExpression focus->formatted-amount#clear"
      type="text"
    />
    <small class="form-text text-muted float-start" v-if="hint !== undefined">{{ hint }}</small>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    required: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    hint: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'change'],
})
</script>
