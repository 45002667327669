import { rails_fetch } from '../../../../util/rails_fetch'

export default {
  async match_payments_index(accountId: String, companyId: String, bankTransactionId: String) {
    const url = `/${companyId}/bank_accounts/${accountId}/bank_reconciliations/match_payments_index.json?bank_transaction_id=${bankTransactionId}&no_payment=true`
    return await rails_fetch(url)
  },

  async customer_invoices(accountId: String, companyId: String, bankTransactionId: String) {
    const url = `/${companyId}/bank_accounts/${accountId}/bank_reconciliations/sale_invoices?bank_transaction_id=${bankTransactionId}`
    return await rails_fetch(url)
  },
}
