import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import JsFormError from '../standalone/components/JsFormError'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = {
    reloadPage: { type: Boolean, default: false },
  }
  static targets = ['modal', 'form']

  declare formTarget: HTMLFormElement
  declare reloadPageValue: boolean

  connect() {
    this._handleAjaxSuccess = this._handleAjaxSuccess.bind(this)
    this._handleAjaxError = this._handleAjaxError.bind(this)

    this.formTarget.addEventListener('ajax:success', this._handleAjaxSuccess)
    this.formTarget.addEventListener('ajax:error', this._handleAjaxError)
  }

  hide() {
    Modal.getOrCreateInstance(this.element).hide()
  }

  _handleAjaxSuccess(event) {
    const data = event.detail[0]
    const modelName = this.element.id.replace('-new-modal', '')
    // We expect the id of the modal to be #model-name-new-modal
    // handle event with: "new-modal:create-model-name->controller#action
    this._dispatch(`new-modal:create-${modelName}`, { model: data })
    JsFormError.removePreviousErrors()
    this.formTarget.reset()
    this.hide()

    if (this.reloadPageValue) {
      location.reload()
    }
  }

  _handleAjaxError(event) {
    const data = event.detail[0]
    new JsFormError(data.model_name, data).updateForm()
  }

  _dispatch(eventName, params = {}) {
    const event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
    Object.assign(event, params)
    this.element.dispatchEvent(event)
  }
}
