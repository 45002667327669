import { Controller } from '@hotwired/stimulus'
import TotalAmountController from './total_amount_controller'
import $ from 'jquery'
import { endOfMonth, isAfter, isSameMonth, parseISO } from 'date-fns'
import { parseDecimal } from '../classes/parse_decimal'
import { Modal } from 'bootstrap'
import { nextFrame } from '../rendering'
import NestedFormController from 'controllers/nested_form_controller'
import { i18n } from '../libraries/i18n'

// IMPORTANT: This controller REQUIRES that total-amount controller is also
// used. You MUST add total-amount controller before opening-balance like so:
//
//   data-controller="total-amount opening-balance"
export default class extends Controller {
  static targets = [
    // Existing opening balance items.
    'item',

    // The row that is used as a template when adding new items to the balance.
    'template',

    // The button used to submit the balance.
    'submitButton',

    // nested form controller
    'nestedFormController',

    // The warning shown when the opening balance cannot be submitted due to
    // incomplete or incorrect data.
    'invalidDataWarning',

    'date',
    'dateInFutureWarning',
    'dateInPastWarning',
    'reconciledAccountTouchedWarning',
    'confirmModal',
    'confirmModalHeader',
    'confirmModalBody',
  ]
  declare templateTarget
  declare nestedFormControllerTarget
  declare itemTargets
  declare submitButtonTarget: HTMLButtonElement
  declare invalidDataWarningTarget: HTMLElement
  declare dateTarget: HTMLInputElement
  declare dateInFutureWarningTarget: HTMLElement
  declare dateInPastWarningTarget: HTMLElement
  declare reconciledAccountTouchedWarningTarget: HTMLElement
  declare confirmModalTarget: HTMLElement
  declare confirmModalHeaderTarget: HTMLElement
  declare confirmModalBodyTarget: HTMLElement
  _totalAmountController: TotalAmountController

  connect() {
    // We need a reference to the total amount controller in order to
    // trigger an update of the total when an item is removed.
    this._totalAmountController = this.application.getControllerForElementAndIdentifier(
      this.element,
      'total-amount',
    ) as TotalAmountController

    if (this._totalAmountController === null) {
      console.error(
        'You need to add the total-amount controller before ' +
          'opening-balance to make it work correctly. The ' +
          'offending element is %o',
        this.element,
      )
      return
    }

    // The event handler is triggered when editing _any_ item. If after making
    // the change the user doesn't have a blank item available then the
    // handler will add another one.
    $(this.element).on('keyup', 'select, input', this._handleChange.bind(this))
    document.addEventListener('account_selector:select', (event) => {
      this._handleChange(event)
    })

    nextFrame(() => {
      this._addNewItem()
    })

    this._updateSubmitButtonAvailability()
  }

  handleItemDeleteClick(event) {
    this._totalAmountController.update()
    this._updateItemNumbers()
    this._updateSubmitButtonAvailability()
    this._handleReconciledAccountsChanges()

    event.preventDefault()
    return false
  }

  handleSubmit(event) {
    if (Object.keys((window as any).openingBalance.reconciledAccounts).length !== 0) {
      const newDate = parseISO(this.dateTarget.value)
      const originDate = parseISO(this.dateTarget.dataset.value)
      let showModal = false
      let modalHeaderText = ''
      let modalBodyText = ''
      if (isSameMonth(newDate, originDate)) {
        if (this._isReconciledAccountsChanged()) {
          modalHeaderText = i18n.t('opening_balance.confirm_modal_header.bank_account_changed')
          modalBodyText = i18n.t('opening_balance.reconciled_account_changed')
          showModal = true
        }
      } else if (isAfter(newDate, endOfMonth(originDate))) {
        modalHeaderText = i18n.t('opening_balance.confirm_modal_header.date_changed')
        modalBodyText = i18n.t('opening_balance.future_date_warning')
        showModal = true
      } else {
        modalHeaderText = i18n.t('opening_balance.confirm_modal_header.date_changed')
        modalBodyText = i18n.t('opening_balance.past_date_warning')
        showModal = true
      }
      if (showModal) {
        this.confirmModalHeaderTarget.innerHTML = modalHeaderText
        this.confirmModalBodyTarget.innerHTML = modalBodyText
        Modal.getOrCreateInstance(this.confirmModalTarget).show()
        event.preventDefault()
      }
    }
  }

  _handleChange(event) {
    const $itemTarget = $(event.target).parents("[data-opening-balance-target='item']")
    $itemTarget.find('a').removeClass('disabled')
    this._ensureLastItemIsEmpty()
    this._updateValidity()
    if (event.target.tagName === 'SELECT') {
      this._validateCode(event.target, event.code)
    }
    this._updateItemNumbers()
    this._updateSubmitButtonAvailability()
    this._handleReconciledAccountsChanges()
  }

  _ensureLastItemIsEmpty() {
    if (this.itemTargets.length === 0 || !this._isLastItemEmpty()) {
      this._addNewItem()
    }
  }

  toggleDateWarning() {
    const reconciledAccounts = (window as any).openingBalance.reconciledAccounts
    if (Object.keys(reconciledAccounts).length === 0) {
      return
    }
    const newDate = parseISO(this.dateTarget.value)
    const originDate = parseISO(this.dateTarget.dataset.value)
    if (isSameMonth(newDate, originDate)) {
      this.dateInFutureWarningTarget.classList.add('d-none')
      this.dateInPastWarningTarget.classList.add('d-none')
    } else if (isAfter(newDate, endOfMonth(originDate))) {
      this.dateInPastWarningTarget.classList.add('d-none')
      this.dateInFutureWarningTarget.classList.remove('d-none')
    } else {
      this.dateInFutureWarningTarget.classList.add('d-none')
      this.dateInPastWarningTarget.classList.remove('d-none')
    }
  }

  _handleReconciledAccountsChanges() {
    if (this._isReconciledAccountsChanged()) {
      this.reconciledAccountTouchedWarningTarget.classList.remove('d-none')
    } else {
      this.reconciledAccountTouchedWarningTarget.classList.add('d-none')
    }
  }

  _isReconciledAccountsChanged() {
    const items = this.itemTargets as Array<HTMLElement>
    let changedAccountsNumber = 0 as number
    ;(window as any).openingBalance.reconciledAccounts.forEach(function (account) {
      if (changedAccountsNumber > 0) {
        return
      }
      let accountSum = 0
      // In this block we sum all amounts found for specific account
      items.forEach(function (item) {
        if (item.querySelector('select').value !== account.account_hashid) {
          return
        } else {
          accountSum += parseDecimal(item.querySelector('.item-amount').nodeValue)?.toNumber()
        }
      })
      if (accountSum !== account.amount_cents) {
        changedAccountsNumber += 1
      }
    })
    return changedAccountsNumber > 0
  }

  _updateValidity() {
    this.itemTargets.forEach((itemTarget, index) => {
      // We skip the last item as its only role is to allow the user to create
      // new elements.
      if (index === this.itemTargets.length - 1) {
        return
      }

      const [account, amount] = this._getAccountAndAmount(itemTarget)

      if (account === '') {
        itemTarget.querySelector('select').classList.add('is-invalid')
      } else {
        itemTarget.querySelector('select').classList.remove('is-invalid')
      }

      const amountElement = itemTarget.querySelector('input[type="text"]')

      if (parseDecimal(amountElement.nodeValue)?.toNumber() === 0) {
        amountElement.classList.add('is-invalid')
      } else {
        amountElement.classList.remove('is-invalid')
      }
    })
  }

  _addNewItem() {
    const nestedFormController = this.application.getControllerForElementAndIdentifier(
      this.nestedFormControllerTarget,
      'nested-form',
    ) as NestedFormController
    nestedFormController.add(document.createEvent('Event'))
  }

  _updateSubmitButtonAvailability() {
    if (this._canSubmit()) {
      $(this.submitButtonTarget).removeAttr('disabled')
      $(this.invalidDataWarningTarget).addClass('d-none')
    } else {
      $(this.submitButtonTarget).attr('disabled', '')
      $(this.invalidDataWarningTarget).removeClass('d-none')
    }
  }

  _canSubmit() {
    return (
      this._hasAtLeastOneCompleteItem() &&
      this._hasOnlyCompleteAndEmptyItems() &&
      this._hasNoInvalidFields() &&
      this._totalAmountController.total === 0
    )
  }

  _hasAtLeastOneCompleteItem() {
    const $otherItems = $(this.itemTargets).filter((_index, element) => this._isItemComplete(element))
    return $otherItems.length > 0
  }

  _hasOnlyCompleteAndEmptyItems() {
    const $otherItems = $(this.itemTargets).filter(
      (_index, element) => !this._isItemComplete(element) && !this._isItemEmpty(element),
    )
    return $otherItems.length === 0
  }

  // This method updates the indicies in the form input from 0 to the correct index
  // Example for the second item (index 1)
  // opening_balance_form_object_base[items_attributes][0][hashid]
  // will be changed to
  // opening_balance_controller.ts:185 opening_balance_form_object_base[items_attributes][1][hashid]
  _updateItemNumbers() {
    $(this.itemTargets).each(function (index, target) {
      $('[name]', target).attr('name', (_index, value) => value.replace(/\d+/, index.toString()))
    })
  }

  // Precondition:
  // - itemTargets is not empty.
  _isLastItemEmpty() {
    return this._isItemEmpty(this.itemTargets[this.itemTargets.length - 1])
  }

  _isItemComplete(itemTarget): boolean {
    const [account, amount] = this._getAccountAndAmount(itemTarget)
    return account !== '' && amount !== ''
  }

  _isItemEmpty(itemTarget): boolean {
    const [account, amount] = this._getAccountAndAmount(itemTarget)
    return account === '' && amount === ''
  }

  _getAccountAndAmount(itemTarget): Array<any> {
    const $itemTarget = $(itemTarget)
    const $select = $itemTarget.find('select')
    const $amount = $itemTarget.find('input.item-amount')
    return [$select.val(), $amount.val()]
  }

  _validateCode(element, code): void {
    let $elem = $(element)
    let $container = $elem.parent()
    $elem.removeClass('is-invalid')
    $container.removeClass('is-invalid')
    $container.siblings('.invalid-feedback').remove()
    if (this._isSystemAccount(code)) {
      $elem.addClass('is-invalid')
      $container.addClass('is-invalid')
      $container.after(`<div class="invalid-feedback">${i18n.t('accounting.errors.system_account')}</div>`)
    }
  }

  _isSystemAccount(code): boolean {
    return parseInt(code) >= 2700 && parseInt(code) < 2740
  }

  _hasNoInvalidFields(): boolean {
    return $('.is-invalid').length === 0
  }
}
