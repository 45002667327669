import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../../classes/parse_decimal'
import Decimal from 'decimal.js'
import { amountStr } from '../formatting_helper'
import { rails_fetch } from '../../util/rails_fetch'

export default class extends Controller {
  static targets = [
    'bankClosingBalance',
    'accountingClosingBalance',
    'difference',
    'wrongTotals',
    'correctTotals',
    'infoAlert',
  ]

  declare hasBankClosingBalanceTarget: boolean
  declare accountingClosingBalanceTarget: HTMLElement
  declare bankClosingBalanceTarget: HTMLInputElement
  declare infoAlertTarget: HTMLElement
  declare wrongTotalsTarget: HTMLElement
  declare correctTotalsTarget: HTMLElement
  declare differenceTarget: HTMLElement

  differenceThreshold: number
  bankClosingBalanceTimeout

  connect() {
    if (this.hasBankClosingBalanceTarget) {
      this.differenceThreshold = 0
      const accountingClosingAmountMutationObserver = new MutationObserver((mutations) => {
        this.totalsDifference()
      })

      accountingClosingAmountMutationObserver.observe(this.accountingClosingBalanceTarget, {
        characterData: true,
        subtree: true,
      })
      this.totalsDifference()
    }
  }

  handleBankClosingBalanceChange() {
    this.bankClosingBalanceTarget.value = this.bankClosingBalanceTarget.value.replace(/[^-0-9,.]/, '')
    this.totalsDifference()
    if (this.bankClosingBalanceTimeout != null) clearTimeout(this.bankClosingBalanceTimeout)
    this.bankClosingBalanceTimeout = setTimeout(this.handleUpdateOutgoingBankAccountBalance.bind(this), 500)
  }

  parseBalanceAmount(amountString): Decimal {
    let parsedAmount = parseDecimal(amountString)
    return parsedAmount.isNaN() ? new Decimal(0) : parsedAmount
  }

  handleUpdateOutgoingBankAccountBalance() {
    let valuesToSubmit = this.bankClosingBalanceTarget.name
    valuesToSubmit += '=' + this.toCents(this.bankClosingBalanceTarget.value)
    let companyHashId = window.location.pathname.split('/')[1]
    let reconciliationId = this.bankClosingBalanceTarget.dataset.reconciliationId

    let url = `/${companyHashId}/bank_reconciliations/${reconciliationId}/update_bank_outgoing_balance?${valuesToSubmit}`
    let options = {
      method: 'PATCH',
    }
    rails_fetch(url, options)
  }

  totalsDifference() {
    let parsedAccountingOutgoingAmount = this.parseBalanceAmount(this.accountingClosingBalanceTarget.innerHTML)
    let parsedBankOutgoingAmount = this.parseBalanceAmount(this.bankClosingBalanceTarget.value)
    let difference = parsedBankOutgoingAmount.minus(parsedAccountingOutgoingAmount).abs().toFixed(2)
    this.toggleAlerts(difference)
  }

  toggleAlerts(difference) {
    if (!this.bankClosingBalanceTarget.value) {
      this.infoAlertTarget.classList.add('alert-group__single-alert')
      this.wrongTotalsTarget.classList.add('d-none')
      this.correctTotalsTarget.classList.add('d-none')
    } else {
      this.infoAlertTarget.classList.remove('alert-group__single-alert')
      if (difference <= this.differenceThreshold) {
        this.correctTotalsTarget.style.display = 'flex'
        this.correctTotalsTarget.classList.remove('d-none')
        this.wrongTotalsTarget.classList.add('d-none')
      } else {
        this.wrongTotalsTarget.style.display = 'flex'
        this.wrongTotalsTarget.classList.remove('d-none')
        this.correctTotalsTarget.classList.add('d-none')
        this.differenceTarget.innerHTML = amountStr(difference)
      }
    }
    this.toggleSubmit(difference)
  }

  toCents(amountString) {
    return this.parseBalanceAmount(amountString).times(100)
  }

  toggleSubmit(difference) {
    let submitButton = document.querySelector('#reconcile-button') as HTMLElement
    let can_be_reconciled =
      submitButton.dataset.disabled === 'false' &&
      difference <= this.differenceThreshold &&
      this.bankClosingBalanceTarget.value
    can_be_reconciled ? submitButton.classList.remove('disabled') : submitButton.classList.add('disabled')
  }

  async disconnect() {
    if (this.hasBankClosingBalanceTarget) {
      await this.handleUpdateOutgoingBankAccountBalance()
    }
  }
}
