<template>
  <div class="col-12 col-sm-10 px-0 px-md-2 pt-sm-4">
    <div class="page-title ps-0">
      <div class="page-title__main">
        {{ i18n().t('found_company.share_capital.page_header') }}
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <FormInput
        class="col-12 col-sm-6 col-xl-4"
        v-model="totalShareCapital"
        @change="setTotalShareCapital"
        id="total-amount"
        name="found_company_submission[total_share_capital_cents]"
        :label="i18n().t(`activerecord.attributes.found_company/submission.total_share_capital_cents`)"
        :required="true"
      >
        <span class="form-control input-group-text rounded-start card">{{ i18n().t('currency.nok') }}</span>
      </FormInput>
      <div class="col-12 col-lg-12 col-xl-8">
        <div class="alert alert-info d-flex">
          <font-awesome-icon :icon="['far', 'info-circle']" class="mt-1" />
          <div>
            <div class="fw-bold ms-2">
              {{ i18n().t('found_company.share_capital.info_share.title') }}
            </div>
            <div class="ms-2" v-html="i18n().t('found_company.share_capital.info_share.description')"></div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="mt-4 mb-4">
      {{ i18n().t('found_company.share_capital.title', { name: company_name }) }}
    </h2>
    <div class="alert alert-info mt-4 d-flex">
      <font-awesome-icon :icon="['far', 'info-circle']" class="mt-1" />
      <div>
        <div class="fw-bold ms-2">
          {{ i18n().t('found_company.share_capital.info_company.title') }}
        </div>
        <div class="ms-2">
          {{ i18n().t('found_company.share_capital.info_company.description') }}
        </div>
      </div>
    </div>
    <OwnerCard
      class="mt-4"
      v-for="(owner, index) in owners"
      :key="`owner-${index}`"
      :owner="owner"
      :id="`owner-${index}`"
    />
    <div :class="totalSummaryClasses" class="alert mt-4">
      <div class="row">
        <div class="col-12 col-sm-6 d-flex justify-content-start">
          <font-awesome-icon
            :icon="['far', [validTotalAmount ? 'check-circle' : 'exclamation-triangle']]"
            class="ms-0 mt-1 mb-1 me-1"
          />
          <span>{{ i18n().t('found_company.share_capital.total_percent', { percent: percentStr() }) }}</span>
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-start justify-content-sm-end">
          <span class="fw-bold me-1 text-nowrap">{{
            i18n().l('number', totalAmountCents / 100, { delimiter: ' ' })
          }}</span>
          <span class="text-nowrap">{{ i18n().t('found_company.share_capital.total_investment') }}</span>
        </div>
      </div>
    </div>

    <div class="button-row float-sm-start d-flex justify-content-between justify-content-sm-start mt-4">
      <button
        class="btn btn-outline-secondary text-nowrap me-2"
        data-bs-toggle="modal"
        data-bs-target="#new-person-modal"
        data-test-id="add-person-modal-button"
        id="add-person-modal-button"
      >
        {{ i18n().t('found_company.share_capital.modal.person.title') }}
      </button>
      <button
        class="btn btn-outline-secondary text-nowrap"
        data-bs-toggle="modal"
        data-bs-target="#new-company-modal"
        data-test-id="add-company-modal-button"
        id="add-company-modal-button"
      >
        {{ i18n().t('found_company.share_capital.modal.company.title') }}
      </button>
    </div>
    <div class="button-row mb-5 pb-5">
      <button
        class="btn btn-primary mt-4"
        id="submit-share-capital"
        v-on:click="nextStepAux"
        :disabled="validShareCapital"
        type="submit"
      >
        {{ i18n().t('found_company.continue') }}
      </button>
    </div>
    <PersonModal id="new-person-modal" :title="i18n().t(`found_company.share_capital.modal.person.title`)" />
    <CompanyModal id="new-company-modal" :title="i18n().t(`found_company.share_capital.modal.company.title`)" />
  </div>
</template>

<script lang="ts">
import AmountInput from './../../../../components/AmountInput.vue'
import OwnerCard from './../../../submissions/edit/components/OwnerCard.vue'
import CompanyModal from './../../../submissions/edit/components/CompanyModal.vue'
import PersonModal from './../../../submissions/edit/components/PersonModal.vue'
import { mapActions, mapState, mapWritableState } from 'pinia'
import JsFormError from './../../../../../standalone/components/JsFormError'
import FormInput from './../../../../components/FormInput.vue'
import Decimal from 'decimal.js'
import { useFoundCompanySubmissionStore } from '../store'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    FormInput,
    AmountInput,
    OwnerCard,
    PersonModal,
    CompanyModal,
  },
  computed: {
    ...mapState(useFoundCompanySubmissionStore, [
      'submission_id',
      'company_name',
      'owners',
      'totalAmountCents',
      'totalPercent',
    ]),
    ...mapWritableState(useFoundCompanySubmissionStore, ['total_share_capital_cents']),
    totalSummaryClasses() {
      if (this.validTotalAmount) {
        return 'alert-success'
      } else {
        return 'alert-danger'
      }
    },
    validTotalAmount() {
      return this.totalAmountCents === this.total_share_capital_cents && (this.totalPercent as number) === 100
    },
    totalShareCapital: {
      get() {
        return this.total_share_capital_cents / 100
      },
      set(newValue: String) {
        let val = newValue === '' ? 0 : Number.parseInt(newValue.replace(/[^0-9]/g, '')) * 100
        this.owners.forEach((owner) => (owner.share_capital_cents = (owner.percent / 100) * val))
        this.total_share_capital_cents = val
      },
    },
    validShareCapital() {
      return this.owners.reduce((a, b) => a + b.share_capital_cents, 0) !== this.total_share_capital_cents
    },
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useFoundCompanySubmissionStore, {
      setTotalShareCapitalCents: 'setTotalShareCapitalCents',
      nextStep: 'nextStep',
    }),
    async setTotalShareCapital() {
      let res = await this.setTotalShareCapitalCents(this.total_share_capital_cents)

      if (res.status == 200) {
        JsFormError.removePreviousErrors()
      } else {
        new JsFormError('found_company_submission', res.body)
      }
    },
    percentStr() {
      return new Decimal(this.totalPercent.toFixed(3)).toString()
    },
    nextStepAux() {
      this.nextStep({
        found_company_submission: {
          current_page: 'share_capital',
          total_share_capital_cents: this.total_share_capital_cents,
          id: this.submission_id,
        },
      })
    },
  },
})
</script>
