import $ from 'jquery'

export default class CompanyRole {
  row

  constructor(editId) {
    this.row = $(`#company-role-${editId}`)
  }

  remove() {
    this.row.remove()
  }
}
