import { Controller } from '@hotwired/stimulus'
import { nextFrame } from '../rendering'
import { isBefore, isFuture, isValid, parse, parseISO, setYear } from 'date-fns'
import PartnerSelectController from './partner/select_controller'
import FlatpickrController from './flatpickr_controller'
import VatRateController from './vat_rate_controller'
import { Modal } from 'bootstrap'
import { parseDecimal } from '../classes/parse_decimal'
import Decimal from 'decimal.js'
import { amountStr } from './formatting_helper'
import NestedFormController from '../controllers/nested_form_controller'
import Vat from '../classes/vat'
import { i18n } from '../libraries/i18n'

function isNaN(argument) {
  // NaN is the only floating value that is different from itself.
  return argument !== argument
}

export default class extends Controller {
  static targets = [
    'amount',
    'totalAmount',
    'totalAmountText',
    'paymentHeaderLabel',
    'invoiceNumber',
    'invoiceTypeSelect',
    'dueDateWarning',
    'purchaseType',
    'debitAccount',
    'templatePostingLine',
    'newPostingLine',
    'postingLine',
    'supplierSelect',
    'transactionText',
    'amountLabel',
    'vatCodeSelect',
    'newOrExisting',
    'paymentOn',
    'creditNoteWarning',
    'paidBy',
    'employees',
    'recipientBankNumber',
    'newOrExistingContainer',
    'employeesContainer',
    'deleteLineItem',
    'postingDate',
    'postingDateText',
    'invoiceDate',
    'dueDate',
    'nestedFormController',
    'vatAmount',
    'paymentBlock',
  ]

  static values = { businessType: String }

  declare businessTypeValue: string

  declare vatAmountTargets
  declare amountTargets
  declare postingLineTargets
  declare newPostingLineTarget
  declare newPostingLineTargets
  declare invoiceDateTarget
  declare hasInvoiceDateTarget: boolean
  declare dueDateTarget
  declare hasDueDateTarget: boolean
  declare paymentOnTarget
  declare hasPaymentOnTarget: boolean
  declare supplierSelectTarget
  declare hasSupplierSelectTarget: boolean
  declare invoiceTypeSelectTarget
  declare hasInvoiceTypeSelectTarget: boolean
  declare dueDateWarningTarget
  declare hasDueDateWarningTarget: boolean
  declare invoiceNumberTarget
  declare hasInvoiceNumberTarget: boolean
  declare transactionTextTarget
  declare transactionTextTargets
  declare hasTransactionTextTarget: boolean
  declare hasVatCodeSelectTarget: boolean
  declare recipientBankNumberTarget
  declare hasRecipientBankNumberTarget: boolean
  declare postingDateTarget
  declare hasPostingDateTarget: boolean
  declare paidByTarget
  declare hasPaidByTarget: boolean
  declare paymentHeaderLabelTarget
  declare hasPaymentHeaderLabelTarget: boolean
  declare deleteLineItemTarget
  declare hasDeleteLineItemTarget: boolean
  declare deleteLineItemTargets
  declare hasDeleteLineItemTargets: boolean
  declare postingDateTextTarget
  declare creditNoteWarningTarget
  declare hasCreditNoteWarningTarget: boolean
  declare vatCodeSelectTargets
  declare vatCodeSelectTarget
  declare totalAmountTextTarget
  declare totalAmountTarget
  declare purchaseTypeTarget
  declare amountLabelTargets
  declare nestedFormControllerTarget
  declare hasPaymentBlockTarget: boolean
  declare paymentBlockTarget: HTMLElement

  invoiceDate: Date
  dueDate: Date
  currentSupplierName: string | null
  currentInvoiceNumber: string | null
  paidBy: string | null

  connect() {
    this.updateDueDateWarning = this.updateDueDateWarning.bind(this)
    this.updateVatCodes = this.updateVatCodes.bind(this)
    this._updateVatCodes = this._updateVatCodes.bind(this)
    this.initializeDates()
    this.handlePaidByChange()
    this.initialFocusOnSupplierInvoice()
    // We need to wait until this frame is done because otherwise the partner
    // controller wouldn't be properly set up.
    nextFrame(() => this.setupAutomaticTranscationText())

    // We need to update the codes in the next frame because the array of VAT
    // codes hasn't been evaluated at the time connect is called.
    nextFrame(() => this.updateVatCodes(null))

    if (this.postingLineTargets.length === 0 && this.newPostingLineTargets.length === 0) {
      // wait when nested-form controller is loaded
      nextFrame(() => {
        this._addNewPostingLine()
      })
    } else {
      this._updateDeleteLineItemButtonAvailability()
      this.updateTotalAmount()
    }

    this.updateLabelsAndInputNames()
    this.showAssetModal()
    this.showReopenVatModalIfExists()

    // update the payment_on field in case the field is moved by the move controller
    window.addEventListener('load', () => {
      if (this.hasDueDateTarget && this.dueDateTarget.value != '') {
        this._updatePaymentOn(parseISO(this.dueDateTarget.value))
      }
    })
  }

  vat = new Vat()
  updateVatAmount(event) {
    if (!event || !event.target) {
      return
    }

    const invoice_line_item = event.target.closest('.supplier-invoice-line-item')
    this.calculateVatAmount(invoice_line_item)
  }

  updateAllVatAmountFields() {
    document.querySelectorAll('.supplier-invoice-line-item').forEach((invoice_line_item) => {
      this.calculateVatAmount(invoice_line_item)
    })
  }

  // If you ever add codes 86, 87, 88 or 89 to this list, please update the calculation. The VAT rate calculation
  // for these codes are different from the regular way and that is NOT currently done correctly in our code here
  // (it is correct on the back end though)
  SHOW_VAT_AMOUNT_CODE_LIST = ['1', '11', '12', '13']
  calculateVatAmount(invoice_line_item) {
    const index = this.amountTargets.findIndex((input) => invoice_line_item.contains(input))

    const [amountInput, vatInput, vatAmountInputRow] = [
      this.amountTargets[index],
      this.vatCodeSelectTargets[index],
      this.vatAmountTargets[index],
    ]
    if (!vatAmountInputRow) return

    const vatAmountInput = vatAmountInputRow.querySelector('input')
    const vatAmountLabel = vatAmountInputRow.querySelector('label')

    if (!vatInput || !vatInput[vatInput.selectedIndex]) {
      return
    }

    const vatOption = vatInput[vatInput.selectedIndex]
    const code = vatOption.value

    if (!this.SHOW_VAT_AMOUNT_CODE_LIST.includes(code)) {
      vatAmountInput.classList.add('d-none')
      vatAmountLabel.classList.add('d-none')
      return
    }
    vatAmountInput.classList.remove('d-none')
    vatAmountLabel.classList.remove('d-none')

    const rate = parseDecimal(vatOption.getAttribute('rate')).toNumber()
    const amount = parseDecimal(amountInput.value).toNumber() * 100
    const result = this.vat.inc_to_ex(amount, rate)[1] / 100.0 || 0

    vatAmountInput.value = amountStr(result)
  }

  setupSelect2ForExistingPostingLines() {
    // We need to skip the template row because if we initialize Select2 in it
    // then it'll break adding new rows. Select2 must be initialized in newly
    // added rows AFTER the template is cloned.
    this.setupSelect2($(this.postingLineTargets))
    this.setupSelect2($(this.newPostingLineTargets))
  }

  setupSelect2($elements) {
    $elements.attr('data-controller', 'account-select')
  }

  showReopenVatModalIfExists() {
    // Back-end decides will this element be present or not
    if (document.getElementById('vat-reopen-modal')) {
      Modal.getOrCreateInstance(document.querySelector('#vat-reopen-modal')).show()
    }
  }

  showAssetModal() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('asset') === 'true' && $('#asset-modal') !== null) {
      Modal.getOrCreateInstance(document.querySelector('#asset-modal')).show()
    }
  }

  initializeDates() {
    if (this.hasInvoiceDateTarget) {
      this.invoiceDateChangedAux(parseISO(this.invoiceDateTarget.value))
    } else {
      this.invoiceDateChangedAux(null)
    }
    if (this.hasDueDateTarget && this.hasPaymentOnTarget && isFuture(parseISO(this.dueDateTarget.value))) {
      this.paymentOnTarget.value = this.dueDateTarget.value
    }
    this.dueDateChanged(null)
  }

  initialFocusOnSupplierInvoice() {
    if (this.hasSupplierSelectTarget) {
      this.supplierSelectTarget.focus()
    }
  }

  invoiceDateChanged(event) {
    this.invoiceDateChangedAux(parseISO(event.target.value))
  }

  invoiceDateChangedAux(invoiceDate) {
    const previousDate = this.invoiceDate
    this.invoiceDate = invoiceDate
    this.updateDueDateWarning()
    if (
      this.hasInvoiceTypeSelectTarget &&
      this.invoiceTypeSelectTarget.value === 'receipt' &&
      this.invoiceDate !== null
    ) {
      this._updatePaymentOn(this.invoiceDate)
    }
    this._updateAutomaticTransactionText({ invoiceDate: this.invoiceDate, oldInvoiceDate: previousDate })
    this.updateVatCodes(previousDate)
  }

  dueDateChanged(event) {
    if (event != null) {
      this.dueDate = parseISO(event.target.value)
      if (!isValid(this.dueDate)) {
        this.dueDate = parse(event.target.value, i18n.t('date.formats.frontend.datefns.short'), new Date())
      }
    }
    this.updateDueDateWarning()
    if (this.dueDate !== null) {
      this._updatePaymentOn(this.dueDate)
    }
  }

  updateDueDateWarning() {
    if (this.hasDueDateWarningTarget) {
      if (this.invoiceDate && this.dueDate && isBefore(this.dueDate, this.invoiceDate)) {
        $(this.dueDateWarningTarget).removeClass('d-none')
      } else {
        $(this.dueDateWarningTarget).addClass('d-none')
      }
    }
  }

  setupAutomaticTranscationText() {
    this.currentSupplierName = null
    this.currentInvoiceNumber = null
    if (this.hasSupplierSelectTarget) {
      const controller = this.application.getControllerForElementAndIdentifier(
        this.supplierSelectTarget,
        'partner--select',
      ) as PartnerSelectController
      this.currentSupplierName = controller.currentPartner && controller.currentPartner.name
    }
    if (this.hasInvoiceNumberTarget) {
      this.currentInvoiceNumber = $(this.invoiceNumberTarget).val() as string
    }
    if (this.hasTransactionTextTarget && this.transactionTextTarget.value === '') {
      this._updateAutomaticTransactionText({
        supplierName: this.currentSupplierName,
        invoiceNumber: this.currentInvoiceNumber,
      })
    }
  }

  deletePostingLine(event) {
    this.removeVatLock()
    this.updateLabelsAndInputNames()
    this._updateDeleteLineItemButtonAvailability()
    this.updateTotalAmount()

    event.preventDefault()
    return false
  }

  updateVatCodes(previousDate) {
    const previousYear = previousDate === undefined || previousDate === null ? null : previousDate
    const date = this._dateForVatPurposes

    if (previousYear !== null && previousYear.getFullYear() !== date.getFullYear()) {
      this._confirmVatRateUpdate(previousYear.getFullYear(), date.getFullYear())
    }

    this._updateAllowedVatCodes()
  }

  handleAssetCode(event) {
    const selectedValue = event.target.parentElement.querySelector('.select2-selection__rendered').innerHTML
    const alert = event.target.parentElement.querySelector('.alert')
    let code = parseInt(selectedValue.substr(0, 2))
    alert.classList.add('d-none')
    if (!isNaN(code)) {
      if ((window as any).SupplierInvoice.AssetCodes.includes(code)) {
        alert.classList.remove('d-none')
      }
    }

    this.updateVatAmount(event)
  }

  submit(event) {
    if (document.querySelector('.modal.show')) {
      return
    }
    // In case the total amount is negative, the user gets a warning about not being able
    // to register the payment. We're not destroying the object before clicking submit
    // because we'll show the payment form again if the total amount changes to positive.
    const paymentForm = document.getElementById('supplier-invoice-payment-form')
    if (paymentForm !== null && this.calculateTotalAmount().lessThan(0)) {
      paymentForm.parentNode.removeChild(paymentForm)
    }
  }

  changeTotalAmount(event) {
    this.totalAmountTextTarget.innerText = i18n.t('supplier_invoice.total_amount', { currency: event.target.value })
  }

  changeAmountLabel(event) {
    this.amountLabelTargets.forEach((label) => {
      label.innerText = i18n.t('posting.amount_in', { currency: event.target.value })
    })
  }

  addNewPostingLine(event) {
    this._addNewPostingLine()
    this.removeVatLock()

    event.preventDefault()
    return false
  }

  updateDefaults(partnerData) {
    if (this.newPostingLineTargets.length > 1) {
      return
    }

    if (this.newPostingLineTargets.length === 1) {
      const {
        default_debit_account_id,
        default_debit_account_code_and_description,
        default_vat_code,
        available_vat_codes,
        bank_account_number,
      } = partnerData
      if (
        this.hasRecipientBankNumberTarget &&
        this.invoiceTypeSelectTarget.value === 'invoice' &&
        bank_account_number
      ) {
        this.recipientBankNumberTarget.value = bank_account_number
      }
      if (default_debit_account_id !== null && default_debit_account_code_and_description !== null) {
        this.newPostingLineTarget.querySelector('select').dispatchEvent(
          Object.assign(new Event('set'), {
            id: default_debit_account_id,
            text: default_debit_account_code_and_description,
          }),
        )

        if (this.hasVatCodeSelectTarget) {
          this._updateVatCodes({
            available_vat_codes: available_vat_codes,
            default_vat_code: default_vat_code,
            vatCodeSelector: this.vatCodeSelectTarget,
          })
        }
      }
    }

    if (partnerData.default_purchase_type) {
      $(this.purchaseTypeTarget).val(partnerData.default_purchase_type)
      this._updateAllowedVatCodes()
    }

    const $newPostingLineTarget = $(this.newPostingLineTarget)
    this._updateDefaultSelected(
      $newPostingLineTarget.find('select.account_select'),
      partnerData.default_debit_account_id,
    )
    this._updateDefaultSelected($newPostingLineTarget.find('select.vat-code'), partnerData.default_vat_code)
  }

  _updateVatCodes(event) {
    let vatCodeSelector = event.vatCodeSelector
    if (!vatCodeSelector) {
      vatCodeSelector = this.vatCodeSelectTargets.find((codeSelector) => {
        return event.target.closest('.supplier-invoice-line-item').querySelector(`#${codeSelector.id}`)
      })
    }

    const controller = this.application.getControllerForElementAndIdentifier(
      vatCodeSelector,
      'vat-rate',
    ) as VatRateController

    controller?.changeAllowedVatCodes(event.available_vat_codes, event.default_vat_code)

    this.updateVatAmount(event)
  }

  updateLabelsAndInputNames() {
    this.postingLineTargets.forEach((row, index) => {
      $(row)
        .find('input[name]')
        .attr('name', (_index, oldName) => oldName.replace(/\d+/, index))
      $(row)
        .find('select')
        .attr('name', (_index, oldName) => oldName.replace(/\d+/, index))
    })
  }

  updateCollapseElements(row) {
    const collapseId = Math.random().toString(36).substr(2)
    $(row)
      .find('.accrual__toggle-form-link')
      .attr({
        href: `.multi-${collapseId}`,
        'aria-controls': collapseId,
      })
    $(row)
      .find('.accrual__info')
      .attr({
        id: collapseId,
        class: `collapse accrual__info multi-${collapseId}`,
      })
  }

  _updateDefaultSelected(selectElement, defaultValue) {
    $(selectElement).find('option').removeAttr('selected')
    $(selectElement).find(`option[value='${defaultValue}']`).attr('selected', 'true')
  }

  _addNewPostingLine() {
    // If the page is read-only then the template posting line is absent. In this
    // case we want an early return because we don't need to add new blank
    // posting_lines.

    if (!this.targets.has('nestedFormController')) {
      return
    }

    const nestedFormController = this.application.getControllerForElementAndIdentifier(
      this.nestedFormControllerTarget,
      'nested-form',
    ) as NestedFormController
    nestedFormController.add(document.createEvent('Event'))

    const $row = $(this.newPostingLineTarget)
    this.updateLabelsAndInputNames()
    this.updateCollapseElements($row)
    this._updateDeleteLineItemButtonAvailability()
    this.updateTotalAmount()
  }

  _confirmVatRateUpdate(previousYear, currentYear): void {
    if (isNaN(previousYear) || isNaN(currentYear)) {
      return
    }
    confirm(i18n.t('supplier_invoice.confirm_vat_code_update', { previousYear, currentYear }))
  }

  get _dateForVatPurposes() {
    if (this.invoiceDate === undefined || this.invoiceDate === null) {
      this.invoiceDate = null
    }
    let value = new Date()
    if (this.invoiceDate === null) {
      if (this.hasPostingDateTarget) {
        value = parseISO(this.postingDateTarget.value)
      }
    } else {
      value = this.invoiceDate
    }
    value = setYear(value, Math.min(Math.max(value.getFullYear(), 2016), new Date().getFullYear()))
    return value
  }

  handleInvoiceNumberKeyUp(event) {
    this._updateAutomaticTransactionText({ invoiceNumber: $(event.target).val() })
  }

  _updateAutomaticTransactionText({
    supplierName = null,
    invoiceNumber = null,
    invoiceDate = null,
    oldInvoiceDate = null,
  }) {
    let paidBy = null
    let previousPaidBy = null
    if (supplierName === null) {
      supplierName = this.currentSupplierName
    }
    if (invoiceNumber === null) {
      invoiceNumber = this.currentInvoiceNumber
    }
    if (oldInvoiceDate === null) {
      oldInvoiceDate = null
    }
    if (this.hasInvoiceTypeSelectTarget) {
      if (this.invoiceTypeSelectTarget.value === 'invoice') {
        invoiceDate = null
      } else if (this.invoiceTypeSelectTarget.value === 'reimbursement') {
        if (this.paidBy === undefined) {
          this.paidBy = null
        }
        invoiceDate = null
        paidBy = this.paidByTarget[this.paidByTarget.selectedIndex].text
        previousPaidBy = this.paidBy
        this.paidBy = paidBy
      }
    }

    const reimbursementTransactionText = this._formatTransactionText(null, null, null, paidBy)
    const oldReimbursementTransactionText = this._formatTransactionText(null, null, null, previousPaidBy)
    const dateTransactionText = this._formatTransactionText(null, null, invoiceDate)
    const oldDateTransactionText = this._formatTransactionText(null, null, oldInvoiceDate)
    const newTransactionText = this._formatTransactionText(supplierName, invoiceNumber)
    const oldTransactionText = this._formatTransactionText(this.currentSupplierName, this.currentInvoiceNumber)

    $(this.transactionTextTargets).val((_index, currentTransactionText) => {
      if (
        reimbursementTransactionText !== '' &&
        (currentTransactionText === oldReimbursementTransactionText || currentTransactionText === '')
      ) {
        return reimbursementTransactionText
      } else if (
        dateTransactionText !== '' &&
        (currentTransactionText === oldDateTransactionText || currentTransactionText === '')
      ) {
        return dateTransactionText
      } else if (currentTransactionText === oldTransactionText || currentTransactionText === '') {
        return newTransactionText
      } else {
        return currentTransactionText
      }
    })

    this.currentSupplierName = supplierName
    this.currentInvoiceNumber = invoiceNumber
  }

  enableDescription(event) {
    event.preventDefault()
    event.target.parentElement.previousElementSibling.removeAttribute('readonly')
  }

  calculateTotalAmount() {
    let relevantAmountTargets = this.amountTargets

    return relevantAmountTargets.reduce((subTotal: Decimal, element: HTMLInputElement) => {
      let amount = parseDecimal(element.value, new Decimal(0))
      if (amount.isNaN()) {
        return subTotal
      } else {
        return subTotal.plus(amount)
      }
    }, new Decimal(0)) as Decimal
  }

  updateTotalAmount() {
    const totalAmount = this.calculateTotalAmount()

    const totalAmountText = amountStr(totalAmount)
    $(this.totalAmountTarget).text(totalAmountText)
    this.negativeAmountWarning(totalAmount)
  }

  updateTotalAmountOnPayableForm(totalAmount: string) {
    const element = document.querySelector('[data-payment-payable-total-amount-value]')
    if (element) {
      element.setAttribute('data-payment-payable-total-amount-value', totalAmount)
    }
  }

  negativeAmountWarning(totalAmount) {
    const paymentForm = document.getElementById('supplier-invoice-payment-form')
    if (paymentForm !== null && this.hasCreditNoteWarningTarget) {
      if (totalAmount < 0) {
        paymentForm.style.display = 'none'
        this.creditNoteWarningTarget.style.display = 'block'
      } else {
        this.creditNoteWarningTarget.style.display = 'none'
        paymentForm.style.display = 'block'
        this.updateTotalAmountOnPayableForm(totalAmount.toFixed(2).toString().replace('.', ','))
      }
    }
  }

  _formatTransactionText(supplierName, invoiceNumber, invoiceDate = null, paidBy = null) {
    if (paidBy) {
      return paidBy
    } else if (supplierName && invoiceNumber) {
      return i18n.t('supplier_invoice.default_description', {
        locale: $(this.element).data('locale'),
        supplier_name: supplierName,
        invoice_number: invoiceNumber,
      })
    } else if (invoiceDate) {
      const locale = $(this.element).data('locale')
      return i18n.t(`supplier_invoice.default_${this.invoiceTypeSelectTarget.value}_description_with_date`, {
        locale: locale,
        invoice_date: invoiceDate.toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }),
      })
    } else {
      return ''
    }
  }

  handlePartnerCreate(event) {
    const { partner } = event
    const controller = this.application.getControllerForElementAndIdentifier(
      this.supplierSelectTarget,
      'partner--select',
    ) as PartnerSelectController
    controller.select(partner)
  }

  handlePartnerChange(event) {
    const partner = event.partner
    this.updateDefaults(partner)
    let supplierInvoiceForeignCurrency = document.querySelector(
      '#supplier_invoice_foreign_currency',
    ) as HTMLInputElement
    supplierInvoiceForeignCurrency.value = event.partner.supplier_currency
    if (supplierInvoiceForeignCurrency.value !== '') {
      supplierInvoiceForeignCurrency.dispatchEvent(new Event('change'))
    }
    this._updateAutomaticTransactionText({ supplierName: partner.name })
  }

  handlePurchaseTypeChange() {
    this._updateAllowedVatCodes()
    const debitAccount = this.newPostingLineTarget.querySelector('select')
    debitAccount.dispatchEvent(new Event('reset'))
  }

  handleInvoiceTypeChange() {
    let path = window.location.pathname
    if (!path.includes('edit')) {
      path = path.concat('/edit')
    }
    window.location.replace(window.location.origin + path + '?type=' + this.invoiceTypeSelectTarget.value)
  }

  _showAndEnableSupplierSelect() {
    this.element.setAttribute('disabled', 'false')
    this._showSupplierSelect(true)
  }

  _hideAndDisableSupplierSelect() {
    this._showSupplierSelect(false)
    this.element.setAttribute('disabled', 'true')
  }

  _showSupplierSelect(status) {
    // Since it is a select2-box we need to hide/show the parent
    let parent = this.supplierSelectTarget.parentElement
    if (status) {
      parent.classList.remove('d-none')
    } else {
      parent.classList.add('d-none')
    }
  }

  async _updateAllowedVatCodes() {
    for (const target of this.vatCodeSelectTargets) {
      const controller = this.application.getControllerForElementAndIdentifier(target, 'vat-rate') as VatRateController
      if (controller !== null) {
        // We wait for each update so we don't do multiple xhr requests
        await controller.changeDate(this._dateForVatPurposes)
        await controller.changePurchaseType(this.purchaseTypeTarget.value)
      }
    }

    this.updateAllVatAmountFields()
  }

  _updateDeleteLineItemButtonAvailability() {
    if (this.hasDeleteLineItemTarget) {
      if (this.deleteLineItemTargets.length > 1) {
        $(this.deleteLineItemTargets).removeClass('d-none')
      } else {
        $(this.deleteLineItemTargets).addClass('d-none')
      }
    }
  }

  //  payments
  _updatePaymentOn(date) {
    // we should get field by id in the case when it is moved by the move controller
    let paymentOn = this.hasPaymentOnTarget
      ? this.paymentOnTarget
      : (document.getElementById('payment_on-payment-modal-base') as HTMLElement)

    if (paymentOn && this.invoiceTypeSelectTarget.value === 'invoice') {
      const flatpickrController = this.application.getControllerForElementAndIdentifier(
        paymentOn,
        'flatpickr',
        // We cast to unknown since FlatpickrController doesn't cover Controller
        // There might be a better way in Typescript, but we dont know how
        // In any case, it is a FlatpickrController that should be returned.
      ) as unknown as FlatpickrController
      if (flatpickrController !== null && isValid(date)) {
        flatpickrController.fp.setDate(date)

        // looking for nearest available date
        let availableDates = flatpickrController.fp.selectedDates
        while (availableDates.length === 0) {
          date.setDate(date.getDate() + 1)
          flatpickrController.fp.setDate(date)
          availableDates = flatpickrController.fp.selectedDates
        }
      }
    }
  }

  handlePaidByChange() {
    if (this.hasPaidByTarget) {
      this._updateAutomaticTransactionText({})
      if (this.hasPaymentHeaderLabelTarget) {
        let selectedValue = this.paidByTarget[this.paidByTarget.selectedIndex].value
        if (this.paidByTarget[this.paidByTarget.selectedIndex].dataset.employee) {
          selectedValue = this.paidByTarget[this.paidByTarget.selectedIndex].dataset.employee
        }
        this.paymentHeaderLabelTarget.innerHTML = i18n.t(`supplier_invoice.payment_header.${selectedValue}`)
      }
      if (this.hasRecipientBankNumberTarget) {
        const bankAccount = this.paidByTarget[this.paidByTarget.selectedIndex].dataset['accountNumber']
        if (bankAccount !== undefined) {
          this.recipientBankNumberTarget.value = bankAccount
        } else {
          this.recipientBankNumberTarget.value = ''
        }
      }
      if (this.hasPaymentBlockTarget) {
        if (this.paidByTarget.value === 'liabilities_to_owners_enk_no_payback') {
          this.paymentBlockTarget.classList.add('d-none')
        } else {
          this.paymentBlockTarget.classList.remove('d-none')
        }
      }
    }
  }

  handlePostingDateChange() {
    let $text = $(this.postingDateTextTarget)
    const link = $text.children().last().get(0)
    let date = this.postingDateTarget.value
    date = new Date(date).toLocaleDateString(i18n.locale, { day: '2-digit', month: '2-digit', year: 'numeric' })
    $text.html(i18n.t('supplier_invoice.posting_date', { posting_date: date }) + ' ' + link.outerHTML)
    this._updateAllowedVatCodes()
  }

  // This method enables amount and vat code fields for line items and also
  // changes account selector behavior to show all accounts for supplier invoice
  // without filtering by vat code
  removeVatLock() {
    document.querySelectorAll('.vat-code').forEach((elem: HTMLInputElement) => {
      if (elem.disabled && elem.dataset.fixed === 'true') {
        elem.disabled = false
        elem.dataset.fixed = 'false'
        if (elem.getAttribute('type') === 'hidden' && !elem.disabled) {
          elem.parentNode.removeChild(elem)
        }
      }
    })
    document.querySelectorAll('.line-item-amount').forEach((elem: HTMLInputElement) => {
      elem.disabled = false
      if (elem.getAttribute('type') === 'hidden') {
        elem.parentNode.removeChild(elem)
      }
    })
  }
}
