import { Controller } from '@hotwired/stimulus'
import {
  Chart,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  LineController,
  LinearScale,
  CategoryScale,
  Legend,
  Title,
  Tooltip,
  ChartType,
} from 'chart.js'
// Register is needed for Tree shaking
Chart.register(
  LineElement,
  BarElement,
  BarController,
  PointElement,
  LineController,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  CategoryScale,
)

export default class extends Controller {
  static targets = ['canvas']
  static values = {
    type: String,
    data: Object,
    options: Object,
  }

  declare canvasTarget: HTMLCanvasElement
  declare hasCanvasTarget: boolean
  declare typeValue: ChartType
  declare dataValue: any
  declare optionsValue: any
  declare hasDataValue: boolean

  chart: Chart

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : (this.element as HTMLCanvasElement)

    this.chart = new Chart(element, {
      type: this.typeValue || 'line',
      data: this.dataValue,
      options: {
        ...this.defaultOptions,
        ...this.optionsValue,
      },
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  get defaultOptions() {
    return {
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
    }
  }
}
