<template>
  <transition-group name="list" tag="div" data-test-id="unmatched-bank-transactions" v-on:before-leave="beforeLeave">
    <BankTransactionItemCard
      v-for="(bankStatementItem, index) in bankStatementItems"
      :key="bankStatementItem.id"
      :id="'bank-transaction-' + bankStatementItem.id"
      :bankStatementItem="bankStatementItem"
      :suggestion="
        bankTransactionSuggestedMatch.candidates.find((suggestion) => suggestion.id === bankStatementItem.id)
      "
      :suggestionList="postingSuggestedMatch.candidates"
      @toggleSelect="toggleBankStatement"
      :show-initial-popover="index === 0 && matchedItems.length === 0"
    />
  </transition-group>
</template>

<script lang="ts">
import { format } from 'date-fns'
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'
import BankTransactionItemCard from './BankTransactionItemCard.vue'

export default defineComponent({
  computed: {
    ...mapState(useBankReconciliationStore, [
      'bankTransactionSuggestedMatch',
      'postingSuggestedMatch',
      'bankStatementItems',
      'bankReconciliation',
      'matchedItems',
      'selectedPostings',
    ]),
  },
  components: {
    BankTransactionItemCard,
  },
  methods: {
    ...mapActions(useBankReconciliationStore, {
      toggleBankStatement: 'toggleBankStatement',
    }),
    formatDate(date: Date) {
      return format(date, i18n.t('date.formats.frontend.datefns.short'))
    },
    beforeLeave(el) {
      el.style.width = el.offsetWidth + 'px'
    },
  },
})
</script>
<style scoped>
.list-leave-active {
  transition: all 0.5s ease-in;
  z-index: 1;
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  border-color: grey;
  transform: translateY(25px);
}
</style>
