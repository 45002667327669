import $ from 'jquery'
import Rails from '@rails/ujs'
import JsFormError from '../../standalone/components/JsFormError'
import { i18n } from '../../libraries/i18n'

export default class SubmitButtonHandler {
  // This class serves for keeping submit button with attribute `data-disable-with`
  // disabled during xhr requests
  // Link to issue description and solution:
  // https://stackoverflow.com/questions/46831525/how-to-keep-submit-buttons-disabled-on-remote-forms-until-the-next-page-has-load
  constructor() {
    const $doc = $(document)
    $doc.on('ajax:send', 'form[data-remote=true]', function (event) {
      const $form = $(event.target)
      const $button = $form.find('[type=submit]')
      if (!$button.length || $button.hasClass('btn-search')) return

      $button.each(function (index, element: HTMLButtonElement) {
        element.disabled = true
        $(element).attr('data-disable-with', i18n.t('generic.please_wait'))
      })
      $form.on('ajax:complete', function (event) {
        // If request was unsuccessful or contains errors (respond with errors) then we enable submit button
        if ((event.originalEvent as any).detail[0].status === 422 || event.detail[0]['response'].includes('errors')) {
          $button.each(function (index, element: HTMLButtonElement) {
            Rails.enableElement(element)
          })
        } else {
          // Use setTimeout to prevent race-condition when Rails re-enables the button
          setTimeout(function () {
            $button.each(function (index, element: HTMLButtonElement) {
              element.disabled = true
            })
          }, 0)
        }
      })

      // Prevent button from being cached in disabled state
      $doc.on('turbo:before-cache', function () {
        $button.each(function (index, element: HTMLButtonElement) {
          element.disabled = false
        })
      })
    })
  }
}
