import { rails_fetch } from '../../../../../util/rails_fetch'

import { Owner } from '../module'

export default {
  async create(owner: Owner) {
    return await rails_fetch(this.path(owner), {
      method: 'POST',
      body: JSON.stringify({ found_company_owner: owner }),
    })
  },
  async update(owner: Owner) {
    return await rails_fetch(`${this.path(owner)}/${owner.id}`, {
      method: 'PATCH',
      body: JSON.stringify({ found_company_owner: owner }),
    })
  },
  async destroy(owner: Owner) {
    return await rails_fetch(`${this.path(owner)}/${owner.id}`, {
      method: 'DELETE',
    })
  },
  path(owner) {
    return `/found_company/submissions/${owner.found_company_submission_id}/owners`
  },
}
