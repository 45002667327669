<template>
  <div class="card card-luca mb-4">
    <div class="card-body border-bottom">
      <div class="row align-items-center">
        <h5 class="col-12 col-sm-6 m-0 fw-bold">{{ nameLabel() }}</h5>
        <div class="col-12 col-sm-6 pt-2 pt-md-0">
          <div class="d-flex justify-content-sm-end justify-content-between align-items-baseline">
            <button
              class="btn btn-outline-secondary text-nowrap me-2 mt-2"
              data-bs-toggle="modal"
              :data-bs-target="`#edit-owner-modal-${owner.id}`"
              :id="`add-person-modal-button-${owner.id}`"
            >
              <font-awesome-icon :icon="['far', 'pencil']" />
              {{ i18n().t('found_company.share_capital.modal.edit') }}
            </button>
            <div class="btn btn-outline-secondary text-nowrap" v-on:click="destroyOwner(owner)">
              <font-awesome-icon :icon="['far', 'trash']" />
              {{ i18n().t('found_company.share_capital.modal.remove') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-12 col-sm-4">
          <PercentInput
            v-model="owner.percent"
            @change="updatePercentShare"
            :label="i18n().t('found_company.share_capital.share')"
          />
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-sm-end">
          <div class="fw-bold me-1">
            {{ i18n().l('number', owner.share_capital_cents / 100, { delimiter: ' ' }) }}
          </div>
          <div>{{ i18n().t('found_company.share_capital.investment') }}</div>
        </div>
      </div>
    </div>
    <PersonModal
      v-if="owner.company_name === null"
      :id="`edit-owner-modal-${owner.id}`"
      :owner="owner"
      :title="i18n().t(`found_company.share_capital.modal.person.edit_title`, { name: owner.signatories[0].full_name })"
    />
    <CompanyModal
      v-else
      :id="`edit-owner-modal-${owner.id}`"
      :owner="owner"
      :title="i18n().t(`found_company.share_capital.modal.company.edit_title`, { name: owner.company_name })"
    />
  </div>
</template>
<script lang="ts">
import AmountInput from './../../../../components/AmountInput.vue'
import PercentInput from './PercentInput.vue'
import PersonModal from './PersonModal.vue'
import CompanyModal from './CompanyModal.vue'
import Decimal from 'decimal.js'
import { mapActions, mapState } from 'pinia'
import { useFoundCompanySubmissionStore } from '../store'
import { Owner } from '../module'
import { i18n } from '../../../../../libraries/i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { PercentInput, AmountInput, PersonModal, CompanyModal },
  props: {
    owner: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useFoundCompanySubmissionStore, ['total_share_capital_cents']),
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useFoundCompanySubmissionStore, {
      updateOwner: 'updateOwner',
      destroyOwner: 'destroyOwner',
    }),

    updatePercentShare(_) {
      this.owner.share_capital_cents =
        Math.round((this.total_share_capital_cents / 100) * (this.owner.percent / 100)) * 100
      this.owner.percent = new Decimal(
        ((this.owner.share_capital_cents / this.total_share_capital_cents) * 100).toFixed(3),
      )
      this.updateOwner(this.owner as Owner)
    },
    nameLabel() {
      if (this.owner.company_name !== null) {
        return this.owner.company_name
      } else {
        return this.owner.signatories[0].full_name
      }
    },
  },
})
</script>
