<template>
  <ItemCard
    :class="cardClasses"
    :header="posting.postingDate"
    :description="posting.description"
    :amountCents="posting.amountCents"
    ref="card"
    v-on:click="toggleSelectClick"
  >
    <SuggestionList v-if="selected" :suggestionList="suggestionList" />

    <div v-if="destroyable" class="d-flex justify-content-end mt-3">
      <TableMoreDropdown dropdown-type="secondary" class="mr-2" v-on:click.stop>
        <DropdownItem
          href=""
          v-on:click="destroyPostingClick"
          v-if="destroyable"
          class="d-flex justify-content-between"
        >
          <font-awesome-icon :icon="['far', 'trash']" class="me-2 ms-0" />
          {{ i18n().t('navigation.delete') }}
        </DropdownItem>
      </TableMoreDropdown>
    </div>
  </ItemCard>
</template>
<script lang="ts">
import { Turbo } from '@hotwired/turbo-rails'
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent, type PropType } from 'vue'
import ItemCard from './ItemCard.vue'
import createOrUpdateCandidatePopover from './CandidatePopover'
import TableMoreDropdown from '../../../components/bootstrap/TableMoreDropdown.vue'
import DropdownItem from '../../../components/bootstrap/DropdownItem.vue'
import { Candidate, Posting, SuggestedMatch } from '../module'
import SuggestionList from './item_card/SuggestionList.vue'

export default defineComponent({
  components: { SuggestionList, DropdownItem, TableMoreDropdown, ItemCard },
  props: {
    posting: {
      type: Object as PropType<Posting>,
      required: true,
    },
    destroyable: {
      type: Boolean,
      default: false,
    },
    suggestion: {
      type: Object as PropType<Candidate>,
      default: null,
    },
    suggestionList: {
      type: Array<Candidate>,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      selected: false as boolean,
    }
  },
  mounted() {
    let urlSearchParams = new URLSearchParams(window.location.search)
    let params = Object.fromEntries(urlSearchParams.entries())
    let cleanUrl = `${location.pathname}?period=${params['period']}`

    if (this.posting.id === params['posting_id']) {
      this.toggleSelectClick()

      if (
        Object.keys(this.selectedBankTransactions).length > 0 &&
        Object.keys(this.selectedPostings).length > 0 &&
        this.selectedPostingSum !== this.selectedBankTransactionSum
      ) {
        Turbo.navigator.history.push(cleanUrl)
        return
      } else if (this.selectedPostingSum !== this.selectedBankTransactionSum) {
        return
      } else if (
        Object.keys(this.selectedBankTransactions).length == 0 ||
        Object.keys(this.selectedPostings).length == 0
      ) {
        return
      }

      Turbo.navigator.history.push(cleanUrl)

      this.match()
    }
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, {
      match: 'match',
      destroyPosting: 'destroyPosting',
      addFeeOrInterestAndMatchItem: 'addFeeOrInterestAndMatchItem',
      fetchUnpaidCustomerInvoices: 'fetchUnpaidCustomerInvoices',
    }),

    destroyPostingClick(): void {
      this.destroyPosting(this.posting as Posting)
    },

    toggleSelectClick(): void {
      this.selected = !this.selected
      this.$emit('toggleSelect', this.selected, this.posting, this.$el)
    },
  },

  updated() {
    createOrUpdateCandidatePopover((this.$refs.card as any).$el, this.selected, this.suggestionLevel, 'left')
  },

  computed: {
    ...mapState(useBankReconciliationStore, [
      'bankReconciliation',
      'selectedPostingSum',
      'selectedBankTransactionSum',
      'selectedBankTransactions',
      'selectedPostings',
      'matchedItems',
    ]),

    suggestionLevel(): string {
      if (this.suggestion == null) {
        return 'none'
      } else {
        return this.suggestion.level
      }
    },

    cardClasses: function (): object {
      return {
        selectable: true,
        selected: this.selected,
        'selectable-success': this.suggestionLevel === 'very_high' || this.suggestionLevel === 'exact',
        'selectable-warning': this.suggestionLevel === 'medium' || this.suggestionLevel === 'high',
      }
    },
  },
})
</script>

<style scoped lang="scss">
@import '../../../build';
</style>
