export default class JsFormError {
  modelName: string
  errors
  visibleModal: Element

  constructor(modelName: string, errors, modalSelector?: string) {
    this.modelName = modelName
    this.errors = errors.errors
    const currentModalSelector = modalSelector === undefined ? '.modal.show' : modalSelector
    this.visibleModal = document.querySelector(currentModalSelector)
    this.updateForm()
  }

  updateForm() {
    if (this.errors) {
      JsFormError.removePreviousErrors()

      if (document.getElementsByTagName('form').length) {
        let nonFoundElements = this.addFormErrors()
        let notFoundElements = this.addLineItemErrors(nonFoundElements)
        if (notFoundElements.length > 0) {
          this.addAlertBox(notFoundElements.join('<br>'))
        }
      } else {
        // could not find the form
        this.addNonFormAlertBox()
      }
    }
  }

  private addLineItemErrors(nonFoundElements: Map<string, any>) {
    let notFoundElements: Array<string> = []
    for (const [key, value] of Object.entries(nonFoundElements) as [string, string][]) {
      // Check if it is line items.
      if (key.substr(key.length - 11) === '_attributes') {
        let name = ''
        // This allows us to pass complex keys
        if (key.indexOf(']') > 0) {
          name = `${this.modelName}${key}`
        } else {
          name = `${this.modelName}[${key}]`
        }

        for (const [k, v] of Object.entries(value) as [string, string][]) {
          let item_name = `${name}[${k}]`
          this.addFormErrors(item_name, v)
        }
      } else {
        notFoundElements.push(value)
      }
    }
    return notFoundElements
  }

  private addFormErrors(baseName = this.modelName, errors = this.errors) {
    let nonFoundElements: Map<string, any> = new Map()
    for (const [key, value] of Object.entries(errors) as [string, string][]) {
      let foundElem = false
      let name = ''
      // This allows us to pass complex keys
      if (key.indexOf(']') > 0) {
        name = `${baseName}${key}`
      } else {
        name = `${baseName}[${key}]`
      }
      // First, lets see if it'a an INPUT group
      let elemList = document.querySelectorAll(`div.input-group[name='${key}']`)
      if (elemList.length > 0) {
        foundElem = true
        elemList.forEach((elem) => {
          if ((elem as any).type !== 'hidden') {
            elem.classList.add('is-invalid')
            elem.insertAdjacentHTML('afterend', `<div class="invalid-feedback">${value}</div>`)
          }
        })
        continue
      }
      // If not, then lets see if it's an INPUT tag.
      elemList = document.querySelectorAll(`input[name='${name}'], input[name='${baseName}[${key}]']`)
      if (elemList.length > 0) {
        foundElem = true
        elemList.forEach((elem) => {
          if (elem.classList.contains('flatpickr-input')) {
            // flatpickr-input
            let inputs = elem.parentElement.getElementsByTagName('input')
            if (inputs != null) {
              let input = inputs[inputs.length - 1]
              input.classList.add('is-invalid')
              input.insertAdjacentHTML('afterend', `<div class="invalid-feedback">${value}</div>`)
            }
          } else {
            elem.classList.add('is-invalid')
            let parentElem = elem.parentElement
            if (parentElem !== null && parentElem.classList.contains('input-group')) {
              parentElem.classList.add('is-invalid')
              parentElem.insertAdjacentHTML('afterend', `<div class="invalid-feedback">${value}</div>`)
            } else {
              elem.insertAdjacentHTML('afterend', `<div class="invalid-feedback">${value}</div>`)
            }
          }
        })
        continue
      }

      // Handle TEXTAREA tags
      const textareaSelector = `textarea[name='${name}'], textarea[name='${baseName}[${key}]']`
      const textareaList = document.querySelectorAll(textareaSelector)
      if (textareaList.length > 0) {
        foundElem = true
        textareaList.forEach((elem) => {
          elem.classList.add('is-invalid')
          elem.insertAdjacentHTML('afterend', `<div class="invalid-feedback">${value}</div>`)
        })
      }

      // If not, lets try SELECT. We assume that it was rendered with
      // #association provided by simple_form and it uses Select2. #association
      // adds _id to the association name so we need update the selector.
      if (key.indexOf(']') > 0) {
        name = `${baseName}${key}`
      } else if (key.substr(key.length - 3) === '_id') {
        name = `${baseName}[${key}]`
      } else {
        name = `${baseName}[${key}_id]`
      }
      elemList = document.querySelectorAll(
        `select[name='${key}'], select[name='${baseName}[${key}]'], select[name='${name}']`,
      )
      if (elemList.length > 0) {
        foundElem = true
        // Select2 hides the original SELECT tag but we need to add is-invalid
        // to it because otherwise the error DIV won't be visible.
        elemList.forEach((elem) => {
          elem.classList.add('is-invalid')
          let select2Container = elem.parentNode.querySelector('.select2-container')
          if (select2Container !== null) {
            select2Container.classList.add('is-invalid')
            select2Container.insertAdjacentHTML('afterend', `<div class="invalid-feedback">${value}</div>`)
          } else {
            elem.insertAdjacentHTML('afterend', `<div class="invalid-feedback">${value}</div>`)
          }
        })
      }

      if (!foundElem && value !== null && value.length !== 0) {
        nonFoundElements[key] = value
      }
    }
    return nonFoundElements
  }

  private addNonFormAlertBox() {
    let errorList = `<ul>`
    for (const [key, value] of Object.entries(this.errors) as [string, string][]) {
      if (!value.length) continue
      errorList += `<li>${value}</li>`
    }
    errorList += '</ul>'
    this.addAlertBox(errorList)
  }

  private addAlertBox(content: string) {
    // If we have a box to place the content in, lets use that
    const existingAlertBox = this.visibleModal
      ? this.visibleModal.querySelector('.form-alert-box')
      : document.querySelector('.form-alert-box')
    if (existingAlertBox) {
      existingAlertBox.classList.remove('d-none')
      existingAlertBox.innerHTML = content
      return
    }

    // Else the default is showing the alert at the top of the page
    let beforeElem = document.querySelector('.page-title')
    let alertBox = `<div class="alert alert-danger no-form-alert">${content}</div>`
    if (beforeElem) {
      let placement: InsertPosition = 'afterend'
      // However, if we have a visible modal, we will show it in the modal instead
      if (this.visibleModal !== null) {
        beforeElem = this.visibleModal.querySelector('.modal-body')
        placement = 'afterbegin'
      }
      beforeElem.insertAdjacentHTML(placement, `${alertBox}`)
      return
    }

    // If we can't find a place to put the alert, we will just show it at the top of the form
    let formInputElem = document.querySelector(`input[name^='${this.modelName}']`)
    if (formInputElem) {
      ;(formInputElem as HTMLInputElement).form.insertAdjacentHTML('afterbegin', `${alertBox}`)
    }
  }

  static removePreviousErrors() {
    document.querySelectorAll('.invalid-feedback').forEach((elem) => {
      elem.parentNode.removeChild(elem)
    })
    document.querySelectorAll('.is-invalid').forEach((elem) => {
      elem.classList.remove('is-invalid')
    })
    document.querySelectorAll('.no-form-alert').forEach((elem) => {
      elem.parentNode.removeChild(elem)
    })
  }
}
