import { Controller } from '@hotwired/stimulus'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  static targets = ['item']
  static values = { maxClampHeight: { type: Number, default: 160 } }

  showMoreLess: HTMLElement
  declare itemTarget: HTMLElement
  declare maxClampHeightValue: number

  connect() {
    this.showMoreLess = document.createElement('small')
    if ((this.element as HTMLElement).offsetHeight > this.maxClampHeightValue) {
      this.clamp()
    } else {
      return
    }
    this.showMoreLess.className = 'btn-link float-end'
    this.showMoreLess.innerHTML = i18n.t('generic.show_more')
    this.showMoreLess.addEventListener('click', this.clamp.bind(this))
    this.element.appendChild(this.showMoreLess)
  }

  clamp() {
    this.itemTarget.classList.toggle('clamped-item')
    if (this.itemTarget.classList.contains('clamped-item')) {
      this.showMoreLess.innerHTML = i18n.t('generic.show_more')
    } else {
      this.showMoreLess.innerHTML = i18n.t('generic.show_less')
    }
  }
}
