import { Controller } from '@hotwired/stimulus'
import KidValidator from '../classes/kid_validator'
import flatpickr from 'flatpickr'
import { nextFrame } from '../rendering'
import { parseDecimal } from '../classes/parse_decimal'
import Decimal from 'decimal.js'
import { i18n } from '../libraries/i18n'
import PaymentDatePickerController from 'controllers/input/payment_date_picker_controller'

// In this controller we're checking quite a few places if we've some targets. The reason is that we've two versions of
// the payment form. One is with all fields, and one is limited to just amount, date and type.
export default class extends Controller {
  static targets = [
    'paymentType',
    'consentExpiredWarning',
    'consentExpiredText',
    'consentExpiredLink',
    'recipientBankNumber',
    'overWriteBankNumberCheckBox',
    'negativeAmountWarning',
    //  Will be added back in https://lucalabs.atlassian.net/browse/IN-2840
    // 'approvePaymentLater',
    'message',
    'messageWarning',
    'account',
    'paymentOn',
    'setAsDefaultPaymentCheckbox',
    'internationalBankFields',
    'domesticBankFields',
    'recipientBic',
    'recipientIban',
    'domesticAmountInput',
    'foreignAmountInput',
  ]

  static values = {
    sourceCurrency: String,
    defaultCurrency: String,
    disabled: Boolean,
    totalAmount: String,
    editableForm: Boolean,
    allowExecuteInForeignCurrency: Boolean,
  }

  declare disabledValue: boolean
  declare hasDisabledValue: boolean
  declare sourceCurrencyValue: string
  declare hasSourceCurrencyValue: boolean
  declare defaultCurrencyValue: string
  declare hasDefaultCurrencyValue: boolean
  declare totalAmountValue: string
  declare editableFormValue: boolean
  declare hasAllowExecuteInForeignCurrencyValue: boolean
  declare allowExecuteInForeignCurrencyValue: boolean

  declare hasSetAsDefaultPaymentCheckboxTarget: boolean
  declare hasOverWriteBankNumberCheckBoxTarget: boolean
  declare hasMessageWarningTarget: boolean
  declare hasPaymentTypeTarget: boolean
  declare hasRecipientBankNumberTarget: boolean
  declare hasAccountTarget: boolean
  declare hasMessageTarget: boolean
  declare internationalBankFieldsTarget: HTMLElement
  declare hasInternationalBankFieldsTarget: boolean
  declare recipientBicTarget: HTMLInputElement
  declare recipientIbanTarget: HTMLInputElement
  declare domesticBankFieldsTarget: HTMLInputElement
  declare recipientBankNumberTarget: HTMLInputElement
  declare overWriteBankNumberCheckBoxTarget: HTMLInputElement
  declare paymentOnTarget: HTMLInputElement
  declare setAsDefaultPaymentCheckboxTarget: HTMLInputElement
  declare paymentTypeTarget: HTMLSelectElement
  declare consentExpiredLinkTarget: HTMLAnchorElement
  declare consentExpiredTextTarget: HTMLElement
  declare consentExpiredWarningTarget: HTMLInputElement
  declare messageWarningTarget: HTMLElement
  declare accountTarget: HTMLSelectElement
  declare accountTargets: Array<HTMLSelectElement>
  declare messageTarget: HTMLInputElement
  declare kidValidator: KidValidator
  declare domesticAmountInputTarget: HTMLInputElement
  declare foreignAmountInputTarget: HTMLInputElement
  declare negativeAmountWarningTarget: HTMLElement
  declare hasNegativeAmountWarningTarget: boolean

  originalValue: string

  connect() {
    this.originalValue = ''
    this.handlePaymentTypeChange()
    if (
      this.hasOverWriteBankNumberCheckBoxTarget &&
      this.hasRecipientBankNumberTarget &&
      this.recipientBankNumberTarget.value === ''
    ) {
      this.overWriteBankNumberCheckBoxTarget.closest('fieldset').style.display = 'none'
    }
    this.validateKidNumber()
    if (this.hasMessageTarget && this.messageTarget.dataset.showMessageWarning && this.hasMessageWarningTarget) {
      this.messageWarningTarget.style.display = 'none'
    }
    this.hideSetAsDefaultPaymentCheckbox(true)

    // Wait for flatpickr controller to initialize
    nextFrame(() => this.handlePaymentTypeChange())

    this.updateRecipientPaymentFields()
  }

  showNegativeAmountAlert() {
    if (!this.hasNegativeAmountWarningTarget) {
      return
    }

    const foreignAmount = parseDecimal(this.foreignAmountInputTarget.value, new Decimal(0))
    const domesticAmount = parseDecimal(this.domesticAmountInputTarget.value, new Decimal(0))

    if (foreignAmount.isNegative() || domesticAmount.isNegative()) {
      this.negativeAmountWarningTarget.classList.remove('d-none')
    } else {
      this.negativeAmountWarningTarget.classList.add('d-none')
    }
  }

  sourceCurrencyValueChanged(value, previousValue) {
    if (!this.hasSourceCurrencyValue) {
      return
    }

    this.disableInvalidAccountOptions()
    this.showAmountInputFieldsByCurrency()

    // Disable execute payment if feature is not enabled
    if (this.hasAllowExecuteInForeignCurrencyValue && this.sourceCurrencyValue !== this.defaultCurrencyValue) {
      Array.from(this.paymentTypeTarget.options).forEach((option: HTMLOptionElement) => {
        // Need at least one account that can execute payments
        let accountCanExecutePayment =
          Array.from(this.accountTarget.options).find((option: HTMLOptionElement) => this.canExecute(option)) !==
          undefined
        if (['execute', 'initiated'].includes(option.value)) {
          if (accountCanExecutePayment && this.allowExecuteInForeignCurrencyValue) option.style.display = 'block'
          else option.style.display = 'none'
        } else {
          option.style.display = 'block'
        }
      })
      const availablePaymentOptions = Array.from(this.paymentTypeTarget.options).filter((option) => {
        return option.style.display != 'none'
      })
      if (this.paymentTypeTarget.selectedOptions[0].style.display === 'none') {
        this.paymentTypeTarget.value = availablePaymentOptions[0].value
        this.handlePaymentTypeChange()
      }
    } else {
      Array.from(this.paymentTypeTarget.options).forEach((option) => (option.style.display = 'block'))
    }

    // NOK -> USD
    if (value != this.defaultCurrencyValue && previousValue == this.defaultCurrencyValue) {
      this.syncAmountValues(this.domesticAmountInputTarget, this.foreignAmountInputTarget)
    }
    // USD -> NOK
    else if (value == this.defaultCurrencyValue && previousValue != this.defaultCurrencyValue) {
      this.syncAmountValues(this.foreignAmountInputTarget, this.domesticAmountInputTarget)
    }

    this.updateRecipientPaymentFields()
  }

  totalAmountValueChanged() {
    if (this.totalAmountValue == '' || parseDecimal(this.totalAmountValue, new Decimal(0)).isNegative()) {
      return
    }

    if (this.sourceCurrencyValue == this.defaultCurrencyValue) {
      this.domesticAmountInputTarget.value = this.totalAmountValue
    } else {
      this.foreignAmountInputTarget.value = this.totalAmountValue
    }
  }

  setLabel(inputElement: HTMLInputElement, labelText: string) {
    const labelElement = inputElement.parentElement.querySelector('label')
    if (labelElement) {
      labelElement.innerText = labelText
    }
  }

  setHint(inputElement: HTMLInputElement, hintContent: HTMLElement) {
    const hintElement = inputElement.parentElement.querySelector('.form-text')
    if (hintElement) {
      hintElement.innerHTML = ''
      hintElement.appendChild(hintContent)
    }
  }

  hideAndDisableElement(element: HTMLInputElement) {
    element.parentElement.classList.add('d-none')
    element.setAttribute('disabled', '')
  }

  showAndEnableElement(element: HTMLInputElement, options: { readonly?: boolean } = { readonly: false }) {
    element.parentElement.classList.remove('d-none')
    element.removeAttribute('disabled')
    if (options.readonly) {
      element.setAttribute('readonly', '')
    } else {
      element.removeAttribute('readonly')
    }
  }

  createPaidPartialLink() {
    const paid_partial_link = document.createElement('a')
    paid_partial_link.setAttribute('id', 'paid-partial')
    paid_partial_link.setAttribute('data-action', 'payment-payable#handlePayPartialAmount')
    paid_partial_link.setAttribute('href', '#')
    paid_partial_link.classList.add('d-flex', 'justify-content-end')
    paid_partial_link.innerText = i18n.t('activerecord.attributes.payment.edit_amount')
    return paid_partial_link
  }

  createEnterDeductedAmountHint() {
    const span = document.createElement('span')
    span.innerText = i18n.t('payment.hint.deducted')
    return span
  }

  showAmountInputFieldsByCurrency() {
    const selectedAccount = this.accountTarget.options[this.accountTarget.selectedIndex]
    if (!selectedAccount) return

    const bankAccountCurrency = selectedAccount.getAttribute('currency')
    const isSourceInDefaultCurrency = this.sourceCurrencyValue === this.defaultCurrencyValue
    const isBankAccountInDefaultCurrency = bankAccountCurrency === this.defaultCurrencyValue

    if (isSourceInDefaultCurrency && isBankAccountInDefaultCurrency) {
      this.handleDomesticCurrency()
    } else if ((!isSourceInDefaultCurrency && !isBankAccountInDefaultCurrency) || this.executingPayment()) {
      this.handleForeignCurrency()
    } else if (!isSourceInDefaultCurrency && isBankAccountInDefaultCurrency) {
      this.handleMixedCurrency(isSourceInDefaultCurrency, bankAccountCurrency)
    } else if (isSourceInDefaultCurrency && !isBankAccountInDefaultCurrency) {
      this.handleMixedCurrency(isSourceInDefaultCurrency, bankAccountCurrency)
    }
  }

  handleDomesticCurrency() {
    this.setLabel(this.domesticAmountInputTarget, i18n.t('posting.amount_in', { currency: this.sourceCurrencyValue }))
    this.showAndEnableElement(this.domesticAmountInputTarget, { readonly: !this.editableFormValue })
    this.hideAndDisableElement(this.foreignAmountInputTarget)
    if (!this.editableFormValue) {
      this.setHint(this.domesticAmountInputTarget, this.createPaidPartialLink())
    }
  }

  handleForeignCurrency() {
    this.setLabel(this.foreignAmountInputTarget, i18n.t('posting.amount_in', { currency: this.sourceCurrencyValue }))
    this.showAndEnableElement(this.foreignAmountInputTarget, { readonly: !this.editableFormValue })
    this.hideAndDisableElement(this.domesticAmountInputTarget)
    if (!this.editableFormValue) {
      this.setHint(this.foreignAmountInputTarget, this.createPaidPartialLink())
    }
  }

  handleMixedCurrency(isSourceInDefaultCurrency, bankAccountCurrency) {
    this.swapAmountInputs(this.foreignAmountInputTarget, this.domesticAmountInputTarget, isSourceInDefaultCurrency)

    const mainAmountInput = isSourceInDefaultCurrency ? this.domesticAmountInputTarget : this.foreignAmountInputTarget
    const secondaryAmountInput = isSourceInDefaultCurrency
      ? this.foreignAmountInputTarget
      : this.domesticAmountInputTarget
    const secondaryInputCurrency = isSourceInDefaultCurrency ? bankAccountCurrency : this.defaultCurrencyValue

    this.setLabel(mainAmountInput, i18n.t('posting.amount_in', { currency: this.sourceCurrencyValue }))
    this.setLabel(secondaryAmountInput, i18n.t('posting.amount_in', { currency: secondaryInputCurrency }))
    this.showAndEnableElement(mainAmountInput, { readonly: !this.editableFormValue })
    this.showAndEnableElement(secondaryAmountInput)

    if (!this.editableFormValue) {
      this.setHint(mainAmountInput, this.createPaidPartialLink())
      this.setHint(secondaryAmountInput, this.createEnterDeductedAmountHint())
    }
  }

  swapAmountInputs(
    domesticAmountInput: HTMLInputElement,
    foreignAmountInput: HTMLInputElement,
    isSourceInDefaultCurrency: boolean,
  ) {
    const domesticAmountElement = domesticAmountInput.parentElement
    const foreignAmountElement = foreignAmountInput.parentElement
    const destinationElement = domesticAmountElement.parentElement

    if (isSourceInDefaultCurrency) {
      destinationElement.innerHTML = ''
      destinationElement.appendChild(foreignAmountElement)
      destinationElement.appendChild(domesticAmountElement)
    } else {
      destinationElement.innerHTML = ''
      destinationElement.appendChild(domesticAmountElement)
      destinationElement.appendChild(foreignAmountElement)
    }
  }

  syncAmountValues(sourceInput: HTMLInputElement, targetInput: HTMLInputElement) {
    if (!parseDecimal(sourceInput.value, new Decimal(0)).isZero() && !this.editableFormValue) {
      targetInput.value = sourceInput.value
      sourceInput.value = '0,00'
    }
  }

  validateKidNumber() {
    if (this.hasSourceCurrencyValue && this.sourceCurrencyValue != this.defaultCurrencyValue) {
      return
    }

    if (this.hasMessageWarningTarget && this.hasMessageTarget) {
      if (this.messageTarget.value === '') {
        this.messageWarningTarget.style.display = 'none'
        return
      }

      this.kidValidator = new KidValidator()
      if (this.kidValidator.validKidNumber(this.messageTarget.value)) {
        this.messageWarningTarget.style.display = 'none'
      } else {
        this.messageWarningTarget.style.display = 'block'
      }
    }
  }

  updateRecipientPaymentFields() {
    if (!this.hasDefaultCurrencyValue || !this.hasSourceCurrencyValue || !this.hasInternationalBankFieldsTarget) {
      return
    }

    if (
      this.sourceCurrencyValue === '' ||
      this.sourceCurrencyValue == this.defaultCurrencyValue ||
      !this.executingPayment()
    ) {
      this.internationalBankFieldsTarget.classList.add('d-none')
      this.domesticBankFieldsTarget.classList.remove('d-none')
      this.recipientBankNumberTarget.disabled = false
      this.recipientIbanTarget.disabled = true
      this.recipientBicTarget.disabled = true
      this.messageTarget.parentElement.querySelector('label').innerText = this.messageTarget.dataset.domesticBankLabel
    } else {
      this.internationalBankFieldsTarget.classList.remove('d-none')
      this.domesticBankFieldsTarget.classList.add('d-none')
      this.recipientBankNumberTarget.disabled = true
      this.recipientIbanTarget.disabled = this.hasDisabledValue && this.disabledValue
      this.recipientBicTarget.disabled = this.hasDisabledValue && this.disabledValue
      this.messageTarget.parentElement.querySelector('label').innerText = i18n.t(
        'activerecord.attributes.payment.message',
      )
    }
  }

  accountHasValidCurrency(bankAccountOption: HTMLOptionElement): boolean {
    if (!this.hasDefaultCurrencyValue) {
      return true
    }

    const bankAccountCurrency = bankAccountOption.getAttribute('currency')
    return (
      this.sourceCurrencyValue === this.defaultCurrencyValue ||
      bankAccountCurrency === this.defaultCurrencyValue ||
      bankAccountCurrency === this.sourceCurrencyValue
    )
  }

  handlePayPartialAmount(event) {
    event.preventDefault()

    if (this.sourceCurrencyValue === this.defaultCurrencyValue) {
      this.domesticAmountInputTarget.removeAttribute('readonly')
    } else {
      this.foreignAmountInputTarget.removeAttribute('readonly')
    }
  }

  // If the bank account has not finished integration it'll be hidden if the payment type is pay and execute now
  accountValidByPaymentType(bankAccountOption: HTMLOptionElement): boolean {
    return this.executingPayment() ? this.canExecute(bankAccountOption) : true
  }

  executingPayment() {
    return !(
      this.paymentTypeTarget.value === 'register_payment_later' || this.paymentTypeTarget.value === 'paid_in_bank'
    )
  }

  disableInvalidAccountOptions() {
    if (!this.hasAccountTarget) {
      return
    }

    // set visible all accounts
    Array.from(this.accountTarget.options).forEach((bankAccountOption: HTMLOptionElement) => {
      bankAccountOption.style.display = 'block'
    })

    // filter accounts
    let accountsToHide = Array.from(this.accountTarget.options).filter((bankAccountOption: HTMLOptionElement) => {
      return !(this.accountHasValidCurrency(bankAccountOption) && this.accountValidByPaymentType(bankAccountOption))
    })

    // hide not valid accounts
    Array.from(accountsToHide).forEach((bankAccountOption: HTMLOptionElement) => {
      bankAccountOption.style.display = 'none'
    })

    this.selectAvailableAccount()
  }

  selectAvailableAccount() {
    if (!this.hasAccountTarget) {
      return
    }

    const defaultAccount = this.accountTarget.querySelector('[selected_bank_account="true"]') as HTMLOptionElement
    const selectedAccount = this.accountTarget.options[this.accountTarget.selectedIndex]
    const availableAccounts = Array.from(this.accountTarget.options).filter((option) => {
      return option.style.display != 'none'
    })

    // do not change anything if account that was selected before is valid
    if (selectedAccount && selectedAccount.style.display != 'none') {
      return
    }
    // try to select default account
    else if (defaultAccount && defaultAccount.style.display != 'none') {
      this.accountTarget.value = defaultAccount.value
    }
    // select first available
    else if (availableAccounts.length != 0) {
      this.accountTarget.value = availableAccounts[0].value
    } else {
      this.accountTarget.value = ''
    }
  }

  handlePaymentTypeChange() {
    const paymentBlock: HTMLElement = this.element.querySelector('#payment-payable')

    this.showConsentExpiredWarning()

    if (paymentBlock === null || !this.hasPaymentTypeTarget) {
      return
    }

    const paymentType = this.paymentTypeTarget.value

    if (paymentType === 'paid_in_bank') {
      this.disableInvalidAccountOptions()
      paymentBlock.style.display = 'block'
      if (this.hasOverWriteBankNumberCheckBoxTarget) {
        this.overWriteBankNumberCheckBoxTarget.closest('fieldset').style.display = 'none'
      }
      if (this.hasMessageTarget) {
        ;(this.messageTarget.closest('div') as HTMLElement).style.display = 'none'
      }
      if (this.hasRecipientBankNumberTarget) {
        ;(this.recipientBankNumberTarget.closest('div') as HTMLElement).style.display = 'none'
      }
      if (this.hasAccountTarget) {
        ;(this.accountTarget.parentElement.querySelector('label') as HTMLElement).innerText = i18n.t(
          'activerecord.attributes.payment.paid_from_account',
        )
      }
    } else if (paymentType === 'register_payment_later') {
      this.disableInvalidAccountOptions()
      paymentBlock.style.display = 'none'
    } else {
      // execute, initiated
      paymentBlock.style.display = 'block'
      if (this.hasRecipientBankNumberTarget) {
        ;(this.recipientBankNumberTarget.closest('div') as HTMLElement).style.display = 'block'
      }
      if (this.hasMessageTarget) {
        ;(this.messageTarget.closest('div') as HTMLElement).style.display = 'block'
      }
      if (this.hasOverWriteBankNumberCheckBoxTarget) {
        this.overWriteBankNumberCheckBoxTarget.closest('fieldset').style.display = 'block'
        this.renderOverWriteReceiversBankAccountCheckBox()
      }
      if (this.hasAccountTarget) {
        ;(this.accountTarget.closest('div').childNodes[0] as HTMLElement).innerText = i18n.t(
          'activerecord.attributes.payment.pay_from_account',
        )
      }
      this.disableInvalidAccountOptions()
      this.validateKidNumber()
    }

    if (this.paymentOnTarget) {
      const paymentDateController = this.application.getControllerForElementAndIdentifier(
        this.paymentOnTarget,
        'input--payment-date-picker',
      ) as PaymentDatePickerController
      if (paymentDateController !== null) {
        paymentDateController.setPaymentType(paymentType)
      }
    }

    this.showAmountInputFieldsByCurrency()
    this.updateRecipientPaymentFields()
  }

  hideSetAsDefaultPaymentCheckbox(hide) {
    if (!this.hasSetAsDefaultPaymentCheckboxTarget) {
      return
    }

    if (hide) {
      this.setAsDefaultPaymentCheckboxTarget.parentElement.classList.add('d-none')
    } else {
      this.setAsDefaultPaymentCheckboxTarget.parentElement.classList.remove('d-none')
    }

    this.setAsDefaultPaymentCheckboxTarget.checked = false
  }

  showSetAsDefaultPaymentCheckbox() {
    if (this.hasAccountTarget) {
      if (
        this.accountTarget.options[this.accountTarget.selectedIndex].getAttribute('default_payment_account') === 'true'
      ) {
        this.hideSetAsDefaultPaymentCheckbox(true)
      } else {
        this.hideSetAsDefaultPaymentCheckbox(false)
      }
    }
  }

  showConsentExpiredWarning() {
    if (!this.hasAccountTarget || !this.hasPaymentTypeTarget) {
      return
    }

    // Hide warning
    this.consentExpiredWarningTarget.classList.add('d-none')

    for (const element of Array.from(this.accountTargets)) {
      let selectedOption = element.options[element.selectedIndex]

      if (selectedOption == undefined) {
        return
      }

      if ('execute' !== this.paymentTypeTarget.value || !this.canExecute(selectedOption)) {
        continue
      }

      let isISO20022Integration = selectedOption.getAttribute('iso20022_integration') === 'true'
      if (isISO20022Integration) {
        continue
      }

      let isPSD2Integration = selectedOption.getAttribute('psd2_integration') === 'true'
      let currentUserHasConsent = selectedOption.getAttribute('psd2_current_user_has_consent') === 'true'
      let currentConsentExpired = selectedOption.getAttribute('psd2_current_consent_expired') === 'true'
      let bankBic = selectedOption.getAttribute('bank_bic')
      let bankName = selectedOption.getAttribute('bank_name')

      if (isPSD2Integration) {
        if (currentUserHasConsent) {
          if (currentConsentExpired) {
            // Show warning that consent is expired
            this.showPsd2Alert(
              i18n.t('bank.account.index_page.consents.expired.text', { bank_name: bankName }),
              i18n.t('bank.account.index_page.consents.login_button', { bank_name: bankName }),
              'warning',
              bankBic,
            )
            return
          }
        } else {
          // Show info that he can connect to do payments
          this.showPsd2Alert(
            i18n.t('bank.account.index_page.consents.other_user_has_consent.text', { bank_name: bankName }),
            i18n.t('bank.account.index_page.consents.login_button', { bank_name: bankName }),
            'info',
            bankBic,
          )
          return
        }
      }
    }
  }

  showPsd2Alert(text: string, buttonText: string, level: string, bankBic: string) {
    this.consentExpiredWarningTarget.classList.remove('d-none')
    this.consentExpiredLinkTarget.dataset.bsTarget = `#reconnect-bank-account-${bankBic}`
    this.consentExpiredTextTarget.innerHTML = text
    this.consentExpiredLinkTarget.text = buttonText

    if (level == 'warning') {
      this.consentExpiredWarningTarget.classList.add('alert-warning')
      this.consentExpiredWarningTarget.classList.remove('alert-info')
    } else {
      // Info
      this.consentExpiredWarningTarget.classList.remove('alert-warning')
      this.consentExpiredWarningTarget.classList.add('alert-info')
    }
  }

  canExecute(bankAccountOption: HTMLOptionElement) {
    let isPsd2Integration = bankAccountOption.getAttribute('psd2_integration') === 'true'
    let isExecuteForeignPayment = this.sourceCurrencyValue != this.defaultCurrencyValue

    let canExecutePayments =
      bankAccountOption.getAttribute('supports_executing_payments') === 'true' ||
      (bankAccountOption.getAttribute('psd2_current_user_has_consent') === 'true' &&
        bankAccountOption.getAttribute('psd2_current_consent_expired') === 'true')

    // We only allow executing foreign payments with PSD2
    if (this.hasAllowExecuteInForeignCurrencyValue && isExecuteForeignPayment) {
      return this.allowExecuteInForeignCurrencyValue && canExecutePayments && isPsd2Integration
    }

    return canExecutePayments
  }

  renderOverWriteReceiversBankAccountCheckBox() {
    if (this.hasOverWriteBankNumberCheckBoxTarget) {
      if (this.originalValue === '') {
        this.originalValue = this.recipientBankNumberTarget.value
      }
      if (this.recipientBankNumberTarget.value === this.originalValue || this.recipientBankNumberTarget.value === '') {
        this.overWriteBankNumberCheckBoxTarget.closest('fieldset').style.display = 'none'
        this.overWriteBankNumberCheckBoxTarget.checked = false
      } else {
        this.overWriteBankNumberCheckBoxTarget.closest('fieldset').style.display = 'block'
      }
    }
  }
}
