import { rails_fetch } from '../../../../util/rails_fetch'

import { BankReconciliation, BankTransaction, Match, Posting } from '../module'
import { formatISO } from 'date-fns'

export default {
  async match(
    bankReconciliation: BankReconciliation,
    bankTransactions: Array<BankTransaction>,
    postings: Array<Posting>,
  ) {
    const path = `/${(window as any).current_company_id}/bank_accounts/${bankReconciliation.bankAccountId}/bank_transactions/match`
    let response = await rails_fetch(path, {
      method: 'PATCH',
      body: JSON.stringify({
        posting_ids: postings.map((posting) => {
          return posting.id
        }),
        bank_transaction_ids: bankTransactions.map((bankTransaction) => {
          return bankTransaction.id
        }),
        period: formatISO(bankReconciliation.beginningOn),
      }),
    })

    if (response.status != 200) {
      window.location.reload()
    }

    return response
  },

  async unMatch(bankReconciliation: BankReconciliation, match: Match) {
    const path = `/${(window as any).current_company_id}/bank_reconciliation_matches/${match.id}`
    return await rails_fetch(path, {
      method: 'DELETE',
    })
  },

  async setReconciled(bankReconciliation: BankReconciliation, reconciled: boolean) {
    if (reconciled) {
      const path = `/${(window as any).current_company_id}/bank_reconciliations/${bankReconciliation.id}/reconcile`
      return await rails_fetch(path, {
        method: 'PATCH',
      })
    } else {
      const path = `/${(window as any).current_company_id}/bank_reconciliations/${bankReconciliation.id}/reopen`
      return await rails_fetch(path, {
        method: 'PATCH',
        body: JSON.stringify({ force_all_reopen: true }),
      })
    }
  },
}
