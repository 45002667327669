import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  handleChangeDate(event) {
    event.preventDefault()
    let reverseDateList = event.target.parentElement.parentElement.querySelectorAll('input')
    // Did not find a better way to remove disabled from flatpickr as it has different fields for
    // the input and the value that is sent in. It does not support readonly and it did not work to wrap
    // the whole field inside another div and disable/enable that div. Feel free to fix this if you know a
    // better way.
    for (let i = 0; i < reverseDateList.length; i++) {
      reverseDateList[i].disabled = false
      reverseDateList[i].classList.remove('disabled')
    }
  }
}
