import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['current', 'template']

  declare currentTarget: HTMLElement
  declare templateTargets: Array<HTMLElement>

  connect() {
    if (this.templateTargets.length === 0) return

    let templateKey = this.templateTargets[0].dataset.template
    this.toggle({ target: { value: templateKey } })
  }

  toggle(event) {
    let newSelectedTemplateKey = (event.target as HTMLInputElement).value
    let template = this.templateTargets.find((el) => el.dataset.template === newSelectedTemplateKey)
    this.currentTarget.innerHTML = template.innerHTML
  }
}
