import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../../../classes/parse_decimal'
import Decimal from 'decimal.js'

export default class extends Controller {
  static values = { postingCreated: String, resultToDistributeCents: String }
  static targets = [
    'type',
    'uncoveredLossInput',
    'uncoveredLossLabel',
    'estimatedTaxesInput',
    'estimatedTaxesLabel',
    'dividendsInput',
    'profitsInput',
  ]
  declare postingCreatedValue: string
  declare resultToDistributeCentsValue: string
  declare typeTarget: HTMLInputElement
  declare uncoveredLossInputTarget: HTMLInputElement
  declare estimatedTaxesInputTarget: HTMLInputElement
  declare hasEstimatedTaxesLabelTarget: boolean
  declare estimatedTaxesLabelTarget: HTMLInputElement
  declare dividendsInputTarget: HTMLInputElement
  declare profitsInputTarget: HTMLInputElement

  connect() {
    if (this.postingCreatedValue == 'true' && this.hasEstimatedTaxesLabelTarget) {
      this.estimatedTaxesLabelTarget.innerHTML = this.estimatedTaxesInputTarget.value
    }
    this.updateTotal()
  }

  updateTotal() {
    if (this.typeTarget.value == 'profit') {
      let taxes = parseDecimal(this.estimatedTaxesInputTarget.value, new Decimal(0))
      let dividends = parseDecimal(this.dividendsInputTarget.value, new Decimal(0))
      let profits = this.resultToDistribute().minus(taxes).minus(dividends)
      this.profitsInputTarget.value = this.toAmountStr(profits)
      this.estimatedTaxesLabelTarget.innerHTML = this.estimatedTaxesInputTarget.value
    } else {
      this.uncoveredLossInputTarget.value = this.toAmountStr(this.resultToDistribute().neg())
    }
  }

  toAmountStr(value: Decimal): string {
    return value.toFixed(2).toString().replace('.', ',')
  }

  resultToDistribute() {
    return parseDecimal(this.resultToDistributeCentsValue, new Decimal(0)).div(100)
  }
}
