import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  static targets = ['serviceCard', 'serviceHeader', 'holdingCard', 'type', 'holdingHeader', 'description', 'alert']

  declare serviceCardTarget: HTMLElement
  declare serviceHeaderTarget: HTMLElement
  declare holdingCardTarget: HTMLElement
  declare holdingHeaderTarget: HTMLElement
  declare typeTarget: HTMLFormElement
  declare descriptionTarget: HTMLFormElement
  declare alertTarget: HTMLElement

  connect() {
    if (this.typeTarget.value === 'true') {
      this.setCardActive(this.holdingHeaderTarget)
    } else {
      this.setCardActive(this.serviceCardTarget)
    }
  }

  select(event) {
    this.setCardActive(event.target)
  }

  setCardActive(target) {
    if (target.closest('[data-found-company--card-select-target="serviceCard"]')) {
      this.serviceCardTarget.classList.add('selected')
      this.holdingCardTarget.classList.remove('selected')
      this.serviceHeaderTarget.classList.remove('d-none')
      this.holdingHeaderTarget.classList.add('d-none')
      this.descriptionTarget.placeholder = i18n.t('found_company.company_type.description_placeholder.service')
      this.descriptionTarget.value = ''
      this.alertTarget.classList.remove('d-none')
      this.typeTarget.value = false
    } else {
      this.holdingCardTarget.classList.add('selected')
      this.serviceCardTarget.classList.remove('selected')
      this.holdingHeaderTarget.classList.remove('d-none')
      this.serviceHeaderTarget.classList.add('d-none')
      this.descriptionTarget.value = i18n.t('found_company.print.statue_holding')
      this.descriptionTarget.placeholder = i18n.t('found_company.company_type.description_placeholder.holding')
      this.alertTarget.classList.add('d-none')
      this.typeTarget.value = true
    }
  }
}
