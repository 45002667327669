import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkBox', 'submit']

  declare checkBoxTarget: HTMLInputElement
  declare submitTarget: HTMLButtonElement
  declare hasCheckBoxTarget: boolean

  checkBoxHandler() {
    // One of the possible buttons is a button which opens a modal. The way modals works is that we use the data
    // target on the button to match and open the modal, so here we use id instead of setting it as a data-bs-target.
    if (this.submitTarget === null || !this.hasCheckBoxTarget) {
      return
    }

    if (this.checkBoxTarget.checked) {
      this.submitTarget.disabled = false
      this.submitTarget.classList.remove('disabled')
    } else {
      this.submitTarget.classList.add('disabled')
      this.submitTarget.disabled = true
    }
  }
}
