import { Controller } from '@hotwired/stimulus'
import { parseDecimal } from '../../../classes/parse_decimal'
import { i18n } from '../../../libraries/i18n'
import Decimal from 'decimal.js'

export interface WageType {
  name: string
  deduction: boolean
  quantityType: string
  showAmount: boolean
  extraAltinnInfo: string
  attachmentOfEarningType: string
  hint: string
  hintType: string
}

export default class extends Controller {
  static targets = [
    'quantity',
    'quantityWrapper',
    'rate',
    'total',
    'wageType',
    'purchaseCar',
    'hint',
    'makeRecurring',
    'attachmentOfEarningOther',
  ]

  declare hasWageTypeTarget: boolean
  declare wageTypeTarget: HTMLSelectElement
  declare hasPurchaseCarTarget: boolean
  declare purchaseCarTarget: HTMLSelectElement
  declare rateTarget: HTMLInputElement
  declare quantityTarget: HTMLInputElement
  declare quantityWrapperTarget: HTMLInputElement
  declare totalTarget: HTMLInputElement
  declare hintTarget: HTMLDivElement
  declare hasMakeRecurringTarget: boolean
  declare makeRecurringTarget: HTMLElement
  declare attachmentOfEarningOtherTarget: HTMLElement

  selectedWageType: WageType

  connect() {
    this.setWageTypeData(JSON.parse(this.wageTypeTarget.dataset.definition)[0])
  }

  calculateTotal() {
    let quantityType = this.selectedWageType.quantityType
    if (!this.selectedWageType.showAmount) return

    let total = parseDecimal(this.quantityTarget.value).mul(parseDecimal(this.rateTarget.value, new Decimal(0)))

    if (quantityType === 'percent') {
      total = total.div(100)
    }

    this.totalTarget.value = new Intl.NumberFormat('nb-NO', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(total.toNumber())
      // Use regular minus instead of the special minus that Intl.NumberFormat seems like use
      .replaceAll('\u2212', '-')
  }

  setWageType(event: CustomEvent) {
    this.setWageTypeData(event.detail.event.params.data)
  }

  private setWageTypeData(data) {
    this.selectedWageType = data

    let quantityType = this.selectedWageType.quantityType
    let showAmount = this.selectedWageType.showAmount
    let percentTemplate = `<span class="input-group-text">%</span>`
    let label = this.quantityTarget.parentNode.previousSibling as HTMLElement
    label.innerText = i18n.t(`activerecord.attributes.salary/wage_type.quantity_type.${quantityType}`)
    switch (quantityType) {
      case 'percent':
        this.quantityWrapperTarget.classList.remove('d-none')
        this.quantityTarget.classList.add('text-end')
        this.quantityTarget.type = ''
        if (!(this.quantityTarget.nextSibling as HTMLElement)?.classList?.contains('input-group-text')) {
          this.quantityTarget.insertAdjacentHTML('afterend', percentTemplate)
        }
        this.rateTarget.parentElement.classList.remove('d-none')
        this.totalTarget.parentElement.classList.remove('d-none')
        break
      case 'hidden':
        this.quantityWrapperTarget.classList.add('d-none')
        this.totalTarget.parentElement.classList.add('d-none')
        break
      default:
        this.quantityWrapperTarget.classList.remove('d-none')
        this.quantityTarget.classList.remove('text-end')
        this.quantityTarget.type = 'string'
        if ((this.quantityTarget.nextSibling as HTMLElement)?.classList?.contains('input-group-text')) {
          this.quantityTarget.nextSibling.remove()
        }
        this.rateTarget.parentElement.classList.remove('d-none')
        this.totalTarget.parentElement.classList.remove('d-none')
    }
    if (!showAmount) {
      this.rateTarget.parentElement.classList.add('d-none')
      this.totalTarget.parentElement.classList.add('d-none')
    }

    if (this.selectedWageType.hint !== '') {
      if (this.selectedWageType.hintType == 'info') {
        this.showInfoAlert()
      } else {
        this.showWarningAlert()
      }
    } else {
      this.hintTarget.classList.add('d-none')
    }

    this.showPurchaseCarInputField()
    this.showAttachmentOfEarning()
    this.calculateTotal()
  }

  showInfoAlert() {
    this.hintTarget.classList.add('alert')
    this.hintTarget.classList.add('alert-info')
    this.hintTarget.classList.remove('alert-warning')
    this.hintTarget.innerHTML = this.selectedWageType.hint
    this.hintTarget.classList.remove('d-none')
  }

  showWarningAlert() {
    this.hintTarget.classList.add('alert')
    this.hintTarget.classList.add('alert-warning')
    this.hintTarget.classList.remove('alert-info')
    this.hintTarget.innerHTML = this.selectedWageType.hint
    this.hintTarget.classList.remove('d-none')
  }

  private showPurchaseCarInputField() {
    if (!this.hasPurchaseCarTarget || !this.hasWageTypeTarget) {
      return
    }

    if (
      this.selectedWageType.extraAltinnInfo == 'car_licence_plate' ||
      this.selectedWageType.extraAltinnInfo == 'car_license_plate_and_price'
    ) {
      this.purchaseCarTarget.style.display = 'block'
    } else {
      this.purchaseCarTarget.style.display = 'none'
    }
  }

  private showAttachmentOfEarning() {
    if (this.selectedWageType.attachmentOfEarningType === 'other_attachment_of_earning') {
      this.attachmentOfEarningOtherTarget.classList.remove('d-none')
      this.attachmentOfEarningOtherTarget.querySelectorAll('input').forEach((item) => {
        item.disabled = false
      })
      if (this.hasMakeRecurringTarget) {
        this.makeRecurringTarget.classList.add('d-none')
        this.makeRecurringTarget.querySelectorAll('input').forEach((item) => {
          item.disabled = true
        })
      }
    } else {
      this.attachmentOfEarningOtherTarget.classList.add('d-none')
      this.attachmentOfEarningOtherTarget.querySelectorAll('input').forEach((item) => {
        item.disabled = true
      })
      if (this.hasMakeRecurringTarget) {
        this.makeRecurringTarget.classList.remove('d-none')
        this.makeRecurringTarget.querySelectorAll('input').forEach((item) => {
          item.disabled = false
        })
      }
    }
  }
}
