<template>
  <div @keydown="keysHandler" @mousemove="cursorEnabled = true">
    <input
      readonly
      :disabled="disabled"
      :class="attributes.class + `${disabled || readonly ? '' : ' bg-default-important form-select'}`"
      :value="selectedAccount.name || placeholder"
      ref="input"
      :id="'input-' + id"
      v-on:click="readonly ? null : (showBody = !showBody)"
    />

    <select hidden ref="select">
      <option ref="option" :value="selectedAccount.id" :label="selectedAccount.name || placeholder"></option>
    </select>

    <div
      :style="floatingStyles"
      v-show="showBody"
      data-test-id="dropdown-content"
      data-move-element-target="element"
      ref="floating"
      :data-controller="controller"
    >
      <div class="card card-luca border">
        <div ref="searchField" class="card-header pb-2 pt-3 px-3">
          <SearchField
            :show-switch="showSwitch"
            :shown="showBody"
            :favorite="favorite"
            @searchInput="searchInput"
            @favoriteSet="favoriteSet"
          />
        </div>
        <div
          class="card-body row"
          v-if="favorite === true && groupedAccounts.length === 0 && hits != 0"
          v-bind:style="listStyleObject"
        >
          <span
            >{{ i18n().t('account.no_hits', { hits: hits }) }}
            <a style="text-decoration: underline" v-on:click.prevent="favoriteSet(false)" href="#">{{
              i18n().t('account.show_all')
            }}</a></span
          >
        </div>
        <div
          class="card-body row"
          v-else-if="groupedAccounts.length === 0 && hits === 0"
          v-bind:style="listStyleObject"
        >
          <span>{{ i18n().t('bank_transaction.no_match') }}</span>
        </div>
        <div v-else class="card-body row py-0 px-3">
          <List
            ref="list"
            v-bind:style="listStyleObject"
            class="col-sm"
            :hover-account="hoverAccount"
            :grouped-accounts="groupedAccounts"
            :cursor-enabled="cursorEnabled"
            @accountHover="accountHover"
            @accountSelected="accountSelected"
          />
          <Description
            v-bind:style="descriptionStyleObject"
            v-if="dropdownContentWidth > 900"
            :description-header="hoverAccount.name"
            :description-body="hoverAccount.description"
            class="col-sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SearchField from './SearchField.vue'
import List from './List.vue'
import Description from './Description.vue'
import AccountSelectorService from '../services/AccountSelectorService'
import { computePosition, flip, autoUpdate, offset, Placement } from '@floating-ui/vue'
import { i18n } from '../../../libraries/i18n'
import { defineComponent, PropType } from 'vue'
import { Account, AccountSelectEvent, DisplayAccountData, GroupedAccount } from '../module'
import { CSSProperties } from 'vue'

export default defineComponent({
  props: {
    attributes: {
      type: Object as PropType<Record<string, any>>,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    initialSelectedAccount: {
      type: Object as PropType<DisplayAccountData>,
      default: null,
    },
    showSwitch: {
      type: Boolean,
      default: true,
    },
    initialFavorite: {
      type: Boolean,
      default: true,
    },
    filters: {},
  },
  components: {
    Description,
    List,
    SearchField,
  },
  data() {
    return {
      abortController: new AbortController() as AbortController,
      placement: 'bottom-start' as Placement,
      cursorEnabled: true as boolean,
      hits: 0 as number,
      showBody: false as boolean,
      id: this.randomId() as string,
      dropdownContentWidth: 0 as number,
      accounts: [] as Array<Account>,
      groupedAccounts: [] as Array<GroupedAccount>,
      selectedAccount: this.initialSelectedAccount as DisplayAccountData,
      searchValue: null as string | null,
      favorite: this.initialFavorite as boolean,
      maxHeight: 365 as number,
      hoverAccount: null as null | Account,
      searchString: '' as string,
      readonly: (this.attributes.readonly || false) as boolean,
      disabled: this.attributes.disabled as boolean,
      timeout: null as NodeJS.Timeout,
    }
  },
  computed: {
    floatingStyles(): CSSProperties {
      return {
        position: 'absolute' as CSSProperties['position'],
        zIndex: 20000,
      }
    },
    listStyleObject(): CSSProperties {
      return {
        cursor: (this.cursorEnabled ? 'auto' : 'none') as CSSProperties['cursor'],
        overflowY: 'scroll' as CSSProperties['overflowY'],
        height: (this.maxHeight + 'px') as CSSProperties['height'],
        minWidth: (this.dropdownContentWidth < 900
          ? 'unset'
          : this.dropdownContentWidth / 2 - 50 + 'px') as CSSProperties['minWidth'],
      }
    },
    descriptionStyleObject(): CSSProperties {
      return {
        overflowY: 'scroll' as CSSProperties['overflowY'],
        height: (this.maxHeight + 'px') as CSSProperties['height'],
        minWidth: (this.dropdownContentWidth < 900
          ? 'unset'
          : this.dropdownContentWidth / 2 - 50 + 'px') as CSSProperties['minWidth'],
      }
    },
  },
  methods: {
    handleReset() {
      this.selectedAccount = { id: '', name: '' }
      this.groupedAccounts = []
      this.pullAccounts()
    },
    i18n() {
      return i18n
    },
    updatePosition() {
      const floating = this.$refs.floating as HTMLDivElement
      const input = this.$refs.input as HTMLInputElement

      computePosition(input, floating, {
        middleware: [offset(5)],
        placement: this.placement,
      }).then(({ x, y }) => {
        Object.assign(floating.style, {
          left: `${x}px`,
          top: `${y}px`,
        })
      })
    },
    keysHandler(event) {
      const input = this.$refs.input as HTMLInputElement

      switch (event.key) {
        case 'Enter':
          if (this.showBody) {
            const floating = this.$refs.floating as HTMLElement
            const tableItem = floating.querySelector('.table-item') as HTMLTableColElement

            tableItem.click()
            input.focus()
          } else {
            event.target.click()
          }
          event.preventDefault()
          break
        case 'Tab':
          if (this.showBody) {
            this.showBody = !this.showBody
            input.focus()
            event.preventDefault()
          }
          break
        case 'ArrowDown':
          this.cursorEnabled = false
          if (this.hoverAccount) {
            const accountIndex = this.accounts.findIndex((acc) => acc.id === this.hoverAccount.id)
            if (this.accounts[accountIndex + 1]) this.accountHover(this.accounts[accountIndex + 1])
          } else {
            if (this.accounts[0]) this.accountHover(this.accounts[0])
          }
          event.preventDefault()
          break
        case 'ArrowUp':
          this.cursorEnabled = false
          if (this.hoverAccount) {
            const accountIndex = this.accounts.findIndex((acc) => acc.id === this.hoverAccount.id)
            if (this.accounts[accountIndex - 1]) this.accountHover(this.accounts[accountIndex - 1])
          } else {
            if (this.accounts[0]) this.accountHover(this.accounts[0])
          }
          event.preventDefault()
          break
        case 'Escape':
          this.showBody = false
          input.focus()
          event.preventDefault()
          break
      }
    },
    async searchInAllAccounts() {
      const response = await AccountSelectorService.pullAccounts(false, this.searchString, this.filters, null)
      if (response) {
        this.hits = response.map((el) => el.children).flat().length
      }
    },
    randomId() {
      try {
        return self.crypto.randomUUID()
      } catch {
        return Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
      }
    },
    controller() {
      return this.$el.closest('.modal') ? 'move-element' : ''
    },
    accountHover(account) {
      if (!account) {
        return
      }

      this.hoverAccount = {
        ...account,
        name: `${account.code} ${account.description}`,
        description: i18n.t(`norwegian_account_plan.level_3.${account.code}.description`),
      }
    },
    accountSelected(account) {
      const name = (account.sub_code ? `${account.code}:${account.sub_code}` : account.code) + ' ' + account.description
      this.selectedAccount = { id: account.id, name: name }
      this.showBody = false

      this._dispatch('account_selector:select', {
        available_vat_codes: account.available_vat_codes,
        default_vat_code: account.default_vat_code,
        sub_code: account.sub_code,
        code: account.code,
      })
    },
    _dispatch(eventName, params = {}) {
      const event = document.createEvent('Event')
      event.initEvent(eventName, true, true)
      Object.assign(event, params)
      this.$nextTick(() => {
        const select = this.$refs.select as HTMLElement
        select.dispatchEvent(event)
      })
    },
    searchInput(input: string) {
      this.searchString = input
      this.pullAccounts()
    },
    favoriteSet(favorite: boolean) {
      this.favorite = favorite
      this.pullAccounts()
    },
    async pullAccounts() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        this.abortController.abort() // Cancel any ongoing requests
        this.abortController = new AbortController()
        const response = await AccountSelectorService.pullAccounts(
          this.favorite,
          this.searchString,
          this.filters,
          this.abortController.signal,
        )
        this.hits = 0
        if (response) {
          this.groupedAccounts = response
          this.accounts = response.map((el) => el.children).flat()
          this.accountHover(this.accounts[0])

          if (response.length == 0 && this.favorite == true) {
            await this.searchInAllAccounts()
          }
        }
      }, 250)
    },
  },
  mounted() {
    const selectElement = this.$refs.select as HTMLSelectElement
    // set same attributes that has origin input
    const attributes = this.attributes
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        selectElement.setAttribute(key, attributes[key])
      })
    }

    // handle adding disable/enable status
    let observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const target = mutation.target as HTMLSelectElement

        if (mutation.attributeName === 'disabled') {
          this.disabled = target.disabled
        }
        this.readonly = target.hasAttribute('readonly')
      })
    })
    observer.observe(selectElement, {
      attributes: true,
    })

    this.$nextTick(() => {
      selectElement.addEventListener('set', (event: AccountSelectEvent) => {
        const { id, text } = event
        this.selectedAccount = { id: id, name: text }
      })
      selectElement.addEventListener('reset', this.handleReset)

      if (this.$el.closest('.modal')) {
        this.$el.closest('.modal').appendChild(this.$refs.floating)
      }

      // detect if click outside the account selector component
      document.addEventListener('click', (event) => {
        const inputField = this.$refs.input as HTMLElement
        const dropdownContent = this.$refs.floating as HTMLElement

        if (!inputField || !dropdownContent) {
          return
        }

        const eventTargetElement = event.target as HTMLElement

        if (
          !document.contains(eventTargetElement) ||
          eventTargetElement === dropdownContent ||
          dropdownContent.contains(eventTargetElement) ||
          eventTargetElement === inputField ||
          inputField.contains(eventTargetElement)
        ) {
          // the click was inside the element, do nothing
          return
        }
        // the click was outside the element, close it
        this.showBody = false
      })
    })
  },
  watch: {
    showBody: function (newValue) {
      this.pullAccounts()

      // determine width and height of dropdown part
      if (newValue) {
        const dropdownContent = this.$refs.floating as HTMLElement
        const inputField = this.$refs.input as HTMLElement

        if (window.innerWidth - inputField.offsetWidth < 100) {
          this.dropdownContentWidth = inputField.offsetWidth
        } else {
          let freeSpaceForDropdownContent = window.innerWidth - inputField.getBoundingClientRect().x
          if (
            inputField.getBoundingClientRect().x + inputField.offsetWidth > freeSpaceForDropdownContent &&
            freeSpaceForDropdownContent < 1000
          ) {
            freeSpaceForDropdownContent = inputField.getBoundingClientRect().x + inputField.offsetWidth
          }

          if (freeSpaceForDropdownContent < 1000) {
            this.dropdownContentWidth = freeSpaceForDropdownContent - 20
          } else {
            this.dropdownContentWidth = 1000
          }
        }

        dropdownContent.style.width = this.dropdownContentWidth + 'px'

        if (inputField && dropdownContent) {
          computePosition(inputField, dropdownContent, {
            placement: 'bottom-start',
            middleware: [
              offset(5),
              flip({ fallbackPlacements: ['bottom-start', 'bottom-end', 'top-start', 'top-end'] }),
            ],
          }).then(({ x, y, placement }) => {
            this.placement = placement

            Object.assign(dropdownContent.style, {
              left: `${x}px`,
              top: `${y}px`,
            })
          })

          const cleanup = autoUpdate(inputField, dropdownContent, this.updatePosition)
          if (!newValue) {
            cleanup()
          }
        }
      }
    },
  },
})
</script>
