import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import JsFormError from '../standalone/components/JsFormError'
import { i18n } from '../libraries/i18n'

export default class extends Controller {
  static targets = [
    'userForm',
    'pinForm',
    'companyForm',
    'submitCompanyFormButton',
    'pinFormSubmitButton',
    'backButton',
    'smsButton',
    'altinnButton',
    'closeButton',
    'alert',
    'userPasswordInput',
    'userUserNameInput',
    'pinMethodInput',
    'altinnPinCode',
    'altinnUser',
    'altinnPassword',
  ]

  declare companyFormTarget: HTMLElement
  declare userFormTarget: HTMLElement
  declare pinFormTarget: HTMLElement
  declare alertTarget: HTMLElement
  declare submitCompanyFormButtonTarget: HTMLButtonElement
  declare pinFormSubmitButtonTarget: HTMLButtonElement
  declare hasPinFormSubmitButtonTarget: boolean
  declare smsButtonTarget: HTMLButtonElement
  declare backButtonTarget: HTMLButtonElement
  declare altinnButtonTarget: HTMLButtonElement
  declare closeButtonTarget: HTMLButtonElement
  declare pinMethodInputTarget: HTMLInputElement
  declare userPasswordInputTarget: HTMLInputElement
  declare userUserNameInputTarget: HTMLInputElement
  declare altinnPinCodeTarget: HTMLInputElement
  declare altinnUserTarget: HTMLInputElement
  declare altinnPasswordTarget: HTMLInputElement

  states: Array<string>
  state: string

  companyFormForm: HTMLFormElement
  userFormForm: HTMLFormElement
  pinFormForm: HTMLFormElement
  connect() {
    // Remember connect will be called when the main page is loaded, not only when the modal is loaded
    this.states = ['companyForm', 'userForm', 'pinForm', 'done', 'failure']

    if (this.altinnUserTarget.value == '') {
      this.state = 'companyForm'
    } else {
      this.state = 'userForm'
    }
    this.updateVisual()

    this.companyFormForm = this.companyFormTarget.getElementsByTagName('form')[0]

    this.companyFormForm.addEventListener('ajax:success', (event: CustomEvent) => {
      let data = event.detail[0]
      if (data['status'] === 'ok') {
        this.alert(i18n.t('altinn_modal.system_login_saved'), 'success')
        this.state = 'userForm'
        this.updateVisual()
        JsFormError.removePreviousErrors()
      }
    })

    this.companyFormForm.addEventListener('ajax:error', (event: CustomEvent) => {
      let data = event.detail[0]
      new JsFormError('company', data).updateForm()
    })

    this.userFormForm = this.userFormTarget.getElementsByTagName('form')[0]

    this.userFormForm.addEventListener('ajax:success', (event: CustomEvent) => {
      let data = event.detail[0]
      if (data['status'] === 'ok') {
        this.alert(data['messages'], 'info')
        this.state = 'pinForm'
        this.updateVisual()
        if (data['redirect']) {
          window.location = data['redirect']
        }
        JsFormError.removePreviousErrors()
      }
    })
    this.userFormForm.addEventListener('ajax:error', (event: CustomEvent) => {
      let data = event.detail[0]
      new JsFormError('altinn_login', data).updateForm()
    })

    this.pinFormForm = this.pinFormTarget.getElementsByTagName('form')[0]

    this.pinFormForm.addEventListener('ajax:success', (event: CustomEvent) => {
      let data = event.detail[0]
      if (data['status'] === 'ok') {
        this.state = 'done'
        if (data['redirect']) {
          Turbo.visit(`${data['redirect']}?message=${data['messages']}`)
        } else {
          let urlParams = new URLSearchParams(location.search)
          urlParams.set('message', data['messages'])
          Turbo.visit(`${location.protocol}//${location.host}${location.pathname}?${urlParams.toString()}`)
        }
        JsFormError.removePreviousErrors()
      }
    })
    this.pinFormForm.addEventListener('ajax:error', (event: CustomEvent) => {
      let data = event.detail[0]
      this.state = 'failure'
      new JsFormError('altinn_login', data).updateForm()
      this.updateVisual()
    })

    // Since altinn validates username, password and letter code in one go, the user should be able to
    // redo the whole flow if validation fails
    this.element.addEventListener('hidden.bs.modal', (event) => {
      // reverse only altinn-modal
      if ((event.target as HTMLElement).dataset.controller !== 'altinn-modal') return

      this.resetModalState()
    })

    this.setUpFocusOnShowModal()
  }

  alert(text, type) {
    this.alertTarget.innerHTML = text
    this.alertTarget.classList.remove('d-none')
    this.alertTarget.classList.remove('alert-success')
    this.alertTarget.classList.remove('alert-danger')
    this.alertTarget.classList.remove('alert-info')
    this.alertTarget.classList.add(`alert-${type}`)
  }

  updateVisual() {
    this.hideEverything()
    switch (this.state) {
      case 'companyForm':
        this.companyFormTarget.classList.remove('d-none')
        this.submitCompanyFormButtonTarget.classList.remove('d-none')
        this.submitCompanyFormButtonTarget.innerHTML = i18n.t('navigation.save')
        break
      case 'userForm':
        this.userFormTarget.classList.remove('d-none')
        this.smsButtonTarget.classList.remove('d-none')
        if (this.altinnUserTarget.value == '') {
          this.backButtonTarget.classList.remove('d-none')
        }
        this.altinnButtonTarget.classList.remove('d-none')
        break
      case 'pinForm':
        this.pinFormTarget.classList.remove('d-none')
        this.backButtonTarget.classList.remove('d-none')
        this.pinFormSubmitButtonTarget.classList.remove('d-none')
        break
      case 'done':
        this.closeButtonTarget.classList.remove('d-none')
        this.pinFormSubmitButtonTarget.disabled = true
        break
      case 'failure':
        this.pinFormTarget.classList.remove('d-none')
        this.backButtonTarget.classList.remove('d-none')
        this.pinFormSubmitButtonTarget.classList.remove('d-none')
        this.pinFormSubmitButtonTarget.disabled = false
        break
    }
    this.targetsFocus()
  }

  hideEverything() {
    this.companyFormTarget.classList.add('d-none')
    this.userFormTarget.classList.add('d-none')
    this.pinFormTarget.classList.add('d-none')

    this.backButtonTarget.classList.add('d-none')
    this.submitCompanyFormButtonTarget.classList.add('d-none')
    this.smsButtonTarget.classList.add('d-none')
    this.altinnButtonTarget.classList.add('d-none')
    this.closeButtonTarget.classList.add('d-none')
    this.pinFormSubmitButtonTarget.classList.add('d-none')
  }

  submitCompanyForm() {
    Rails.fire(this.companyFormForm, 'submit')
    this.updateVisual()
  }

  submitPinForm(event) {
    event.preventDefault()
    // The server side may take some time so we need to immediately disable the submit button, if
    // the request fails it'll be enabled in updateVisual
    this.pinFormSubmitButtonTarget.disabled = true
    Rails.fire(this.pinFormForm, 'submit')
    this.updateVisual()
  }

  requestAltinnPin() {
    this.pinMethodInputTarget.value = 'AltinnPin'
    Rails.fire(this.userFormForm, 'submit')
  }

  requestSmsPin() {
    this.pinMethodInputTarget.value = 'SMSPin'
    Rails.fire(this.userFormForm, 'submit')
  }

  resetModalState() {
    this.alertTarget.classList.add('d-none')
    this.state = this.states[0]
    this.updateVisual()
  }

  previousModalState(event: Event) {
    event.preventDefault()

    this.alertTarget.classList.add('d-none')
    this.state = this.states[this.states.indexOf(this.state) - 1]
    this.updateVisual()
  }

  autoSignClick(event: Event) {
    let checkbox = event.target as HTMLInputElement
    if (this.hasPinFormSubmitButtonTarget) {
      if (checkbox.checked) {
        this.pinFormSubmitButtonTarget.innerText = i18n.t('altinn_modal.submit_text.send_and_sign')
      } else {
        this.pinFormSubmitButtonTarget.innerText = i18n.t('altinn_modal.submit_text.send')
      }
    }
  }

  setUpFocusOnShowModal() {
    // we can't use target since bootstrap fires events through jQuery
    // here should be arrow function since it does the trick with closures
    this.element.addEventListener('shown.bs.modal', () => {
      this.targetsFocus()
    })
  }

  targetsFocus() {
    switch (this.state) {
      case 'companyForm':
        if (this.altinnUserTarget.value === '') {
          this.altinnUserTarget.focus()
        } else if (this.altinnPasswordTarget.value === '') {
          this.altinnPasswordTarget.focus()
        } else {
          this.submitCompanyFormButtonTarget.focus()
        }
        break
      case 'userForm':
        if (this.userUserNameInputTarget.value === '') {
          this.userUserNameInputTarget.focus()
        } else {
          this.userPasswordInputTarget.focus()
        }
        break
      case 'pinForm':
        this.altinnPinCodeTarget.focus()
        break
    }
  }
}
