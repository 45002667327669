import { Controller } from '@hotwired/stimulus'
import Select2Builder from '../../standalone/components/Select2Builder'
import $ from 'jquery'
import { i18n } from '../../libraries/i18n'

export default class extends Controller {
  initialize() {
    let $element = $(this.element)

    $element.select2(
      new Select2Builder().createParams(`/salary/norwegian_standardised_occupations.json`, {
        minimumInputLength: 2,
        tags: false,
        placeholder: i18n.t('salary.employment.choose_standard_occupation'),
        dropdownParent: $element.parent().parent(),

        templateResult(repo) {
          if (repo.loading) {
            return repo.code
          }
          if (repo.code) {
            return $(`<div>${repo.name} <small><samp class="float-end">${repo.code}</samp></small></div>`)
          }
        },
        templateSelection(selected) {
          if (selected.text) {
            return selected.text
          }
          return `${selected.name}`
        },
      }),
    )
  }
}
