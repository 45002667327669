import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import Select2Builder from '../../../standalone/components/Select2Builder'
import { i18n } from '../../../libraries/i18n'

export default class extends Controller {
  connect() {
    this.bankSearchSetSelect2()
  }

  bankSearchSetSelect2(): void {
    let $select2 = $(this.element)
    const dropdownParent = this.element.closest('.modal') || document.body

    const options = new Select2Builder().createParams(`/${(window as any).current_company_id}/bank/banks/search.json`, {
      minimumInputLength: 1,
      tags: false,
      placeholder: i18n.t('bank.account.search_for_bank'),
      dropdownParent: dropdownParent,
      templateResult(bank) {
        return bank.name
      },
      templateSelection(selected) {
        if (selected.text) {
          return selected.text
        }
        return selected.name
      },
    })
    $select2.select2(options)
  }
}
