import { Controller } from '@hotwired/stimulus'

// This controller is used when you want to move an element.
// This is useful for items such as Modals which we usually add together with the links to the modals.
// By moving them to the another element we avoid them being styled by the parent elements of the link.

export default class extends Controller {
  static targets = ['element', 'destination']

  declare elementTargets: Array<HTMLElement>
  declare hasDestinationTarget: boolean
  declare destinationTarget: HTMLElement

  initialize() {
    // Since an element can only be once in the DOM-tree, this will actually move this element to the target element
    if (this.hasDestinationTarget) {
      this.elementTargets.forEach((element) => this.destinationTarget.appendChild(element))
    } else {
      document.body.appendChild(this.element)
    }
  }
}
