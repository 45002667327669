import { Controller } from '@hotwired/stimulus'
import { Tab } from 'bootstrap'

export default class extends Controller {
  connect() {
    let urlParams = new URLSearchParams(location.search)

    if (window.location.hash) {
      let nav = document.querySelector('.nav a[href="' + window.location.hash + '"]')
      if (nav) {
        Tab.getOrCreateInstance(nav).show()
      } else {
        console.log(`Could not find tab with href=${window.location.hash}, is it missing?`)
      }
    } else if (this.data.get('default-tab')) {
      Tab.getOrCreateInstance(document.querySelector('.nav a[href="#' + this.data.get('default-tab') + '"]')).show()
    }

    const tabElems = this.element.querySelectorAll('a[data-bs-toggle="tab"]')
    tabElems.forEach((tabElem) => {
      tabElem.addEventListener('shown.bs.tab', function (e) {
        history.pushState({}, '', (e.target as HTMLAnchorElement).href)
      })
    })
  }
}
