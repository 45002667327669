<template>
  <div class="badge badge-info w-100 text-start mt-3 mb-1">
    <b>{{ suggestionList.length }}</b>
    {{ i18n().t('automatic_bank_reconciliation.v2.suggestion_found', { count: suggestionList.length }) }}
  </div>
  <suggestion-list-item
    v-for="suggestionSummary in suggestionSummaries"
    :level="suggestionSummary.level"
    :quantity="suggestionSummary.quantity"
    :key="suggestionSummary.level"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SuggestionListItem from './SuggestionListItem.vue'
import { i18n } from '../../../../../libraries/i18n'
import { Candidate, SuggestionLevel } from '../../module'

interface SuggestionSummary {
  level: SuggestionLevel
  quantity: number
}

export default defineComponent({
  components: { SuggestionListItem },
  props: {
    suggestionList: {
      type: Array<Candidate>,
      default: () => {
        return []
      },
    },
  },
  methods: {
    i18n() {
      return i18n
    },
  },
  computed: {
    suggestionSummaries(): Array<SuggestionSummary> {
      let summary = {}
      this.suggestionList.forEach((suggestion) => {
        if (summary[suggestion.level]) {
          summary[suggestion.level] += 1
        } else {
          summary[suggestion.level] = 1
        }
      })
      let sortedSummary = []
      const sortedLevels = ['exact', 'very_high', 'high', 'medium']
      sortedLevels.forEach((level) => {
        if (summary[level]) {
          sortedSummary.push({
            level: level,
            quantity: summary[level],
          })
        }
      })
      return sortedSummary
    },
  },
})
</script>
