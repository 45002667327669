import { Controller } from '@hotwired/stimulus'
import autoComplete from '../../standalone/components/AutoComplete'
import $ from 'jquery'
import { Modal } from 'bootstrap'
import { i18n } from '../../libraries/i18n'
// IMPORTANT: You MUST define data-partner-type on the controlled element. Its
// value is passed as the value of the type query parameter to the search
// endpoint.
export default class extends Controller {
  static targets = ['searchInput']

  declare searchInputTarget: HTMLInputElement

  searchCorporation: boolean

  connect() {
    this.setupSelect2(this.element)
  }

  _templateNewCompany(repo): JQuery<HTMLElement> | string {
    if (repo.loading) {
      return repo.text
    }
    // This is the case where we found a result:
    if (repo.name) {
      return $(`<div>
                    <div>${repo.name}</div>
                    <div class="d-flex justify-content-between">
                      <div><small>${repo.organisation_number}</small></div>
                    </div>
                  </div>`)
    }
    return ''
  }

  setupSelect2(selector): void {
    const params = {
      theme: 'bootstrap',
      width: '100%',
      minimumInputLength: 3, // PS Minimum input length hides the default options
      tags: true,
      ajax: {
        url: '/companies/search.json',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            term: params.term,
            page: params.page,
          }
        },
        processResults(data, params) {
          //  parse the results into the format expected by Select2
          //  since we are using custom formatting functions we do not need to
          //  alter the remote JSON data, except to indicate that infinite
          //  scrolling can be used
          params.page = params.page || 1
          return {
            results: data,
            pagination: {
              more: params.page * 25 < data.total_count,
            },
          }
        },
        cache: true,
      },
      /* This formats what is shown in the selection options */
      templateResult: this._templateNewCompany,
      /* How a selected result will look when clicked (or selected) */
      templateSelection(selected) {
        if (selected.text) {
          return selected.text
        }
        return selected.name
      },
      placeholder: i18n.t('company.new_search_placeholder'),
    }
    const elem = $(selector).select2(params)

    $(selector)
      .select2(params)
      .on('select2:select', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event)
      })
  }
}
