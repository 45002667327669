import { Controller } from '@hotwired/stimulus'

/*
 This controller enables/disables the a target element based on targets value.
 */
export default class extends Controller {
  static targets = ['button']

  declare buttonTarget: HTMLFormElement
  declare hasButtonTarget: Boolean

  enableButton() {
    if (!this.hasButtonTarget) {
      console.error('Button must be defined.')
      return
    }
    this.buttonTarget.classList.remove('d-none')
    this.buttonTarget.classList.remove('disabled')
    this.buttonTarget.disabled = false
  }
}
