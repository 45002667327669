import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { urlParamNames: String, targetParamName: String, path: String }

  declare urlParamNamesValue: string
  declare targetParamNameValue: string
  declare pathValue: string

  replace() {
    let url = new URL(`${window.location.origin}${this.pathValue}`)
    this.urlParamNamesValue.split(' ').forEach((paramName) => {
      let param = new URL(window.location.href).searchParams.get(paramName)

      if (param != null) {
        let targetParamName = this.targetParamNameValue + '[' + paramName + ']'
        url.searchParams.set(targetParamName, param)
      }
    })

    let pages_number = '0'
    if (document.querySelector('.pagination') !== null) {
      pages_number = document.querySelector('.pagination').childElementCount.toString()
    }
    url.searchParams.set('pages', pages_number)

    let target = this.element as HTMLAnchorElement
    target.href = `${window.location.origin}${url.pathname}?${url.searchParams.toString()}`
  }
}
