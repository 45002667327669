import StimulusFlatpickr from 'stimulus-flatpickr'

import { Norwegian } from 'flatpickr/dist/l10n/no.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import flatpickr from 'flatpickr'
import { i18n } from '../libraries/i18n'
import { DateOption } from 'flatpickr/dist/types/options'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class FlatpickrController extends StimulusFlatpickr {
  locales = {
    nb: Norwegian,
    en: english,
  }
  config: Object
  fp: flatpickr.Instance

  hasBeenDisconnected = false

  initialize() {
    super.initialize()
  }

  disconnect() {
    this.hasBeenDisconnected = true
  }

  connect() {
    // We avoid rerunning this controller if it has been disconnected.
    // One example where this happens is when we move an element with this controller in the DOM.
    // We then get a connect&disconnect&connect sequence, and this makes us lose our input value.
    // One place we use this is with the controller "move-element" which moves a modal to a different element
    // If you need to change this logic, you can make sure this still works by checking that
    // the dates in the edit employee modal are pre-populated
    if (this.hasBeenDisconnected) return

    this.config = {
      ...this.config, //spread options in case some where defined in initialize
      locale: this.locales[i18n.locale],
      // NB! Other default are set in date_picker_input.rb
      // The reason is that it makes it easier to set the default alt-format with I18n from the project
    }
    super.connect()

    // 2022-06-29 We don`t use the calendar icon so this part if commented out
    // After upgrading to Bootstrap 5 this probably doesnt work since input-group-append was removed
    // If we decide to add back the calendar icon we will probably need to fix this code

    const picker = this.fp
    // Make the calendar icon in the input field clickable
    const pickerButton = picker._input.parentNode.querySelector('.input-group-text')
    if (pickerButton) {
      pickerButton.addEventListener('click', function () {
        picker.open()
      })
    }

    const originalParseDate = picker.parseDate
    picker.parseDate = (date: DateOption, format?: string, timeless?: boolean): Date | undefined => {
      // flatpickr use d.m.Y or m/d/Y format for manual input depending on locale
      // Also, we need to ensure that the input does not include letters to prevent cases like 'today'.
      if (typeof date === 'string' && (format === 'd.m.Y' || format === 'm/d/Y') && !/[a-zA-Z]/.test(date)) {
        const locale: 'en' | 'nb' = format === 'd.m.Y' ? 'nb' : 'en'
        const parsedDate = this.parseDateStr(date, locale)
        return typeof parsedDate === 'string' ? originalParseDate(parsedDate, 'Y-m-d', timeless) : undefined
      }

      return originalParseDate(date, format, timeless)
    }
  }

  parseDateStr(date: string, locale: 'en' | 'nb'): string | undefined {
    date = date.replace(/[\s-]+/g, '') // Removes all whitespaces and hyphens
    const separator = ['.', '/'].find((s) => date.includes(s))
    const separatorCount = date.split('').filter((c) => c === separator).length
    let [date1, date2] = ['', '']
    const toFullYear = (y: string) => {
      return `${new Date().getFullYear().toString().slice(0, 2)}${y}`
    }
    const validateDates = (date1: string, date2: string): string | null => {
      if (Date.parse(date1) && Date.parse(date2)) {
        // return closest to current date
        const currentDate = new Date()
        const diff1 = Math.abs(Date.parse(date1) - currentDate.getTime())
        const diff2 = Math.abs(Date.parse(date2) - currentDate.getTime())
        return diff1 < diff2 ? date1 : date2
      } else if (Date.parse(date1)) {
        return date1
      } else if (Date.parse(date2)) {
        return date2
      } else {
        return undefined
      }
    }

    if (separatorCount === 0) {
      if (date.length === 4) {
        const [day1, month1] = [date.slice(0, 2), date.slice(2)]
        date1 = this.guessYear(month1) + '-' + month1 + '-' + day1
      } else if (date.length === 6) {
        const [year1, month1, day1] = [toFullYear(date.slice(0, 2)), date.slice(2, 4), date.slice(4)]
        const [day2, month2, year2] = [date.slice(0, 2), date.slice(2, 4), toFullYear(date.slice(4))]
        date1 = year1 + '-' + month1 + '-' + day1
        date2 = year2 + '-' + month2 + '-' + day2
      } else if (date.length === 8) {
        const [year1, month1, day1] = [date.slice(0, 4), date.slice(4, 6), date.slice(6)]
        const [day2, month2, year2] = [date.slice(0, 2), date.slice(2, 4), date.slice(4)]
        date1 = year1 + '-' + month1 + '-' + day1
        date2 = year2 + '-' + month2 + '-' + day2
      } else {
        return undefined
      }
    } else if (separatorCount === 1) {
      if (locale === 'nb') {
        const [day1, month1] = date.split(separator)
        date1 = this.guessYear(month1) + '-' + month1 + '-' + day1
      } else if (locale === 'en') {
        const [month1, day1] = date.split(separator)
        date1 = this.guessYear(month1) + '-' + month1 + '-' + day1
      }
    } else if (separatorCount === 2) {
      if (locale === 'nb') {
        let [day1, month1, year1] = date.split(separator)
        year1 = year1.length === 2 ? toFullYear(year1) : year1
        date1 = year1 + '-' + month1 + '-' + day1
      } else if (locale === 'en') {
        let [month1, day1, year1] = date.split(separator)
        year1 = year1.length === 2 ? toFullYear(year1) : year1
        date1 = year1 + '-' + month1 + '-' + day1
      }
    } else {
      return undefined
    }

    return validateDates(date1, date2)
  }

  guessYear(month: string | number): number {
    const currentMonth = new Date().getMonth() + 1

    // Check if the date this year is within one month of the current date
    if (Number(month) <= currentMonth + 1) {
      return new Date().getFullYear()
    }

    // If not, return the date for the same month of the previous year
    return new Date().getFullYear() - 1
  }
}
