<template>
  <div id="'unmatch-'id" class="col-6 d-grid gap-2">
    <button v-on:click="unMatch(matching)" class="btn btn-outline-secondary text-nowrap">
      <font-awesome-icon :icon="['far', 'unlink']" />
      {{ i18n().t('automatic_bank_reconciliation.v2.unmatch_items') }}
    </button>
  </div>
</template>
<script lang="ts">
import { mapActions } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'
import { Match } from '../module'
import type { PropType } from 'vue'

export default defineComponent({
  props: {
    matching: {
      type: Object as PropType<Match>,
      required: true,
    },
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, {
      unMatch: 'unMatch',
    }),
  },
})
</script>
