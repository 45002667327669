import FlatpickrController from './flatpickr_controller'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'

export default class extends FlatpickrController {
  initialize() {
    super.initialize()
    this.config = {
      altInput: true,
      allowInput: true,
      plugins: [
        monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y-m',
        }),
      ],
    }
  }
}
