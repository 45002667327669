import CheckboxSelectAll from './checkbox_select_all_controller'

export interface BulkActionParams {
  checkedRows: Array<HTMLTableRowElement>
  bulkActionSelector: HTMLSelectElement
}

export default class extends CheckboxSelectAll {
  static targets: string[] = ['actionRow', 'selectedCount', 'waitingSpinner', 'selector']

  declare actionRowTarget: HTMLInputElement
  declare hasActionRowTarget: HTMLInputElement
  declare selectedCountTarget: HTMLInputElement
  declare waitingSpinnerTarget: HTMLElement
  declare selectorTarget: HTMLSelectElement

  static values = { checkboxAction: String }

  declare checkboxActionValue: string
  declare hasCheckboxActionValue: boolean

  refresh(): void {
    super.refresh()
    this.updateOnCheckTarget()
  }

  toggle(e: Event): void {
    super.toggle(e)
    this.updateOnCheckTarget()
  }

  async handleCheckedOption(event) {
    if (!this.hasCheckboxActionValue) return

    const actions = JSON.parse(this.checkboxActionValue) as Array<string>
    for (const action of actions) {
      const [controllerName, actionName] = action.split('#')

      const controllerInstance = this.application.getControllerForElementAndIdentifier(
        this.element,
        controllerName,
      ) as any

      await controllerInstance[actionName](this.params())
    }
  }

  async submit(event: Event) {
    const [controllerName, actionName] = (event.target as HTMLOptionElement).value.split('#')

    const controllerInstance = this.application.getControllerForElementAndIdentifier(
      this.element,
      controllerName,
    ) as any

    this.displayWaitingSpinner(true)
    await controllerInstance[actionName](this.params())
    this.displayWaitingSpinner(false)
    this.resetActionSelector()
  }

  resetActionSelector() {
    this.actionRowTarget.querySelector('select').selectedIndex = 0
  }

  displayWaitingSpinner(display: boolean): void {
    display ? this.waitingSpinnerTarget.classList.remove('d-none') : this.waitingSpinnerTarget.classList.add('d-none')
  }

  params(): BulkActionParams {
    return {
      checkedRows: this.checked.map((input) => input.closest('tr') as HTMLTableRowElement),
      bulkActionSelector: this.selectorTarget,
    } as BulkActionParams
  }

  private updateOnCheckTarget() {
    if (!this.hasActionRowTarget) {
      return
    }
    const checkboxesCheckedCount = this.checked.length
    if (checkboxesCheckedCount >= 1) {
      this.actionRowTarget.style.visibility = 'visible'
      this.actionRowTarget.classList.remove('d-none')
      this.selectedCountTarget.textContent = checkboxesCheckedCount.toString()
    } else {
      this.actionRowTarget.style.visibility = 'hidden'
      this.actionRowTarget.classList.add('d-none')
    }
  }
}
