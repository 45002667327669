import { createApp, App } from 'vue'
import BankReconciliationApp from './components/App.vue'
import { parseISO } from 'date-fns'
import { createPinia } from 'pinia'
import { useBankReconciliationStore } from './store'

export default (data): App<Element> => {
  const pinia = createPinia()

  const app = createApp(BankReconciliationApp)
  app.use(pinia)

  const store = useBankReconciliationStore()
  store.postings = []
  data.postings.forEach((posting) => {
    posting.postingDate = parseISO(posting.postingDate)
    store.postings.push(posting)
  })
  store.bankStatementItems = []
  data.bankTransactions.forEach((bankTransaction) => {
    bankTransaction.transactionOn = parseISO(bankTransaction.transactionOn)
    store.bankStatementItems.push(bankTransaction)
  })
  store.bankReconciliation = data.bankReconciliation
  store.showReconnectIntegrationNotification = data.showReconnectIntegrationNotification
  store.reconnectIntegrationLink = data.reconnectIntegrationLink
  store.bankReconciliation.beginningOn = parseISO(data.bankReconciliation.beginningOn)
  store.bankReconciliation.endingOn = parseISO(data.bankReconciliation.endingOn)
  store.bankReconciliation.finalizedTransactionsOn = parseISO(data.bankReconciliation.finalizedTransactionsOn)
  store.showDataInProgressAlert = data.showDataInProgressAlert

  store.selectedPostingId = data.selectedPostingId

  store.matchedItems = []
  data.matchedItems.forEach((item) => {
    item.postings.forEach((posting) => {
      posting.postingDate = parseISO(posting.postingDate)
    })
    item.bankTransactions.forEach((bankTransaction) => {
      bankTransaction.transactionOn = parseISO(bankTransaction.transactionOn)
    })
    store.matchedItems.push(item)
  })
  store.suggestedMatchings = data.suggestedMatchings

  return app
}
