import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import DateLimit = flatpickr.Options.DateLimit
import FlatpickrController from 'controllers/flatpickr_controller'

export default class PaymentDatePickerController extends Controller {
  static values = { holidayDates: Array }

  declare holidayDatesValue: DateLimit[]

  invalidDatesForExecution: DateLimit[]

  connect() {
    this.invalidDatesForExecution = this.holidayDatesValue.concat((date: Date) => {
      return date.getDay() === 6 || date.getDay() === 0
    })
  }

  setPaymentType(paymentType: string) {
    if (paymentType === 'register_payment_later') {
      return
    }

    const selectedDate = (this.element as HTMLInputElement).value
    const flatpickrController = this.application.getControllerForElementAndIdentifier(this.element, 'flatpickr') as any

    if (flatpickrController === null) {
      return
    }

    switch (paymentType) {
      case 'execute':
      case 'initiated':
        flatpickrController.fp.set('minDate', this.executePaymentMinDate())
        flatpickrController.fp.set('disable', this.invalidDatesForExecution)
        break
      case 'paid_in_bank':
        flatpickrController.fp.set('minDate', null)
        flatpickrController.fp.set('disable', [])
        break
      default:
        throw new Error(`Payment type ${paymentType} unknown`)
    }

    this.selectNearestAvailablePaymentDate(selectedDate, flatpickrController)
  }

  private selectNearestAvailablePaymentDate(selectedDate: string, flatpickrController: FlatpickrController) {
    if (selectedDate == '') {
      return
    }

    let date = flatpickr.parseDate(selectedDate, 'Y-m-d')
    flatpickrController.fp.setDate(date)

    let availableDates = flatpickrController.fp.selectedDates
    while (availableDates.length === 0) {
      date.setDate(date.getDate() + 1)
      flatpickrController.fp.setDate(date)
      availableDates = flatpickrController.fp.selectedDates
    }
  }

  private executePaymentMinDate(): string {
    const last_executable_hour = 14
    const last_executable_minute = 15

    let today = new Date()
    if (today.getHours() > last_executable_hour && today.getMinutes() > last_executable_minute) {
      let tomorrow = new Date()
      tomorrow.setDate(today.getDate() + 1)
      return tomorrow.toISOString().slice(0, 10)
    } else {
      return today.toISOString().slice(0, 10)
    }
  }
}
