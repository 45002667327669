import Select2Builder from '../standalone/components/Select2Builder'
import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { formatISO, isWithinInterval, parseISO, isSameDay } from 'date-fns'
import { rails_fetch } from '../util/rails_fetch'

const DEFAULT_PURCHASE_TYPE = 'domestic_purchase'
const DEFAULT_ALLOWED_VAT_CODES = ''

// The controller expects that window.vatCodesByPurchaseType is set and follows this structure:
//
//   window.vatCodesByPurchaseType = {
//     date: "2022-01-01",
//     rates: {
//       domestic_purchase: [0, 1, 2],
//       international_goods_purchase: [3, 4, 5],
//       international_services_purchase: [6, 7, 8],
//       loan: [9, 10],
//     }
//   }
export default class extends Controller {
  static values = { postingDateBeforeVatPeriodWarning: String }

  declare postingDateBeforeVatPeriodWarningValue: string
  declare hasPostingDateBeforeVatPeriodWarningValue: boolean

  $element
  _date
  _allowedVatCodes
  _purchaseType

  connect() {
    this.$element = $(this.element)

    // Notice .data() evals values into JavaScript objects but dataset always returns strings.
    this._date = parseISO(this.$element.data('date')) || new Date()
    this._purchaseType = this.getCurrentPurchaseType()
    let elem = this.element as HTMLElement
    this._allowedVatCodes = (elem.dataset.allowedVatCodes || DEFAULT_ALLOWED_VAT_CODES).split(' ')

    const params = new Select2Builder().createStaticParams({ minimumInputLength: 0 })
    this.$element.select2(params)

    this._updateOptions(elem.dataset.selected || null)
  }

  async changeDate(date: Date) {
    this._date = date
    await this._updateOptions()
  }

  getCurrentPurchaseType() {
    let elem = document.querySelector('#supplier_invoice_purchase_type') as HTMLInputElement
    return elem.value || DEFAULT_PURCHASE_TYPE
  }

  async changePurchaseType(purchaseType) {
    this._purchaseType = purchaseType
    await this._updateOptions()
  }

  async changeAllowedVatCodes(allowedVatCodes, defaultVatCode = null) {
    this._allowedVatCodes = allowedVatCodes.map((code) => code.toString())
    await this._updateOptions(defaultVatCode)
  }

  async _updateOptions(overrideSelectedCode = null) {
    const currentlySelectedCode = overrideSelectedCode || this.$element.val()
    const availableVatRegistrations = (window as any).vatRegistrations || []
    let date = this._date
    if (date === null || isNaN(date.getFullYear())) {
      date = new Date()
    }

    let inVatRegistrationPeriod = false
    let isDateBeforeFirstVatPeriod = true
    availableVatRegistrations.forEach((period) => {
      if (!inVatRegistrationPeriod) {
        inVatRegistrationPeriod = isWithinInterval(date, { start: parseISO(period[0]), end: parseISO(period[1]) })
      }
      if (isDateBeforeFirstVatPeriod) {
        isDateBeforeFirstVatPeriod = date < parseISO(period[0])
      }
    })
    if (
      !(window as any).vatCodesByPurchaseType ||
      !isSameDay(date, parseISO((window as any).vatCodesByPurchaseType['date']))
    ) {
      let response = await rails_fetch(
        `/${(window as any).current_company_id}/vat/rates?inbound=true&date=${formatISO(date)}`,
      )
      // We might have multiple vat-rate-controllers on the same page so we store the result globally to share
      ;(window as any).vatCodesByPurchaseType = await response.json()
    }

    const availableVatOptions = (window as any).vatCodesByPurchaseType['rates'][this._purchaseType].filter(
      ([_label, code]) => {
        if (inVatRegistrationPeriod) {
          this.showPostingDateBeforeVatPeriodWarning(false)
          return this._allowedVatCodes.includes(code)
        } else {
          // We use code 21 for international goods purchases and code 0 for everything else
          this.showPostingDateBeforeVatPeriodWarning(isDateBeforeFirstVatPeriod)
          return code === '0' || code === '21'
        }
      },
    )
    this.$element.empty()

    availableVatOptions.forEach(([label, code, rate]) => {
      const option = new Option(label, code, false, false)
      option.setAttribute('rate', rate)
      this.$element.append(option)
    })

    if (
      currentlySelectedCode &&
      availableVatOptions.some(([_label, code]) => currentlySelectedCode.toString() === code.toString())
    ) {
      this.$element.val(currentlySelectedCode).change()
    } else if (availableVatOptions.length > 0) {
      // Take the first VAT rate (index 0) and pick it's code (index 1).
      this.$element.val(availableVatOptions[0][1]).change()
    }
  }

  showPostingDateBeforeVatPeriodWarning(show: boolean) {
    let hintElement = this.element.parentElement.querySelector('.form-text')
    if (!this.hasPostingDateBeforeVatPeriodWarningValue || !hintElement) return

    if (show) {
      hintElement.innerHTML = this.postingDateBeforeVatPeriodWarningValue
      hintElement.classList.remove('d-none')
    } else {
      hintElement.classList.add('d-none')
    }
  }
}
