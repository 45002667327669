import CrudTableController from '../../crud_table_controller'

export default class extends CrudTableController {
  create(html: string): void {
    this.hideModal()
    window.location.reload()
  }

  update(hashid: string, html: string): void {
    window.location.reload() // We just reload page as other board members might have changed role.
  }

  destroy(hashid: string): void {
    window.location.reload() // We just reload page as other board members might have changed role.
  }
}
