import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['inputs', 'modal']

  declare inputsTarget: HTMLInputElement
  declare modalTarget: HTMLInputElement

  connect() {
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('pension_report_amount_cents') !== null) {
      Modal.getOrCreateInstance(this.modalTarget).show()
      Turbo.navigator.history.push(location.pathname)
    }
  }

  submit(event: Event): void {
    event.preventDefault()
    this._copySelectedInputsToAltinnForm()
    Modal.getOrCreateInstance(this.element).hide()
    Modal.getOrCreateInstance(document.querySelector('#altinn-login-altinn-modal')).show()
  }

  _copySelectedInputsToAltinnForm() {
    const pinForm = document.getElementById('pin_form')
    let inputDiv = pinForm.querySelector('.hidden-inputs')
    if (inputDiv === null) {
      inputDiv = document.createElement('div')
      inputDiv.classList.add('hidden-inputs')
      pinForm.appendChild(inputDiv)
    } else {
      // reset in case it has been used earlier
      inputDiv.innerHTML = ''
    }

    const inputs = this.inputsTarget.querySelectorAll('input')

    for (let i = 0; i < inputs.length; i++) {
      const name = inputs[i].name
      if (name.trim().length > 0) {
        let hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = inputs[i].name
        hiddenInput.value = inputs[i].value
        inputDiv.appendChild(hiddenInput)
      }
    }
  }
}
