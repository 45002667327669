<template>
  <div
    v-show="showMatchButton"
    style="min-width: 30%; position: absolute; margin: auto; left: 0; right: 0"
    v-bind:style="topPosition"
  >
    <div class="d-flex justify-content-center" id="bank-statement-match-card">
      <button v-if="sumEqual" v-on:click="matchClick" class="btn btn-primary">
        <font-awesome-icon :icon="matchIcon" />
        {{ i18n().t('automatic_bank_reconciliation.v2.match_items') }}
      </button>
      <button v-if="!sumEqual" class="btn btn-primary" disabled>
        <font-awesome-icon :icon="['far', 'exclamation-triangle']" />
        {{ i18n().t('automatic_bank_reconciliation.v2.cant_match') }}
      </button>
    </div>
    <div v-if="!sumEqual" class="mt-4">
      <div class="text-center text-danger">{{ difference }}</div>
      <div class="text-center text-uppercase">
        <strong>{{ i18n().t('automatic_bank_reconciliation.v2.difference') }}</strong>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { useBankReconciliationStore } from '../store'
import { getAbsoluteBoundingRect, unionBoundingRectFromObject } from '../GeometricFunctions'
import { Popover } from 'bootstrap'
import { amountStr } from '../../../../controllers/formatting_helper'
import { i18n } from '../../../../libraries/i18n'
import { defineComponent } from 'vue'

function boundingTopAndHeight(boundingRect, matchRect) {
  let bottom = Math.max(boundingRect.top + boundingRect.height, matchRect.top + matchRect.height)
  let top = Math.min(boundingRect.top, matchRect.top)
  return {
    top: top,
    height: bottom - top,
  }
}

function findMidPosition(elem, transactions, statements) {
  if (Object.keys(transactions).length === 0 || Object.keys(statements).length === 0) {
    return {}
  }

  const boundingRectTransactions = unionBoundingRectFromObject(transactions)
  const transMid = boundingRectTransactions.top + boundingRectTransactions.height / 2
  const boundingRectStatements = unionBoundingRectFromObject(statements)
  const statementMid = boundingRectStatements.top + boundingRectStatements.height / 2
  const parentTop = getAbsoluteBoundingRect(elem.parentElement).top

  const midDiff = Math.abs(transMid - statementMid)

  return Math.min(transMid, statementMid) + midDiff / 2 - parentTop
}

export default defineComponent({
  computed: {
    ...mapState(useBankReconciliationStore, [
      'showMatchButton',
      'selectedBankTransactions',
      'selectedPostingSum',
      'selectedBankTransactionSum',
      'selectedBankTransactionCards',
      'selectedPostingCards',
    ]),
    sumEqual() {
      return this.selectedPostingSum === this.selectedBankTransactionSum
    },
    cardClass() {
      return this.sumEqual ? 'bank-reconciliation__match-card--match' : 'bank-reconciliation__match-card--not-match'
    },
    matchIcon() {
      return this.selectedBankTransactions.length > 0 ? ['far', 'arrows-h'] : ['far', 'arrows-v']
    },
    difference() {
      return amountStr((this.selectedPostingSum - this.selectedBankTransactionSum) / 100.0)
    },
    topPosition() {
      return `top: ${findMidPosition(this.$el, this.selectedBankTransactionCards, this.selectedPostingCards)}px`
    },
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(useBankReconciliationStore, {
      match: 'match',
    }),

    matchClick(event) {
      // Hide toggleable popovers
      Array.from(document.getElementsByClassName('toggleable-popover')).forEach((el) => {
        Popover.getOrCreateInstance(el).hide()
      })

      this.match()
    },
  },
})
</script>
