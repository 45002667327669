import { App, createApp } from 'vue'
import AccountSelectorApp from './components/App.vue'
import { i18n } from '../../libraries/i18n'

export default (data): App<Element> => {
  let showSwitch = true
  let favorite = true
  let placeholder = null

  if (data.showSwitch !== undefined) {
    showSwitch = data.showSwitch
    favorite = false
  }
  if (data.attributes['data-placeholder'] !== undefined) {
    placeholder = data.attributes['data-placeholder']
  } else {
    placeholder = i18n.t('account.new_search_placeholder')
  }

  return createApp(AccountSelectorApp, {
    attributes: data.attributes,
    initialSelectedAccount: { id: data.selectedAccount.id, name: data.selectedAccount.text },
    filters: {
      onlyDepreciationAccounts: data.onlyDepreciationAccounts,
      onlyBalanceAccounts: data.onlyBalanceAccounts,
      onlyCostAccounts: data.onlyCostAccounts,
      onlyWithVatCodes: data.onlyWithVatCodes,
      onlyAccrual: data.onlyAccrual,
    },
    showSwitch: showSwitch,
    initialFavorite: favorite,
    placeholder: placeholder,
  })
}
