import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import { DEFAULT_STATIC_PARMS } from '../../standalone/components/Select2Builder'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [
    'companyName',
    'municipality',
    'taxZones',
    'disbursementBankAccountSelect',
    'taxBankAccountSelect',
    'salaryPensionProviderSelect',
    'roadFreightTransport',
  ]

  declare companyNameTarget: HTMLInputElement
  declare municipalityTarget: HTMLInputElement
  declare taxZonesTarget: HTMLSelectElement
  declare hasCompanyNameTarget: Boolean
  declare disbursementBankAccountSelectTarget: HTMLSelectElement
  declare taxBankAccountSelectTarget: HTMLSelectElement
  declare salaryPensionProviderSelectTarget: HTMLSelectElement
  declare roadFreightTransportTarget: HTMLInputElement
  declare hasTaxZonesTarget: boolean
  declare hasSalaryPensionProviderSelectTarget: boolean

  initialize() {
    this.handleZoneSelect()
    if (this.hasSalaryPensionProviderSelectTarget) {
      let select2Element = $(this.salaryPensionProviderSelectTarget)
      select2Element.select2({
        ...DEFAULT_STATIC_PARMS,
        minimumInputLength: 0,
      })
    }
  }

  submitForm(event) {
    if (this.hasCompanyNameTarget && this.companyNameTarget.value !== this.companyNameTarget.dataset.name) {
      Modal.getOrCreateInstance(document.querySelector('#change_company_name_modal')).show()
      return event.preventDefault()
    }
  }

  findAvailableTaxZones() {
    if (this.municipalityTarget.value === null || this.municipalityTarget.value === '') {
      return
    }
    $.ajax({
      type: 'GET',
      data: {
        name: this.municipalityTarget.value,
      },
      url: `/norwegian_municipalities/search.json`,
      dataType: 'json',
      success: (result) => {
        if (result.tax_rates !== null) {
          console.log(result.tax_rates)
          this.taxZonesTarget.innerText = null
          for (let i in result.tax_rates) {
            console.log(result.tax_rates[i][0])
            this.taxZonesTarget.append(new Option(result.tax_rates[i][0], result.tax_rates[i][1], false, false))
          }
          this.handleZoneSelect()
        }
      },
    })
  }

  handleZoneSelect() {
    if (this.hasTaxZonesTarget) {
      if (this.taxZonesTarget.options[this.taxZonesTarget.selectedIndex].value === '1a') {
        this.roadFreightTransportTarget.classList.remove('d-none')
      } else {
        this.roadFreightTransportTarget.classList.add('d-none')
      }
    }
  }

  handleBankAccountCreate(event) {
    const { model } = event
    const option = new Option(`${model.name}  - ${model.number}`, model.id, true, true)
    switch (model.account_type) {
      case 'standard':
        this.disbursementBankAccountSelectTarget.append(option)
        break
      case 'tax_deduction':
        this.taxBankAccountSelectTarget.append(option)
        break
    }
  }
}
