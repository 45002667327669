import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['searchInput', 'progressBar', 'warning', 'form', 'okButton']

  declare searchInputTarget: HTMLInputElement
  declare progressBarTarget: HTMLElement
  declare warningTarget: HTMLElement
  declare formTarget: HTMLElement
  declare okButtonTarget: HTMLButtonElement

  timeout: NodeJS.Timeout

  connect() {
    this.okButtonTarget.classList.add('d-none')

    $(this.element).on('shown.bs.modal', () => {
      this._dispatch('search-modal:open')
      let badge = this.searchInputTarget.nextSibling.firstChild as HTMLElement
      badge.style.right = `${badge.getBoundingClientRect().width}px`

      this.resetModal()
    })

    $(this.searchInputTarget).on('keyup', (event) => {
      if (this.searchInputTarget.value === '') {
        return
      }

      clearTimeout(this.timeout)
      setTimeout(() => {
        this.searchCustomer()
      }, 250)
    })
  }

  resetModal() {
    this.okButtonTarget.classList.add('d-none')

    this.searchInputTarget.value = ''

    this.warningTarget.classList.add('d-none')
    this.formTarget.classList.add('d-none')
    this.progressBarTarget.classList.add('d-none')
  }

  searchCustomer() {
    this.okButtonTarget.classList.add('d-none')
    this.warningTarget.classList.add('d-none')
    this.formTarget.classList.add('d-none')
    this.progressBarTarget.classList.remove('d-none')

    let xhr
    ;((term, response, self) => {
      // Abort if the last call did not finish before new call
      try {
        xhr.abort()
      } catch (e) {}
      xhr = $.getJSON(
        `/${(window as any).current_company_id}/partners/search.json`,
        { term: term, type: 'customer', corporation: false },
        function (data) {
          response.call(self, data)
        },
      )
    })(this.searchInputTarget.value, this.handleResult, this)
  }

  handleResult(data) {
    this.progressBarTarget.classList.add('d-none')

    if (data[0] === undefined) {
      this.okButtonTarget.classList.add('d-none')
      this.warningTarget.classList.remove('d-none')
      this.formTarget.classList.add('d-none')
    } else {
      this.okButtonTarget.classList.remove('d-none')
      this.formTarget.classList.remove('d-none')
      this.warningTarget.classList.add('d-none')
      this._dispatch('partner--search:change', { partner: data[0] })
    }
  }

  _dispatch(eventName, params = {}) {
    const cancelEvent = document.createEvent('Event')
    cancelEvent.initEvent(eventName, true, true)
    Object.assign(cancelEvent, params)
    this.element.dispatchEvent(cancelEvent)
  }
}
