import { Controller } from '@hotwired/stimulus'
import { BulkActionParams } from '../bulk_action_table_controller'
import { rails_fetch } from '../../util/rails_fetch'

export default class extends Controller {
  async approve(params: BulkActionParams) {
    const checkedRows = params.checkedRows
    const hashIds = checkedRows
      .map((row) => row.querySelector('div[payment-hashid]')?.getAttribute('payment-hashid'))
      .filter(Boolean)

    const url = `/${(window as any).current_company_id}/payments/approve`

    const data = {
      payment_id: hashIds,
    }

    await rails_fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.text())
      .then((data) => {
        eval(data)
      })
  }
}
