import { Controller } from '@hotwired/stimulus'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faSortUp, faSortDown } from 'fontawesome-luca/pro-regular-svg-icons'

export default class extends Controller {
  static targets = ['table']

  declare tableTarget: HTMLFormElement

  updateHeader(event) {
    const th = event.currentTarget
    const isAscending = th.classList.toggle('asc')

    // Remove any existing icons
    th.querySelectorAll('.fa-sort-up, .fa-sort-down').forEach((icon) => icon.remove())

    th.appendChild(icon(isAscending ? faSortDown : faSortUp).node[0])
  }

  sort(event) {
    event.preventDefault()

    const table = this.tableTarget
    const th = event.currentTarget.closest('th')
    const direction = th.dataset.direction || 'asc'
    const index = Array.prototype.indexOf.call(th.parentNode.children, th)
    const rows = Array.from(table.tBodies[0].querySelectorAll('tr:not(.total):not(.total-in-out)'))
    const totalRows = Array.from(table.tBodies[0].querySelectorAll('tr.total, tr.total-in-out'))

    this.updateHeader(event)

    const sortedRows = rows.sort((a: HTMLTableRowElement, b: HTMLTableRowElement) => {
      const aCell = a.cells[index]
      const bCell = b.cells[index]

      let aValue = parseFloat(aCell.textContent.trim().replace(/[, ]/g, ''))
      let bValue = parseFloat(bCell.textContent.trim().replace(/[, ]/g, ''))

      if (isNaN(aValue)) aValue = 0
      if (isNaN(bValue)) bValue = 0

      if (direction === 'asc') {
        return aValue - bValue
      } else {
        return bValue - aValue
      }
    })

    table.tBodies[0].append(...sortedRows)
    table.tBodies[0].append(...totalRows)

    th.dataset.direction = direction === 'asc' ? 'desc' : 'asc'
  }
}
